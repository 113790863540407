import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import MyButton from "../basicUI/MyButton";
import { withTranslation } from 'react-i18next';

@inject(
  "mapListStore",
  "navigationStore",
  "sessionStore",
  "userStore",
  "requestStore"
)
@observer
class GroupCard extends Component {
  async componentDidMount() {
  }

  componentWillUnmount() {}

  render() {
    console.log(this.props.userStore.user.productsExtra.compliance)
    const {t} = this.props;
    if (this.props.sessionStore.initialized) {
      return (
        <div key={this.props.ad._id + this.props.index} className="wa-ad-list">
          <div
            key={this.props.ad._id + this.props.index}
            className="second-child-div-content"
          >
            <MyButton
              className="btn btn-default goAd"
              toolPlace = "right"
              name={t("wa.tooltips.gotoAd")}
              onClick={() => this.props.navigationStore.openInNewTab(this.props.ad.url)}
              text={
                <>
                  <img
                    className="ad-platform-logo"
                    src={"/logos/" + this.props.ad.website.toLowerCase() + ".png"}
                    alt={this.props.ad.website}
                  />
                </>
              }
            />
            <p className="ad-info-message">
            <svg
              className={
                "svg-icon-message" +
                (!!this.props.ad.ownerContacted
                  ? " messActive"
                  : " messDisable")
              }
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 63.71 47.79">
                <path 
                  className="cls-1" 
                  d="M8,43.71V35.78c0-1.12,0-2.25,0-3.37,0-.57.31-.92.77-.91a1.53,1.53,0,0,1,.65.29c.83.68,1.68,1.34,2.56,2s1.8,1.39,2.74,2a10.5,10.5,0,0,0,1.26.93q1.91,1.46,3.87,2.83a.75.75,0,0,0,.32.23c1.22.94,2.48,1.84,3.74,2.73l1.74,1.27,2.26,1.64c1.41,1.07,2.8,2.19,4.26,3.2a20.25,20.25,0,0,0,3.67,2.12,8.77,8.77,0,0,0,4,.94,9.45,9.45,0,0,0,4.11-1,21.06,21.06,0,0,0,3.83-2.26,7.59,7.59,0,0,0,1-.73l3-2.29c.8-.55,1.59-1.11,2.35-1.71.57-.37,1.11-.77,1.65-1.19,1.23-.87,2.45-1.74,3.64-2.66l.36-.25c1.32-.95,2.64-1.9,3.92-2.9A10.5,10.5,0,0,0,65,35.79c1-.67,1.91-1.38,2.83-2.11.82-.57,1.61-1.18,2.39-1.81l.1-.05a.82.82,0,0,1,1-.23.94.94,0,0,1,.4.94c0,1.09,0,2.17,0,3.25V55.71c-.06.83,0,1.66-.06,2.49a6,6,0,0,1-5.16,5.37c-.93.11-1.86,0-2.79.07H14.05a6.15,6.15,0,0,1-2.53-.54,6,6,0,0,1-3.17-3.46l-.26-1C8,57.65,8,56.67,8,55.71v-12Z" 
                  transform="translate(-8 -15.85)"/>
                <path 
                  className="cls-1" 
                  d="M59.78,15.85h3.93c.85.05,1.71,0,2.57.05a6,6,0,0,1,5.37,5.37c.07.86,0,1.72.06,2.58l-.07.95a3.51,3.51,0,0,1-1.5,2.08c-.42.29-.81.64-1.21,1l-1.31,1-3.77,2.86-.15.11q-2,1.41-3.93,2.9a16.67,16.67,0,0,0-1.44,1.06c-.87.59-1.72,1.21-2.56,1.86-1,.68-2,1.39-2.92,2.12a9.34,9.34,0,0,0-1.08.79l-.27.17c-2.59,1.8-5,3.86-7.65,5.55-.54.3-1.08.61-1.65.87s-1.06.35-1.59.55l-.76,0-1-.09a12.61,12.61,0,0,1-3-1.36l-1-.65c-1.72-1.14-3.28-2.48-5-3.67a20.11,20.11,0,0,0-1.95-1.37,7.47,7.47,0,0,0-1.16-.87c-.93-.72-1.87-1.4-2.83-2.06s-1.69-1.28-2.57-1.86a14.91,14.91,0,0,0-1.44-1.07L16,31.81a.05.05,0,0,0-.05,0l-.74-.57L12,28.8l-1.22-.95c-.41-.32-.8-.67-1.22-1A3.76,3.76,0,0,1,8.08,25L8,23.86c.06-.85,0-1.69.06-2.54a6,6,0,0,1,5.37-5.4c.86-.07,1.72,0,2.58-.06H59.78Z" 
                  transform="translate(-8 -15.85)"/>
            </svg>
            </p>
            <MyButton
              id={this.props.ad._id}
              onClick={() => {
                this.props.navigationStore.openInNewTab(
                  "/groupAds/" + this.props.ga
                );
              }}
              className="dropdown"
              onMouseEnter={e => {
                this.props.mapListStore.changeCircleColor(this.props.ad);
              }}
              onMouseLeave={e => {
                this.props.mapListStore.switchCircleColor("repaint");
              }}
              text={
                <>
                  <p className="ad-info-id">
                    {this.props.ad._id.substring(0, 14)}
                    {this.props.ad._id.length > 14 ? "..." : null}
                  </p>
                  <p className="ad-info-license-code">
                    {this.props.ad.license && this.props.ad.license.code ? (
                      <>
                        {this.props.ad.license.code.substring(0, 14)}
                        {this.props.ad.license.code.length > 14 ? "..." : null}
                      </>
                    ) : (
                      "-"
                    )}
                  </p>
                  <p className="ad-info-license-status">
                    {
                      this.props.userStore.user.productsExtra.compliance ?
                        this.props.ad.license && this.props.ad.license.compliance_status?
                          this.props.ad.license.compliance_status === "exc" || this.props.ad.license.compliance_status === "Correcto" ? t("compliance.exc") :
                          this.props.ad.license.compliance_status === "hig" || this.props.ad.license.compliance_status === "Leve" ? t("compliance.hig") : 
                          this.props.ad.license.compliance_status === "mid" ? t("compliance.mid"): 
                          this.props.ad.license.compliance_status === "low" || this.props.ad.license.compliance_status === "Posible Fraude" ? t("compliance.low"): 
                          this.props.ad.license.compliance_status === "def" || this.props.ad.license.compliance_status === "Fraude" ? t("compliance.def") : "-"
                          : "-"
                      : this.props.ad.license ?
                        this.props.ad.license.status === "noff"
                      ? t("wa.noOfficial")
                      : this.props.ad.license.status === "offi"
                      ? t("wa.official")
                      : this.props.ad.license.status === "bfor"
                      ? t("wa.badFormat")
                      : this.props.ad.license.status === "noli"
                      ? t("wa.noLicense")
                      : "-"
                      :"-"
                      
                    }
                  </p>
                  <p className="ad-info-roomtype">
                    {this.props.ad.roomType ? this.props.ad.roomType.substring(0, 12) : '-'}
                    {this.props.ad.roomType && this.props.ad.roomType.length > 12 ? "..." : null}
                  </p>
                  <p className="ad-info-occupancy">
                    {this.props.ad.occupancy ? this.props.ad.occupancy.numCurrentYearReservations : '-'}
                  </p>
                  <p className="ad-info-city">
                    {this.props.ad.city ? this.props.ad.city.substring(0, 12) : '-'}
                    {this.props.ad.city && this.props.ad.city.length > 12 ? "..." : null}
                  </p>
                 
                  
                </>
              }
            />
          </div>
        </div>
      );
    }
  }
}

export default withTranslation()(GroupCard);