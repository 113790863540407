import React from "react";
import { Redirect, Route } from "react-router-dom";
import { observer, inject } from "mobx-react";

const PrivateRoute = inject("sessionStore")(
  observer(({ sessionStore, component: Component, ...rest }) => {
    if (sessionStore.initialized) {
      return (
        <Route
          {...rest}
          render={props =>
            sessionStore.hasSession ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{ pathname: "/login", state: { from: props.location } }}
              />
            )
          }
        />
      );
    }
  })
);

export default PrivateRoute;
