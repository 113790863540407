import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import MyButton from "../basicUI/MyButton";
import MyInput from "../basicUI/MyInput";
import MyTextArea from "../basicUI/MyTextArea";
import { withTranslation } from 'react-i18next';

@inject("contactStore", "requestStore")
@observer
class ContactForm extends Component {
  async componentDidMount() {
    try {
      let { t } = this.props;
      document.title = t("contact.title");
      //this.props.requestStore.getDashboardData();
      await this.props.contactStore.getUser()
      this.props.contactStore.showError = false;
      await this.props.contactStore.createEditor();
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  componentWillUnmount() {
    this.props.contactStore.updateProperty("showError", false);
    this.props.contactStore.updateProperty("formSent", false);
    this.props.contactStore.updateProperty("isLoading", false);
    this.props.contactStore.updateProperty("emailConfirmed", false);
    this.props.contactStore.updateProperty('showAlert', false)
    this.props.contactStore.updateProperty('confirmAlert', false)
  }

  render() {
    const { t } = this.props;
    if (this.props.contactStore.isLoading) {
      return <div>{t("processing")}</div>;
    }
    return (
      <>
      <main role="main" className="container-fluid contact-page">
          <div className={"alert alert-danger" + (!!this.props.contactStore.showAlert ? " alert-custom show" : " alert-custom hide")} id="danger-alert-contact">
            <p>{t("contact.sendingError")}</p>
            <button type="button" className="close" data-dismiss="alert">x</button>
          </div>

          <div className={"alert alert-success" + (!!this.props.contactStore.confirmAlert ? " alert-custom show" : " alert-custom hide")} id="succes-alert-contact">
            <p>{t("contact.sent")}</p>
            <button type="button" className="close" data-dismiss="alert">x</button>
          </div>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              
              <div className="row">
                <h4 className="contact-title">{t("contact.form")}</h4>
                
              </div>
              <hr />
              <div>
                {/* {this.props.contactStore.emailConfirmed && (
                  <p className="valid">
                    {" "}
                    El missatge ha sigut enviat. El revisarem i ens posarem en
                    contacte amb vostè si és necessari.{" "}
                  </p>
                )} */}
                {/* {this.props.contactStore.showError && (
                  <p className="invalid">
                    {" "}
                    El missatge no s'ha pogut enviar. Siusplau, intenti-ho més
                    tard.{" "}
                  </p>
                )} */}
                {!this.props.contactStore.hasTitle &&
                  this.props.contactStore.formSent && (
                    <p className="invalid">
                      {t("contact.subjectInput")}<b style={{ color: "red" }}>{t("contact.subject")}</b>
                    </p>
                  )}
                {!this.props.contactStore.hasDescription &&
                  this.props.contactStore.formSent && (
                    <p className="invalid">
                      {t("contact.descriptionInput")}<b style={{ color: "red" }}>{t("contact.description")}</b>
                    </p>
                  )}
              </div>
              <form className="contact-form">
                <div className="form-group text-contact">
                  <MyInput
                    id="subject-contact"
                    visible="false"
                    type="text"
                    label="Title"
                    name="title"
                    className="form-control input-contact subject-contact"
                    placeholder={t("contact.emailSubject")}
                    value={this.props.contactStore.contactForm.title}
                    onChange={this.props.contactStore.updateFormProperty}
                  />
                </div>
                <div className="form-group text-contact">
                  <MyTextArea
                    id="info-contact"
                    label="Description"
                    name="description"
                    className="form-control input-contact info-contact"
                    placeholder={t("contact.detail")}
                    rows="10"
                    onChange={this.props.contactStore.updateFormProperty}
                  />
                </div>
                <MyButton
                  type="button"
                  className="btn btn-info submit-button"
                  text={t("contact.send")}
                  onClick={this.props.contactStore.sendContactEmail}
                />
              </form>
            </div>
            <div className="col-md-3"></div>
          </div>
        </main>
      </>
    );
  }
}

export default withTranslation()(ContactForm);
