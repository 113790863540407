import React, { Component } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import MyCheckbox from "./MyCheckbox";
//import { convertTypeAcquisitionFromJson } from "typescript";


@observer
class MyCheckboxList extends Component {

  render() {
    return (
      <div className="form-group">
        <label htmlFor={this.props.id}>{this.props.label}</label>
        {this.props.selectOptions.map(selectopt => (
          <MyCheckbox
            key={selectopt.key}
            id={selectopt.key}
            name={this.props.name}
            onChange={this.props.onChange}
            value={this.props.selectedOptions.indexOf(selectopt.key) > -1}
            labelValue={selectopt.value}
            defaultChecked={this.props.selectedOptions.includes(selectopt.key)}
            tooltipA = {this.props.tooltipA ===  "true"? "true" : "false"}
            tooltipName = {this.props.tooltipA === "true"? selectopt.name : "null"}
            toolPlace = {this.props.toolPlace}
          />
        ))}
      </div>
    );
  }
}

MyCheckboxList.propTypes = {
  selectedOptions: PropTypes.array.isRequired
};

export default MyCheckboxList;
