import React, { Component, Suspense } from "react";
import { Route } from "react-router-dom";
import { Router } from "react-router";
import { Provider, observer } from "mobx-react";
import SessionStore from "mobx-session";
import RootStore from "../stores/RootStore";
import UserProfile from "./UserProfile/UserProfile";
import MapDiv from "../components/Map/MapDiv";
import Navbar from "./Navbar";
import Login from "./Login";
import ContactForm from "./Contact/ContactForm";
//import ProcessList from "../components/Process/ProcessList";
import GenericList from "./GenericList/GenericList";
import TeamForm from "./Team/TeamForm";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
//import RegionSelector from "./RegionSelector/RegionSelector";
import GenericStatistics from "./Statistics/StatisticsGeneral";

import GroupAds from "../components/GroupAds/GroupAds";
import GroupEditor from "../components/GroupEditor/GroupEditor";
import WorkingRegionEdit from "../components/WorkingRegionEdit/WorkingRegionEdit";
import Activity from "../components/Activity/Activity";

import PrivateRoute from "./PrivateRoute";
import SupervisorRoute from "./SupervisorRoute";
import SuperuserRoute from "./SuperuserRoute";

import "../App.css";
import "../assets/css/custom.css";
import NewUser from "./NewUser/NewUser";
//import Union from "./GroupAdsUnion/Union";
import TransferAds from "./TransferAds/TransferAds";

import CityHall from "./CityHall/CityHall";
import Tickets from "./Tickets/Tickets";
import Command from "./Command/Command";
import Cockpit from "./Cockpit/Cockpit";


SessionStore.initialize({ name: "shrek" });

@observer
class AppInitializer extends Component {
  render() {
    if (SessionStore.initialized) {
      const rootStore = new RootStore(SessionStore);
      return (
        <div className="App">
          <div>
            <Provider
              rootStore={rootStore}
              sessionStore={rootStore.sessionStore}
              navigationStore={rootStore.navigationStore}
              requestStore={rootStore.requestStore}
              userStore={rootStore.userStore}
              dashboardStore={rootStore.dashboardStore}
              mapListStore={rootStore.mapListStore}
              logStore={rootStore.logStore}
              adStore={rootStore.adStore}
              teamStore={rootStore.teamStore}
              licenseStore={rootStore.licenseStore}
              pageStore={rootStore.pageStore}
              forgotPasswordStore={rootStore.forgotPasswordStore}
              resetPasswordStore={rootStore.resetPasswordStore}
              hostStore={rootStore.hostStore}
              contactStore={rootStore.contactStore}
              searchStore={rootStore.searchStore}
              userProfileStore={rootStore.userProfileStore}
              processStore={rootStore.processStore}
              uiStore={rootStore.uiStore}
              groupAdsStore={rootStore.groupAdsStore}
              groupEditorStore={rootStore.groupEditorStore}
              genericListStore={rootStore.genericListStore}
              licenseListStore={rootStore.licenseListStore}
              hostListStore={rootStore.hostListStore}
              statisticStore={rootStore.statisticStore}
              regionSelectorStore={rootStore.regionSelectorStore}
              workingRegionStore={rootStore.workingRegionStore}
              activityStore={rootStore.activityStore}
              newUserStore={rootStore.newUserStore}
              unionStore={rootStore.unionStore}
              transferAdsStore={rootStore.transferAdsStore}
              cityHallStore={rootStore.cityHallStore}
              ticketsStore={rootStore.ticketsStore}
              commandStore={rootStore.commandStore}
              newRegionSelectorStore={rootStore.newRegionSelectorStore}
              statsNircStore={rootStore.statsNircStore}
              cockpitStore={rootStore.cockpitStore}
            >
              <Router history={rootStore.navigationStore.history}>
                <Navbar />
                <div>
                  <PrivateRoute path="/area" component={MapDiv} />
                  <PrivateRoute path="/lists" component={GenericList} />
                  <PrivateRoute
                    path="/groupEditor/:code"
                    component={GroupEditor}
                  />
                  <PrivateRoute path="/groupAds/:code" component={GroupAds} />
                  <PrivateRoute path="/transferAds/:code" component={TransferAds}/>
                  <PrivateRoute path="/team/list" component={TeamForm} />
                  <PrivateRoute path="/user/:id" component={UserProfile} />
                  <Route path="/login" component={Login} />
                  <Route path="/forgotPassword" component={ForgotPassword} />
                  <Route path="/reset/:token" component={ResetPassword} />
                  <PrivateRoute path="/contactForm" component={ContactForm} />
                  <PrivateRoute
                    path="/statistics"
                    component={GenericStatistics}
                  />
                  <PrivateRoute path="/tickets" component={Tickets}/>
                  {/*   <PrivateRoute path="/stats" component={RegionSelector} /> */}
                  <PrivateRoute exact path="/" component={MapDiv} />
                  {/* <SupervisorRoute
                    path="/team/processes"
                    component={ProcessList}
                  /> */}
                  <SupervisorRoute
                    path="/workingRegionEdit"
                    component={WorkingRegionEdit}
                  />
                  <SuperuserRoute path="/activity" component={Activity} />
                  <SuperuserRoute path="/newUser" component={NewUser}/>
                  <PrivateRoute path="/cityHall" component={CityHall}/>
                  {/* <PrivateRoute path="/statistics" component={}/> */}
                  <PrivateRoute path="/command" component={Command}/>
                  <PrivateRoute path="/cockpit" component={Cockpit}/>
                </div>
              </Router>
            </Provider>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default function App() {
  return (
    <Suspense fallback="loading">
      <AppInitializer />
    </Suspense>
  );
}
