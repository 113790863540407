import React, { Component } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";

@observer
class MySelect extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    //console.log('event.target =', event.target.name);
    
    this.props.onChange(event.target.name, event.target.value);
  }

  render() {
    //form-control select btn btn-outline-info dropdown-toggle
    return (
        <>
    {/*<div className="form-group">*/}
        <label className={this.props.labelClass} htmlFor={this.props.id}>{this.props.label}</label>
        <select
          className = {"form-control select "}
          //className={"form-control select btn btn-outline-info dropdown-toggle " + this.props.selectClass}
          //className = "custom-select"
          // className="btn btn-outline-secondary dropdown-toggle"
          id={this.props.selectId}
          //className={this.props.selectClass}
          name={this.props.selectName}
          onChange={this.onChange}
          // defaultValue={"-1"}
          //value={this.props.selectedOption}
        >
          {/* <option className={this.props.optionClass} value="-1" disabled={true}>Select</option> */}
          {this.props.selectOptions.map(selectopt => (
            <option className={this.props.optionClass} key={selectopt.key} value={selectopt.key}>
              {selectopt.value}
            </option>
          ))}
        </select>
      {/*</div>*/}
      </>
    );
  }
}

MySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedOption: PropTypes.string.isRequired
};

export default MySelect;
