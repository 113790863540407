import { observable, action} from "mobx";


class DashboardStore {
  @observable favAds = null;
  @observable favRegions = null;
  @observable logs = null;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }
  
  @action("get dashboard data") getDashboard = async () => {
    try {
      let res = await this.rootStore.requestStore.getDashboardData();
      this.setDashboard(res.data);
    } catch (e) {
      if (e.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("Set dashboard") setDashboard = ({ ads, regions, logs }) => {
    this.favAds = ads;
    this.favRegions = regions;
    this.logs = logs;
  };
}


export default DashboardStore;
