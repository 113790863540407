import React from "react";
import { Redirect, Route } from "react-router-dom";
import { observer, inject } from "mobx-react";

const SupervisorRoute = inject(
  "sessionStore",
  "userStore"
)(
  observer(({ sessionStore, userStore, component: Component, ...rest }) => {
    if (sessionStore.initialized) {
      if (!sessionStore.hasSession)
        return (
          <Route
            {...rest}
            render={props => (
              <Redirect
                to={{ pathname: "/login", state: { from: props.location } }}
              />
            )}
          />
        );
      if (userStore.user !== null) {
        return (
          <Route
            {...rest}
            render={props =>
              sessionStore.hasSession ? (
                userStore.user ? (
                  userStore.user.isSuperuser ? (
                    <Component {...props} />
                  ) : (
                    <Redirect
                      to={{ pathname: "/area", state: { from: props.location } }}
                    />
                  )
                ) : (
                  <Redirect
                    to={{ pathname: "/login", state: { from: props.location } }}
                  />
                )
              ) : (
                <Redirect
                  to={{ pathname: "/login", state: { from: props.location } }}
                />
              )
            }
          />
        );
      }
    }
  })
);

export default SupervisorRoute;
