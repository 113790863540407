import { observable, action } from "mobx";

class LicenseStore {
  @observable licenses = null
  @observable id = null
  @observable username = null
  @observable idRegion = null
  @observable adList = null

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.adList = new Array(this.rootStore.pageStore.len)
  }

  @action('Get ads by license Id') getAdsByLicenseId = async (username, id, i) => {
    try {
      // this.adList = new Array(this.rootStore.pageStore.len)
      if (this.adList[i]) this.adList[i] = null;
      else {
        let res = await this.rootStore.requestStore.getAdsLicense(username, id)
        this.adList[i] = res.data

        this.rootStore.mapListStore.printAdsFromList(res.data)
      }
    } catch(error) {
	      if (error.toString() === "Error: AuthError")
	        console.log("Authentication failed");
			}
		}

  @action('Get license by username') getLicensesByUsername = async (username, filterValue) => {
    try {
      this.adList = new Array(this.rootStore.pageStore.len)
      if (filterValue === this.filter) this.rootStore.pageStore.changeDirection();
      else this.rootStore.pageStore.restartDirection();
      this.rootStore.pageStore.setPage(0);
      this.rootStore.pageStore.setTotalData(0);
      this.filter = filterValue;
      let auxArray = [];
      let auxData = 0;
      let aux = await this.rootStore.requestStore.getInitialLicenseByUser(username, this.filter, this.rootStore.pageStore.len, this.rootStore.pageStore.searchDirection);

      for (let i = 1; i < this.rootStore.pageStore.maxPages && aux.data.length > 0; i++) {
        auxData += aux.data.length;
        auxArray.push(aux.data);
        let last = null;

        switch (this.filter) {
          case "idLicense":
            last = auxArray[i - 1][auxArray[i - 1].length - 1].id
            break;
          case "numberads":
            last = auxArray[i - 1][auxArray[i - 1].length - 1].countLicense
            break;
          case "website":
            last = auxArray[i - 1][auxArray[i - 1].length - 1].website
            break;
          case "status":
            last = auxArray[i - 1][auxArray[i - 1].length - 1].status
            break;
          default:
            last = auxArray[i - 1][auxArray[i - 1].length - 1].id
        }
        let lastUnique = auxArray[i - 1][auxArray[i - 1].length - 1].id;

        aux = await this.rootStore.requestStore.getLicensesByUser(username, this.filter, last, lastUnique, this.rootStore.pageStore.len, this.rootStore.pageStore.searchDirection);
      }
      if (aux.data.length > 0) {
        auxArray.push(aux.data);
        auxData += aux.data.length;
      }
      this.rootStore.pageStore.setTotalData(auxData);
      this.rootStore.pageStore.setPageArray(auxArray);
      this.licenses = this.rootStore.pageStore.pageArray[0]
    } catch(error) {
	      if (error.toString() === "Error: AuthError")
	        console.log("Authentication failed");
			}
		}

  @action("Get Next licenses") getNext = async (num) => {
    this.adList = new Array(this.rootStore.pageStore.len)
    this.rootStore.pageStore.nextPage(num);
    this.licenses = this.rootStore.pageStore.pageArray[this.rootStore.pageStore.page]
  }

  @action("Get Previous licenses") getPrevious = async (num) => {
    this.adList = new Array(this.rootStore.pageStore.len)
    this.rootStore.pageStore.previousPage(num);
    this.licenses = this.rootStore.pageStore.pageArray[this.rootStore.pageStore.page]
  }

  @action("Get Last licenses") getLast = async () => {
    this.adList = new Array(this.rootStore.pageStore.len)
    this.rootStore.pageStore.setPage(this.rootStore.pageStore.totalPages - 1)
    this.licenses = this.rootStore.pageStore.pageArray[this.rootStore.pageStore.totalPages - 1]
  }

  @action("Get First licenses") getFirst = async () => {
    this.adList = new Array(this.rootStore.pageStore.len)
    this.rootStore.pageStore.setPage(0)
    this.licenses = this.rootStore.pageStore.pageArray[0]
  }
}
export default LicenseStore
