import { observable, action } from "mobx";

class TransferAdsStore {
    constructor(rootStore) {
      this.rootStore = rootStore;
      this.updateProperty = this.updateProperty.bind(this);
      this.updateFormProperty = this.updateFormProperty.bind(this);
    }
  
    @observable initialized = false;
  
    @observable searchForm = {
      option: "",
    };
  
    @observable receiver = null;
    @observable search = "";
  
    @observable elements = [];
    @observable selectedAds = [];

    @observable receiverMunicipality = null;
  
    @action("Initialize store") initStore = () => {
        this.selectedAds = [];
        this.getMunicipality()
        this.initialized = true;
    };

    @action("Clear Store") clearStore = () => {
        this.initialized = false;
        this.selectedAds = [];
        this.search = "";
        this.elements = [];
        this.receiver = null;
    };

    @action("update property not in form") updateProperty(key, value) {
        this[key] = value;
    }

    @action("update nested attribute") updateNestedProperty = (attrPath,value) => {
        let path = attrPath.split(".");
        this[path[0]][path[1]] = value;
    };

    @action("update form property") updateFormProperty(key, value) {
        this.searchForm[key] = value;
    }

    @action("initial search for the different options") initialSearch = () => {
        this.search = "";
        this.elements = [];
        this.searchElements();    
    };

    @action("Search for left Elements") searchElements = async () => {
        console.log("Searching")
        let selectedGroup = [];
        let selectedIds = [];
        for(let el of this.elements){
            if(el.selected){
                selectedGroup.push(el)
                selectedIds.push(el.id)
            }
            if(el.childs) {
                for(let children of el.childs) {
                    console.log(children)
                    if(this.selectedAds.includes(children._id)){
                        selectedGroup.push(el)
                        selectedIds.push(el.id)
                        break;
                    }
                }
            }
        }
        let founded = (await this.rootStore.requestStore.searchGroupsForMerge(this.search, selectedIds)).data;
        console.log("Founded",founded)
        if(selectedGroup.length === 0) {
            this.elements = founded
        }
        else {
            this.elements = selectedGroup.concat(founded);
        }
        

        for(let adgroup of this.elements){
            this.getMunicipalityForAdgroup(adgroup)
        }
    };

    @action("Add ads to selected ads") addSelectedTuple = async (array) => {
        for(let ad of array){
            this.addSelectedAd(ad);
        }
    }

    @action("Add ad to selected ad") addSelectedAd = async (ad) => {
        let index = this.selectedAds.indexOf(ad);
        if(index === -1) this.selectedAds.push(ad)
    }

    @action("Remove ads to selected ads") removeSelectedTuple = async (array) => {
        for(let ad of array){
            this.removeSelectedAd(ad);
        }
    }

    @action("Remove ads to selected ads") removeSelectedAd = async (ad) => {
        let index = this.selectedAds.indexOf(ad);
        if(index > -1) this.selectedAds.splice(index,1);
    }

    @action("Confirm button") confirmButton = async () => {
        await this.rootStore.requestStore.transferAds(this.receiver,this.selectedAds)
    }

    @action("Getter childs of groupAd") takeChilds = async (element) => {
        element.childs = (await this.rootStore.requestStore.getAdsFromGroupAd(element.id)).data;
    }

    @action("Getmunicipality)") getMunicipality = async () => {
        this.receiverMunicipality = (await this.rootStore.requestStore.getMunicipality(this.receiver)).data;
        console.log(this.receiverMunicipality)
    }

    @action("Getmunicipality)") getMunicipalityForAdgroup = async (adgroup) => {
        adgroup.municipality = (await this.rootStore.requestStore.getMunicipality(adgroup.id)).data;
    }

}
export default TransferAdsStore;