//CurrentProcessCopy//import React, { Component } from "react";
import { observable, action } from "mobx";
import { computedFn } from "mobx-utils";
import i18n from "../../i18n";

class ProcessStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.updateFormProperty = this.updateFormProperty.bind(this);
    this.createNewProcess = this.createNewProcess.bind(this);
  }

  //observables
  @observable processList = [];
  @observable CurrentProcess = {
    _id: "IDdefault",
    title: "",
    generalReport: "",
    finished: false,
    task: [
      {
        id_task: "undefined",
        name: "",
        editing: false,
        lastModified: "",
        report: ""
      }
    ]
  };
  @observable CurrentProcessCopy = {
    _id: "IDdefault",
    title: "Title",
    generalReport: "",
    finished: false,
    task: [
      {
        id_task: "undefined",
        name: "",
        editing: false,
        lastModified: "",
        report: ""
      }
    ]
  };
  @observable CurrentTask = {
    id_task: "undefined",
    name: "",
    editing: false,
    lastModified: "",
    report: ""
  };

  //Modal observable Variables

  //Indica si podem o no veure el Modal
  @observable showHideModal = false;

  //Indica si podem o no veure la part del modal corresponent a crear una tasca nova
  @observable showHideTask = false;

  //Indica si podem o no veure la part de modal corresponent a editar una tasca existent
  @observable showHideTask2 = false;

  @observable closing = false;
  @observable saving = false;
  @observable notFinished = false

  @observable showAlert = false;
  @observable confirmAlert = false;

  @action("All task string modification") allTaskTranslate = () => {
    //console.log("I enter all task translate",this.CurrentProcess.task[0].name)
    this.CurrentProcess.task[0].name = i18n.t("mymodal.allTask");
    this.CurrentProcessCopy.task[0].name = i18n.t("mymodal.allTask");
    this.CurrentTask.name = i18n.t("mymodal.allTask");
    this.defaultProcess.task[0].name = i18n.t("mymodal.allTask");
    //console.log("Coming out",this.CurrentProcess.task[0].name)
  }

  //Local Variables
  processId = 0;
  taskId = 0;

  group = 0;
  //Indica si estem obrint un process o alguns dels seus atributs ja existents
  opening = false;
  //Indica si hem obert el report d'una tasca
  edit = false;
  //Indica si hem obert el General Report d'un process
  edit2 = false;
  //Indica si un process esta en tramits de finalitzacio
  finish = false;
  //Indica si hem obert tots el processos d'un Grup d'anuncis
  edit3 = false;
  //Indica si estèm creant un procès
  creatingProcess = false
  //Indica si hem obert totes les tasques del processos d'un Grup d'anuncis
  edit4 = false;

  defaultProcess = {
    _id: "IDdefault",
    title: "",
    generalReport: "",
    finished: false,
    task: [
      {
        id_task: "undefined",
        name: "",
      }
    ]
  };

  messageError = info => {
    this.updateProperty("showAlert", true);
    switch (info) {
      case "addproces":
        document.getElementById("danger-alert-proces").innerHTML = i18n.t("process.notifications.notAdd")
        break
      case "updateproces":
        document.getElementById("danger-alert-proces").innerHTML = i18n.t("process.notifications.notProcessAct")
        break
      case "updatetask":
        document.getElementById("danger-alert-proces").innerHTML = i18n.t("process.notifications.notTaskAct")
        break
      default:
        document.getElementById("danger-alert-proces").innerHTML = i18n.t("process.notifications.notGReportAct")
        break
    }
    setTimeout(() => {
      this.updateProperty("showAlert", false);
    }, 5000);
  }

  messageAlert = info => {
    this.updateProperty("confirmAlert", true);
    switch (info) {
      case "addproces":
        document.getElementById("succes-alert-proces").innerHTML = i18n.t("process.notifications.Add")
        break
      case "updateproces":
        document.getElementById("succes-alert-proces").innerHTML = i18n.t("process.notifications.ProcessAct")
        break
      case "updatetask":
        document.getElementById("succes-alert-proces").innerHTML = i18n.t("process.notifications.TaskAct")
        break
      default: document.getElementById("succes-alert-proces").innerHTML = i18n.t("process.notifications.GReportAct")
        break
    }
    setTimeout(() => {
      this.updateProperty("confirmAlert", false);
    }, 5000);
  }

  //Actions

  /*
    Actualitza la propietat d'un formulari
    */
  @action("Update Form Property") updateFormProperty = async (key, value) => {
    if (this.CurrentProcess[key] === "") this.CurrentProcess[key] = value;
    else if (this.CurrentProcess[key]) {
      this.CurrentProcess[key] = value;
    }
    if (this.CurrentTask[key] === "") this.CurrentTask[key] = value;
    else if (this.CurrentTask[key]) {
      this.CurrentTask[key] = value;
    }
  };

  /*
    Actualitza la propietat d'un formulari que s'està editant.
    */
  @action("Update Edit Property") updateEditProperty = async (
    key,
    value,
    task
  ) => {
    if (this.opening) {
      if (this.CurrentProcessCopy[key] === "") {
        this.CurrentProcessCopy[key] = value;
      }
      else if (this.CurrentProcessCopy[key]) {
        this.CurrentProcessCopy[key] = value;
      }
      if (task[key] === "") {
        if (key === "name")
          this.CurrentProcessCopy.task[task.id_task].name = value;
        else if (key === "report") this.CurrentTask.report = value;
        else if (key === "generalReport")
          this.CurrentProcess.generalReport = value;
      } else if (task[key]) {
        if (key === "name")
          this.CurrentProcessCopy.task[task.id_task].name = value;
        else if (key === "report") this.CurrentTask.report = value;
        else if (key === "generalReport")
          this.CurrentProcess.generalReport = value;
      }
    } else {
      if (task[key] === "") {
        if (key === "name") this.CurrentProcess.task[task.id_task].name = value;
        else if (key === "report") this.CurrentTask.report = value;
      } else if (task[key]) {
        if (key === "name") this.CurrentProcess.task[task.id_task].name = value;
        else if (key === "report") this.CurrentTask.report = value;
      }
    }
  };

  /*
    Actualitza la propietat concreta
    */
  @action("Update Property") updateProperty = async (key, value) => {
    this[key] = value;
  };


  @action("Closing") closed = async () => {
    this.closing = true
  }

  /*
    Tanca qualsevol modal obert
    */

  @action("Close") close = async (show) => {
    this.saving = false;
    this.closing = false
    this.notFinished = false;
    this.showHideTask = false;
    this.showHideModal = false;
    this.edit = false;
    this.edit2 = false;
    this.edit4 = false;
    if (show) {
      this.edit3 = false;
    }
    if (this.edit3) {
      this.showHideModal = true
      this.opening = true
    }
    this.creatingProcess = false;
    this.finish = false;
    let processDef = JSON.parse(JSON.stringify(this.defaultProcess));
    this.CurrentProcess = processDef;
    this.taskId = 0;
  };

  /*
    S'obté la llista de processos de la BD
    */
  @action("get Process List") getProcessList = async () => {
    try {
      const response = await this.rootStore.requestStore.getProcessList();
      this.processList = response.data;
      this.processId = this.processList.length;
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  /*
    Crea un nou process, sense guardar-lo.
    */
  @action("create New Process") createNewProcess = async () => {
    try {
      //this.close();
      this.opening = false;
      let processDef = JSON.parse(JSON.stringify(this.defaultProcess));
      this.CurrentProcess = processDef;
      this.taskId = 0;
      this.showHideModal = true;
      this.creatingProcess = true;
    } catch (error) {
      console.log(error);
    }
  };

  /*
    Guarda el process actual a la BD
    */
  @action("add New Process") addNewProcess = async () => {
    try {
      let finished = true
      if (this.CurrentProcess.task.length === 0) finished = false
      for (let i in this.CurrentProcess.task) {
        if (this.CurrentProcess.task[i].name === i18n.t("mymodal.allTask")) finished = false
        else if (this.CurrentProcess.task[i].editing === true) finished = false
        else if (this.CurrentProcess.title === "") finished = false
      }
      if (finished) {
        this.showHideTask = false;
        this.showHideModal = false;
        this.CurrentProcess._id = this.processId;
        this.processId += 1;
        let processCopy = JSON.parse(JSON.stringify(this.CurrentProcess));

        let res = await this.rootStore.requestStore.insertInProcessList(processCopy);
        if (res === undefined) {

          this.messageError("addproces")
        } else {
          this.processList.push(processCopy)
          this.messageAlert("addproces")
        }
        let processDef = JSON.parse(JSON.stringify(this.defaultProcess));
        this.CurrentProcess = processDef;
        this.taskId = 0;
        this.close();
      }
      else this.notFinished = true
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  /*
    Crea una nova tasca.
    */
  @action("create New Task") createNewTask = async () => {
    try {
      this.showHideTask = true;
    } catch (error) {
      console.log(error);
    }
  };

  /*
    Afegeix una tasca al process actual.
    */
  @action("add New Task") addNewTask = async () => {
    try {
      this.showHideTask = false;
      this.CurrentTask.id_task = this.taskId;
      this.taskId += 1;
      let taskCopy = JSON.parse(JSON.stringify(this.CurrentTask));
      if (this.opening) {
        if (
          this.CurrentProcessCopy.task.length === 1 &&
          (this.CurrentProcessCopy.task[0].name === "undefined" ||
            this.CurrentProcessCopy.task[0].name ===
            i18n.t("mymodal.allTask")) &&
          this.CurrentProcessCopy.task[0].id_task === "undefined"
        )
          this.CurrentProcessCopy.task[0] = taskCopy;
        else this.CurrentProcessCopy.task.push(taskCopy);
      } else {
        if (
          this.CurrentProcess.task.length === 1 &&
          (this.CurrentProcess.task[0].name === "undefined" ||
            this.CurrentProcess.task[0].name ===
            i18n.t("mymodal.allTask")) &&
          this.CurrentProcess.task[0].id_task === "undefined"
        )
          this.CurrentProcess.task[0] = taskCopy;
        else this.CurrentProcess.task.push(taskCopy);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /*
    S'obre un process ja existent
    */
  @action("open Process") openProcess = async (e, val, id, task, general, finished) => {
    try {
      this.showHideModal = true;
      //this.updateFormProperty("title",val);
      this.CurrentProcess._id = id;
      this.CurrentProcess.title = val;
      this.CurrentProcess.task = task;
      this.CurrentProcess.generalReport = general;
      this.taskId = this.CurrentProcess.task.length;
      this.opening = true;
      let prCopy = JSON.parse(JSON.stringify(this.CurrentProcess));
      this.CurrentProcessCopy = prCopy;
    } catch (error) {
      console.log(error);
    }
  };

  /*
    Actualitza un process ja existent a la BD.
    */
  @action("add existent Process") addExistentProcess = async () => {
    try {
      let finished = true
      if (this.CurrentProcessCopy.task.length === 0) finished = false
      for (let i in this.CurrentProcessCopy.task) {
        if (this.CurrentProcessCopy.task[i].name === "Aquí tens totes les teves tasques!") finished = false
        else if (this.CurrentProcessCopy.task[i].editing === true) finished = false
        else if (this.CurrentProcessCopy.title === "") finished = false
      }
      if (finished) {
        this.showHideTask = false;
        this.showHideModal = false;
        let processCopy = JSON.parse(JSON.stringify(this.CurrentProcessCopy));
        // for (let j = 0; j < this.processList.length; j++)
        
        let res = await this.rootStore.requestStore.updateProcessList(processCopy);

        if (res === undefined) {
          this.messageError("updateproces")
        } else {
          this.processList[processCopy._id] = processCopy;
          this.messageAlert("updateproces")
        }

        let processDef = JSON.parse(JSON.stringify(this.defaultProcess));
        this.CurrentProcess = processDef;
        this.taskId = 0;
        this.close();
      }
      else this.notFinished = true
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  /*
    Esborra una tasca
    */
  @action("delete task") deleteTask = async task => {
    try {
      if (this.opening) {
        this.CurrentProcessCopy.task.splice(task.id_task, 1);
        for (let i = 0; i < this.CurrentProcessCopy.task.length; ++i)
          this.CurrentProcessCopy.task[i].id_task = i;
      } else {
        this.CurrentProcess.task.splice(task.id_task, 1);
        for (let i = 0; i < this.CurrentProcess.task.length; ++i)
          this.CurrentProcess.task[i].id_task = i;
      }
      this.taskId -= 1;
    } catch (error) {
      console.log(error);
    }
  };

  /*
    Edita una tasca
    */
  @action("edit task") editTask = async task => {
    try {
      let j = false;
      task.editing = false;
      this.CurrentProcessCopy.task[task.id_task].editing = false;
      this.CurrentProcessCopy.task.map((t, i) => {
        if (this.CurrentProcessCopy.task[t.id_task].editing === true) return j = true
        else return null
      })
      if (!j) this.showHideTask2 = false;

    } catch (error) {
      console.log(error);
    }
  };

  /*
    Obre un informe de tasques ja existent
    */
  @action("open Task Report") openTaskReport = async (
    e,
    val,
    id,
    tasks,
    task,
    general,
    finished,
    idGroup
  ) => {
    try {
      this.group = idGroup
      this.showHideModal = true;
      this.updateFormProperty("title", val);
      this.CurrentProcess._id = id;
      this.CurrentProcess.title = val;
      this.CurrentProcess.task = tasks;
      this.CurrentProcess.finished = finished;
      this.CurrentProcess.generalReport = general;
      let tCopy = JSON.parse(JSON.stringify(task));
      this.CurrentTask = tCopy;
      this.taskId = this.CurrentProcess.task.length;
      this.opening = true;
      this.edit = true;
      let prCopy = JSON.parse(JSON.stringify(this.CurrentProcess));
      this.CurrentProcessCopy = prCopy;
    } catch (error) {
      console.log(error);
    }
  };

  /*
    Actualitza un informe de tasques, aixi com la data de modificacio
    */
  @action("save Report") addExistentReport = async (task, show) => {
    try {
    var today = new Date();
    var year = today.getFullYear();
    var month = today.getMonth() + 1;
    var day = today.getDate();
    if (month <= 9) month = "0" + month;
    if (day <= 9) day = "0" + day;
    var date = year + "-" + month + "-" + day;
    var hours = today.getHours();
    var minutes = today.getMinutes();
    var seconds = today.getSeconds();
    if (hours <= 9) hours = "0" + hours;
    if (minutes <= 9) minutes = "0" + minutes;
    if (seconds <= 9) seconds = "0" + seconds;
    var time = hours + ":" + minutes + ":" + seconds;
    var datatime = date + "T" + time + ".000Z";
    this.CurrentTask.lastModified = datatime;
    let taskCopy = JSON.parse(JSON.stringify(this.CurrentTask));
    this.CurrentProcessCopy.task[this.CurrentTask.id_task] = taskCopy;
    let processCopy = JSON.parse(JSON.stringify(this.CurrentProcessCopy));
    this.edit = false;
    this.opening = false;
    let res = await this.rootStore.requestStore.updateProcessList2(processCopy,this.group);
    let processDef = JSON.parse(JSON.stringify(this.defaultProcess));
    this.CurrentProcess = processDef;
    this.showHideModal = false;
    if (this.processList.length > 0) {
      this.processList[processCopy._id] = processCopy;
      this.close(false)
    }
    else this.close(true);
    if (res === undefined) {
      this.messageError("updatetask")
    } else {
      this.messageAlert("updatetask")
    }
  } catch(error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  /*
    Converteix les dates ISO de les tasques a un format concret.
    Format desitjat: hh:mm DD-MM-YYYY
    Format ISO: "2013-03-10T02:00:00Z
    */
  convertToFormatTable = computedFn(
    function convertToFormatTable(date) {
      ///hh:mm DD-MM-YYYY
      //"2013-03-10T02:00:00Z
      if (date !== null) {
        let h = date.split("T")[0];
        let t = date.split("T", 2);
        t = t[1].split(".")[0];
        t = t.split(":", 2);
        h = h.split("-", 3);
        return h[2] + "-" + h[1] + "-" + h[0] + "      " + t[0] + ":" + t[1]
      }
      else return null
    }
  );

  /*
    Obre un informe general del process
    */
  @action("open Gen Report") openGenReport = async (
    e,
    val,
    id,
    tasks,
    general,
    finished,
    idGroup
  ) => {
    try {
      this.group = idGroup
      this.showHideModal = true;
      this.CurrentProcess._id = id;
      this.CurrentProcess.title = val;
      this.CurrentProcess.task = tasks;
      this.CurrentProcess.finished = finished;
      this.CurrentProcess.generalReport = general;
      this.opening = true;
      this.edit2 = true;
      let prCopy = JSON.parse(JSON.stringify(this.CurrentProcess));
      this.CurrentProcessCopy = prCopy;
    } catch (error) {
      console.log(error);
    }
  };

  /*
    Actualitza a BD l'informe General d'un process
    */
  @action("save General Report") addExistentGenReport = async (process, show) => {
    try {
      let processCopy = JSON.parse(JSON.stringify(process));
      this.edit = false;
      this.edit2 = false;
      this.opening = false;
      let res = await this.rootStore.requestStore.updateProcessList2(processCopy, this.group);
      let processDef = JSON.parse(JSON.stringify(this.defaultProcess));
      this.CurrentProcess = processDef;
      this.showHideModal = false;
      if (this.processList.length > 0) {
        this.processList[processCopy._id] = processCopy;
        this.close(false)
      }
      else {
        this.close(true);
      }
      if (res === undefined) {
        this.messageError("report")
      } else {
          this.messageAlert("report")
      }
    } catch(error) {
	      if (error.toString() === "Error: AuthError")
	        console.log("Authentication failed");
			}
		}

  /*
    Finalitza el process
    */
  @action("finish Process") endProcess = async (e, process, group) => {
    try {
      this.finish = true;
      this.showHideModal = true;
      this.group = group
      this.CurrentProcess._id = process._id;
      this.CurrentProcess.title = process.title;
      this.CurrentProcess.task = process.task;
      this.CurrentProcess.finished = process.finished
      this.CurrentProcess.generalReport = process.generalReport;
    } catch (error) {
      console.log(error);
    }
  };

  @action("save process state") saveProcessState = async process => {
    try {
      let processCopy = JSON.parse(JSON.stringify(process));
      this.processList[processCopy._id] = processCopy;
      this.edit = false;
      this.edit2 = false;
      this.opening = false;
      await this.rootStore.requestStore.updateProcessState(processCopy, this.group);
      let processDef = JSON.parse(JSON.stringify(this.defaultProcess));
      this.CurrentProcess = processDef;
      this.showHideModal = false;
      this.close();
    } catch(error) {
	      if (error.toString() === "Error: AuthError")
	        console.log("Authentication failed");
			}
    }
    
    /*
    Obre i mostra tots els processos d'un GA
    */
  @action("open process reports") openGAProcesses = async (
    processes,
    idGroup
  ) => {
    try {
      this.group = idGroup
      this.processList = processes
      this.showHideModal = true;
      this.opening = true;
      this.edit3 = true;
    } catch (error) {
      console.log(error);
    }
  };

  /*
    Obre i mostra tots els noms de les tasques dels processos d'un GA
    */
   @action("open tasks") openGATasks = async (
    processes,
    idGroup
  ) => {
    try {
      this.group = idGroup
      this.processList = processes
      this.showHideModal = true;
      this.opening = true;
      this.edit4 = true;
    } catch (error) {
      console.log(error);
    }
  };
  
  // @action("Close2") close2 = async () => {
  //   this.saving = false;
  //   this.closing = false
  //   this.notFinished = false;
  //   this.showHideTask = false;
  //   this.showHideModal = false;
  //   this.edit = false;
  //   this.edit2 = false;
  //   this.creatingProcess = false;
  //   this.finish = false;
  //   let processDef = JSON.parse(JSON.stringify(this.defaultProcess));
  //   this.CurrentProcess = processDef;
  //   this.taskId = 0;
  // };

}

export default ProcessStore;
