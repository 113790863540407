import { observable, action } from "mobx";

class UnionStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.updateProperty = this.updateProperty.bind(this);
    this.updateFormProperty = this.updateFormProperty.bind(this);
  }

  @observable initialized = false;

  @observable searchForm = {
    option: "",
  };

  @observable rightSearch = "";
  @observable leftSearch = "";

  @observable rightElements = [];
  @observable leftElements = [];

  nextGroupId = null;

  @action("Initialize store") initStore = () => {
    this.initialized = true;
    this.searchForm = {
      option: "group",
    };
    this.searchLastId();
  };
  
  @action("Search last id of GA in db")searchLastId = async () => {
    this.nextGroupId = parseInt((await this.rootStore.requestStore.getMaxCode()).data._id) + 1
  }

  @action("Clear Store") clearStore = () => {
    this.initialized = false;
    this.searchForm = {
      option: "",
    };
    this.rightSearch = "";
    this.leftSearch = "";
    this.rightElements = [];
    this.leftElements = [];
  };

  @action("update property not in form") updateProperty(key, value) {
    this[key] = value;
  }

  @action("update nested attribute") updateNestedProperty = (
    attrPath,
    value
  ) => {
    let path = attrPath.split(".");
    this[path[0]][path[1]] = value;
  };

  @action("update form property") updateFormProperty(key, value) {
    // console.log(key, value);

    this.searchForm[key] = value;
    // console.log(this.searchForm);
  }

  @action("initial search for the different options") initialSearch = () => {
    this.rightElements = [];
    this.leftElements = [];
    this.rightSearch = "";
    this.leftSearch = "";
    // console.log(this.searchForm.option);
    this.searchLeftElements();
    if (
      this.searchForm.option === "group" ||
      this.searchForm.option === "new" ||
      this.searchForm.option === "ad"
    ) {
      this.searchGroupsForMerge();
    }
  };

  @action("Search AdGroups") searchGroupsForMerge = async () => {
    this.rightElements = await this.rootStore.requestStore.searchGroupsForMerge(
      this.rightSearch
    );
  };

  @action("Search for left Elements") searchLeftElements = async () => {
    this.leftElements = await this.rootStore.requestStore.searchGroupsForMerge(
      this.leftSearch
    );
  };

  confirmButton = () => {
    switch (this.searchForm.option) {
      case "group":
        this.mergeGroup();
        break;
      case "new":
        this.newGroup();
        break;
      case "ad":
        this.transferAds();
        break;
      default:
    }
  };

  @action("Merge groups") mergeGroup = async () => {
    let right;
    for (let e of this.rightElements) if (e.selected) right = e;

    let left;
    for (let e of this.leftElements) if (e.selected) left = e;
    console.log(right, left);

    if (right === undefined || left === undefined)
      //ERROR - No selected element
      return;

    if (right.id === left.id)
      //ERROR - Same element
      return;

    await this.rootStore.requestStore.mergeAdGroup(left, right);
    this.rootStore.navigationStore.openInNewTab("/groupAds/" + left.id);
  };

  @action("New group from ads selected from 2 different groups")
  newGroup = async () => {
    let right, left, rChilds, lChilds;
    for (let e of this.rightElements) {
      if (e.selected) {
        right = e;
        rChilds = e.selectedChildren;
      }
    }
    for (let e of this.leftElements) {
      if (e.selected) {
        left = e;
        lChilds = e.selectedChildren;
      }
    }

    if (
      right === undefined ||
      left === undefined ||
      lChilds === undefined ||
      rChilds === undefined ||
      lChilds === [] ||
      rChilds === []
    )
      //ERROR - No selected element
      return;

    if (right.id === left.id)
      //ERROR - Same element
      return;

    let newGroup = (
      await this.rootStore.requestStore.newGroupFromMergeAds(
        left.id,
        lChilds,
        right.id,
        rChilds
      )
    ).data;

    this.rootStore.navigationStore.openInNewTab("/groupAds/" + newGroup._id);
  };

  @action("Merge groups") transferAds = async () => {
    let right, rChilds;
    for (let e of this.rightElements) {
      if (e.selected) {
        right = e;
        rChilds = e.selectedChildren;
      }
    }
    let left;
    for (let e of this.leftElements) if (e.selected) left = e;
    console.log(right, left);

    if (
      right === undefined ||
      left === undefined ||
      rChilds === undefined ||
      rChilds === []
    )
      //ERROR - No selected element
      return;

    if (right.id === left.id)
      //ERROR - Same element
      return;

    await this.rootStore.requestStore.mergeTransferAds(left, right, rChilds);
    this.rootStore.navigationStore.openInNewTab("/groupAds/" + left.id);
  };
}
export default UnionStore;
