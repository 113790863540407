import React, { Component } from "react";
import { observer, inject } from "mobx-react";
//import Stats from "../RegionSelector/RegionSelector";
import NewStats from "../NewRegionSelector/NewRegionSelector";
import { withTranslation } from "react-i18next";

@inject("commandStore", "userStore")
@observer
class Command extends Component {
    async componentDidMount() {
        try {
            let { t } = this.props;
            document.title = t("command.title");
            await this.props.commandStore.initiateStore();
        } catch (error) {
            if (error.toString() === "Error: AuthError")
                console.log("Authentication failed");
        }
    }

    async componentWillUnmount() {
        try {
            this.props.commandStore.cityHallData = null;
            this.props.commandStore.cityHallDataLast = null;
        } catch (error) {
            console.log(error.toString());
        }
    }

    overInProcess = async (e, row) => {
        //console.log(e);
        //console.log(e.movementX, e.movementY, e.clientX, e.clientY);
        let x = e.clientX;
        let y = e.clientY;
        //console.log(x, y);
        let style = {
            top: JSON.stringify(y) + "px",
            left: JSON.stringify(x - 120) + "px",
        };
        //console.log(style);
        let { t } = this.props;

        let textForModal = "";
        switch (row) {
            case "totalAds":
                textForModal = t("command.modalInfo.totalAds");
                break;
            case "withLicense":
                textForModal = t("command.modalInfo.withLicense");
                break;
            case "okAds":
                textForModal = t("command.modalInfo.okAds");
                break;
            case "withLicenseIncorrect":
                textForModal = t("command.modalInfo.withLicenseIncorrect");
                break;
            case "licenseDuplicated":
                textForModal = t("command.modalInfo.licenseDuplicated");
                break;
            case "usersWithLicense":
                textForModal = t("command.modalInfo.usersWithLicense");
                break;
            case "withAddressWithLicense":
                textForModal = t("command.modalInfo.withAddressWithLicense");
                break;
            case "withError":
                textForModal = t("command.modalInfo.withError");
                break;
            case "withErrorExempt":
                textForModal = t("command.modalInfo.withErrorExempt");
                break;
            case "withErrorNoLicense":
                textForModal = t("command.modalInfo.withErrorNoLicense");
                break;
            case "withErrors":
                textForModal = t("command.modalInfo.withErrors");
                break;
            case "idOwnerDuplicated":
                textForModal = t("command.modalInfo.idOwnerDuplicated");
                break;
            case "usersWithErrorLicense":
                textForModal = t("command.modalInfo.usersWithErrorLicense");
                break;
            case "WithErrorWithAddress":
                textForModal = t("command.modalInfo.withErrorWithAddress");
                break;
            case "fraud":
                textForModal = t("command.modalInfo.fraud");
                break;
            default:
                break;
        }

        document.getElementById("textModalCityHall").textContent = textForModal;
        document.getElementById("modalForCityHall").className = document
            .getElementById("modalForCityHall")
            .className.replace("display-none", "display-block");
        document.getElementById("modalForCityHall").style.left = style.left;
        document.getElementById("modalForCityHall").style.top = style.top;
    };

    closeModal = () => {
        //console.log("closing");
        document.getElementById("modalForCityHall").className = document
            .getElementById("modalForCityHall")
            .className.replace("display-block", "display-none");
        document.getElementById("modalForCityHall").style.top = "";
        document.getElementById("modalForCityHall").style.left = "";
    };

    onLeaves = async (e) => {
        /*let x = e.clientX;
    let y = e.clientY;
    let style = {
      top: JSON.stringify(y) + "px",
      left: JSON.stringify(x - 120) + "px",
    };*/
        this.closeModal();
    };

    render() {
        const { t } = this.props;
        console.log(
            this.props.commandStore.cityHallData,
            this.props.commandStore.cityHallDataLast
        );
        return this.props.commandStore.cityHallData &&
            this.props.commandStore.cityHallDataLast ? (
            <>
                <div
                    id={"modalForCityHall"}
                    className={"modalHover display-none"}
                >
                    <section className="modal-main">
                        <div
                            id={"textModalCityHall"}
                            className="card container"
                            style={{ maxWidth: "200px" }}
                        >
                            {"A"}
                        </div>
                    </section>
                </div>
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <h4 className="stats-title">
                            {this.props.commandStore.cityName}
                        </h4>
                        <div className="quick-search-statistics">
                            <NewStats page="command" regBool={false}></NewStats>
                        </div>
                        <div className="row stats-table">
                        <p style={{"text-align":"left"}}>
                                    {t("statistics.stats.date") +
                                        this.props.commandStore.dateInfo}
                                </p>
                            <p>{t("command.ref")}</p>
                            <table className="table table-borderless">
                                <thead>
                                    <tr>
                                        <th className="cover"></th>

                                        <th>{t("command.total")}</th>
                                        {Object.keys(
                                            this.props.commandStore.cityHallData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <th key={"" + currentValue + i}>
                                                {currentValue}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {/* <svg
                        className="fas fa-info-circle"
                        style={{ width: "1rem", fill: "#808080" }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        onMouseEnter={(e) => {
                          this.overInProcess(e, "totalAds");
                        }}
                        onMouseLeave={(e) => {
                          this.onLeaves(e);
                        }}
                      >
                        <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
                      </svg>   */}
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("command.totalAds")}
                                        </td>
                                        <td
                                            onClick={() => {
                                                this.props.commandStore.tableToWA(
                                                    "total_ads",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.commandStore
                                                    .cityHallData.total_ads
                                            }
                                        </td>
                                        {Object.values(
                                            this.props.commandStore.cityHallData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={() => {
                                                    this.props.commandStore.tableToWA(
                                                        "total_ads",
                                                        Object.keys(
                                                            this.props
                                                                .commandStore
                                                                .cityHallData
                                                                .websites
                                                        )[i]
                                                    );
                                                }}
                                            >
                                                {currentValue}
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <table className="table table-borderless">
                                <thead>
                                    <tr>
                                        <th>{t("municipality.correct")}</th>

                                        <th>{t("municipality.total")}</th>
                                        {Object.keys(
                                            this.props.commandStore.cityHallData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <th key={"" + currentValue + i}>
                                                {currentValue}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="command-good-tr">
                                        {/* <svg
                        className="fas fa-info-circle"
                        style={{ width: "1rem", fill: "#808080" }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        onMouseEnter={(e) => {
                          this.overInProcess(e,"withLicense");
                        }}
                        onMouseLeave={(e) => {
                          this.onLeaves(e);
                        }}
                      >
                        <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
                      </svg> */}
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "withLicense"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("command.withLicense.name")}
                                        </td>
                                        <td
                                            onClick={() => {
                                                this.props.commandStore.tableToWA(
                                                    "license",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.commandStore
                                                    .cityHallData.licenses
                                                    .withLicense.count
                                            }
                                        </td>
                                        {Object.keys(
                                            this.props.commandStore.cityHallData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <td
                                                key={
                                                    "" +
                                                    this.props.commandStore
                                                        .cityHallData.licenses
                                                        .withLicense.websites[
                                                        currentValue
                                                    ] +
                                                    i
                                                }
                                                onClick={() => {
                                                    this.props.commandStore.tableToWA(
                                                        "license",
                                                        currentValue
                                                    );
                                                }}
                                            >
                                                {
                                                    this.props.commandStore
                                                        .cityHallData.licenses
                                                        .withLicense.websites[
                                                        currentValue
                                                    ]
                                                }
                                            </td>
                                        ))}
                                    </tr>
                                    <tr className="command-goodsub-tr">
                                        {/* <svg
                        className="fas fa-info-circle"
                        style={{ width: "1rem", fill: "#808080" }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        onMouseEnter={(e) => {
                          this.overInProcess(e, "okAds");
                        }}
                        onMouseLeave={(e) => {
                          this.onLeaves(e);
                        }}
                      >
                        <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
                      </svg> */}
                                        <td
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(e, "okAds");
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("command.withLicense.official")}
                                        </td>
                                        <td
                                            onClick={() => {
                                                this.props.commandStore.tableToWA(
                                                    "okAds",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.commandStore
                                                    .cityHallData.licenses
                                                    .withLicense.official.count
                                            }
                                        </td>
                                        {Object.keys(
                                            this.props.commandStore.cityHallData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <td
                                                key={
                                                    "" +
                                                    this.props.commandStore
                                                        .cityHallData.licenses
                                                        .withLicense.official
                                                        .websites[
                                                        currentValue
                                                    ] +
                                                    i
                                                }
                                                onClick={() => {
                                                    this.props.commandStore.tableToWA(
                                                        "okAds",
                                                        currentValue
                                                    );
                                                }}
                                            >
                                                {
                                                    this.props.commandStore
                                                        .cityHallData.licenses
                                                        .withLicense.official
                                                        .websites[currentValue]
                                                }
                                            </td>
                                        ))}
                                    </tr>
                                    <tr className="command-goodsub-tr">
                                        {/* <svg
                        className="fas fa-info-circle"
                        style={{ width: "1rem", fill: "#808080" }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        onMouseEnter={(e) => {
                          this.overInProcess(e,"withLicenseIncorrect");
                        }}
                        onMouseLeave={(e) => {
                          this.onLeaves(e);
                        }}
                      >
                        <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
                      </svg> */}
                                        <td
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "withLicenseIncorrect"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("command.withLicense.incorrect")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget === e.target
                                                )
                                                    this.props.commandStore.tableToWA(
                                                        "licenseWithErrors",
                                                        "total"
                                                    );
                                            }}
                                        >
                                            {
                                                this.props.commandStore
                                                    .cityHallData.licenses
                                                    .withLicense.incorrect.count
                                            }
                                            {/* <div className="div-content">
                        <button
                          id="flt-message"
                          className="btn btn-outline-info flt-message"
                          onClick={() => {
                            console.log("Downloading");
                            this.props.commandStore.downloadExcel(
                              this.props.userStore.user.username,
                              "all"
                            );
                          }}
                          type="button"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            style={{ fill: "#17a2b8", "min-width": "1rem" }}
                          >
                            <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                          </svg>
                        </button>
                      </div> */}
                                        </td>
                                        {Object.keys(
                                            this.props.commandStore.cityHallData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <td
                                                key={
                                                    "" +
                                                    this.props.commandStore
                                                        .cityHallData.licenses
                                                        .withLicense.incorrect
                                                        .websites[
                                                        currentValue
                                                    ] +
                                                    i
                                                }
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (
                                                        e.currentTarget ===
                                                        e.target
                                                    )
                                                        this.props.commandStore.tableToWA(
                                                            "licenseWithErrors",
                                                            currentValue
                                                        );
                                                }}
                                            >
                                                {
                                                    this.props.commandStore
                                                        .cityHallData.licenses
                                                        .withLicense.incorrect
                                                        .websites[currentValue]
                                                }
                                                {/* <div className="div-content">
                          <button
                            id="flt-message"
                            className="btn btn-outline-info flt-message"
                            onClick={() => {
                              console.log("Downloading");
                              this.props.commandStore.downloadExcel(
                                this.props.userStore.user.username,
                                currentValue
                              );
                            }}
                            type="button"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              style={{ fill: "#17a2b8", "min-width": "1rem" }}
                            >
                              <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                            </svg>
                          </button>
                        </div> */}
                                            </td>
                                        ))}
                                    </tr>

                                    {/* <tr>
                    <svg
                        className="fas fa-info-circle"
                        style={{ width: "1rem", fill: "#808080" }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        onMouseEnter={(e) => {
                          this.overInProcess(e, "usersWithLicense");
                        }}
                        onMouseLeave={(e) => {
                          this.onLeaves(e);
                        }}
                      >
                        <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
                      </svg> 
                    <td
                      viewBox="0 0 512 512"
                      onMouseEnter={(e) => {
                        this.overInProcess(e, "usersWithLicense");
                      }}
                      onMouseLeave={(e) => {
                        this.onLeaves(e);
                      }}
                    >
                      {t("command.withLicense.users")}
                    </td>
                    <td>
                      {
                        this.props.commandStore.cityHallData.licenses
                          .withLicense.users
                      }
                    </td>
                    {Object.values(
                      this.props.commandStore.cityHallData.licenses.withLicense
                        .websites
                    ).map((currentValue, i) => (
                      <td key={"b" + currentValue + i}>{"-"}</td>
                    ))}
                  </tr>*/}

                                    <tr className="command-good-tr">
                                        {/* <svg
                        className="fas fa-info-circle"
                        style={{ width: "1rem", fill: "#808080" }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        onMouseEnter={(e) => {
                          this.overInProcess(e, "withAddressWithLicense");
                        }}
                        onMouseLeave={(e) => {
                          this.onLeaves(e);
                        }}
                      >
                        <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
                    </svg> */}
                                        <td
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "withAddressWithLicense"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t(
                                                "command.withLicense.withAddress"
                                            )}
                                        </td>
                                        <td
                                            onClick={() => {
                                                this.props.commandStore.tableToWA(
                                                    "licenseOnPortal",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.commandStore
                                                    .cityHallData.licenses
                                                    .withLicense.withAddress
                                                    .count
                                            }
                                        </td>
                                        {Object.keys(
                                            this.props.commandStore.cityHallData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <td
                                                key={
                                                    "" +
                                                    this.props.commandStore
                                                        .cityHallData.licenses
                                                        .withLicense.withAddress
                                                        .websites[
                                                        currentValue
                                                    ] +
                                                    i
                                                }
                                                onClick={() => {
                                                    this.props.commandStore.tableToWA(
                                                        "licenseOnPortal",
                                                        currentValue
                                                    );
                                                }}
                                            >
                                                {
                                                    this.props.commandStore
                                                        .cityHallData.licenses
                                                        .withLicense.withAddress
                                                        .websites[currentValue]
                                                }
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <table className="table table-borderless">
                                <thead>
                                    <tr>
                                        <th>{t("municipality.doubt")}</th>

                                        <th>{t("municipality.total")}</th>
                                        {Object.keys(
                                            this.props.commandStore.cityHallData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <th key={"" + currentValue + i}>
                                                {currentValue}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="command-doubt-tr">
                                        {/* <svg
                        className="fas fa-info-circle"
                        style={{ width: "1rem", fill: "#808080" }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        onMouseEnter={(e) => {
                          this.overInProcess(e,"withError");
                        }}
                        onMouseLeave={(e) => {
                          this.onLeaves(e);
                        }}
                      >
                        <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
                      </svg> */}
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "withError"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("command.withErrorLicense.name")}
                                        </td>
                                        <td
                                            onClick={() => {
                                                this.props.commandStore.tableToWA(
                                                    "errorLicense",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.commandStore
                                                    .cityHallData.licenses
                                                    .withErrorLicense.count
                                            }
                                        </td>
                                        {Object.keys(
                                            this.props.commandStore.cityHallData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <td
                                                key={
                                                    "" +
                                                    this.props.commandStore
                                                        .cityHallData.licenses
                                                        .withErrorLicense
                                                        .websites[
                                                        currentValue
                                                    ] +
                                                    i
                                                }
                                                onClick={() => {
                                                    this.props.commandStore.tableToWA(
                                                        "errorLicense",
                                                        currentValue
                                                    );
                                                }}
                                            >
                                                {
                                                    this.props.commandStore
                                                        .cityHallData.licenses
                                                        .withErrorLicense
                                                        .websites[currentValue]
                                                }
                                            </td>
                                        ))}
                                    </tr>
                                    <tr className="command-doubtsub-tr">
                                        {/* <svg
                        className="fas fa-info-circle"
                        style={{ width: "1rem", fill: "#808080" }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        onMouseEnter={(e) => {
                          this.overInProcess(e, "withErrorExempt");
                        }}
                        onMouseLeave={(e) => {
                          this.onLeaves(e);
                        }}
                      >
                        <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
                      </svg> */}
                                        <td
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "withErrorExempt"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t(
                                                "command.withErrorLicense.exempt"
                                            )}
                                        </td>

                                        <td
                                            onClick={() => {
                                                this.props.commandStore.tableToWA(
                                                    "exempt",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.commandStore
                                                    .cityHallData.licenses
                                                    .withErrorLicense.exempt
                                                    .count
                                            }
                                        </td>
                                        {Object.keys(
                                            this.props.commandStore.cityHallData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <td
                                                key={
                                                    "" +
                                                    this.props.commandStore
                                                        .cityHallData.licenses
                                                        .withErrorLicense.exempt
                                                        .websites[
                                                        currentValue
                                                    ] +
                                                    i
                                                }
                                                onClick={() => {
                                                    this.props.commandStore.tableToWA(
                                                        "exempt",
                                                        currentValue
                                                    );
                                                }}
                                            >
                                                {
                                                    this.props.commandStore
                                                        .cityHallData.licenses
                                                        .withErrorLicense.exempt
                                                        .websites[currentValue]
                                                }
                                            </td>
                                        ))}
                                    </tr>
                                    <tr className="command-doubtsub-tr">
                                        {/* <svg
                        className="fas fa-info-circle"
                        style={{ width: "1rem", fill: "#808080" }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        onMouseEnter={(e) => {
                          this.overInProcess(e, "withErrorNoLicense");
                        }}
                        onMouseLeave={(e) => {
                          this.onLeaves(e);
                        }}
                      >
                        <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
                      </svg> */}
                                        <td
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "withErrorNoLicense"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t(
                                                "command.withErrorLicense.noLicense"
                                            )}
                                        </td>

                                        <td
                                            onClick={() => {
                                                this.props.commandStore.tableToWA(
                                                    "noLicense",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.commandStore
                                                    .cityHallData.licenses
                                                    .withErrorLicense.noLicense
                                                    .count
                                            }
                                        </td>
                                        {Object.keys(
                                            this.props.commandStore.cityHallData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <td
                                                key={
                                                    "" +
                                                    this.props.commandStore
                                                        .cityHallData.licenses
                                                        .withErrorLicense
                                                        .noLicense.websites[
                                                        currentValue
                                                    ] +
                                                    i
                                                }
                                                onClick={() => {
                                                    this.props.commandStore.tableToWA(
                                                        "noLicense",
                                                        currentValue
                                                    );
                                                }}
                                            >
                                                {
                                                    this.props.commandStore
                                                        .cityHallData.licenses
                                                        .withErrorLicense
                                                        .noLicense.websites[
                                                        currentValue
                                                    ]
                                                }
                                            </td>
                                        ))}
                                    </tr>
                                    <tr className="command-doubtsub-tr">
                                        {/* <svg
                        className="fas fa-info-circle"
                        style={{ width: "1rem", fill: "#808080" }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        onMouseEnter={(e) => {
                          this.overInProcess(e, "withErrors");
                        }}
                        onMouseLeave={(e) => {
                          this.onLeaves(e);
                        }}
                      >
                        <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
                      </svg> */}
                                        <td
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "withErrors"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t(
                                                "command.withErrorLicense.withErrors"
                                            )}
                                        </td>

                                        <td
                                            onClick={() => {
                                                this.props.commandStore.tableToWA(
                                                    "errorOnLicenseCode",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.commandStore
                                                    .cityHallData.licenses
                                                    .withErrorLicense.withErrors
                                                    .count
                                            }
                                        </td>
                                        {Object.keys(
                                            this.props.commandStore.cityHallData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <td
                                                key={
                                                    "" +
                                                    this.props.commandStore
                                                        .cityHallData.licenses
                                                        .withErrorLicense
                                                        .withErrors.websites[
                                                        currentValue
                                                    ] +
                                                    i
                                                }
                                                onClick={() => {
                                                    this.props.commandStore.tableToWA(
                                                        "errorOnLicenseCode",
                                                        currentValue
                                                    );
                                                }}
                                            >
                                                {
                                                    this.props.commandStore
                                                        .cityHallData.licenses
                                                        .withErrorLicense
                                                        .withErrors.websites[
                                                        currentValue
                                                    ]
                                                }
                                            </td>
                                        ))}
                                    </tr>
                                    <tr className="command-doubt-tr">
                                        {/* <svg
                        className="fas fa-info-circle"
                        style={{ width: "1rem", fill: "#808080" }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        onMouseEnter={(e) => {
                          this.overInProcess(e, "licenseDuplicated");
                        }}
                        onMouseLeave={(e) => {
                          this.onLeaves(e);
                        }}
                      >
                        <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
                      </svg> } */}
                                        <td
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "licenseDuplicated"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t(
                                                "command.withLicense.licenseDuplicated"
                                            )}
                                        </td>

                                        <td
                                            onClick={() => {
                                                this.props.commandStore.tableToWA(
                                                    "licenseDuplicated",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.commandStore
                                                    .cityHallData.licenses
                                                    .withLicense.duplicated
                                            }
                                        </td>
                                        {Object.values(
                                            this.props.commandStore.cityHallData
                                                .licenses.withLicense.websites
                                        ).map((currentValue, i) => (
                                            <td key={"a" + currentValue + i}>
                                                {"-"}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr className="command-doubt-tr">
                                        {/* <svg
                        className="fas fa-info-circle"
                        style={{ width: "1rem", fill: "#808080" }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        onMouseEnter={(e) => {
                          this.overInProcess(e, "idOwnerDuplicated");
                        }}
                        onMouseLeave={(e) => {
                          this.onLeaves(e);
                        }}
                      >
                        <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
                      </svg> } */}
                                        <td
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "idOwnerDuplicated"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t(
                                                "command.withErrorLicense.idDuplicated"
                                            )}
                                        </td>
                                        <td
                                            onClick={() => {
                                                this.props.commandStore.tableToWA(
                                                    "idOwnerDuplicated",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.commandStore
                                                    .cityHallData.licenses
                                                    .withErrorLicense.duplicated
                                            }
                                        </td>
                                        {Object.values(
                                            this.props.commandStore.cityHallData
                                                .licenses.withErrorLicense
                                                .websites
                                        ).map((currentValue, i) => (
                                            <td key={"c" + currentValue + i}>
                                                {"-"}
                                            </td>
                                        ))}
                                    </tr>
                                    {/* <tr>
                    <svg
                        className="fas fa-info-circle"
                        style={{ width: "1rem", fill: "#808080" }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        onMouseEnter={(e) => {
                          this.overInProcess(e, "usersWithErrorLicense");
                        }}
                        onMouseLeave={(e) => {
                          this.onLeaves(e);
                        }}
                      >
                        <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
                    </svg>
                    <td
                      viewBox="0 0 512 512"
                      onMouseEnter={(e) => {
                        this.overInProcess(e, "usersWithErrorLicense");
                      }}
                      onMouseLeave={(e) => {
                        this.onLeaves(e);
                      }}
                    >
                      {t("command.withErrorLicense.differentUsers")}
                    </td>
                    <td>
                      {
                        this.props.commandStore.cityHallData.licenses
                          .withErrorLicense.users
                      }
                    </td>
                    {Object.values(
                      this.props.commandStore.cityHallData.licenses
                        .withErrorLicense.websites
                    ).map((currentValue, i) => (
                      <td key={"d" + currentValue + i}>{"-"}</td>
                    ))}
                  </tr> */}
                                    <tr className="command-doubt-tr">
                                        {/* <svg
                        className="fas fa-info-circle"
                        style={{ width: "1rem", fill: "#808080" }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        onMouseEnter={(e) => {
                          this.overInProcess(e, "WithErrorWithAddress");
                        }}
                        onMouseLeave={(e) => {
                          this.onLeaves(e);
                        }}
                      >
                        <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
                    </svg> */}
                                        <td
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "WithErrorWithAddress"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t(
                                                "command.withErrorLicense.withAddress"
                                            )}
                                        </td>
                                        <td
                                            onClick={() => {
                                                this.props.commandStore.tableToWA(
                                                    "errorOnPortal",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.commandStore
                                                    .cityHallData.licenses
                                                    .withErrorLicense
                                                    .withAddress.count
                                            }
                                        </td>
                                        {Object.keys(
                                            this.props.commandStore.cityHallData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <td
                                                key={
                                                    "" +
                                                    this.props.commandStore
                                                        .cityHallData.licenses
                                                        .withErrorLicense
                                                        .withAddress.websites[
                                                        currentValue
                                                    ] +
                                                    i
                                                }
                                                onClick={() => {
                                                    this.props.commandStore.tableToWA(
                                                        "errorOnPortal",
                                                        currentValue
                                                    );
                                                }}
                                            >
                                                {
                                                    this.props.commandStore
                                                        .cityHallData.licenses
                                                        .withErrorLicense
                                                        .withAddress.websites[
                                                        currentValue
                                                    ]
                                                }
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>

                            {this.props.commandStore.errorGroupAdsCount ? (
                            <p>
                                {
                                this.props.commandStore.cityHallData
                                .licenses.withErrorLicense.count +
                                    t("command.ads") +
                                    this.props.commandStore.errorGroupAdsCount +
                                    t("command.reducted") +
                                    Math.round(
                                        ((1 -
                                            this.props.commandStore
                                                   .errorGroupAdsCount
                                                   /
                                            this.props.commandStore.cityHallData
                                                .licenses.withErrorLicense
                                                .count 
                                                /// this.props.commandStore.errorGroupAdsCount
                                                
                                                    ) *
                                            100 +
                                            Number.EPSILON) *
                                            100
                                    ) /
                                        100 +
                                        t("command.percentage") 
                                }
                            </p>
                        ) : null}

                            {this.props.commandStore.cityHallData
                                        .licenses.withErrorLicense.realFraud  ? (
                            <table className="table table-borderless">
                                <thead>
                                    <tr>
                                        <th>{t("municipality.fraud")}</th>

                                        <th>{t("municipality.total")}</th>
                                        {Object.keys(
                                            this.props.commandStore.cityHallData
                                                .websites
                                        ).map((currentValue, i) => (
                                            <th key={"" + currentValue + i}>
                                                {currentValue}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                   
                                        <tr className="command-bad-tr">
                                            {/* <svg
                            className="fas fa-info-circle"
                            style={{ width: "1rem", fill: "#808080" }}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            onMouseEnter={(e) => {
                              this.overInProcess(e, "fraud");
                            }}
                            onMouseLeave={(e) => {
                              this.onLeaves(e);
                            }}
                          >
                            <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
                          </svg> */}

                                            <td
                                                viewBox="0 0 512 512"
                                                onMouseEnter={(e) => {
                                                    this.overInProcess(
                                                        e,
                                                        "fraud"
                                                    );
                                                }}
                                                onMouseLeave={(e) => {
                                                    this.onLeaves(e);
                                                }}
                                            >
                                                {t(
                                                    "command.withErrorLicense.fraud"
                                                )}
                                            </td>

                                            <td
                                                onClick={() => {
                                                    this.props.commandStore.tableToWA(
                                                        "realFraud",
                                                        "total"
                                                    );
                                                }}
                                            >
                                                {
                                                    this.props.commandStore
                                                        .cityHallData.licenses
                                                        .withErrorLicense
                                                        .realFraud.count
                                                }
                                            </td>

                                            {Object.keys(
                                                this.props.commandStore
                                                    .cityHallData.websites
                                            ).map((currentValue, i) => (
                                                <td
                                                    key={
                                                        "" +
                                                        this.props.commandStore
                                                            .cityHallData
                                                            .licenses
                                                            .withErrorLicense
                                                            .realFraud.websites[
                                                            currentValue
                                                        ] +
                                                        i
                                                    }
                                                    onClick={() => {
                                                        this.props.commandStore.tableToWA(
                                                            "realFraud",
                                                            currentValue
                                                        );
                                                    }}
                                                >
                                                    {
                                                        this.props.commandStore
                                                            .cityHallData
                                                            .licenses
                                                            .withErrorLicense
                                                            .realFraud.websites[
                                                            currentValue
                                                        ]
                                                    }
                                                </td>
                                            ))}
                                        </tr>
                                    
                                </tbody>
                            </table> 
                            ): null}
                        </div>
                        
                        {/* { this.props.commandStore.cityHallData.licenses.withErrorLicense.count} */}
                        {/*Total anuncis dubtosos (llincencia incorrecte) municipi i numero de grups*/}
                        
                    </div>
                </div>
                <div className="col-md-3"></div>
            </>
        ) : (
            <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                    <div className="row stats-table">
                        <h4 className="stats-title">
                            {this.props.commandStore.cityName}
                        </h4>
                        {/*<div className="quick-search-statistics">
              <Stats cityhall="true"></Stats>
    </div>*/}
                    </div>
                </div>
                <div className="col-md-3"></div>
            </div>
        );
    }
}

export default withTranslation()(Command);
