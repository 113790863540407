import { observable, action } from "mobx";

class HostStore {
	@observable data = null;
	@observable filter = "name"
	@observable adList = null;

	constructor(rootStore) {
		this.rootStore = rootStore;
		this.adList = new Array(this.rootStore.pageStore.len)
	}

	@action("Get Next Data") getNext = async (num)=>{
		this.adList = new Array(this.rootStore.pageStore.len)
		this.rootStore.pageStore.nextPage(num);
		this.data = this.rootStore.pageStore.pageArray[this.rootStore.pageStore.page]
	}

	@action("Get Previous Data") getPrevious = async (num)=>{
		this.adList = new Array(this.rootStore.pageStore.len)
		this.rootStore.pageStore.previousPage(num);
		this.data = this.rootStore.pageStore.pageArray[this.rootStore.pageStore.page]
	}

	@action("Get Last Data") getLast = async ()=>{
		this.adList = new Array(this.rootStore.pageStore.len)
		this.rootStore.pageStore.setPage(this.rootStore.pageStore.totalPages-1)
		this.data = this.rootStore.pageStore.pageArray[this.rootStore.pageStore.totalPages-1]
	}

	@action("Get First Data") getFirst = async ()=>{
		this.adList = new Array(this.rootStore.pageStore.len)
		this.rootStore.pageStore.setPage(0)
		this.data = this.rootStore.pageStore.pageArray[0]
	}

	/***
	*	Prepara els diferents ads de cada host al clicar, també mou el mapa a on toca.
	*	hostname: Nom del host
	*	i: posició en l'array d'ads
	***/
	@action("Get ads data") getAdsData = async (hostname,i)=>{
		try {
			if(this.adList[i]) this.adList[i] = null;
			else {
				let ads = await this.rootStore.requestStore.getAdsByHost(hostname);
				this.adList[i] = ads.data;
				await this.rootStore.mapListStore.printAdsFromList(ads.data);
			}
		} catch(error) {
	      if (error.toString() === "Error: AuthError")
	        console.log("Authentication failed");
			}
		}


	/***
	*	Agafa de la base de dades els diferents hosts de les zones que l'usuari connectat te assignades i les pagina per a poder ser mostrades
	*	fliterValue: Variable per la cual s'ordenarà la paginació
	***/
	@action("Get Hosts data") getHosts = async (filterValue)=>{
			try {
			this.adList = new Array(this.rootStore.pageStore.len)
			let user = await this.rootStore.sessionStore.getSession()
			user = user.username

			if(filterValue === this.filter) this.rootStore.pageStore.changeDirection();
			else this.rootStore.pageStore.restartDirection();
			this.filter = filterValue;
			this.rootStore.pageStore.setPage(0);
			this.rootStore.pageStore.setTotalData(0);
			let auxArray = [];
			let auxData = 0;
			let params = {
				filter: this.filter,
				len:this.rootStore.pageStore.len,
				dir:this.rootStore.pageStore.searchDirection,
				username:user,
				minPrice:0,
				maxPrice:999999999999999,
				platform:"none",
				license:"none",
				firstMonth:1,
				lastMonth:12,
				minRes:0,
				maxRes:999999999999999
			}
			let aux = await this.rootStore.requestStore.getInitialHosts(params);
			for(let i = 1; aux.data.length > 0 && i < this.rootStore.pageStore.maxPages; i++ ){
				auxData += aux.data.length;
				auxArray.push(aux.data);
				params.last = null;
				switch (this.filter) {
					/*case "num":
						last = auxArray[i-1][auxArray[i-1].length-1].count;
						break;*/
					default:
						params.last = auxArray[i-1][auxArray[i-1].length-1]._id;
				}
				aux = await this.rootStore.requestStore.getHosts(params);
			}
			if (aux.data.length > 0) {
				auxArray.push(aux.data);
				auxData += aux.data.length;
			}
			this.rootStore.pageStore.setTotalData(auxData);
			this.rootStore.pageStore.setPageArray(auxArray);
			this.data = this.rootStore.pageStore.pageArray[0]


		} catch(error) {
	      if (error.toString() === "Error: AuthError")
	        console.log("Authentication failed");
			}
		}
}

export default HostStore;
