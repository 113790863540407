import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { observer, inject } from "mobx-react";
import Link from "../basicUI/LinkComponent";
import MyInput from "../basicUI/MyInput";
import MyButton from "../basicUI/MyButton";
import logo from "../../assets/icons/logo_stl_pro.svg";
import background from "../../assets/img/screen-login.jpg";
import { withTranslation } from "react-i18next";

@inject("forgotPasswordStore", "rootStore")
@observer
class ForgotPassword extends Component {
  componentDidMount() {
    try {
      document.title = 'STLPRO - Forget Password'
      document.getElementById("boop").style[
        "backgroundImage"
      ] = `url(${background})`;
      document.getElementById("boop").style["backgroundAttachment"] = "fixed";
      document.getElementById("boop").style["backgroundPosition"] = "center top";
    } catch (error) {
      if (error.toString() === "Error")
        console.log("Authentication failed");
    }
  }

  componentWillUnmount() {
    document.getElementById("boop").style["backgroundImage"] = "";
    document.getElementById("boop").style["backgroundAttachment"] = "";
    document.getElementById("boop").style["backgroundPosition"] = "";
    this.props.forgotPasswordStore.updateProperty("showMessage", false);
    this.props.forgotPasswordStore.updateProperty("showError", false);
  }

  showHidden = id => {
    var m = document.getElementById("message");
    var e = document.getElementById("username");
    var c = document.getElementById("group");
    var b = document.getElementById("reset-pass");

    if (this.props.forgotPasswordStore.formIsValid) {
      if (id === "reset-pass") {
        if (m.style.display === "none") {
          m.style.display = "block";
          e.style.display = "none";
          c.style.display = "none";
          b.style.display = "none";
        } else {
          m.style.display = "block";
          e.style.display = "none";
          c.style.display = "none";
        }
      }
    }
  };

  render() {
    const {t} = this.props
    if (!this.props.rootStore.sessionStore.hasSession) {
      if (this.props.forgotPasswordStore.isLoading) {
        return (
          <main className="container-fluid">
            <div className="row">
              <div className="col-md-3 login">
                <div className="card card-login">
                  <div className="card-body">
                    <form
                      id="passform"
                      onSubmit={this.props.forgotPasswordStore.sendEmail}
                    >
                      <img
                        className="icon-login"
                        src={logo}
                        width="60%"
                        alt="logo"
                      />
                      <div className="form-row">
                        <div className="col-md-12 mb-3">
                          <div className="login-fields">
                            <div>{t("processing")}</div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </main>
        );
      }
      return (
        <main className="container-fluid">
          <div className="row">
            <div className="col-md-3 login">
              <div className="card card-login">
                <div className="card-body">
                  <form
                    id="passform"
                    onSubmit={this.props.forgotPasswordStore.sendEmail}
                  >
                    <img
                      className="icon-login"
                      src={logo}
                      width="60%"
                      alt="logo"
                    />
                    <div className="form-row">
                      <div className="col-md-12 mb-3">
                        <div className="login-fields">
                          {this.props.forgotPasswordStore.showMessage
                            ? this.showHidden("reset-pass")
                            : null}
                          <div className="form-group">
                            <MyInput
                              visible="false"
                              id="username"
                              className="input-login"
                              label="Nom d'usuari"
                              name="username"
                              value={
                                this.props.forgotPasswordStore
                                  .forgotPasswordForm.username
                              }
                              onChange={
                                this.props.forgotPasswordStore
                                  .updateFormProperty
                              }
                              placeholder={t("forgotPassword.user")}
                            />
                            {this.props.forgotPasswordStore.showError && !this.props.forgotPasswordStore.hasUsername && (
                              <div id="message-error">
                                <p style={{ color: "red" }}>
                                  {t("forgotPassword.writeUser")}
                              </p>
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <MyInput
                              visible="false"
                              id="group"
                              className="input-login"
                              label="Grup"
                              name="group"
                              value={
                                this.props.forgotPasswordStore
                                  .forgotPasswordForm.group
                              }
                              onChange={
                                this.props.forgotPasswordStore
                                  .updateFormProperty
                              }
                              placeholder={t("forgotPassword.code")}
                            />
                            {this.props.forgotPasswordStore.showError && !this.props.forgotPasswordStore.hasGroup && (
                              <div id="message-error">
                                <p style={{ color: "red" }}>
                                  {t("forgotPassword.writeGroup")}
                              </p>
                              </div>
                            )}
                          </div>
                          <div
                            id="message"
                            className="message"
                            style={{ display: "none" }}
                          >{t("forgotPassword.sent")}
                          </div>
                        </div>
                      </div>
                    </div>
                    <MyButton
                      id="reset-pass"
                      type="submit"
                      className="btn form-button-able"
                      text={t("forgotPassword.forgot")}
                      onSubmit={this.props.forgotPasswordStore.sendEmail}
                    />
                    <div className="forgotten-pass">
                      <Link
                        to="../login"
                        text={t("forgotPassword.goToLogin")}
                        className="nav-link"
                        href="#"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      );
    } else
      return (
        <Redirect
          to={{ pathname: "/", state: { from: this.props.location } }}
        />
      );
  }
}

export default withTranslation()(ForgotPassword);
