import { observable, action, computed } from "mobx";

class ContactStore {

	constructor(rootStore) {
		this.rootStore = rootStore;
		this.updateFormProperty = this.updateFormProperty.bind(this)
	}

	// Form fields
	@observable contactForm = {
		title: "",
		description: ""
	}

	// Control variables
	@observable formSent = false
	@observable formCouldntBeSent = false
	@observable formIsValid = true
	@observable emailConfirmed = false
	@observable showError = false
	@observable isLoading = false

	@observable showAlert = false;
	@observable confirmAlert = false;

	@action("update form property") updateFormProperty(key, value) {
		this.contactForm[key] = value;		
	}

	@action("update property not in form") updateProperty(key, value) {
		this[key] = value
	}

	@computed get hasTitle() {
		return this.formSent && (this.contactForm.title !== '')
	}
	@computed get hasDescription() {
		return this.formSent && (this.contactForm.description !== '')
	}

	@computed get checkIfFormIsValid() {
		return this.hasTitle
			&& this.hasDescription
	};

	toggleAlert = () => {
		this.updateProperty('showAlert', true)
		setTimeout(() => {
			this.updateProperty('showAlert', false)
		}, 5000)
	}

	toggleConfirm = () => {
		this.updateProperty('confirmAlert', true)
		setTimeout(() => {
			this.updateProperty('confirmAlert', false)
		}, 5000)
	}

	@action("send contact email") sendContactEmail = async () => {
		try {
			this.updateProperty('formSent', true)
			// this.updateProperty('showError', false)
			this.updateProperty('formIsValid', this.checkIfFormIsValid)
			if (this.formIsValid) {
				this.updateProperty('isLoading', true)
				const response = await this.rootStore.requestStore.sendContactEmail(
					this.contactForm.title,
					this.contactForm.description
				)

				if (response === undefined) {
					this.toggleAlert()
					// this.updateProperty('showError', true)
				} else {

					this.toggleConfirm()

					this.updateFormProperty('title', '')
					this.updateFormProperty('description', '')
					this.updateProperty('emailConfirmed', true)
					// this.updateProperty('showError', false)
				}
				this.updateProperty('formSent', false)
				this.updateProperty('isLoading', false)
			}
		} catch (error) {
			if (error.toString() === "Error: AuthError")
				console.log("Authentication failed");
			// this.updateProperty('showError', true)
			this.updateProperty('formSent', false)
			this.updateProperty('isLoading', false)
		}
	}
	@action ("get user") getUser = async () => {
		try {
			let user = await this.rootStore.sessionStore.getSession();
              user = (await this.rootStore.requestStore.getUserById(user.id))
                .data;
			return user
		} catch (error) {
			if (error.toString() === "Error: AuthError")
				console.log("Authentication failed");
		}
	}

	
}
export default ContactStore;
