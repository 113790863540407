import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import MyButton from "../basicUI/MyButton";
import MyInput from "../basicUI/MyInput";
import MyModal from "../basicUI/MyModal";
//import MyTextArea from "../basicUI/MyTextArea";
import MyRadioButton from "../basicUI/MyRadioButton";
import Map from "../Map/GroupAdsMap";
import Spinner from "react-bootstrap/Spinner";

import "../../assets/css/bootstrap.min.css";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { withTranslation } from "react-i18next";

const { BroadcastChannel } = require("broadcast-channel");
const channelPin = new BroadcastChannel("pin");
const channelFav = new BroadcastChannel("fav");
const channelAbbrv = new BroadcastChannel("abbrv");

@inject(
  "userProfileStore",
  "userStore",
  "navigationStore",
  "processStore",
  "groupAdsStore",
  "mapListStore",
  "groupEditorStore"
)
@observer
class GroupAds extends Component {
  maxCards = 4;

  async componentDidMount() {
    console.log("FENT DID MOUNT");
    try {
      let { t } = this.props;
      document.title = t("ga.title");
      const {
        match: {
          params: { code },
        },
      } = this.props;
      //profile function ASDASD
      await this.props.groupAdsStore.getAdGroupByCode(code);
      let tags = document.getElementById("tags");
      if (tags) {
        tags.value = this.props.groupAdsStore.convertToFormat2(tags.value);
      }
      //}
      //await this.props.groupAdsStore.findSubRegions();
      //this.props.groupAdsStore.user = await this.props.groupAdsStore.getUser();
      //await this.props.groupAdsStore.getAdsByCode(code);

      this.slideCarousel(-1);
      //New part

      //process part
      //await this.props.processStore.getProcessList();
      //this.props.groupAdsStore.toggleAlert()

      if (
        this.props.groupAdsStore.processes.length !==
        this.props.groupAdsStore.processesFromStore.length
      ) {
        if (this.props.groupAdsStore.processes.length === 0) {
          let proc = JSON.parse(
            JSON.stringify(this.props.groupAdsStore.processesFromStore)
          );
          this.props.groupAdsStore.processes = proc;
        } else {
          for (
            let i = this.props.groupAdsStore.processes.length;
            i < this.props.groupAdsStore.processesFromStore.length;
            ++i
          ) {
            let proc2 = JSON.parse(
              JSON.stringify(this.props.groupAdsStore.processesFromStore[i])
            );
            this.props.groupAdsStore.processes.push(proc2);
          }
        }
        await this.props.groupAdsStore.updateProcesses();
      }
      channelPin.addEventListener("message", async (event) => {
        if (event.id === this.props.groupAdsStore.adGroupForm.id) {
          this.props.mapListStore.userWhoHasIt = event.user;
          this.props.mapListStore.changeStyle(
            this.props.groupAdsStore.adGroupForm.id,
            "pin"
          );
          this.props.groupAdsStore.ads.forEach((child) => {
            this.props.mapListStore.changeStyle(child.id, "pin");
          });
          await this.props.mapListStore.updateMarkerAndCard(
            this.props.groupAdsStore.adGroupForm.id
          );
          this.props.groupAdsStore.workingUser = event.user;
          let mod = document.getElementsByClassName("modalw display-block");
          if (mod.length > 0) this.props.groupAdsStore.close(false);
        }
      });
      channelFav.addEventListener("message", async (event) => {
        if (this.props.mapListStore.user === null)
          this.props.mapListStore.user = this.props.groupAdsStore.user;
        this.props.mapListStore.user.favAds = event.favAds;
        this.props.groupAdsStore.user.favAds = event.favAds;
        if (event.code === this.props.groupAdsStore.adGroupForm.id) {
          this.props.mapListStore.changeStyle(
            this.props.groupAdsStore.adGroupForm.id,
            "fav",
            event.code
          );
          this.props.groupAdsStore.ads.forEach((child) => {
            this.props.mapListStore.changeStyle(child.id, "fav", event.code);
          });
          await this.props.mapListStore.updateMarkerAndCard(
            this.props.groupAdsStore.adGroupForm.id
          );
        }
      });
      //this.props.groupAdsStore.convertToFormat(this.props.groupAdsStore.adGroupForm.tags)
      channelAbbrv.addEventListener("message", async (event) => {
        if (event.id === this.props.groupAdsStore.adGroupForm.id) {
          this.props.groupAdsStore.statesGroup = event.statesGroup;
          this.props.groupAdsStore.indexSituation = event.index;
          if (event.statesGroup.message && event.statesGroup.message.notes)
            this.props.groupAdsStore.notesForm =
              event.statesGroup.message.notes;
          else this.props.groupAdsStore.notesForm = "";
          let elem2 = document.getElementById("inputGroupSelect01");
          let name =
            this.props.groupAdsStore.states[
              this.props.groupAdsStore.indexSituation
            ].name;
          for (let j in this.props.groupAdsStore.states) {
            if (this.props.groupAdsStore.states[j].name !== name) {
              let elem = document.getElementById(
                "id_" + this.props.groupAdsStore.states[j].name
              );
              if (elem) elem.checked = false;
            } else {
              let elem = document.getElementById(
                "id_" + this.props.groupAdsStore.states[j].name
              );
              if (elem) elem.checked = true;
            }
          }
          if (elem2) {
            for (let j = 0; j < elem2.length; j++) {
              var option = elem2.options[j];
              if (this.props.groupAdsStore.statesGroup.value === null) {
                if (option.value === "choose") option.selected = true;
                else option.selected = false;
              } else if (this.props.groupAdsStore.statesGroup.value) {
                if (option.value === this.props.groupAdsStore.statesGroup.value)
                  option.selected = true;
                else option.selected = false;
              }
            }
          }
        }
        
        
      }
      );
      if (this.props.userStore.user.productsExtra.compliance){
        await this.props.groupAdsStore.searchComplianceForGroupAd(code)

      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  async componentWillUnmount() {
    console.log("FENT UNMOUNT");
    this.props.groupAdsStore.ads = [];
    this.props.groupAdsStore.contactMessages = [];
    this.props.groupAdsStore.noDiscards = [];
    this.props.groupAdsStore.noDiscardsdiscards = [];
    this.props.groupAdsStore.selectedAdIndex = -1;
    this.props.groupAdsStore.index = 0;
    this.props.groupAdsStore.user = null;
    this.props.groupAdsStore.updateProperty("showAlert", false);
    this.props.groupAdsStore.updateProperty("confirmAlert", false);
    this.props.groupAdsStore.openModal = false;
    this.props.groupAdsStore.showHideModal = false;
    this.props.groupAdsStore.closing = false;
    this.props.groupAdsStore.saving = false;
    this.props.groupAdsStore.edit2 = false;
    this.props.groupAdsStore.addState = false;
    this.props.groupAdsStore.retrievedMunicipality = "";
    //this.props.mapListStore.iDiv = undefined
  }

  onDisableTrue = () => {
    const allInputs = document.getElementsByClassName("controlDisable");
    for (let i = 0; i < allInputs.length; i++) {
      allInputs[i].disabled = false;
    }
    document.getElementById("disable_false").style.display = "inline";
    document.getElementById("disable_false2").style.display = "inline";
    this.props.groupAdsStore.editing = true;
  };

  onDisableFalse = () => {
    // console.log("1");
    const allInputs = document.getElementsByClassName("controlDisable");
    for (let i = 0; i < allInputs.length; i++) {
      allInputs[i].disabled = true;
    }
    if (document.getElementById("disable_false")) {
      // console.log("inside if disable_false");
      document.getElementById("disable_false").style.display = "none";
    }
    if (document.getElementById("disable_false2")) {
      // console.log("inside if disable_false2");
      document.getElementById("disable_false2").style.display = "none";
    }
    this.props.groupAdsStore.editing = false;
    // console.log("2");
  };

  updateF = (key, value) => {
    this.props.groupAdsStore.updateFormProperty(key, value);
    //this.changes()
  };

  discard = async () => {
    await this.props.groupAdsStore.discardChanges();
    this.onDisableFalse();
    this.props.groupAdsStore.resetClassName("longitude");
    this.props.groupAdsStore.resetClassName("latitude");
    this.props.groupAdsStore.resetClassName("tags");
    let tags = document.getElementById("tags");
    if (tags) {
      tags.value = this.props.groupAdsStore.convertToFormat2(tags.value);
    }
    // this.props.userProfileStore.resetClassName("username")
    // this.props.userProfileStore.resetClassName("email")
    // this.props.userProfileStore.resetClassName("group")
  };

  save = async () => {
    // console.log("save 1");
    let formCopy = JSON.parse(
      JSON.stringify(this.props.groupAdsStore.adGroupForm)
    );
    // console.log("save 2");
    if (formCopy.tags instanceof Array) {
      // console.log("save 3");
      formCopy.tags = this.props.groupAdsStore.convertToFormat(formCopy.tags);
    } else {
      // console.log("save 4");
      formCopy.tags = this.props.groupAdsStore.convertToFormat2(formCopy.tags);
    }
    let tags = [];
    let splitTags = formCopy.tags.split(/,| /);
    // console.log("save 5");
    for (let i in splitTags) {
      if (splitTags[i] !== "") tags.push(splitTags[i]);
    }
    // console.log("save 6");
    let valid3 = tags.length <= this.props.groupAdsStore.maxTags;
    // console.log("save 7");
    let valid =
      this.props.groupAdsStore.hasLongitude &&
      this.props.groupAdsStore.hasLatitude;
    // console.log("save 8");
    if (valid === true) {
      // console.log("save 9");
      let long = parseFloat(this.props.groupAdsStore.adGroupForm.longitude);
      let lat = parseFloat(this.props.groupAdsStore.adGroupForm.latitude);
      valid = long >= -180 && long <= 180 && lat >= -90 && lat <= 90;
      // console.log("save 10");
    }
    // console.log("save 11");
    let valid2 =
      !this.props.groupAdsStore.hasLongitude &&
      !this.props.groupAdsStore.hasLatitude;
    // console.log("save 12");
    if (valid3 && (valid || valid2)) {
      // console.log("save 13");
      await this.props.groupAdsStore.saveChanges();
      // console.log("save 14");
      this.onDisableFalse();
      // console.log("save 15");
      this.props.groupAdsStore.resetClassName("longitude");
      // console.log("save 16");
      this.props.groupAdsStore.resetClassName("latitude");
      // console.log("save 17");
      this.props.groupAdsStore.resetClassName("tags");
      // console.log("save 18");
      let tags = document.getElementById("tags");
      // console.log("save 19");
      // console.log("Before IF");
      // console.log(tags);
      // console.log("primera comprobación");
      // console.log(tags != null);
      // console.log("segunda comprobación");
      // console.log(tags == null);
      // console.log("1 line before IF");
      if (tags != null) {
        // console.log("save 20");
        tags.value = this.props.groupAdsStore.convertToFormat2(tags.value);
        // console.log("save 21");
      }
    } else {
      // console.log("save 22");
      this.changes(tags.length);
    }

    console.log("Exit Save");
    this.props.groupAdsStore.newObs = !this.props.groupAdsStore.newObs;
    console.log("forzando render");
    //this.render()
    this.forceUpdate();
    console.log("after forzar render");
    // this.props.userProfileStore.resetClassName("username")
    // this.props.userProfileStore.resetClassName("email")
    // this.props.userProfileStore.resetClassName("group")
    // this.user()
  };

  changes = (tags) => {
    if (
      !this.props.groupAdsStore.hasLongitude &&
      this.props.groupAdsStore.hasLatitude
    )
      this.props.groupAdsStore.updateInvalidClassName("longitude");
    else {
      let long = parseFloat(this.props.groupAdsStore.adGroupForm.longitude);
      if (long < -180 || long > 180)
        this.props.groupAdsStore.updateInvalidClassName("longitude");
      else this.props.groupAdsStore.resetClassName("longitude");
    }
    if (
      !this.props.groupAdsStore.hasLatitude &&
      this.props.groupAdsStore.hasLongitude
    )
      this.props.groupAdsStore.updateInvalidClassName("latitude");
    else {
      let lat = parseFloat(this.props.groupAdsStore.adGroupForm.latitude);
      if (lat < -90 || lat > 90)
        this.props.groupAdsStore.updateInvalidClassName("latitude");
      else this.props.groupAdsStore.resetClassName("latitude");
    }
    if (tags > this.props.groupAdsStore.maxTags) {
      this.props.groupAdsStore.updateInvalidClassName("tags");
    }
  };

  seenAd = (ad, id) => {
    var selAd = document.getElementById(ad);
    var selcol = document.getElementsByClassName(id);
    for (let l = 0; l < selcol.length; ++l) {
      selcol[l].className = selcol[l].className.replace("new-ad ", " ");
    }
    selAd.childNodes[0].className = selAd.childNodes[0].className.replace(
      " new-card-ad",
      " active-ad"
    );
    selAd.childNodes[0].childNodes[0].className =
      selAd.childNodes[0].childNodes[0].className.replace(" border-info", " ");
    // var btn = document.getElementById(ad + "-seenBtn");
    // btn.remove();

    //BD
    let variableStateName = "";
    for (let l = 0; l < this.props.groupAdsStore.ads.length; ++l) {
      // //    if(this.props.groupAdsStore.ads[l]._id === id) this.props.groupAdsStore.ads[l].adIsNew = false;
      // this.props.groupAdsStore.ads[l].adIsNew = false;
      if (this.props.groupAdsStore.ads[l].id === id) {
        this.props.groupAdsStore.ads[l].adIsSeen = true;
        //variableStateName= Es crea per actualitzar els filtres quan se incorpora un nou anunci al grup o una edici� de text
        variableStateName = this.props.groupAdsStore.statesGroup.name;
        this.props.groupAdsStore.ads[l].statesGroupname = variableStateName;
      }
    }
    this.props.groupAdsStore.sendUpdateStateToAd(id);
    if (variableStateName !=="") this.props.groupAdsStore.sendUpdateStateName(id, variableStateName);
  };

  discardAd = async (ad, id) => {
    var selAd = document.getElementById(ad);
    if (selAd.childNodes[0].classList.contains("new-card-ad")) {
      selAd.childNodes[0].className = selAd.childNodes[0].className.replace(
        " new-card-ad",
        " discard-card-ad"
      );
      selAd.childNodes[0].childNodes[0].className =
        selAd.childNodes[0].childNodes[0].className.replace(
          " border-info",
          " no-border"
        );
    } else {
      selAd.childNodes[0].className = selAd.childNodes[0].className.replace(
        " active-ad",
        " discard-card-ad"
      );
    }
    var selcol = document.getElementsByClassName(id);
    for (let l = 0; l < selcol.length; ++l) {
      if (selcol[l].classList.contains("new-ad"))
        selcol[l].className = selcol[l].className.replace(
          "new-ad ",
          "discard-ad "
        );
      else
        selcol[l].className = selcol[l].className.replace(
          id,
          "discard-ad " + id
        );
    }
    var btn = document.getElementById(ad + "-discardBtn");
    btn.style.display = "none";
    var keepBtn = document.getElementById(ad + "-keepBtn");
    keepBtn.style.display = "inline";

    //parte de la BD
    for (let l in this.props.groupAdsStore.ads) {
      if (this.props.groupAdsStore.ads[l].id === id)
        this.props.groupAdsStore.ads[l].discarded = true;
    }
    this.props.groupAdsStore.sendUpdateStateToDiscardAd(id);

    //
    //bloqueo
    await this.props.groupAdsStore.findSubRegions();

    //
  };

  keepAd = (ad, id) => {
    var selAd = document.getElementById(ad);
    selAd.childNodes[0].className = selAd.childNodes[0].className.replace(
      " discard-card-ad",
      " active-ad"
    );

    var selcol = document.getElementsByClassName(id);
    for (let l = 0; l < selcol.length; ++l) {
      if (document.getElementById(ad + "-seenBtn") !== null) {
        selcol[l].className = selcol[l].className.replace(
          "discard-ad ",
          "new-ad "
        );
        selAd.childNodes[0].className = selAd.childNodes[0].className.replace(
          " active-ad",
          " new-card-ad"
        );
        selAd.childNodes[0].childNodes[0].className =
          selAd.childNodes[0].childNodes[0].className.replace(
            " no-border",
            " border-info"
          );
      } else
        selcol[l].className = selcol[l].className.replace(
          "discard-ad " + id,
          id
        );
    }

    var btn = document.getElementById(ad + "-discardBtn");
    btn.style.display = "inline";
    var keepBtn = document.getElementById(ad + "-keepBtn");
    keepBtn.style.display = "none";

    //parte de la BD
    for (let l in this.props.groupAdsStore.ads) {
      if (this.props.groupAdsStore.ads[l].id === id)
        this.props.groupAdsStore.ads[l].discarded = false;
    }
    this.props.groupAdsStore.sendUpdateStateToKeepAd(id);
    //
  };

  selectedAd = (ad, index) => {
    let fieldsChanged;
    var cards = document.getElementsByClassName("card-ad");
    for (var i = 0; i < cards.length; i++) {
      cards[i].style.border = "1px solid grey";
    }
    this.props.groupAdsStore.selectedAdIndex = index;
    var selAd = document.getElementById(ad);
    var color;
    if(this.props.groupAdsStore.ads[index].error === 1){
      color = "rgb(255, 205, 210,0.5)"
    }else if(this.props.groupAdsStore.ads[index].fieldsChanged.length === 0){
      color = "rgba(0, 0, 0,0.1)"
    }else{
      color = "rgba(0, 165, 173,0.2)"
    }
    selAd.childNodes[0].childNodes[0].style.border = "2px solid grey";
    // document.getElementById("history-tab").click();
    if(this.props.groupAdsStore.ads[index].error === 1){
      fieldsChanged = document.getElementsByClassName("last-date");
      for (i in fieldsChanged) {
        if (fieldsChanged[i].style)
          fieldsChanged[i].style = "background: " + color;
    
      }
  
     
    } else if  (this.props.groupAdsStore.ads[index].fieldsChanged.length === 0) {
      fieldsChanged = document.getElementsByClassName("last-date");
      for (i in fieldsChanged) {
        if (fieldsChanged[i].style)
          fieldsChanged[i].style = "background: " + color;

      }
    } else {
      fieldsChanged = document.getElementsByClassName("last-date");
      for (i in fieldsChanged) {
        if (fieldsChanged[i].style)
          fieldsChanged[i].style = "background: " + color;
      }
    
      this.props.groupAdsStore.ads[index].fieldsChanged.map((item) => {
        this.lastDate = new Date(item.dateMapping)
          .toLocaleString("en-GB")
          .split(", ")
          .slice(0, 1);
        if (item.license !== undefined && item.license.status === true)
          document.getElementById("last-date-licenseStatus").style =
            "background: " + color;
        if (item.license !== undefined && item.license.code === true)
          document.getElementById("last-date-licenseCode").style =
            "background: " + color;
        if (item.id !== undefined && item.id === true)
          document.getElementById("last-date-adId").style =
            "background: " + color;
        if (item.title !== undefined && item.title === true)
          document.getElementById("last-date-title").style =
            "background: " + color;
        if (item.host !== undefined && item.host.id === true)
          document.getElementById("last-date-hostId").style =
            "background: " + color;
        if (item.roomType !== undefined && item.roomType === true)
          document.getElementById("last-date-roomType").style =
            "background: " + color;
        if (item.capacity !== undefined && item.capacity === true)
          document.getElementById("last-date-capacity").style =
            "background: " + color;
        if (item.price !== undefined && item.price === true)
          document.getElementById("last-date-price").style =
            "background: " + color;
        if (item.occupancy === true && item.occupancy === true)
          document.getElementById("last-date-takenDays").style =
            "background: " + color;
        if (item.minNights === true && item.minNights === true)
          document.getElementById("last-date-minNights").style =
            "background: " + color;
        if (
          item.geometry !== undefined &&
          item.geometry.coordinates &&
          item.geometry.coordinates[0]
        )
          document.getElementById("last-date-long").style =
            "background: " + color;
        if (
          item.geometry !== undefined &&
          item.geometry.coordinates &&
          item.geometry.coordinates[1]
        )
          document.getElementById("last-date-lat").style =
            "background: " + color;
        if (item.error !== undefined && item.error === true)
          document.getElementById("last-date-active").style =
            "background: " + color;
        if (item.host !== undefined && item.host.nickname === true)
          document.getElementById("last-date-hostName").style =
            "background: " + color;
        if (item.numReviews !== undefined && item.numReviews === true)
          document.getElementById("last-date-numReviews").style =
            "background: " + color;
        if (item.rate !== undefined && item.rate === true)
          document.getElementById("last-date-rate").style =
            "background: " + color;
        if (item.address !== undefined && item.address === true)
          document.getElementById("last-date-address").style =
            "background: " + color;
        if (item.adGroupCode !== undefined && item.adGroupCode === true)
          document.getElementById("last-date-associate").style =
            "background: " + color;
        return item;
      });
    }
  };

  moveToAd = (url) => {
    if (url !== null && url !== undefined) {
      this.props.navigationStore.openInNewTab(url);
    }
  };

  slideCarousel = (direction) => {
    // 1 es next , 0 es prev
    var cards = document.getElementsByClassName("carousel-content");
    var numAds = cards.length;

    if (direction === 1) {
      if (
        this.props.groupAdsStore.index + this.maxCards <
        this.props.groupAdsStore.ads.length
      ) {
        this.props.groupAdsStore.index += 1;
        for (let j = 0; j < numAds; ++j) {
          if (
            j < this.props.groupAdsStore.index ||
            j >= this.props.groupAdsStore.index + this.maxCards
          )
            this.props.groupAdsStore.noneClassName(cards[j].id);
          else if (
            j >= this.props.groupAdsStore.index &&
            j < this.props.groupAdsStore.index + this.maxCards
          )
            this.props.groupAdsStore.blockClassName(cards[j].id);
        }
      }
    } else if (direction === 0) {
      if (this.props.groupAdsStore.index > 0) {
        this.props.groupAdsStore.index -= 1;
        for (let j = 0; j < numAds; ++j) {
          if (
            j < this.props.groupAdsStore.index ||
            j >= this.props.groupAdsStore.index + this.maxCards
          )
            this.props.groupAdsStore.noneClassName(cards[j].id);
          else if (
            j >= this.props.groupAdsStore.index &&
            j < this.props.groupAdsStore.index + this.maxCards
          )
            this.props.groupAdsStore.blockClassName(cards[j].id);
        }
      }
    } else if (direction === -1) {
      for (let j = 0; j < numAds; ++j) {
        var cardsBorder = document.getElementsByClassName("card-ad");
        for (var i = 0; i < cards.length; i++) {
          if (i === this.props.groupAdsStore.selectedAdIndex)
            cardsBorder[i].style.border = "2px solid grey";
          else cardsBorder[i].style.border = "1px solid grey";
        }
        if (
          j < this.props.groupAdsStore.index ||
          j >= this.props.groupAdsStore.index + this.maxCards
         )
          this.props.groupAdsStore.noneClassName(cards[j].id);
        else if (
          j >= this.props.groupAdsStore.index &&
          j < this.props.groupAdsStore.index + this.maxCards
        )
          this.props.groupAdsStore.blockClassName(cards[j].id);
      }
    }
  };

  slideImage = (index, direction) => {
    let indexOfImage = this.props.groupAdsStore.ads[index].index;
    let lengthOfImagesArray = this.props.groupAdsStore.ads[index].images.length;
    if (direction === -1) {
      if (indexOfImage - 1 < 0)
        this.props.groupAdsStore.ads[index].index = lengthOfImagesArray - 1;
      else this.props.groupAdsStore.ads[index].index = indexOfImage - 1;
    } else if (direction === 1) {
      if (lengthOfImagesArray - 1 < indexOfImage + 1)
        this.props.groupAdsStore.ads[index].index = 0;
      else this.props.groupAdsStore.ads[index].index = indexOfImage + 1;
    }
  };

  updateUser = async (id) => {
    let u = await this.props.groupAdsStore.toggleFav(id);
    this.props.groupAdsStore.user = u;
    this.props.mapListStore.user = u;
  };

  //lastDate;
  render() {
    //console.log("FENT RENDER");
    const { t } = this.props;
    let tam = window.innerWidth - window.innerWidth * 0.16666666;
    tam / 360 > 4
      ? (this.maxCards = 4)
      : tam / 360 > 1
      ? (this.maxCards = tam / 360)
      : (this.maxCards = 1);
    console.log(tam, window.innerWidth, window.innerWidth * 0.16666666, this.maxCards)
    return (
      <>
        <main role="main" className="container-fluid groupAd-view">
          <div className="row">
            <div
              className={
                "alert alert-danger" +
                (!!this.props.groupAdsStore.showAlert
                  ? " alert-custom show"
                  : " alert-custom hide")
              }
            >
              <p id="danger-alert-groupAds"></p>
              <button
                type="button"
                className="close"
                onClick={() => {
                  this.props.groupAdsStore.updateProperty("showAlert", false);
                }}
              >
                x
              </button>
            </div>

            <div
              className={
                "alert alert-success" +
                (!!this.props.groupAdsStore.confirmAlert
                  ? " alert-custom show"
                  : " alert-custom hide")
              }
            >
              <p id="succes-alert-groupAds"></p>
              <button
                type="button"
                className="close"
                onClick={() => {
                  this.props.groupAdsStore.updateProperty(
                    "confirmAlert",
                    false
                  );
                }}
              >
                x
              </button>
            </div>

            <div
              className={
                "alert alert-danger" +
                (!!this.props.processStore.showAlert
                  ? " alert-custom show"
                  : " alert-custom hide")
              }
            >
              <p id="danger-alert-proces"></p>
              <button
                type="button"
                className="close"
                onClick={() => {
                  this.props.processStore.updateProperty("showAlert", false);
                }}
              >
                x
              </button>
            </div>

            <div
              className={
                "alert alert-success" +
                (!!this.props.processStore.confirmAlert
                  ? " alert-custom show"
                  : " alert-custom hide")
              }
            >
              <p id="succes-alert-proces"></p>
              <button
                type="button"
                className="close"
                onClick={() => {
                  this.props.processStore.updateProperty("confirmAlert", false);
                }}
              >
                x
              </button>
            </div>
            <div className="col-md-1">
            </div>
            <div className="col-md-10">
              <div id="groupID-generic-data" className="row generic-data">
                <form className="generic-group-data">
                  <div className="row">
                    <div className="col-md-7">
                      <h4>
                        {t("ga.head.group") +
                          " " +
                          this.props.groupAdsStore.adGroup.id}
                        {this.props.groupAdsStore.user ? (
                          <>
                            <MyButton
                              id={
                                this.props.groupAdsStore.adGroupForm.id +
                                "-btn-fav"
                              }
                              type="button"
                              name={t("wa.tooltips.addFav")}
                              onClick={async () => {
                                await this.updateUser(
                                  this.props.groupAdsStore.adGroupForm.id
                                );
                                this.props.mapListStore.changeStyle(
                                  this.props.groupAdsStore.adGroupForm.id,
                                  "fav",
                                  this.props.groupAdsStore.adGroupForm.id
                                );
                                this.props.groupAdsStore.ads.forEach(
                                  (child) => {
                                    this.props.mapListStore.changeStyle(
                                      child.id,
                                      "fav",
                                      this.props.groupAdsStore.adGroupForm.id
                                    );
                                  }
                                );
                                await this.props.mapListStore.updateMarkerAndCard(
                                  this.props.groupAdsStore.adGroupForm.id
                                );
                                let info = {
                                  code: this.props.groupAdsStore.adGroupForm.id,
                                  favAds: JSON.parse(
                                    JSON.stringify(
                                      this.props.groupAdsStore.user.favAds
                                    )
                                  ),
                                };
                                await channelFav.postMessage(info);
                              }}
                              className="btn btn-default fav-groupAd"
                              text={
                                this.props.groupAdsStore.user &&
                                this.props.groupAdsStore.user.favAds &&
                                this.props.groupAdsStore.user.favAds.includes(
                                  this.props.groupAdsStore.adGroupForm.id
                                ) ? (
                                  //   <svg
                                  //     className="svg-icon-fav favActive"
                                  //     data-name="fas fa-star"
                                  //     xmlns="http://www.w3.org/2000/svg"
                                  //     viewBox="0 0 66.62 63.69">
                                  //     <path
                                  //       className="cls-1"
                                  //       d="M51.87,69.13c-.78-.45-1.57-.86-2.38-1.25A16.65,16.65,0,0,0,47.87,67c-1.3-.71-2.61-1.41-3.93-2.06-.72-.41-1.45-.79-2.19-1.15s-1.19-.67-1.82-.94a14.72,14.72,0,0,0-1.76.92c-.77.36-1.52.75-2.25,1.18-1.33.65-2.63,1.35-3.93,2a15.78,15.78,0,0,0-1.47.77c-.89.44-1.77.89-2.63,1.39-1.32.65-2.63,1.33-3.92,2a11.05,11.05,0,0,1-1.23.52,4,4,0,0,1-4.54-2.17,3.68,3.68,0,0,1-.42-1.69c0-.28.07-.55.12-.82.17-1.06.35-2.12.53-3.18l.12-.61c.18-1.13.37-2.26.55-3.39.28-1.3.48-2.62.7-3.94a10,10,0,0,0,.25-1.74s.06-.07.07-.11c.12-.72.23-1.43.35-2.15.17-.9.33-1.81.5-2.72a.26.26,0,0,0-.1-.25L20,48.14l-.2-.2c-1.23-1.23-2.47-2.45-3.74-3.64a2.24,2.24,0,0,0-.44-.43c-1.16-1.19-2.37-2.33-3.57-3.48a1.44,1.44,0,0,0-.38-.36C10.46,38.84,9.27,37.65,8,36.51L7.56,36a4.09,4.09,0,0,1-.71-4,4.53,4.53,0,0,1,1.26-1.73,4.46,4.46,0,0,1,2.06-.82L12,29.18c1.34-.16,2.67-.36,4-.59,1.11-.12,2.2-.28,3.3-.48A1.78,1.78,0,0,0,20,28l1.18-.14L24,27.43c1.34-.16,2.68-.35,4-.58a.28.28,0,0,0,.32-.17c.4-.84.81-1.68,1.21-2.51.71-1.35,1.37-2.72,2-4.09A8.26,8.26,0,0,0,32.06,19c.53-.95,1-1.93,1.45-2.92l1.94-3.92L36,11a6.53,6.53,0,0,1,1.12-1.72A3.92,3.92,0,0,1,40.55,8.2a1.06,1.06,0,0,0,.25,0,4.12,4.12,0,0,1,2.34,1.55c.29.4.46.88.73,1.29a9.47,9.47,0,0,0,.54,1.11c.63,1.35,1.3,2.68,2,4,.45,1,1,2,1.46,3l.51,1,1.92,3.92c.42.85.84,1.7,1.25,2.56.07.14.16.23.33.2,1.34.23,2.67.42,4,.58.37.06.74.13,1.11.18l2.9.4a4.33,4.33,0,0,0,1,.15c1,.19,1.93.33,2.91.43l.64.12,3.37.47.39.07a7,7,0,0,1,3.61,1.1A4.77,4.77,0,0,1,73,32a4,4,0,0,1-.72,4l-.45.49c-1.23,1.13-2.42,2.31-3.6,3.5a1.48,1.48,0,0,0-.4.39c-1.24,1.16-2.45,2.35-3.64,3.55a1.27,1.27,0,0,0-.37.36c-1.27,1.19-2.51,2.4-3.74,3.63l-.2.2L59,49c-.06.06-.12.12-.1.22.17.92.34,1.83.5,2.75s.26,1.65.42,2.48L60.06,56c.22,1.31.41,2.62.69,3.92s.43,2.67.68,4l.64,4c0,.24,0,.48-.1.72a4,4,0,0,1-5.09,3,7.35,7.35,0,0,1-1-.43C54.56,70.5,53.23,69.8,51.87,69.13Z"
                                  //       transform="translate(-6.62 -8.15)"
                                  //     />
                                  //   </svg>
                                  // ) : (
                                  //   <svg
                                  //     className="svg-icon-fav favDisable"
                                  //     data-name="fas fa-star"
                                  //     xmlns="http://www.w3.org/2000/svg"
                                  //     viewBox="0 0 66.62 63.69">
                                  //     <path
                                  //       className="cls-1"
                                  //       d="M51.87,69.13c-.78-.45-1.57-.86-2.38-1.25A16.65,16.65,0,0,0,47.87,67c-1.3-.71-2.61-1.41-3.93-2.06-.72-.41-1.45-.79-2.19-1.15s-1.19-.67-1.82-.94a14.72,14.72,0,0,0-1.76.92c-.77.36-1.52.75-2.25,1.18-1.33.65-2.63,1.35-3.93,2a15.78,15.78,0,0,0-1.47.77c-.89.44-1.77.89-2.63,1.39-1.32.65-2.63,1.33-3.92,2a11.05,11.05,0,0,1-1.23.52,4,4,0,0,1-4.54-2.17,3.68,3.68,0,0,1-.42-1.69c0-.28.07-.55.12-.82.17-1.06.35-2.12.53-3.18l.12-.61c.18-1.13.37-2.26.55-3.39.28-1.3.48-2.62.7-3.94a10,10,0,0,0,.25-1.74s.06-.07.07-.11c.12-.72.23-1.43.35-2.15.17-.9.33-1.81.5-2.72a.26.26,0,0,0-.1-.25L20,48.14l-.2-.2c-1.23-1.23-2.47-2.45-3.74-3.64a2.24,2.24,0,0,0-.44-.43c-1.16-1.19-2.37-2.33-3.57-3.48a1.44,1.44,0,0,0-.38-.36C10.46,38.84,9.27,37.65,8,36.51L7.56,36a4.09,4.09,0,0,1-.71-4,4.53,4.53,0,0,1,1.26-1.73,4.46,4.46,0,0,1,2.06-.82L12,29.18c1.34-.16,2.67-.36,4-.59,1.11-.12,2.2-.28,3.3-.48A1.78,1.78,0,0,0,20,28l1.18-.14L24,27.43c1.34-.16,2.68-.35,4-.58a.28.28,0,0,0,.32-.17c.4-.84.81-1.68,1.21-2.51.71-1.35,1.37-2.72,2-4.09A8.26,8.26,0,0,0,32.06,19c.53-.95,1-1.93,1.45-2.92l1.94-3.92L36,11a6.53,6.53,0,0,1,1.12-1.72A3.92,3.92,0,0,1,40.55,8.2a1.06,1.06,0,0,0,.25,0,4.12,4.12,0,0,1,2.34,1.55c.29.4.46.88.73,1.29a9.47,9.47,0,0,0,.54,1.11c.63,1.35,1.3,2.68,2,4,.45,1,1,2,1.46,3l.51,1,1.92,3.92c.42.85.84,1.7,1.25,2.56.07.14.16.23.33.2,1.34.23,2.67.42,4,.58.37.06.74.13,1.11.18l2.9.4a4.33,4.33,0,0,0,1,.15c1,.19,1.93.33,2.91.43l.64.12,3.37.47.39.07a7,7,0,0,1,3.61,1.1A4.77,4.77,0,0,1,73,32a4,4,0,0,1-.72,4l-.45.49c-1.23,1.13-2.42,2.31-3.6,3.5a1.48,1.48,0,0,0-.4.39c-1.24,1.16-2.45,2.35-3.64,3.55a1.27,1.27,0,0,0-.37.36c-1.27,1.19-2.51,2.4-3.74,3.63l-.2.2L59,49c-.06.06-.12.12-.1.22.17.92.34,1.83.5,2.75s.26,1.65.42,2.48L60.06,56c.22,1.31.41,2.62.69,3.92s.43,2.67.68,4l.64,4c0,.24,0,.48-.1.72a4,4,0,0,1-5.09,3,7.35,7.35,0,0,1-1-.43C54.56,70.5,53.23,69.8,51.87,69.13Z"
                                  //       transform="translate(-6.62 -8.15)"
                                  //     />
                                  //   </svg>
                                  <svg
                                    className="fas fa-star favActive"
                                    data-name="fas fa-star"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 66.62 63.69"
                                  >
                                    <path
                                      className="cls-1"
                                      d="M51.87,69.13c-.78-.45-1.57-.86-2.38-1.25A16.65,16.65,0,0,0,47.87,67c-1.3-.71-2.61-1.41-3.93-2.06-.72-.41-1.45-.79-2.19-1.15s-1.19-.67-1.82-.94a14.72,14.72,0,0,0-1.76.92c-.77.36-1.52.75-2.25,1.18-1.33.65-2.63,1.35-3.93,2a15.78,15.78,0,0,0-1.47.77c-.89.44-1.77.89-2.63,1.39-1.32.65-2.63,1.33-3.92,2a11.05,11.05,0,0,1-1.23.52,4,4,0,0,1-4.54-2.17,3.68,3.68,0,0,1-.42-1.69c0-.28.07-.55.12-.82.17-1.06.35-2.12.53-3.18l.12-.61c.18-1.13.37-2.26.55-3.39.28-1.3.48-2.62.7-3.94a10,10,0,0,0,.25-1.74s.06-.07.07-.11c.12-.72.23-1.43.35-2.15.17-.9.33-1.81.5-2.72a.26.26,0,0,0-.1-.25L20,48.14l-.2-.2c-1.23-1.23-2.47-2.45-3.74-3.64a2.24,2.24,0,0,0-.44-.43c-1.16-1.19-2.37-2.33-3.57-3.48a1.44,1.44,0,0,0-.38-.36C10.46,38.84,9.27,37.65,8,36.51L7.56,36a4.09,4.09,0,0,1-.71-4,4.53,4.53,0,0,1,1.26-1.73,4.46,4.46,0,0,1,2.06-.82L12,29.18c1.34-.16,2.67-.36,4-.59,1.11-.12,2.2-.28,3.3-.48A1.78,1.78,0,0,0,20,28l1.18-.14L24,27.43c1.34-.16,2.68-.35,4-.58a.28.28,0,0,0,.32-.17c.4-.84.81-1.68,1.21-2.51.71-1.35,1.37-2.72,2-4.09A8.26,8.26,0,0,0,32.06,19c.53-.95,1-1.93,1.45-2.92l1.94-3.92L36,11a6.53,6.53,0,0,1,1.12-1.72A3.92,3.92,0,0,1,40.55,8.2a1.06,1.06,0,0,0,.25,0,4.12,4.12,0,0,1,2.34,1.55c.29.4.46.88.73,1.29a9.47,9.47,0,0,0,.54,1.11c.63,1.35,1.3,2.68,2,4,.45,1,1,2,1.46,3l.51,1,1.92,3.92c.42.85.84,1.7,1.25,2.56.07.14.16.23.33.2,1.34.23,2.67.42,4,.58.37.06.74.13,1.11.18l2.9.4a4.33,4.33,0,0,0,1,.15c1,.19,1.93.33,2.91.43l.64.12,3.37.47.39.07a7,7,0,0,1,3.61,1.1A4.77,4.77,0,0,1,73,32a4,4,0,0,1-.72,4l-.45.49c-1.23,1.13-2.42,2.31-3.6,3.5a1.48,1.48,0,0,0-.4.39c-1.24,1.16-2.45,2.35-3.64,3.55a1.27,1.27,0,0,0-.37.36c-1.27,1.19-2.51,2.4-3.74,3.63l-.2.2L59,49c-.06.06-.12.12-.1.22.17.92.34,1.83.5,2.75s.26,1.65.42,2.48L60.06,56c.22,1.31.41,2.62.69,3.92s.43,2.67.68,4l.64,4c0,.24,0,.48-.1.72a4,4,0,0,1-5.09,3,7.35,7.35,0,0,1-1-.43C54.56,70.5,53.23,69.8,51.87,69.13Z"
                                      transform="translate(-6.62 -8.15)"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    className="fas fa-star favDisable"
                                    data-name="fas fa-star"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 66.62 63.69"
                                  >
                                    <path
                                      className="cls-1"
                                      d="M51.87,69.13c-.78-.45-1.57-.86-2.38-1.25A16.65,16.65,0,0,0,47.87,67c-1.3-.71-2.61-1.41-3.93-2.06-.72-.41-1.45-.79-2.19-1.15s-1.19-.67-1.82-.94a14.72,14.72,0,0,0-1.76.92c-.77.36-1.52.75-2.25,1.18-1.33.65-2.63,1.35-3.93,2a15.78,15.78,0,0,0-1.47.77c-.89.44-1.77.89-2.63,1.39-1.32.65-2.63,1.33-3.92,2a11.05,11.05,0,0,1-1.23.52,4,4,0,0,1-4.54-2.17,3.68,3.68,0,0,1-.42-1.69c0-.28.07-.55.12-.82.17-1.06.35-2.12.53-3.18l.12-.61c.18-1.13.37-2.26.55-3.39.28-1.3.48-2.62.7-3.94a10,10,0,0,0,.25-1.74s.06-.07.07-.11c.12-.72.23-1.43.35-2.15.17-.9.33-1.81.5-2.72a.26.26,0,0,0-.1-.25L20,48.14l-.2-.2c-1.23-1.23-2.47-2.45-3.74-3.64a2.24,2.24,0,0,0-.44-.43c-1.16-1.19-2.37-2.33-3.57-3.48a1.44,1.44,0,0,0-.38-.36C10.46,38.84,9.27,37.65,8,36.51L7.56,36a4.09,4.09,0,0,1-.71-4,4.53,4.53,0,0,1,1.26-1.73,4.46,4.46,0,0,1,2.06-.82L12,29.18c1.34-.16,2.67-.36,4-.59,1.11-.12,2.2-.28,3.3-.48A1.78,1.78,0,0,0,20,28l1.18-.14L24,27.43c1.34-.16,2.68-.35,4-.58a.28.28,0,0,0,.32-.17c.4-.84.81-1.68,1.21-2.51.71-1.35,1.37-2.72,2-4.09A8.26,8.26,0,0,0,32.06,19c.53-.95,1-1.93,1.45-2.92l1.94-3.92L36,11a6.53,6.53,0,0,1,1.12-1.72A3.92,3.92,0,0,1,40.55,8.2a1.06,1.06,0,0,0,.25,0,4.12,4.12,0,0,1,2.34,1.55c.29.4.46.88.73,1.29a9.47,9.47,0,0,0,.54,1.11c.63,1.35,1.3,2.68,2,4,.45,1,1,2,1.46,3l.51,1,1.92,3.92c.42.85.84,1.7,1.25,2.56.07.14.16.23.33.2,1.34.23,2.67.42,4,.58.37.06.74.13,1.11.18l2.9.4a4.33,4.33,0,0,0,1,.15c1,.19,1.93.33,2.91.43l.64.12,3.37.47.39.07a7,7,0,0,1,3.61,1.1A4.77,4.77,0,0,1,73,32a4,4,0,0,1-.72,4l-.45.49c-1.23,1.13-2.42,2.31-3.6,3.5a1.48,1.48,0,0,0-.4.39c-1.24,1.16-2.45,2.35-3.64,3.55a1.27,1.27,0,0,0-.37.36c-1.27,1.19-2.51,2.4-3.74,3.63l-.2.2L59,49c-.06.06-.12.12-.1.22.17.92.34,1.83.5,2.75s.26,1.65.42,2.48L60.06,56c.22,1.31.41,2.62.69,3.92s.43,2.67.68,4l.64,4c0,.24,0,.48-.1.72a4,4,0,0,1-5.09,3,7.35,7.35,0,0,1-1-.43C54.56,70.5,53.23,69.8,51.87,69.13Z"
                                      transform="translate(-6.62 -8.15)"
                                    />
                                  </svg>
                                )
                              }
                            />

                            <MyButton
                              id={
                                this.props.groupAdsStore.adGroupForm.id +
                                "-btn-pin"
                              }
                              type="button"
                              name={t("wa.tooltips.addAssigned")}
                              disabled={
                                !this.props.groupAdsStore.isFound ||
                                (this.props.groupAdsStore.user &&
                                  this.props.groupAdsStore.workingUser !==
                                    this.props.groupAdsStore.user.username &&
                                  this.props.groupAdsStore.workingUser !==
                                    undefined &&
                                  this.props.groupAdsStore.workingUser !==
                                    null &&
                                  this.props.groupAdsStore.workingUser !== "-")
                                  ? "true"
                                  : "false"
                              }
                              onClick={async () => {
                                // this.updateUser(
                                // this.props.groupAdsStore.adGroupForm.id
                                // );
                                await this.props.groupAdsStore.toggleWorking(
                                  this.props.groupAdsStore.adGroupForm.id,
                                  this.props.groupAdsStore.workingUser,
                                  this.props.groupAdsStore.user.username
                                );
                                if (
                                  this.props.groupAdsStore.userWhoHasIt ===
                                    this.props.groupAdsStore.user.username ||
                                  this.props.groupAdsStore.userWhoHasIt ===
                                    null ||
                                  this.props.groupAdsStore.userWhoHasIt ===
                                    undefined
                                ) {
                                  this.props.groupAdsStore.ads.forEach(
                                    (child) => {
                                      this.props.mapListStore.changeStyle(
                                        child.id,
                                        "pin"
                                      );
                                    }
                                  );
                                }
                                let info = {
                                  id: this.props.groupAdsStore.adGroupForm.id,
                                  user: this.props.groupAdsStore.userWhoHasIt,
                                };
                                await this.props.mapListStore.updateMarkerAndCard(
                                  this.props.groupAdsStore.adGroupForm.id
                                );
                                await channelPin.postMessage(info);
                              }}
                              style={
                                !this.props.groupAdsStore.isFound ||
                                (this.props.groupAdsStore.user &&
                                  this.props.groupAdsStore.workingUser !==
                                    this.props.groupAdsStore.user.username &&
                                  this.props.groupAdsStore.workingUser !==
                                    undefined &&
                                  this.props.groupAdsStore.workingUser !==
                                    null &&
                                  this.props.groupAdsStore.workingUser !== "-")
                                  ? { opacity: "0.5" }
                                  : null
                              }
                              className="btn btn-default"
                              text={
                                this.props.groupAdsStore.user &&
                                this.props.groupAdsStore.workingUser ===
                                  this.props.groupAdsStore.user.username ? (
                                  // <svg
                                  //   className="svg-icon-pin pinActive"
                                  //   data-name="fas fa-thumbtack"
                                  //   xmlns="http://www.w3.org/2000/svg"
                                  //   viewBox="0 0 47.88 63.79"
                                  // >
                                  //   <path
                                  //     className="cls-1"
                                  //     d="M57.65,7.9a3.15,3.15,0,0,1,2.27,2.35c.08.53,0,1.07.06,1.6a11.63,11.63,0,0,0,0,1.77,19.6,19.6,0,0,0,0,2.17c0,.54,0,1.07-.06,1.61a5.63,5.63,0,0,1-.37.93,3.38,3.38,0,0,1-1.82,1.4,12.82,12.82,0,0,1-1.75.08c-1.3,0-2.61,0-3.91,0-.3,0-.37.08-.33.37.13,1.21.26,2.43.38,3.65.05.46.09.92.14,1.37.09.88.19,1.76.29,2.64.09,1.31.25,2.63.4,3.94.08.83.17,1.66.24,2.49a.44.44,0,0,0,.3.43A22.4,22.4,0,0,1,55.94,36a22.66,22.66,0,0,1,4,3.25,3,3,0,0,0,.44.5,16.28,16.28,0,0,1,2.5,3.93c.1.26.2.51.29.77a17.68,17.68,0,0,1,.64,2.58l.08.66a11.38,11.38,0,0,1-.06,1.75,3.26,3.26,0,0,1-1,1.56,4.13,4.13,0,0,1-1.45.69H48l-3.71,0c-.19,0-.25.06-.25.25,0,1.26,0,2.52,0,3.78v7.94A9.73,9.73,0,0,1,43.94,65l-1.32,2.69-1.54,3c-.42.84-.57.93-1.5.88a2,2,0,0,1-.64-.7c-.57-1.06-1-2.2-1.57-3.23L36,64.88V55.74c0-1.26,0-2.52,0-3.78,0-.19-.06-.25-.25-.25l-3.7,0H20a15.73,15.73,0,0,1-1.68-.07c-.28-.15-.58-.22-.85-.39a3.34,3.34,0,0,1-1.34-1.73,10,10,0,0,1-.07-1.82,1.12,1.12,0,0,0,.06-.44,15.16,15.16,0,0,1,.94-3.57l.35-.72a17.35,17.35,0,0,1,2.16-3.21l.44-.49c.61-.58,1.19-1.18,1.84-1.7a24.82,24.82,0,0,1,4.61-2.9c.17-.08.32-.13.34-.37.06-.86.16-1.71.24-2.57,0-.19.05-.38.07-.57l.33-3.36.42-4c.13-1.24.25-2.48.39-3.72,0-.22,0-.3-.27-.29H24a13.49,13.49,0,0,1-1.68-.07,3.37,3.37,0,0,1-1.63-1,9,9,0,0,1-.62-1.09L20,15.79V11.86L20.08,10a4.93,4.93,0,0,1,.85-1.33,3,3,0,0,1,2-.85c.44,0,.88,0,1.31,0H56.67C57,7.85,57.33,7.88,57.65,7.9Z"
                                  //     transform="translate(-16.05 -7.84)"
                                  //   />
                                  // </svg>
                                  <svg
                                    className="fas fa-thumbtack pinActive"
                                    data-name="fas fa-thumbtack"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 47.88 63.79"
                                  >
                                    <path
                                      className="cls-1"
                                      d="M57.65,7.9a3.15,3.15,0,0,1,2.27,2.35c.08.53,0,1.07.06,1.6a11.63,11.63,0,0,0,0,1.77,19.6,19.6,0,0,0,0,2.17c0,.54,0,1.07-.06,1.61a5.63,5.63,0,0,1-.37.93,3.38,3.38,0,0,1-1.82,1.4,12.82,12.82,0,0,1-1.75.08c-1.3,0-2.61,0-3.91,0-.3,0-.37.08-.33.37.13,1.21.26,2.43.38,3.65.05.46.09.92.14,1.37.09.88.19,1.76.29,2.64.09,1.31.25,2.63.4,3.94.08.83.17,1.66.24,2.49a.44.44,0,0,0,.3.43A22.4,22.4,0,0,1,55.94,36a22.66,22.66,0,0,1,4,3.25,3,3,0,0,0,.44.5,16.28,16.28,0,0,1,2.5,3.93c.1.26.2.51.29.77a17.68,17.68,0,0,1,.64,2.58l.08.66a11.38,11.38,0,0,1-.06,1.75,3.26,3.26,0,0,1-1,1.56,4.13,4.13,0,0,1-1.45.69H48l-3.71,0c-.19,0-.25.06-.25.25,0,1.26,0,2.52,0,3.78v7.94A9.73,9.73,0,0,1,43.94,65l-1.32,2.69-1.54,3c-.42.84-.57.93-1.5.88a2,2,0,0,1-.64-.7c-.57-1.06-1-2.2-1.57-3.23L36,64.88V55.74c0-1.26,0-2.52,0-3.78,0-.19-.06-.25-.25-.25l-3.7,0H20a15.73,15.73,0,0,1-1.68-.07c-.28-.15-.58-.22-.85-.39a3.34,3.34,0,0,1-1.34-1.73,10,10,0,0,1-.07-1.82,1.12,1.12,0,0,0,.06-.44,15.16,15.16,0,0,1,.94-3.57l.35-.72a17.35,17.35,0,0,1,2.16-3.21l.44-.49c.61-.58,1.19-1.18,1.84-1.7a24.82,24.82,0,0,1,4.61-2.9c.17-.08.32-.13.34-.37.06-.86.16-1.71.24-2.57,0-.19.05-.38.07-.57l.33-3.36.42-4c.13-1.24.25-2.48.39-3.72,0-.22,0-.3-.27-.29H24a13.49,13.49,0,0,1-1.68-.07,3.37,3.37,0,0,1-1.63-1,9,9,0,0,1-.62-1.09L20,15.79V11.86L20.08,10a4.93,4.93,0,0,1,.85-1.33,3,3,0,0,1,2-.85c.44,0,.88,0,1.31,0H56.67C57,7.85,57.33,7.88,57.65,7.9Z"
                                      transform="translate(-16.05 -7.84)"
                                    />
                                  </svg>
                                ) : this.props.groupAdsStore.isFound &&
                                  this.props.groupAdsStore.workingUser !==
                                    undefined &&
                                  this.props.groupAdsStore.workingUser !==
                                    null &&
                                  this.props.groupAdsStore.workingUser !==
                                    "-" ? (
                                    <svg
                                      className="svg-icon-pin pinActiveFromAnother"
                                      data-name="fas fa-thumbtack"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 47.88 63.79"
                                    >
                                      <path
                                        className="cls-1"
                                        d="M57.65,7.9a3.15,3.15,0,0,1,2.27,2.35c.08.53,0,1.07.06,1.6a11.63,11.63,0,0,0,0,1.77,19.6,19.6,0,0,0,0,2.17c0,.54,0,1.07-.06,1.61a5.63,5.63,0,0,1-.37.93,3.38,3.38,0,0,1-1.82,1.4,12.82,12.82,0,0,1-1.75.08c-1.3,0-2.61,0-3.91,0-.3,0-.37.08-.33.37.13,1.21.26,2.43.38,3.65.05.46.09.92.14,1.37.09.88.19,1.76.29,2.64.09,1.31.25,2.63.4,3.94.08.83.17,1.66.24,2.49a.44.44,0,0,0,.3.43A22.4,22.4,0,0,1,55.94,36a22.66,22.66,0,0,1,4,3.25,3,3,0,0,0,.44.5,16.28,16.28,0,0,1,2.5,3.93c.1.26.2.51.29.77a17.68,17.68,0,0,1,.64,2.58l.08.66a11.38,11.38,0,0,1-.06,1.75,3.26,3.26,0,0,1-1,1.56,4.13,4.13,0,0,1-1.45.69H48l-3.71,0c-.19,0-.25.06-.25.25,0,1.26,0,2.52,0,3.78v7.94A9.73,9.73,0,0,1,43.94,65l-1.32,2.69-1.54,3c-.42.84-.57.93-1.5.88a2,2,0,0,1-.64-.7c-.57-1.06-1-2.2-1.57-3.23L36,64.88V55.74c0-1.26,0-2.52,0-3.78,0-.19-.06-.25-.25-.25l-3.7,0H20a15.73,15.73,0,0,1-1.68-.07c-.28-.15-.58-.22-.85-.39a3.34,3.34,0,0,1-1.34-1.73,10,10,0,0,1-.07-1.82,1.12,1.12,0,0,0,.06-.44,15.16,15.16,0,0,1,.94-3.57l.35-.72a17.35,17.35,0,0,1,2.16-3.21l.44-.49c.61-.58,1.19-1.18,1.84-1.7a24.82,24.82,0,0,1,4.61-2.9c.17-.08.32-.13.34-.37.06-.86.16-1.71.24-2.57,0-.19.05-.38.07-.57l.33-3.36.42-4c.13-1.24.25-2.48.39-3.72,0-.22,0-.3-.27-.29H24a13.49,13.49,0,0,1-1.68-.07,3.37,3.37,0,0,1-1.63-1,9,9,0,0,1-.62-1.09L20,15.79V11.86L20.08,10a4.93,4.93,0,0,1,.85-1.33,3,3,0,0,1,2-.85c.44,0,.88,0,1.31,0H56.67C57,7.85,57.33,7.88,57.65,7.9Z"
                                        transform="translate(-16.05 -7.84)"
                                      />
                                    </svg>
                                  // ) : (
                                  //   <svg
                                  //     className="svg-icon-pin pinDisable"
                                  //     data-name="fas fa-thumbtack"
                                  //     xmlns="http://www.w3.org/2000/svg"
                                  //     viewBox="0 0 47.88 63.79"
                                  //   >
                                  //     <path
                                  //       className="cls-1"
                                  //       d="M57.65,7.9a3.15,3.15,0,0,1,2.27,2.35c.08.53,0,1.07.06,1.6a11.63,11.63,0,0,0,0,1.77,19.6,19.6,0,0,0,0,2.17c0,.54,0,1.07-.06,1.61a5.63,5.63,0,0,1-.37.93,3.38,3.38,0,0,1-1.82,1.4,12.82,12.82,0,0,1-1.75.08c-1.3,0-2.61,0-3.91,0-.3,0-.37.08-.33.37.13,1.21.26,2.43.38,3.65.05.46.09.92.14,1.37.09.88.19,1.76.29,2.64.09,1.31.25,2.63.4,3.94.08.83.17,1.66.24,2.49a.44.44,0,0,0,.3.43A22.4,22.4,0,0,1,55.94,36a22.66,22.66,0,0,1,4,3.25,3,3,0,0,0,.44.5,16.28,16.28,0,0,1,2.5,3.93c.1.26.2.51.29.77a17.68,17.68,0,0,1,.64,2.58l.08.66a11.38,11.38,0,0,1-.06,1.75,3.26,3.26,0,0,1-1,1.56,4.13,4.13,0,0,1-1.45.69H48l-3.71,0c-.19,0-.25.06-.25.25,0,1.26,0,2.52,0,3.78v7.94A9.73,9.73,0,0,1,43.94,65l-1.32,2.69-1.54,3c-.42.84-.57.93-1.5.88a2,2,0,0,1-.64-.7c-.57-1.06-1-2.2-1.57-3.23L36,64.88V55.74c0-1.26,0-2.52,0-3.78,0-.19-.06-.25-.25-.25l-3.7,0H20a15.73,15.73,0,0,1-1.68-.07c-.28-.15-.58-.22-.85-.39a3.34,3.34,0,0,1-1.34-1.73,10,10,0,0,1-.07-1.82,1.12,1.12,0,0,0,.06-.44,15.16,15.16,0,0,1,.94-3.57l.35-.72a17.35,17.35,0,0,1,2.16-3.21l.44-.49c.61-.58,1.19-1.18,1.84-1.7a24.82,24.82,0,0,1,4.61-2.9c.17-.08.32-.13.34-.37.06-.86.16-1.71.24-2.57,0-.19.05-.38.07-.57l.33-3.36.42-4c.13-1.24.25-2.48.39-3.72,0-.22,0-.3-.27-.29H24a13.49,13.49,0,0,1-1.68-.07,3.37,3.37,0,0,1-1.63-1,9,9,0,0,1-.62-1.09L20,15.79V11.86L20.08,10a4.93,4.93,0,0,1,.85-1.33,3,3,0,0,1,2-.85c.44,0,.88,0,1.31,0H56.67C57,7.85,57.33,7.88,57.65,7.9Z"
                                  //       transform="translate(-16.05 -7.84)"
                                  //     />
                                  //   </svg>
                                  // <svg
                                  //   className="fas fa-thumbtack pinActiveFromAnother"
                                  //   data-name="fas fa-thumbtack"
                                  //   xmlns="http://www.w3.org/2000/svg"
                                  //   viewBox="0 0 47.88 63.79"
                                  // >
                                  //   <path
                                  //     className="cls-1"
                                  //     d="M57.65,7.9a3.15,3.15,0,0,1,2.27,2.35c.08.53,0,1.07.06,1.6a11.63,11.63,0,0,0,0,1.77,19.6,19.6,0,0,0,0,2.17c0,.54,0,1.07-.06,1.61a5.63,5.63,0,0,1-.37.93,3.38,3.38,0,0,1-1.82,1.4,12.82,12.82,0,0,1-1.75.08c-1.3,0-2.61,0-3.91,0-.3,0-.37.08-.33.37.13,1.21.26,2.43.38,3.65.05.46.09.92.14,1.37.09.88.19,1.76.29,2.64.09,1.31.25,2.63.4,3.94.08.83.17,1.66.24,2.49a.44.44,0,0,0,.3.43A22.4,22.4,0,0,1,55.94,36a22.66,22.66,0,0,1,4,3.25,3,3,0,0,0,.44.5,16.28,16.28,0,0,1,2.5,3.93c.1.26.2.51.29.77a17.68,17.68,0,0,1,.64,2.58l.08.66a11.38,11.38,0,0,1-.06,1.75,3.26,3.26,0,0,1-1,1.56,4.13,4.13,0,0,1-1.45.69H48l-3.71,0c-.19,0-.25.06-.25.25,0,1.26,0,2.52,0,3.78v7.94A9.73,9.73,0,0,1,43.94,65l-1.32,2.69-1.54,3c-.42.84-.57.93-1.5.88a2,2,0,0,1-.64-.7c-.57-1.06-1-2.2-1.57-3.23L36,64.88V55.74c0-1.26,0-2.52,0-3.78,0-.19-.06-.25-.25-.25l-3.7,0H20a15.73,15.73,0,0,1-1.68-.07c-.28-.15-.58-.22-.85-.39a3.34,3.34,0,0,1-1.34-1.73,10,10,0,0,1-.07-1.82,1.12,1.12,0,0,0,.06-.44,15.16,15.16,0,0,1,.94-3.57l.35-.72a17.35,17.35,0,0,1,2.16-3.21l.44-.49c.61-.58,1.19-1.18,1.84-1.7a24.82,24.82,0,0,1,4.61-2.9c.17-.08.32-.13.34-.37.06-.86.16-1.71.24-2.57,0-.19.05-.38.07-.57l.33-3.36.42-4c.13-1.24.25-2.48.39-3.72,0-.22,0-.3-.27-.29H24a13.49,13.49,0,0,1-1.68-.07,3.37,3.37,0,0,1-1.63-1,9,9,0,0,1-.62-1.09L20,15.79V11.86L20.08,10a4.93,4.93,0,0,1,.85-1.33,3,3,0,0,1,2-.85c.44,0,.88,0,1.31,0H56.67C57,7.85,57.33,7.88,57.65,7.9Z"
                                  //     transform="translate(-16.05 -7.84)"
                                  //   />
                                  // </svg>
                                ) : (
                                  <svg
                                    className="fas fa-thumbtack pinDisable"
                                    data-name="fas fa-thumbtack"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 47.88 63.79"
                                  >
                                    <path
                                      className="cls-1"
                                      d="M57.65,7.9a3.15,3.15,0,0,1,2.27,2.35c.08.53,0,1.07.06,1.6a11.63,11.63,0,0,0,0,1.77,19.6,19.6,0,0,0,0,2.17c0,.54,0,1.07-.06,1.61a5.63,5.63,0,0,1-.37.93,3.38,3.38,0,0,1-1.82,1.4,12.82,12.82,0,0,1-1.75.08c-1.3,0-2.61,0-3.91,0-.3,0-.37.08-.33.37.13,1.21.26,2.43.38,3.65.05.46.09.92.14,1.37.09.88.19,1.76.29,2.64.09,1.31.25,2.63.4,3.94.08.83.17,1.66.24,2.49a.44.44,0,0,0,.3.43A22.4,22.4,0,0,1,55.94,36a22.66,22.66,0,0,1,4,3.25,3,3,0,0,0,.44.5,16.28,16.28,0,0,1,2.5,3.93c.1.26.2.51.29.77a17.68,17.68,0,0,1,.64,2.58l.08.66a11.38,11.38,0,0,1-.06,1.75,3.26,3.26,0,0,1-1,1.56,4.13,4.13,0,0,1-1.45.69H48l-3.71,0c-.19,0-.25.06-.25.25,0,1.26,0,2.52,0,3.78v7.94A9.73,9.73,0,0,1,43.94,65l-1.32,2.69-1.54,3c-.42.84-.57.93-1.5.88a2,2,0,0,1-.64-.7c-.57-1.06-1-2.2-1.57-3.23L36,64.88V55.74c0-1.26,0-2.52,0-3.78,0-.19-.06-.25-.25-.25l-3.7,0H20a15.73,15.73,0,0,1-1.68-.07c-.28-.15-.58-.22-.85-.39a3.34,3.34,0,0,1-1.34-1.73,10,10,0,0,1-.07-1.82,1.12,1.12,0,0,0,.06-.44,15.16,15.16,0,0,1,.94-3.57l.35-.72a17.35,17.35,0,0,1,2.16-3.21l.44-.49c.61-.58,1.19-1.18,1.84-1.7a24.82,24.82,0,0,1,4.61-2.9c.17-.08.32-.13.34-.37.06-.86.16-1.71.24-2.57,0-.19.05-.38.07-.57l.33-3.36.42-4c.13-1.24.25-2.48.39-3.72,0-.22,0-.3-.27-.29H24a13.49,13.49,0,0,1-1.68-.07,3.37,3.37,0,0,1-1.63-1,9,9,0,0,1-.62-1.09L20,15.79V11.86L20.08,10a4.93,4.93,0,0,1,.85-1.33,3,3,0,0,1,2-.85c.44,0,.88,0,1.31,0H56.67C57,7.85,57.33,7.88,57.65,7.9Z"
                                      transform="translate(-16.05 -7.84)"
                                    />
                                  </svg>
                                )
                              }
                            />
                            <MyButton
                              id=""
                              type="button"
                              name="message"
                              className="btn btn-default"
                              text={
                                <>
                                  <svg
                                    className={
                                      "svg-icon-message" +
                                      (!!this.props.groupAdsStore.ownerContacted
                                        ? " messActive"
                                        : " messDisable")
                                    }
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 63.71 47.79"
                                  >
                                    <path
                                      className="cls-1"
                                      d="M8,43.71V35.78c0-1.12,0-2.25,0-3.37,0-.57.31-.92.77-.91a1.53,1.53,0,0,1,.65.29c.83.68,1.68,1.34,2.56,2s1.8,1.39,2.74,2a10.5,10.5,0,0,0,1.26.93q1.91,1.46,3.87,2.83a.75.75,0,0,0,.32.23c1.22.94,2.48,1.84,3.74,2.73l1.74,1.27,2.26,1.64c1.41,1.07,2.8,2.19,4.26,3.2a20.25,20.25,0,0,0,3.67,2.12,8.77,8.77,0,0,0,4,.94,9.45,9.45,0,0,0,4.11-1,21.06,21.06,0,0,0,3.83-2.26,7.59,7.59,0,0,0,1-.73l3-2.29c.8-.55,1.59-1.11,2.35-1.71.57-.37,1.11-.77,1.65-1.19,1.23-.87,2.45-1.74,3.64-2.66l.36-.25c1.32-.95,2.64-1.9,3.92-2.9A10.5,10.5,0,0,0,65,35.79c1-.67,1.91-1.38,2.83-2.11.82-.57,1.61-1.18,2.39-1.81l.1-.05a.82.82,0,0,1,1-.23.94.94,0,0,1,.4.94c0,1.09,0,2.17,0,3.25V55.71c-.06.83,0,1.66-.06,2.49a6,6,0,0,1-5.16,5.37c-.93.11-1.86,0-2.79.07H14.05a6.15,6.15,0,0,1-2.53-.54,6,6,0,0,1-3.17-3.46l-.26-1C8,57.65,8,56.67,8,55.71v-12Z"
                                      transform="translate(-8 -15.85)"
                                    />
                                    <path
                                      className="cls-1"
                                      d="M59.78,15.85h3.93c.85.05,1.71,0,2.57.05a6,6,0,0,1,5.37,5.37c.07.86,0,1.72.06,2.58l-.07.95a3.51,3.51,0,0,1-1.5,2.08c-.42.29-.81.64-1.21,1l-1.31,1-3.77,2.86-.15.11q-2,1.41-3.93,2.9a16.67,16.67,0,0,0-1.44,1.06c-.87.59-1.72,1.21-2.56,1.86-1,.68-2,1.39-2.92,2.12a9.34,9.34,0,0,0-1.08.79l-.27.17c-2.59,1.8-5,3.86-7.65,5.55-.54.3-1.08.61-1.65.87s-1.06.35-1.59.55l-.76,0-1-.09a12.61,12.61,0,0,1-3-1.36l-1-.65c-1.72-1.14-3.28-2.48-5-3.67a20.11,20.11,0,0,0-1.95-1.37,7.47,7.47,0,0,0-1.16-.87c-.93-.72-1.87-1.4-2.83-2.06s-1.69-1.28-2.57-1.86a14.91,14.91,0,0,0-1.44-1.07L16,31.81a.05.05,0,0,0-.05,0l-.74-.57L12,28.8l-1.22-.95c-.41-.32-.8-.67-1.22-1A3.76,3.76,0,0,1,8.08,25L8,23.86c.06-.85,0-1.69.06-2.54a6,6,0,0,1,5.37-5.4c.86-.07,1.72,0,2.58-.06H59.78Z"
                                      transform="translate(-8 -15.85)"
                                    />
                                  </svg>
                                </>
                              }
                            />
                            {this.props.groupAdsStore.adGroup.reason ? (
                              <svg
                                className="svg-icon-reason"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 63.71 47.79"
                                onMouseEnter={(e) => {
                                  document.getElementById(
                                    "modalReason"
                                  ).className = document
                                    .getElementById("modalReason")
                                    .className.replace(
                                      "display-none",
                                      "display-block"
                                    );
                                  //Hover in
                                }}
                                onMouseLeave={(e) => {
                                  document.getElementById(
                                    "modalReason"
                                  ).className = document
                                    .getElementById("modalReason")
                                    .className.replace(
                                      "display-block",
                                      "display-none"
                                    );
                                }}
                              >
                                <path
                                  className="st0"
                                  d="M74.9,76.1c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0-0.5,0c-5.3,0-10.6,0-15.8,0c-0.3,0-0.6,0-0.8-0.1	c-0.9-0.4-1.3-1-1.3-2.1c0-5.2,0-10.4,0-15.6c0-1.4,0.8-2.2,2.2-2.2c1.4,0,2.8,0,4.3,0c3.8,0,7.6,0,11.4,0c0.7,0,1.3,0.2,1.7,0.8	c0.4,0.5,0.4,1,0.4,1.5c0,5.1,0,10.2,0,15.4c0,0.6-0.1,1.2-0.5,1.8l0,0c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.2,0.2l0,0c0,0,0,0,0,0	C75.4,75.8,75.1,75.9,74.9,76.1z M60.3,61.4c0,0.3,0,0.5,0,0.8c0,0.8,0,1.5,0,2.3c0,0.3,0,0.6,0,0.9c0,0.7,0,1.5,0,2.2 c0,0.3,0,0.6,0,0.9c0,0.7,0,1.5,0,2.2 c0,0.3,0,0.6,0,0.9 c0,0.1,0,0.2,0,0.2c0,0.3,0.2,0.2,0.3,0.2c3.7,0,7.5,0,11.2,0	c0.4,0,0.5-0.1,0.5-0.5c0-2.9,0-5.8,0-8.7 c0-0.8,0-1.7,0-2.5c0-0.1,0.1-0.3-0.2-0.3c-0.1,0-0.2,0-0.2,0c-0.3,0-0.6,0-0.9,0c-0.7,0-1.5,0-2.2,0c-0.3,0-0.6,0-0.9,0c-0.7,0-1.5,0-2.2,0c-0.3,0-0.6,0-0.9,0c-0.8,0-1.5,0-2.3,0c-0.3,0-0.5,0-0.8,0 C60.2,60,60.1,60.1,60.3,61.4z"
                                />
                                <path
                                  className="st0"
                                  d="M54.7,56c-0.5,0-1,0-1.5-0.3c-0.7-0.5-1-1.2-1-2c0-0.1,0-0.1,0-0.2c0.3-0.7,0.8-1.2,1.5-1.5c0.1,0,0.1,0,0.2,0	c0.7,0,1.3,0.1,1.8,0.7c0.6,0.7,0.7,1.7,0.2,2.4c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0,0,0	c-0.2,0.1-0.5,0.3-0.7,0.4C54.8,56,54.7,56,54.7,56z"
                                />
                                <path
                                  className="st0"
                                  d="M46.1,60c0.8,0.1,1.5,0.4,1.9,1.2c0.6,1.4-0.3,2.8-1.9,2.8c-2.3,0-4.6,0-6.9,0c-11,0-21.9,0-32.9,0 c-0.5,0-0.9,0-1.3-0.3c-0.6-0.3-0.9-0.8-1-1.4c0-0.2,0-0.4,0-0.6c0-18.5,0-37,0-55.5C4,5.9,4,5.4,4.2,5c0.3-0.7,0.9-1.1,1.6-1.1 c2.1,0,4.1,0,6.2,0c15.5,0,31,0,46.5,0c1.2,0,2.3,0,3.5,0c1.4,0,2.2,0.9,2.2,2.2c0,11.5,0,23.1,0,34.6c0,1.7,0,3.5,0,5.2 c0,1-0.5,1.6-1.3,1.9c-1.3,0.5-2.7-0.3-2.8-1.7c0-0.1,0-0.1,0-0.2c0-0.7,0-1.5,0-2.2c0-0.3,0-0.6,0-0.9c0-0.7,0-1.5,0-2.2 c0-0.3,0-0.6,0-0.9c0-0.7,0-1.5,0-2.2c0-0.3,0-0.6,0-0.9c0-0.7,0-1.5,0-2.2c0-0.3,0-0.6,0-0.9c0-0.7,0-1.5,0-2.2c0-0.3,0-0.6,0-0.9 c0-0.7,0-1.5,0-2.2c0-0.3,0-0.6,0-0.9c0-0.7,0-1.5,0-2.2c0-0.3,0-0.6,0-0.9c0-0.7,0-1.5,0-2.2c0-0.3,0-0.6,0-0.9c0-0.7,0-1.5,0-2.2 c0-0.3,0-0.6,0-0.9c0-0.7,0-1.5,0-2.2c0-0.3,0-0.6,0-0.9c0-0.7,0-1.5,0-2.2c0-0.3,0-0.6,0-0.9c0-0.7,0-1.5,0-2.2c0-0.3,0-0.6,0-0.9 c0-0.1,0-0.2,0-0.2c0-0.4-0.3-0.2-0.4-0.2c-17.1,0-34.1,0-51.2,0C8,7.9,8,7.9,8,8.4c0,17,0,34.1,0,51.1c0,0.1,0,0.2,0,0.4 C8,60,8.1,60.1,8.2,60c0.1,0,0.2,0,0.2,0c0.3,0,0.6,0,0.9,0c0.7,0,1.5,0,2.2,0c0.3,0,0.6,0,0.9,0c0.7,0,1.5,0,2.2,0 c0.3,0,0.6,0,0.9,0c0.7,0,1.5,0,2.2,0c0.3,0,0.6,0,0.9,0c0.7,0,1.5,0,2.2,0c0.3,0,0.6,0,0.9,0c0.7,0,1.5,0,2.2,0c0.3,0,0.6,0,0.9,0 c0.7,0,1.5,0,2.2,0c0.3,0,0.6,0,0.9,0c0.7,0,1.5,0,2.2,0c0.3,0,0.6,0,0.9,0c0.8,0,1.5,0,2.3,0c0.3,0,0.5,0,0.8,0c0.8,0,1.5,0,2.3,0 c0.3,0,0.6,0,0.9,0c0.7,0,1.5,0,2.2,0c0.3,0,0.6,0,0.9,0c0.7,0,1.5,0,2.2,0c0.3,0,0.6,0,0.9,0C44.7,60.1,45.4,60.1,46.1,60z"
                                />
                                <path
                                  className="st0"
                                  d="M12.1,30.3c0-2.5,0-4.9,0-7.4c0-3.1,0-6.1,0-9.2c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0.2-0.8,0.8-1.4,1.6-1.6 c0,0,0,0,0,0l0,0c2.8,0,5.6,0,8.3,0c2.7,0,5.5,0,8.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.5,0.2,1,0.5,1.3,1.1 c0.1,0.3,0.2,0.6,0.3,0.9c0,0.1,0,0.2,0,0.2c0,0.3-0.1,0.6,0,0.9c-0.1,0.8,0,1.5,0,2.3c-0.1,0.3-0.1,0.6,0,0.9c-0.1,0.7,0,1.5,0,2.2 c-0.1,0.3-0.1,0.6,0,1c-0.1,0.7,0,1.5,0,2.2c-0.1,0.3-0.1,0.6,0,1c-0.1,0.7,0,1.5,0,2.2c-0.1,0.3-0.1,0.6,0,1c-0.1,0.7,0,1.5,0,2.2 c-0.1,0.4-0.1,0.7-0.3,1.1c-0.4,0.8-1.1,1.1-2,1.1c-0.7,0-1.4,0-2.2,0c-0.3-0.1-0.7-0.1-1,0c-0.7,0-1.5-0.1-2.2,0 c-0.3-0.1-0.6-0.1-1,0c-0.7,0-1.5-0.1-2.2,0c-0.3-0.1-0.6-0.1-1,0c-0.7,0-1.5-0.1-2.2,0c-0.3-0.1-0.6-0.1-0.9,0 c-0.8,0-1.5-0.1-2.3,0c-0.3-0.1-0.6-0.1-0.9,0c-0.1,0-0.2,0-0.2,0c-1-0.1-1.6-0.6-2-1.6C12.1,30.4,12.1,30.3,12.1,30.3z M28.1,25.7 c0-0.3,0-0.6,0-0.9c0.1-0.7,0-1.5,0-2.2c0-0.3,0-0.6,0-0.9c0.1-0.7,0-1.5,0-2.2c0-0.3,0-0.6,0-0.9c0.1-0.7,0-1.5,0-2.2 c0.1-0.3,0-0.4-0.3-0.4c-3.8,0-7.5,0-11.3,0c-0.3,0-0.4,0.1-0.4,0.4c0,3.8,0,7.5,0,11.3c0,0.3,0.1,0.4,0.4,0.3c0.7,0,1.5,0.1,2.2,0 c0.3,0.1,0.6,0.1,0.9,0c0.7,0,1.5,0.1,2.2,0c0.3,0.1,0.6,0.1,0.9,0c0.7,0,1.5,0.1,2.2,0c0.3,0.1,0.6,0.1,0.9,0c0.7,0,1.3,0,2,0 c0.2,0,0.3-0.1,0.3-0.3C28.1,27,28.1,26.4,28.1,25.7z"
                                />
                                <path
                                  className="st0"
                                  d="M54.7,11.9c0.6,0.3,1.1,0.7,1.4,1.3c0,0.1,0,0.1,0,0.2c0,5.4,0,10.8,0,16.2c0,0.3,0,0.7-0.1,1 c-0.3,0.8-1,1.3-1.9,1.3c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0C53.3,32,53.2,32,53,32c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.6-0.1-1,0 c-0.5,0-1-0.1-1.5,0c-0.2-0.1-0.4-0.1-0.5,0c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.6-0.1-0.9,0c-0.5,0-1-0.1-1.5,0c-0.2-0.1-0.4-0.1-0.5,0 c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.6-0.1-1,0c-0.5,0-1-0.1-1.5,0C44,32,43.8,32,43.6,32c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.6-0.1-1,0 c-0.5,0-1-0.1-1.5,0c-0.2-0.1-0.4-0.1-0.5,0c-0.1,0-0.1,0-0.2,0C40,32,39.7,32,39.4,32c-0.2,0-0.4,0-0.6,0c-0.2-0.1-0.4,0-0.6,0 c-0.9-0.1-1.5-0.4-1.8-1.2c-0.2-0.4-0.2-0.8-0.2-1.3c0-5,0-10.1,0-15.1c0-0.8,0.1-1.4,0.7-2l0,0l0,0c0.3-0.1,0.6-0.3,0.8-0.4 c0.1,0,0.1,0,0.2,0c3.1,0,6.3,0,9.4,0c2.5,0,4.9,0,7.4,0C54.6,11.9,54.7,11.9,54.7,11.9z M41.6,27.9c0.8,0,1.5,0.1,2.3,0 c0.3,0.1,0.6,0.1,0.9,0c0.8,0,1.5,0.1,2.3,0c0.3,0.1,0.6,0.1,0.9,0c0.8,0,1.5,0.1,2.3,0c0.5,0.1,1.1,0,1.6,0c0.4,0,0.5-0.1,0.5-0.5 c0-3.7,0-7.4,0-11.1c0-0.4-0.1-0.5-0.5-0.5c-3.7,0-7.4,0-11.1,0c-0.5,0-0.5,0-0.4,0.5c0,0.8,0,1.5,0,2.3c0,0.3,0,0.6,0,0.9 c0,0.7,0,1.5,0,2.2c0,0.3,0,0.6,0,0.9c0,0.7,0,1.5,0,2.2c0,0.3,0,0.6,0,0.9c0,0.5,0,1,0,1.6c0,0.5,0,0.5,0.5,0.5 C41,28,41.3,28,41.6,27.9z"
                                />
                                <path
                                  className="st0"
                                  d="M13.4,56c-0.6-0.3-1.1-0.8-1.3-1.4c0-0.1,0-0.1,0-0.2c0-3.2,0-6.4,0-9.7c0-2.4,0-4.7,0-7.1c0-0.1,0-0.1,0-0.2 c0.1-0.2,0.1-0.3,0.2-0.5c0.4-0.7,1.1-1,1.9-1c3.2,0,6.4,0,9.6,0c2.1,0,4.1,0,6.2,0c1.2,0,1.9,0.6,2.1,1.7c0.1,0.3,0.1,0.6,0.1,0.9 c-0.1,0.2,0,0.4,0,0.6c-0.1,0.3-0.1,0.6,0,0.9c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.1,0.4,0,0.5c-0.1,0.5,0,1,0,1.5c-0.1,0.3-0.1,0.6,0,1 c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.1,0.3,0,0.5c-0.1,0.5,0,1,0,1.6c-0.1,0.3-0.1,0.6,0,1c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.1,0.3,0,0.5 c-0.1,0.5,0,1.1,0,1.6c-0.1,0.3-0.1,0.6,0,0.9c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.1,0.3,0,0.5c-0.1,0.5,0,1,0,1.6c-0.1,0.3-0.1,0.6,0,1 c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.1,0.3,0,0.5c0,0.1,0,0.2,0,0.3c-0.1,0.3,0,0.7-0.1,1c-0.2,0.7-0.9,1.3-1.6,1.4c-0.7,0-1.3,0-2,0 c-4.6,0-9.3,0-13.9,0c-0.3,0-0.6,0-0.9,0C13.5,56,13.5,56,13.4,56z M19.8,40c-0.3,0-0.6,0-0.9,0c-0.8,0-1.5,0-2.3,0 c-0.5-0.1-0.5-0.1-0.5,0.4c0,3.7,0,7.4,0,11.1c0,0.4,0.1,0.5,0.5,0.5c3.1,0,6.1,0,9.2,0c0.7,0,1.4,0,2,0c0.2,0,0.3,0,0.3-0.3 c0-0.6,0-1.2,0-1.8c0.1-0.8,0-1.5,0-2.3c0-0.3,0-0.6,0-0.9c0.1-0.8,0-1.5,0-2.3c0-0.3,0-0.6,0-0.9c0.1-0.8,0-1.5,0-2.3 c0-0.3,0-0.6,0-0.9c0-0.2,0.2-0.5-0.2-0.5c-0.6,0-1.2,0-1.8,0c-0.3,0-0.6,0-0.9,0c-0.7,0-1.5,0-2.2,0c-0.3,0-0.6,0-0.9,0 C21.2,40,20.5,40,19.8,40z"
                                />
                                <path
                                  className="st0"
                                  d="M48.2,37.5c0,0.1,0,0.2,0,0.2c0,0.2,0,0.4,0,0.6c0,0.1-0.1,0.2,0,0.2c-0.2,0.2-0.3,0.5-0.4,0.8 c-0.3,0.4-0.8,0.6-1.2,0.7c-0.2,0-0.4,0-0.6,0c-0.1-0.1-0.3-0.1-0.4-0.2c-1-0.4-1.6-1.6-1.2-2.6c0.5-1.1,1.7-1.6,2.7-1.1 C47.6,36.4,47.9,36.9,48.2,37.5z"
                                />
                                <path
                                  className="st0"
                                  d="M37.8,48c-0.1,0-0.1,0-0.2,0c-0.1-0.1-0.2-0.1-0.3-0.2c-1-0.4-1.5-1.6-1.1-2.6c0.4-1,1.6-1.5,2.7-1.1 c0.7,0.3,1,0.9,1.2,1.5c0,0.2,0,0.4,0,0.6c-0.2,0.8-0.7,1.3-1.5,1.7c-0.1,0-0.2,0-0.2,0C38.2,48,38,48,37.8,48z"
                                />
                                <path
                                  className="st0"
                                  d="M40.2,54.4C40,55,39.7,55.6,39,55.9c-0.8,0.4-1.8,0.2-2.4-0.5C36,54.7,36,53.7,36.5,53c0.1-0.1,0.1-0.2,0.2-0.3 c0,0,0,0,0,0c0.3-0.3,0.6-0.4,1-0.6c0.1,0,0.1,0,0.2,0c0.6,0,1.2,0.1,1.7,0.5l0,0c0.4,0.5,0.6,1,0.7,1.6 C40.1,54.2,40.1,54.3,40.2,54.4z"
                                />
                                <path
                                  className="st0"
                                  d="M54.3,40c-0.9-0.1-1.6-0.4-1.9-1.3c-0.4-1,0-2.1,1.1-2.6c0.9-0.4,2.1,0,2.6,0.9c0.6,1.2,0,2.5-1.4,2.9 c-0.1,0-0.1,0-0.2,0.1C54.4,40,54.3,40,54.3,40z"
                                />
                                <path
                                  className="st0"
                                  d="M48.2,54.4c0,0.1-0.1,0.2,0,0.2c-0.2,0.1-0.2,0.3-0.2,0.5c-0.4,0.7-1,1-1.8,1c-0.8,0-1.4-0.4-1.7-1.1 c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.1,0-0.2c-0.1-0.7,0-1.3,0.5-1.8c0.3-0.3,0.6-0.4,0.9-0.6c0.1,0,0.1,0,0.2,0c0.2,0,0.5,0,0.7,0 c0.1,0,0.2,0.1,0.2,0c0.2,0.1,0.4,0.3,0.7,0.4c0.4,0.2,0.5,0.7,0.7,1c0,0.1,0,0.2,0,0.3C48.2,54,48.2,54.2,48.2,54.4z"
                                />
                                <path
                                  className="st0"
                                  d="M53.9,48c-0.1,0-0.2-0.1-0.2,0c-0.1-0.2-0.3-0.2-0.4-0.2c-0.5-0.3-0.7-0.7-1-1.2c0-0.1,0-0.2,0-0.2 c0-0.2,0-0.5,0-0.7c0-0.1,0-0.1,0-0.2c0.3-0.6,0.6-1.1,1.2-1.3c0.4-0.2,0.8-0.1,1.2-0.1c0.1,0,0.1,0,0.2,0c0.5,0.3,1,0.6,1.2,1.1 c0.5,1.1,0,2.2-1,2.7c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2,0-0.2,0C54.3,48,54.1,48,53.9,48z"
                                />
                                <path
                                  className="st0"
                                  d="M40.2,38c0,1.1-0.9,2-2,2c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2C39.2,36,40.2,36.9,40.2,38z"
                                />
                              </svg>
                            ) : null}
                            <div
                              id="modalReason"
                              className="modalReason display-none"
                            >
                              <div>
                                <section className="modal-main">
                                  <div className="card container">
                                    <p style={{ fontSize: "18px" }}>
                                      {t("reason")}
                                    </p>
                                    <p style={{ fontSize: "15px" }}>
                                      {this.props.groupAdsStore.adGroup.reason}
                                    </p>
                                  </div>
                                </section>
                              </div>
                            </div>
                            {!this.props.groupAdsStore.isFound && (
                              <p
                                style={{
                                  fontSize: ".67em",
                                  color: "#e57070",
                                  display: "inline-block",
                                  marginLeft: "1rem",
                                }}
                              >
                                {t("ga.head.noRegion")}
                              </p>
                            )}

                            {this.props.groupAdsStore.isFound &&
                              this.props.groupAdsStore.workingUser !==
                                undefined &&
                              this.props.groupAdsStore.workingUser !== null &&
                              this.props.groupAdsStore.workingUser !== "-" &&
                              this.props.groupAdsStore.workingUser !==
                                this.props.groupAdsStore.user.username && (
                                <p
                                  style={{
                                    fontSize: ".67em",
                                    color: "#e57070",
                                    display: "inline-block",
                                    marginLeft: "1rem",
                                  }}
                                >
                                  {t("ga.notifications.handled") +
                                    this.props.groupAdsStore.workingUser}
                                </p>
                              )}
                          </>
                        ) : (
                          <>
                            <div className="SpinnerDiv col-md-2">
                              <Spinner
                                animation="border"
                                variant="info"
                                role="status"
                                className="loadingSpinner"
                              >
                                <span className="sr-only">{t("loading")}</span>
                              </Spinner>
                            </div>
                          </>
                        )}
                      </h4>

                      <h6>
                        {t("ga.form.city") + ": "}{" "}
                        {this.props.groupAdsStore.adGroup.municipality !==
                        "" ? (
                          <>
                            {this.props.groupAdsStore.adGroup.municipality}
                            <b>
                              <OverlayTrigger
                                delay={{ show: 500, hide: 100 }}
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip">
                                    {t("ga.head.edited")}
                                  </Tooltip>
                                }
                              >
                                <svg
                                  className="fas fa-user-edit"
                                  style={{ marginLeft: "1%", height: "16px" }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 640 512"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h274.9c-2.4-6.8-3.4-14-2.6-21.3l6.8-60.9 1.2-11.1 7.9-7.9 77.3-77.3c-24.5-27.7-60-45.5-99.9-45.5zm45.3 145.3l-6.8 61c-1.1 10.2 7.5 18.8 17.6 17.6l60.9-6.8 137.9-137.9-71.7-71.7-137.9 137.8zM633 268.9L595.1 231c-9.3-9.3-24.5-9.3-33.8 0l-37.8 37.8-4.1 4.1 71.8 71.7 41.8-41.8c9.3-9.4 9.3-24.5 0-33.9z"
                                  ></path>
                                </svg>
                              </OverlayTrigger>
                            </b>
                          </>
                        ) : this.props.groupAdsStore.retrievedMunicipality !==
                          "" ? (
                          this.props.groupAdsStore.retrievedMunicipality
                        ) : (
                          t("ga.noMunicipality")
                        )}
                        <br></br>
                        {t("ga.head.numAds") +
                          ": " +
                          (this.props.groupAdsStore.ads.length > 0
                            ? this.props.groupAdsStore.ads.length
                            : 0)}
                      </h6>
                      {this.props.userStore.user && this.props.userStore.user.productsExtra && this.props.userStore.user.productsExtra.compliance ? 
                       
                       <div className="compliance-array-top">
                       {this.props.groupAdsStore.complianceGroup["exc"] > 0 ? <div><svg><circle r="40%" cx="50%" cy="43%" fill="#00FF00" stroke="#0d6d0d" strokeWidth="1.5" /></svg> {this.props.groupAdsStore.complianceGroup["exc"]}</div>:null}
                       {this.props.groupAdsStore.complianceGroup["hig"] > 0 ? <div><svg><circle r="40%" cx="50%" cy="43%" fill="#FFFF00" stroke="#6d6d0d" strokeWidth="1.5" /></svg> {this.props.groupAdsStore.complianceGroup["hig"]}</div>:null}
                       {this.props.groupAdsStore.complianceGroup["mid"] > 0 ? <div><svg><circle r="40%" cx="50%" cy="43%" fill="#FFA500" stroke="#916619" strokeWidth="1.5" /></svg> {this.props.groupAdsStore.complianceGroup["mid"]}</div>:null}
                       {this.props.groupAdsStore.complianceGroup["low"] > 0 ? <div><svg><circle r="40%" cx="50%" cy="43%" fill="#FF0000" stroke="#6d0d0d" strokeWidth="1.5" /></svg> {this.props.groupAdsStore.complianceGroup["low"]}</div>:null}
                       {this.props.groupAdsStore.complianceGroup["def"] > 0 ? <div><svg><circle r="40%" cx="50%" cy="43%" fill="#900C3F" stroke="#3d0c0c" strokeWidth="1.5" /></svg> {this.props.groupAdsStore.complianceGroup["def"]}</div>:null}
                       </div>
                     
                    
                   : null}
                    </div>
                    <div className="col-md-5">
                      {this.props.groupAdsStore.isFound &&
                      this.props.groupAdsStore.userIsTheWorkingUser ? (
                        <>
                          <p>
                            <MyButton
                              id="new-group1"
                              type="button"
                              name={t("ga.head.transfer")}
                              onClick={() => {
                                this.props.navigationStore.push(
                                  "/transferAds/" +
                                    this.props.groupAdsStore.adGroup.id
                                );
                              }}
                              visible="false"
                              className="btn btn-outline-info edit-group"
                              text={t("ga.head.transfer")}
                            />
                            <MyButton
                              id="new-group2"
                              type="button"
                              name={t("ga.head.newGroup")}
                              onClick={() => {
                                this.props.navigationStore.push(
                                  "/groupEditor/" +
                                    this.props.groupAdsStore.adGroup.id
                                );
                              }}
                              visible="false"
                              className="btn btn-outline-info edit-group"
                              text={t("ga.head.newGroup")}
                              disabled={
                                this.props.groupAdsStore.ads.length === 0
                                  ? "true"
                                  : "false"
                              }
                            />
                          </p>
                          <br />
                          <p>
                            <OverlayTrigger
                              delay={{ show: 500, hide: 100 }}
                              placement="left"
                              overlay={
                                <Tooltip id="tooltip">
                                  {t("ga.head.edit")}
                                </Tooltip>
                              }
                            >
                              <button
                                id="edit-group"
                                type="button"
                                onClick={this.onDisableTrue}
                                visible="false"
                                className="btn btn-outline-info edit-group"
                              >
                                <svg
                                  className="svg-icon-edit-ga"
                                  data-name="Capa 31"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 63.72 63.68"
                                >
                                  <path
                                    className="cls-1"
                                    d="M12.1,72.21a5.05,5.05,0,0,1-1.69,0,3,3,0,0,1-2.16-1.81c0-.09,0-.21-.15-.27V70a.2.2,0,0,0,0-.07,7.59,7.59,0,0,1,.05-1.26c.25-1.42.49-2.84.73-4.26.05-.25.1-.49.14-.74.19-1.09.37-2.18.55-3.27.27-1.3.49-2.61.69-3.92.14-.75.28-1.49.4-2.24a1.31,1.31,0,0,1,.42-.81c.33-.29.62-.63.93-1a.06.06,0,0,0,0,0l4-4,0,0c1.34-1.3,2.66-2.61,3.95-4s2.65-2.6,3.94-3.94l.05-.05q2-1.94,4-3.95l0-.05,4-3.95.06-.05,3.88-3.88,0,0c1.34-1.3,2.65-2.61,4-4l0-.06,3.22-3.19a3.92,3.92,0,0,1,.78-.63l.15-.06h.8a3,3,0,0,1,1,.67c.66.67,1.34,1.32,2,2l1.29,1.29,2.71,2.71,1.32,1.36c.88.92,1.77,1.82,2.7,2.69l1.17,1.16,2.06,2.08a2.78,2.78,0,0,1,.71,1v.72a.29.29,0,0,0-.07.22,3.71,3.71,0,0,1-.59.72c-1.09,1.1-2.18,2.19-3.26,3.29q-2,1.92-3.93,3.93l-.08.08c-1.34,1.28-2.64,2.59-3.93,3.92l-.08.08q-2,1.92-3.93,3.93t-4,4l-.94.91-3,3-.09.09C34.59,61.72,33.28,63,32,64.36h0q-2,2-4,4l-.08.07-1,1a.76.76,0,0,1-.44.23L24,70.11l-.86.14L20,70.83,16,71.5c-.41.07-.81.13-1.21.21C13.92,71.88,13,72,12.1,72.21ZM16,55.41h-.58c-1,0-1,0-1.13,1-.05.24-.1.48-.14.71-.19,1.08-.37,2.15-.55,3.22a13.45,13.45,0,0,1-.38,1.91A1.65,1.65,0,0,0,14,64.34l0,0c.65.68,1.31,1.34,2,2l.78.8a.31.31,0,0,0,.32.11L20,66.74l.89-.14L24,66l.79-.11c.16,0,.22-.09.22-.26,0-.44,0-.88,0-1.31,0-.67,0-1.35,0-2,0-1,0-1-1-.95H20c-.23,0-.46,0-.69,0s-.29,0-.28-.26a6.45,6.45,0,0,0,0-.76q0-2,0-3.93c0-.27,0-.53,0-.79s-.06-.23-.23-.23ZM27.62,44.35l-1.19,1.16c-1,1-2.06,2-3,3.07a1.74,1.74,0,0,0,2.71,2.18L45.05,31.84a2.05,2.05,0,0,0,.39-.49,1.66,1.66,0,0,0,0-1.73,1.62,1.62,0,0,0-1.47-.84,1.76,1.76,0,0,0-1.37.61L29.88,42.06l-2,2A.83.83,0,0,0,27.62,44.35Z"
                                    transform="translate(-8.08 -8.62)"
                                  />
                                  <path
                                    className="cls-1"
                                    d="M55.88,9.13l1.48-.45a7,7,0,0,1,2.49.13,6.48,6.48,0,0,1,2.85,1.66l1.11,1.09,1.07,1.07c1,1,1.94,2,3,2.94l1,1c.18.19.36.39.55.57a7.28,7.28,0,0,1,2.28,3.43l.14.58a12.29,12.29,0,0,1,0,1.82,12.65,12.65,0,0,1-.48,1.59A7.07,7.07,0,0,1,70,26.36l-2.1,2.11-.07.07c-1.12,1.11-2.25,2.21-3.36,3.33a1.65,1.65,0,0,1-1.74.53,4.19,4.19,0,0,1-.84-.67L60,29.85l-1.31-1.31-2.79-2.78-1.21-1.22q-1.36-1.44-2.79-2.79l-1.22-1.21-.45-.48A20.09,20.09,0,0,1,48,17.63a2.06,2.06,0,0,1,.82-1.95c1-1,2-2,3-3.06l.06-.06C52.42,12,53,11.5,53.51,11A8.28,8.28,0,0,1,55.88,9.13Z"
                                    transform="translate(-8.08 -8.62)"
                                  />
                                </svg>
                              </button>
                            </OverlayTrigger>
                          </p>
                          
                        </>
                      ) : null}
                      
                    </div>
                  </div>
                  <hr />
                  <br />
                  <div className="form-row">
                    <div className="col-md-4 mb-3">
                      <label>{t("ga.form.title")}</label>
                      <MyInput
                        onChange={this.updateF}
                        value={this.props.groupAdsStore.adGroupForm.title}
                        visible="false"
                        name="title"
                        valid="true"
                        type="text"
                        className="form-control controlDisable generic-input"
                        id="groupID-title"
                        placeholder={t("ga.form.inputTitle")}
                        disabled
                      />
                      <div className="valid-feedback">Looks good!</div>
                      <div className="invalid-feedback">
                        {t("ga.notifications.title")}
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>{t("ga.form.hostName")}</label>
                      <MyInput
                        onChange={this.updateF}
                        value={this.props.groupAdsStore.adGroupForm.ownerName}
                        visible="false"
                        name="ownerName"
                        valid="true"
                        type="text"
                        className="form-control controlDisable generic-input "
                        id="groupID-ownerName"
                        placeholder={t("ga.form.inputHostName")}
                        disabled
                      />
                      <div className="valid-feedback">Looks good!</div>
                      <div className="invalid-feedback">
                        {t("ga.notifications.name")}
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>{t("ga.form.roomType")}</label>
                      <MyInput
                        onChange={this.updateF}
                        value={this.props.groupAdsStore.adGroupForm.typeOfRoom}
                        visible="false"
                        name="typeOfRoom"
                        valid="true"
                        type="text"
                        className="form-control controlDisable generic-input "
                        id="groupID-typeRoom"
                        placeholder={t("ga.form.inputRoomType")}
                        disabled
                      />
                      <div className="valid-feedback">Looks good!</div>
                      <div className="invalid-feedback">
                        {t("ga.notifications.roomType")}
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-4 mb-3">
                      <label>{t("ga.form.address")}</label>
                      <MyInput
                        onChange={this.updateF}
                        value={this.props.groupAdsStore.adGroupForm.adAdress}
                        visible="false"
                        name="adAdress"
                        valid="true"
                        type="text"
                        className="form-control controlDisable generic-input "
                        id="groupID-adress"
                        placeholder={t("ga.form.inputAddress")}
                        disabled
                      />
                      <div className="valid-feedback">Looks good!</div>
                      <div className="invalid-feedback">
                        {t("ga.notifications.address")}
                      </div>
                    </div>
                    <div className="col-md-2 mb-3">
                      <label>{t("ga.form.license")}</label>
                      <MyInput
                        onChange={this.updateF}
                        value={
                          this.props.groupAdsStore.adGroupForm.licenseNumber
                        }
                        visible="false"
                        name="licenseNumber"
                        valid="true"
                        type="text"
                        className="form-control controlDisable generic-input disabled"
                        id="groupID-licenseNumber"
                        placeholder={t("ga.form.inputLicense")}
                        disabled
                      />
                      <div className="valid-feedback">Looks good!</div>
                      <div className="invalid-feedback">
                        {t("ga.notifications.license")}
                      </div>
                    </div>
                    <div className="col-md-2 mb-3">
                      <label>{t("ga.form.city")}</label>
                      <MyInput
                        onChange={this.updateF}
                        value={
                          this.props.groupAdsStore.adGroupForm.municipality
                        }
                        visible="false"
                        id="groupID-takenDays"
                        name="municipality"
                        valid="true"
                        type="text"
                        className="controlDisable form-control generic-input disabled  "
                        placeholder={t("ga.form.inputCity")}
                        disabled
                      />
                      <div className="valid-feedback">Looks good!</div>
                      <div className="invalid-feedback">
                        {t("ga.notifications.municipality")}
                      </div>
                    </div>
                    <div className="col-md-2 mb-3">
                      <label>{t("ga.form.latitude")}</label>
                      <MyInput
                        onChange={this.updateF}
                        value={this.props.groupAdsStore.adGroupForm.latitude}
                        visible="false"
                        id="latitude"
                        name="latitude"
                        valid="true"
                        type="text"
                        className="controlDisable form-control generic-input disabled"
                        placeholder={t("ga.form.inputLatitude")}
                        disabled
                      />
                      <div className="valid-feedback">Looks good!</div>
                      <div className="invalid-feedback">
                        {t("ga.notifications.latitude")}
                      </div>
                    </div>
                    <div className="col-md-2 mb-3">
                      <label>{t("ga.form.longitude")}</label>
                      <MyInput
                        onChange={this.updateF}
                        value={this.props.groupAdsStore.adGroupForm.longitude}
                        visible="false"
                        id="longitude"
                        name="longitude"
                        valid="true"
                        type="text"
                        className="controlDisable form-control generic-input disabled"
                        placeholder={t("ga.form.inputLongitude")}
                        disabled
                      />
                      <div className="valid-feedback">Looks good!</div>
                      <div className="invalid-feedback">
                        {t("ga.notifications.longitude")}
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>{t("ga.form.tags")}</label>
                      <MyInput
                        onChange={this.updateF}
                        value={this.props.groupAdsStore.adGroupForm.tags}
                        visible="false"
                        id="tags"
                        name="tags"
                        valid="true"
                        type="text"
                        className="controlDisable form-control generic-input disabled"
                        placeholder={t("ga.form.inputTags")}
                        disabled
                      />
                      <div className="valid-feedback">Looks good!</div>
                      <div className="invalid-feedback">
                        {t("ga.notifications.tags")}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    //id="edit-group-confirm"
                    onClick={this.save}
                    className="btn btn-info generic-data-btn hiddenbutton"
                    id="disable_false"
                    style={{ display: "none" }}
                  >
                    {t("ga.head.confirm")}
                  </button>
                  <button
                    type="button"
                    //id="edit-group-cancel"
                    onClick={this.discard}
                    className="btn btn-outline-danger generic-data-btn hiddenbutton"
                    id="disable_false2"
                    style={{ display: "none" }}
                  >
                    {t("ga.head.cancel")}
                  </button>
                </form>
              </div>
              <hr />
              <div id="groupID-ads-carusel" className="row ads-carusel">
                <div className="control-btns">
                  <button
                    type="button"
                    className="carrousel-btn-prev"
                    onClick={() => {
                      this.slideCarousel(0);
                    }}
                    data-slide="prev"
                  >
                    <svg
                      className="icon-arrow-carroussel-ga"
                      data-name="fas fa-angle-left"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 26.2 40.11"
                    >
                      <path
                        d="M52.15,28.68a2.08,2.08,0,0,0,0-3l-4-4s-1-1-3,0L27.08,39.51s-1,1,0,3L44.94,60.63a2.08,2.08,0,0,0,3,0l4-4a2.08,2.08,0,0,0,0-3L40.06,41.6Z"
                        transform="translate(-26.62 -21.2)"
                      />
                    </svg>
                  </button>
                  <button
                    type="button"
                    className="carrousel-btn-next"
                    onClick={() => {
                      this.slideCarousel(1);
                    }}
                    data-slide="next"
                  >
                    <svg
                      className="icon-arrow-carroussel-ga"
                      data-name="fas fa-angle-right"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 26.12 40.11"
                    >
                      <path
                        d="M27.22,53.48a2.08,2.08,0,0,0,0,3l4,4s1,1,3,0l18-18s1-1,0-3l-18-18a2.08,2.08,0,0,0-3,0l-4,4a2.08,2.08,0,0,0,0,3l12,12Z"
                        transform="translate(-26.54 -20.81)"
                      />
                    </svg>
                  </button>
                </div>
                <div className="carousel-container row">
                  {this.props.groupAdsStore.ads.map((ad, i) => {
                    if (
                      i >= this.props.groupAdsStore.index &&
                      i <
                        this.props.groupAdsStore.index +
                          Math.floor(this.maxCards)
                    ) {
                      return (
                        <div
                          key={"adID" + ad.id + "Key"}
                          id={"adID" + ad.id}
                          onClick={() => {
                            this.selectedAd("adID" + ad.id, i);
                          }}
                          className="carousel-content"
                        >
                          <div
                            className={
                              ad.error ===1 
                              ? "item error-card-ad"
                              :
                              ad.discarded
                                ? "item discard-card-ad"
                                : !ad.adIsSeen
                                ? "item new-card-ad"
                                : "item active-ad"
                                
                            }
                          >
                            <div
                              className={
                                ad.error ===1 
                                ? "error-card-ad "
                                 :  
                                ad.discarded
                                  ? "card-ad no-border mb-2"
                                  : !ad.adIsSeen
                                  ? "card-ad border-info mb-2"
                                  : "card-ad  no-border mb-2"
                              }
                            >
                              <div className="card-header">
                                <img
                                  className="card-img-top"
                                  src={
                                    ad.index >= 0 ? ad.images[ad.index] : null
                                  }
                                  alt="Card img cap"
                                />
                                <div className="img-card-ad-components">
                                  <MyButton
                                    className="btn btn-default arrow-btn arrow-btn-left"
                                    text={
                                      <>
                                        <svg
                                          className="icon-arrow-image-ad"
                                          data-name="fas fa-angle-left"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 26.2 40.11"
                                        >
                                          <path
                                            d="M52.15,28.68a2.08,2.08,0,0,0,0-3l-4-4s-1-1-3,0L27.08,39.51s-1,1,0,3L44.94,60.63a2.08,2.08,0,0,0,3,0l4-4a2.08,2.08,0,0,0,0-3L40.06,41.6Z"
                                            transform="translate(-26.62 -21.2)"
                                          />
                                        </svg>
                                      </>
                                    }
                                    onClick={() => {
                                      this.slideImage(i, -1);
                                    }}
                                  />

                                  <MyButton
                                    className="btn btn-default arrow-btn arrow-btn-right"
                                    text={
                                      <>
                                        <svg
                                          className="icon-arrow-image-ad"
                                          data-name="fas fa-angle-right"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 26.12 40.11"
                                        >
                                          <path
                                            d="M27.22,53.48a2.08,2.08,0,0,0,0,3l4,4s1,1,3,0l18-18s1-1,0-3l-18-18a2.08,2.08,0,0,0-3,0l-4,4a2.08,2.08,0,0,0,0,3l12,12Z"
                                            transform="translate(-26.54 -20.81)"
                                          />
                                        </svg>
                                      </>
                                    }
                                    onClick={() => {
                                      this.slideImage(i, 1);
                                    }}
                                  />
                                  <MyButton
                                    className="btn btn-outline-info google-btn-ads"
                                    name={t("ga.gSearch")}
                                    text={
                                      <>
                                        <img
                                          className="google-maps-img"
                                          src={"/logos/google-logo.png"}
                                          alt="logo-google"
                                          onClick={() => {
                                            this.props.navigationStore.openInNewTab(
                                              "https://www.google.com/searchbyimage?image_url=" +
                                                ad.images[ad.index]
                                            );
                                          }}
                                        />
                                      </>
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                className="card-body"
                                onMouseEnter={(e) => {
                                  this.props.mapListStore.changeCircleColor(ad);
                                }}
                                onMouseLeave={(e) => {
                                  this.props.mapListStore.switchCircleColor(
                                    "repaint"
                                  );
                                }}
                              >
                                <div className="row">
                                  <div className="col-md-4">
                                    <MyButton
                                      className="btn btn-default platform-logo"
                                      onClick={() =>
                                        this.props.navigationStore.openInNewTab(
                                          ad.url
                                        )
                                      }
                                      text={
                                        <>
                                          <OverlayTrigger
                                            delay={{ show: 500, hide: 100 }}
                                            placement="bottom"
                                            overlay={
                                              <Tooltip id="tooltip">
                                                {ad.website.toLowerCase()}
                                              </Tooltip>
                                            }
                                          >
                                            <img
                                              className="ad-platform-logo"
                                              src={
                                                "/logos/" +
                                                ad.website.toLowerCase() +
                                                ".png"
                                              }
                                              alt={ad.website}
                                            />
                                          </OverlayTrigger>
                                        </>
                                      }
                                    />
                                    {/* <p className="ad-info-message">
                                      <svg
                                        className={
                                          "svg-icon-message" +
                                          (!!ad.ownerContacted
                                            ? " messActive"
                                            : " messDisable")
                                        } */}

                                    <MyButton
                                      id=""
                                      type="button"
                                      name="message"
                                      className="btn btn-default"
                                      text={
                                        <>
                                          <svg
                                            className={
                                              "svg-icon-message" +
                                              (!!ad.ownerContacted
                                                ? " messActive"
                                                : " messDisable")
                                            }
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 63.71 47.79"
                                          >
                                            <path
                                              className="cls-1"
                                              d="M8,43.71V35.78c0-1.12,0-2.25,0-3.37,0-.57.31-.92.77-.91a1.53,1.53,0,0,1,.65.29c.83.68,1.68,1.34,2.56,2s1.8,1.39,2.74,2a10.5,10.5,0,0,0,1.26.93q1.91,1.46,3.87,2.83a.75.75,0,0,0,.32.23c1.22.94,2.48,1.84,3.74,2.73l1.74,1.27,2.26,1.64c1.41,1.07,2.8,2.19,4.26,3.2a20.25,20.25,0,0,0,3.67,2.12,8.77,8.77,0,0,0,4,.94,9.45,9.45,0,0,0,4.11-1,21.06,21.06,0,0,0,3.83-2.26,7.59,7.59,0,0,0,1-.73l3-2.29c.8-.55,1.59-1.11,2.35-1.71.57-.37,1.11-.77,1.65-1.19,1.23-.87,2.45-1.74,3.64-2.66l.36-.25c1.32-.95,2.64-1.9,3.92-2.9A10.5,10.5,0,0,0,65,35.79c1-.67,1.91-1.38,2.83-2.11.82-.57,1.61-1.18,2.39-1.81l.1-.05a.82.82,0,0,1,1-.23.94.94,0,0,1,.4.94c0,1.09,0,2.17,0,3.25V55.71c-.06.83,0,1.66-.06,2.49a6,6,0,0,1-5.16,5.37c-.93.11-1.86,0-2.79.07H14.05a6.15,6.15,0,0,1-2.53-.54,6,6,0,0,1-3.17-3.46l-.26-1C8,57.65,8,56.67,8,55.71v-12Z"
                                              transform="translate(-8 -15.85)"
                                            />
                                            <path
                                              className="cls-1"
                                              d="M59.78,15.85h3.93c.85.05,1.71,0,2.57.05a6,6,0,0,1,5.37,5.37c.07.86,0,1.72.06,2.58l-.07.95a3.51,3.51,0,0,1-1.5,2.08c-.42.29-.81.64-1.21,1l-1.31,1-3.77,2.86-.15.11q-2,1.41-3.93,2.9a16.67,16.67,0,0,0-1.44,1.06c-.87.59-1.72,1.21-2.56,1.86-1,.68-2,1.39-2.92,2.12a9.34,9.34,0,0,0-1.08.79l-.27.17c-2.59,1.8-5,3.86-7.65,5.55-.54.3-1.08.61-1.65.87s-1.06.35-1.59.55l-.76,0-1-.09a12.61,12.61,0,0,1-3-1.36l-1-.65c-1.72-1.14-3.28-2.48-5-3.67a20.11,20.11,0,0,0-1.95-1.37,7.47,7.47,0,0,0-1.16-.87c-.93-.72-1.87-1.4-2.83-2.06s-1.69-1.28-2.57-1.86a14.91,14.91,0,0,0-1.44-1.07L16,31.81a.05.05,0,0,0-.05,0l-.74-.57L12,28.8l-1.22-.95c-.41-.32-.8-.67-1.22-1A3.76,3.76,0,0,1,8.08,25L8,23.86c.06-.85,0-1.69.06-2.54a6,6,0,0,1,5.37-5.4c.86-.07,1.72,0,2.58-.06H59.78Z"
                                              transform="translate(-8 -15.85)"
                                            />
                                          </svg>
                                        </>
                                      }
                                    />
                                    {/*<p className="ad-info-message" name="message">*/}
                                    {/*<svg
                                        className="svg-icon-message messDisable"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 63.71 47.79"
                                      >
                                        <path
                                          className="cls-1"
                                          d="M8,43.71V35.78c0-1.12,0-2.25,0-3.37,0-.57.31-.92.77-.91a1.53,1.53,0,0,1,.65.29c.83.68,1.68,1.34,2.56,2s1.8,1.39,2.74,2a10.5,10.5,0,0,0,1.26.93q1.91,1.46,3.87,2.83a.75.75,0,0,0,.32.23c1.22.94,2.48,1.84,3.74,2.73l1.74,1.27,2.26,1.64c1.41,1.07,2.8,2.19,4.26,3.2a20.25,20.25,0,0,0,3.67,2.12,8.77,8.77,0,0,0,4,.94,9.45,9.45,0,0,0,4.11-1,21.06,21.06,0,0,0,3.83-2.26,7.59,7.59,0,0,0,1-.73l3-2.29c.8-.55,1.59-1.11,2.35-1.71.57-.37,1.11-.77,1.65-1.19,1.23-.87,2.45-1.74,3.64-2.66l.36-.25c1.32-.95,2.64-1.9,3.92-2.9A10.5,10.5,0,0,0,65,35.79c1-.67,1.91-1.38,2.83-2.11.82-.57,1.61-1.18,2.39-1.81l.1-.05a.82.82,0,0,1,1-.23.94.94,0,0,1,.4.94c0,1.09,0,2.17,0,3.25V55.71c-.06.83,0,1.66-.06,2.49a6,6,0,0,1-5.16,5.37c-.93.11-1.86,0-2.79.07H14.05a6.15,6.15,0,0,1-2.53-.54,6,6,0,0,1-3.17-3.46l-.26-1C8,57.65,8,56.67,8,55.71v-12Z"
                                          transform="translate(-8 -15.85)"
                                        />
                                        <path
                                          className="cls-1"
                                          d="M59.78,15.85h3.93c.85.05,1.71,0,2.57.05a6,6,0,0,1,5.37,5.37c.07.86,0,1.72.06,2.58l-.07.95a3.51,3.51,0,0,1-1.5,2.08c-.42.29-.81.64-1.21,1l-1.31,1-3.77,2.86-.15.11q-2,1.41-3.93,2.9a16.67,16.67,0,0,0-1.44,1.06c-.87.59-1.72,1.21-2.56,1.86-1,.68-2,1.39-2.92,2.12a9.34,9.34,0,0,0-1.08.79l-.27.17c-2.59,1.8-5,3.86-7.65,5.55-.54.3-1.08.61-1.65.87s-1.06.35-1.59.55l-.76,0-1-.09a12.61,12.61,0,0,1-3-1.36l-1-.65c-1.72-1.14-3.28-2.48-5-3.67a20.11,20.11,0,0,0-1.95-1.37,7.47,7.47,0,0,0-1.16-.87c-.93-.72-1.87-1.4-2.83-2.06s-1.69-1.28-2.57-1.86a14.91,14.91,0,0,0-1.44-1.07L16,31.81a.05.05,0,0,0-.05,0l-.74-.57L12,28.8l-1.22-.95c-.41-.32-.8-.67-1.22-1A3.76,3.76,0,0,1,8.08,25L8,23.86c.06-.85,0-1.69.06-2.54a6,6,0,0,1,5.37-5.4c.86-.07,1.72,0,2.58-.06H59.78Z"
                                          transform="translate(-8 -15.85)"
                                        />
                                      </svg>
                                      </p>*/}
                                  </div>
                                  <div className="col-md-8 card-title-ad">
                                    <h5
                                      style={{ minWidth: "100%" }}
                                      className="card-title"
                                    >
                                      {(!ad.name || ad.name === undefined)
                                        ? "-"
                                        : ad.name.length >= 15
                                        ? ad.name.substring(0, 15) + "..."
                                        : ad.name}
                                    </h5>
                                  </div>
                                </div>
                                <hr />
                                <div className="row card-ad-table">
                                  <table
                                    className="table table-condensed table-bordered table-sm"
                                    style={{ width: "90%" }}
                                  >
                                    <tbody>
                                      <tr>
                                        <th>{t("ga.card.ad")}</th>
                                        <td>
                                          {ad.id === undefined ? "-" : ad.id}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>{t("ga.card.licenseCode")}</th>
                                        <td>
                                          {ad.license
                                            ? ad.license.webCode
                                              ? ad.license.webCode
                                              : "-"
                                            : "-"}
                                        </td>
                                      </tr>
                                      {
                                        this.props.userStore.user && this.props.userStore.user.productsExtra && this.props.userStore.user.productsExtra.compliance?
                                        <tr>
                                        <th>{t("compliance.status")}</th>
                                        <td>
                                          <div className="compliance-array">

                                          {ad.license
                                            ? ad.license.compliance_status === "exc" || ad.license.compliance_status === "Correcto" ? <div><svg><circle r="30%" cx="50%" cy="43%" fill="#00FF00" stroke="#0d6d0d" strokeWidth="1.5"/></svg> {t("compliance.exc") }</div>:
                                            ad.license.compliance_status === "hig" || ad.license.compliance_status === "Leve" ? <div><svg><circle r="30%" cx="50%" cy="43%" fill="#FFFF00" stroke="#6d6d0d" strokeWidth="1.5" /></svg> {t("compliance.hig")} </div>: 
                                            ad.license.compliance_status === "mid" ? <div><svg><circle r="30%" cx="50%" cy="43%" fill="#FFA500" stroke="#916619" strokeWidth="1.5"/></svg> {t("compliance.mid")}</div>: 
                                            ad.license.compliance_status === "low" || ad.license.compliance_status === "Posible Fraude" ? <div><svg><circle r="30%" cx="50%" cy="43%" fill="#FF0000" stroke="#6d0d0d" strokeWidth="1.5" /></svg> {t("compliance.low")}</div> : 
                                            ad.license.compliance_status === "def" || ad.license.compliance_status === "Fraude" ? <div><svg><circle r="30%" cx="50%" cy="43%" fill="#900C3F" stroke="#3d0c0c" strokeWidth="1.5"/></svg> {t("compliance.def")} </div> : "-"
                                            : "-"}
                                            
                                            </div>
                                        </td>
                                      </tr>
                                        :
                                      <tr>
                                        <th>{t("ga.card.licenseStatus")}</th>
                                        <td>
                                          {ad.license
                                            ? ad.license.status === "noff"
                                              ? t("ga.noOfficial")
                                              : ad.license.status === "offi"
                                              ? t("ga.official")
                                              : ad.license.status === "bfor"
                                              ? t("ga.badFormat")
                                              : ad.license.status === "noli"
                                              ? t("ga.noLicense")
                                              : "-"
                                            : "-"}
                                        </td>
                                      </tr>
                                      }
                                      <tr>
                                        <th>{t("ga.card.hostName")}</th>
                                        <td>
                                          {ad.host && ad.host.nickname
                                            ? ad.host.nickname.length >= 15
                                              ? ad.host.nickname.substring(
                                                  0,
                                                  15
                                                ) + "..."
                                              : ad.host.nickname
                                            : "-"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>{t("ga.card.hostId")}</th>
                                        <td>
                                          {ad.host && ad.host.id
                                            ? ad.host.id
                                            : "-"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>{t("ga.card.type")}</th>
                                        <td>
                                          {ad.roomType
                                            ? ad.roomType.length >= 15
                                              ? ad.roomType.substring(0, 15) +
                                                "..."
                                              : ad.roomType
                                            : "-"}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="row card-ad-table">
                                  <table
                                    className="table table-condensed table-bordered table-sm"
                                    style={{ width: "90%" }}
                                  >
                                    <tbody>
                                      <tr>
                                        <th>{t("ga.card.capacity")}</th>
                                        <td>
                                          {ad.capacity !== undefined
                                            ? ad.capacity
                                            : "-"}
                                        </td>
                                        <th>{t("ga.card.takenDays")}</th>
                                        <td>
                                          {ad.occupancy !== undefined &&
                                          ad.occupancy
                                            .numCurrentYearReservations !==
                                            undefined
                                            ? ad["monthOcc"] +
                                              " / " +
                                              ad.occupancy
                                                .numCurrentYearReservations
                                            : "-"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>{t("ga.card.price")}</th>
                                        <td>{ad.price ? ad.price : 0}</td>
                                        <th>{t("ga.card.minNights")}</th>
                                        <td>
                                          {ad.minNights !== undefined
                                            ? ad.minNights
                                            : "-"}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="row card-ad-table">
                                  <table
                                    className="table table-condensed table-bordered table-sm"
                                    style={{ width: "90%" }}
                                  >
                                    <tbody>
                                      <tr>
                                        <th>{t("ga.card.latitude")}</th>
                                        <th>{t("ga.card.longitude")}</th>
                                      </tr>
                                      <tr>
                                        <td>
                                          {ad.geometry &&
                                          ad.geometry.coordinates[1]
                                            ? ad.geometry.coordinates[1]
                                            : "-"}
                                        </td>
                                        <td>
                                          {ad.geometry &&
                                          ad.geometry.coordinates[0]
                                            ? ad.geometry.coordinates[0]
                                            : "-"}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="row card-btns justify-content-center">
                                  {this.props.groupAdsStore.found &&
                                  this.props.groupAdsStore
                                    .userIsTheWorkingUser ? (
                                    <>
                                      {/*<button id={"adID" + ad.id + "-keepBtn"}
                              onClick={() => { this.keepAd("adID" + ad.id, ad.id) }}
                              className="keep-btn btn btn-outline-success"
                              style={ad.discarded ? { display: "inline" } : { display: "none" }}>Keep
                              </button>*/}
                                      {/*<button id={"adID" + ad.id + "-discardBtn"}
                              onClick={() => { this.discardAd("adID" + ad.id, ad.id) }}
                              className="discard-btn btn btn-outline-danger"
                              style={ad.discarded ? { display: "none" } : { display: "inline" }}>Discard
                              </button>*/}

                                      {!ad.adIsSeen && (
                                        <button
                                          id={"adID" + ad.id + "-seenBtn"}
                                          className="seen-btn btn btn-info"
                                          onClick={() => {
                                            this.seenAd("adID" + ad.id, ad.id);
                                          }}
                                        >
                                          {t("ga.card.seen")}
                                        </button>
                                      )}
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );}
                    else return null;
                  })}
                </div>
              </div>
              <div id="groupID-tabs-container" className="row ads-tabs">
                <ul
                  className="nav nav-tabs"
                  id="groupAd"
                  role="tablist"
                  style={{ width: "100%" }}
                >
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="comparator-tab"
                      data-toggle="tab"
                      href="#comparator"
                      role="tab"
                      aria-controls="comparator"
                      aria-selected="false"
                    >
                      {t("ga.comparator.comparator")}
                    </a>
                  </li>

                  {/*<li className="nav-item">
                    <a
                      className="nav-link"
                      id="tasks-tab"
                      data-toggle="tab"
                      href="#tasks"
                      role="tab"
                      aria-controls="tasks"
                      aria-selected="true"
                    >
                      {t("ga.tasks.title")}
                    </a>
                  </li>*/}

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="status-tab"
                      data-toggle="tab"
                      href="#status"
                      role="tab"
                      aria-controls="status"
                      aria-selected="true"
                    >
                      {t("ga.status.status")}
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="history-tab"
                      data-toggle="tab"
                      href="#history"
                      role="tab"
                      aria-controls="history"
                      aria-selected="true"
                    >
                      {t("ga.history.history")}
                    </a>
                  </li>

                  {/*this.props.groupAdsStore.remarks ? (
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="remark-tab"
                        data-toggle="tab"
                        href="#remark"
                        role="tab"
                        aria-controls="remark"
                        aria-selected="true"
                      >
                        {t("ga.remark.remark")}
                      </a>
                    </li>
                  ) : null*/}

                  {this.props.groupAdsStore.oldProcedures ? (
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="oldProcedure-tab"
                        data-toggle="tab"
                        href="#oldProcedure"
                        role="tab"
                        aria-controls="oldProcedure"
                        aria-selected="true"
                      >
                        {t("ga.oldProcedure.oldProcedure")}
                      </a>
                    </li>
                  ) : null}

                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="map-tab"
                      data-toggle="tab"
                      href="#map"
                      role="tab"
                      aria-controls="map"
                      aria-selected="true"
                    >
                      {t("ga.map.map")}
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a
                      className="nav-link"
                      id="contact-tab"
                      data-toggle="tab"
                      href="#contact"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="true"
                    >
                      Contacte
                    </a>
                  </li> */}

                  {this.props.userStore.user && this.props.userStore.user.productsExtra && this.props.userStore.user.productsExtra.compliance ? (
                    <li className="nav-item">
                      <a
                      className="nav-link"
                      id="compliance-tab"
                      data-toggle="tab"
                      href="#compliance"
                      role="tab"
                      aria-controls="compliance"
                      aria-selected="true"
                    >
                      {t("compliance.compliance")}
                    </a>
                    </li>
                  ) : null}
                </ul>
                <div
                  className="tab-content"
                  id="groupAdContent"
                  style={{ width: "100%" }}
                >
                  <div
                    className="tab-pane fade "
                    id="comparator"
                    role="tabpanel"
                    style={{ width: "100%" }}
                    aria-labelledby="comparator-tab"
                  >
                    <div id="comparator-content" className="col-md-12">
                      <div className="comparator-tab-control-btns control-btns">
                        <button
                          type="button"
                          className="carrousel-btn-prev"
                          onClick={() => {
                            this.slideCarousel(0);
                          }}
                          data-slide="prev"
                        >
                          <svg
                            className="icon-arrow-comparator-ga"
                            data-name="fas fa-angle-left"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 26.2 40.11"
                          >
                            <path
                              d="M52.15,28.68a2.08,2.08,0,0,0,0-3l-4-4s-1-1-3,0L27.08,39.51s-1,1,0,3L44.94,60.63a2.08,2.08,0,0,0,3,0l4-4a2.08,2.08,0,0,0,0-3L40.06,41.6Z"
                              transform="translate(-26.62 -21.2)"
                            />
                          </svg>
                        </button>
                        <button
                          type="button"
                          className="carrousel-btn-next"
                          onClick={() => {
                            this.slideCarousel(1);
                          }}
                          data-slide="next"
                        >
                          <svg
                            className="icon-arrow-comparator-ga"
                            data-name="fas fa-angle-right"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 26.12 40.11"
                          >
                            <path
                              d="M27.22,53.48a2.08,2.08,0,0,0,0,3l4,4s1,1,3,0l18-18s1-1,0-3l-18-18a2.08,2.08,0,0,0-3,0l-4,4a2.08,2.08,0,0,0,0,3l12,12Z"
                              transform="translate(-26.54 -20.81)"
                            />
                          </svg>
                        </button>
                      </div>
                      <div
                        id="comparator-table"
                        className="row comparator-table"
                      >
                        <table className="table table-hover table-borderless">
                          <tbody>
                          <tr>
                              <th className="th-table">
                                {t("ga.comparator.ad")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  return (
                                    <td
                                      key={ad.id + " adIDKey"}
                                      id={ad.id + " adID"}
                                      className={
                                        ad.error === 1
                                           ? "error-ad " + ad.id
                                           : 
                                        ad.discarded
                                          ? "discard-ad " + ad.id 
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.id === undefined ? "-" : ad.id}
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            <tr>
                              <th className="th-table">
                                {t("ga.comparator.licenseStatus")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  //document.getElementById("adID"+ad.id).childNodes[0].classList.contains("discard-card-ad")) ? "discard-ad "+ad.id

                                  return (
                                    <td
                                      key={ad.id + " licenseKey"}
                                      id={ad.id + " license"}
                                      className={
                                        ad.error === 1
                                         ? "error-ad " + ad.id
                                         :
                                        ad.discarded
                                          ? "discard-ad " + ad.id
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.license
                                        ? ad.license.status === "noff"
                                          ? t("ga.noOfficial")
                                          : ad.license.status === "offi"
                                          ? t("ga.official")
                                          : ad.license.status === "bfor"
                                          ? t("ga.badFormat")
                                          : ad.license.status === "noli"
                                          ? t("ga.noLicense")
                                          : "-"
                                        : "-"}
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            <tr>
                              <th className="th-table">
                                {t("ga.comparator.licenseCode")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  //document.getElementById("adID"+ad.id).childNodes[0].classList.contains("discard-card-ad")) ? "discard-ad "+ad.id
                                  return (
                                    <td
                                      key={ad.id + " licenseKey"}
                                      id={ad.id + " license"}
                                      className={
                                        ad.error === 1
                                        ? "error-ad " +ad.id
                                        :
                                        ad.discarded
                                          ? "discard-ad " + ad.id
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.license
                                        ? ad.license.webCode
                                          ? ad.license.webCode
                                          : "-"
                                        : "-"}
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            <tr>
                              <th className="th-table">
                                {t("ga.comparator.title")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  return (
                                    <td
                                      key={ad.id + " titleKey"}
                                      id={ad.id + " title"}
                                      className={
                                        ad.error === 1
                                        ? "error-ad " +ad.id 
                                        :
                                        ad.discarded
                                          ? "discard-ad " + ad.id
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.name === undefined ? "-" : ad.name}
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            <tr>
                              <th className="th-table">
                                {t("ga.comparator.owner")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  return (
                                    <td
                                      key={ad.id + " ownerIDKey"}
                                      id={ad.id + " ownerID"}
                                      className={
                                        ad.error === 1 
                                        ? "error-ad " + ad.id
                                        :
                                        ad.discarded
                                          ? "discard-ad " + ad.id
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.host && ad.host.id ? ad.host.id : "-"}
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            <tr>
                              <th className="th-table">
                                {t("ga.comparator.city")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  return (
                                    <td
                                      key={ad.id + " city"}
                                      id={ad.id + " city"}
                                      className={
                                        ad.error === 1
                                        ? "error-ad " + ad.id
                                        :
                                        ad.discarded
                                          ? "discard-ad " + ad.id
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.city ? ad.city : "-"}
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            <tr>
                              <th className="th-table">
                                {t("ga.comparator.type")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  return (
                                    <td
                                      key={ad.id + " roomTypeKey"}
                                      id={ad.id + " roomType"}
                                      className={
                                        ad.error === 1
                                        ? "error-ad " + ad.id
                                        :
                                        ad.discarded
                                          ? "discard-ad " + ad.id
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.roomType ? ad.roomType : "-"}
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            <tr>
                              <th className="th-table">
                                {t("ga.comparator.capacity")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  return (
                                    <td
                                      key={ad.id + " capacityKey"}
                                      id={ad.id + " capacity"}
                                      className={
                                        ad.error === 1
                                        ? "error-ad " + ad.id
                                        :
                                        ad.discarded
                                          ? "discard-ad " + ad.id
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.capacity !== undefined
                                        ? ad.capacity
                                        : "-"}
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            <tr>
                              <th className="th-table">
                                {t("ga.comparator.price")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  return (
                                    <td
                                      key={ad.id + " priceKey"}
                                      id={ad.id + " price"}
                                      className={
                                        ad.error === 1
                                        ? "error-ad " + ad.id
                                        :
                                        ad.discarded
                                          ? "discard-ad " + ad.id
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.price ? ad.price : 0}
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            <tr>
                              <th className="th-table">
                                {t("ga.comparator.takenDays")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  return (
                                    <td
                                      key={ad.id + " occupancyKey"}
                                      id={ad.id + " occupancy"}
                                      className={
                                        ad.error === 1
                                        ? "error-ad " + ad.id
                                        :
                                        ad.discarded
                                          ? "discard-ad " + ad.id
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.occupancy !== undefined &&
                                      ad.occupancy
                                        .numCurrentYearReservations !==
                                        undefined
                                        ? ad["monthOcc"] +
                                          " / " +
                                          ad.occupancy
                                            .numCurrentYearReservations
                                        : "-"}
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            <tr>
                              <th className="th-table">
                                {t("ga.comparator.minNights")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  return (
                                    <td
                                      key={ad.id + " minNightsKey"}
                                      id={ad.id + " minNights"}
                                      className={
                                        ad.error === 1
                                        ? "error-ad " + ad.id
                                        :
                                        ad.discarded
                                          ? "discard-ad " + ad.id
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.minNights !== undefined
                                        ? ad.minNights
                                        : "-"}
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            <tr>
                              <th className="th-table">
                                {t("ga.comparator.long")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  return (
                                    <td
                                      key={ad.id + " longitudeKey"}
                                      id={ad.id + " longitude"}
                                      className={
                                        ad.error === 1
                                        ? "error-ad " + ad.id 
                                        :
                                        ad.discarded
                                          ? "discard-ad " + ad.id
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.geometry && ad.geometry.coordinates[0]
                                        ? ad.geometry.coordinates[0]
                                        : "-"}
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            <tr>
                              <th className="th-table">
                                {t("ga.comparator.lat")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  return (
                                    <td
                                      key={ad.id + " latitudeKey"}
                                      id={ad.id + " latitude"}
                                      className={
                                        ad.error === 1
                                        ? "error-ad " + ad.id
                                        :
                                        ad.discarded
                                          ? "discard-ad " + ad.id
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.geometry && ad.geometry.coordinates[1]
                                        ? ad.geometry.coordinates[1]
                                        : "-"}
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            <tr>
                              <th className="th-table">
                                {t("ga.comparator.ownerName")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  return (
                                    <td
                                      key={ad.id + " ownerNameKey"}
                                      id={ad.id + " ownerName"}
                                      className={
                                        ad.error === 1
                                        ? "error-ad " + ad.id
                                        :
                                        ad.discarded
                                          ? "discard-ad " + ad.id
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.host && ad.host.nickname
                                        ? ad.host.nickname
                                        : "-"}
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            <tr>
                              <th className="th-table">
                                {t("ga.comparator.numReview")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  return (
                                    <td
                                      key={ad.id + " numReviewsKey"}
                                      id={ad.id + " numReviews"}
                                      className={
                                        ad.error === 1
                                        ? "error-ad " + ad.id
                                        :
                                        ad.discarded
                                          ? "discard-ad " + ad.id
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.numReviews === undefined ||
                                      isNaN(ad.numReviews) ||
                                      ad.numReviews === null
                                        ? 0
                                        : ad.numReviews}
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            <tr>
                              <th className="th-table">
                                {t("ga.comparator.rate")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  return (
                                    <td
                                      key={ad.id + " rateKey"}
                                      id={ad.id + " rate"}
                                      className={
                                        ad.error === 1
                                        ? "error-ad " + ad.id
                                        :
                                        ad.discarded
                                          ? "discard-ad " + ad.id
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.rate === undefined ||
                                      isNaN(ad.rate) ||
                                      ad.rate === null
                                        ? 0
                                        : ad.rate}
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            <tr>
                              <th className="th-table">
                                {t("ga.comparator.address")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  return (
                                    <td
                                      key={ad.id + " addressKey"}
                                      id={ad.id + " address"}
                                      className={
                                        ad.error === 1
                                        ? "error-ad " + ad.id
                                        :
                                        ad.discarded
                                          ? "discard-ad " + ad.id
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.address ? ad.address : "-"}
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            <tr>
                              <th className="th-table">
                                {t("ga.comparator.associate")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  return (
                                    <td
                                      key={ad.id + " associateKey"}
                                      id={ad.id + " associate"}
                                      className={
                                        ad.error === 1
                                        ? "error-ad " + ad.id
                                        :
                                        ad.discarded
                                          ? "discard-ad " + ad.id
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.adGroupCode ? ad.adGroupCode : "-"}
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            
                          </tbody>
                        </table>
                      </div>
                      <hr />
                    </div>
                  </div>
                  <div
                    className="tab-pane fade "
                    id="compliance"
                    role="tabpanel"
                    style={{ width: "100%" }}
                    aria-labelledby="compliance-tab"
                  >
                    <div id="compliance-content" className="col-md-12">
                      <div className="compliance-tab-control-btns control-btns">
                        <button
                          type="button"
                          className="carrousel-btn-prev"
                          onClick={() => {
                            this.slideCarousel(0);
                          }}
                          data-slide="prev"
                        >
                          <svg
                            className="icon-arrow-compliance-ga"
                            data-name="fas fa-angle-left"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 26.2 40.11"
                          >
                            <path
                              d="M52.15,28.68a2.08,2.08,0,0,0,0-3l-4-4s-1-1-3,0L27.08,39.51s-1,1,0,3L44.94,60.63a2.08,2.08,0,0,0,3,0l4-4a2.08,2.08,0,0,0,0-3L40.06,41.6Z"
                              transform="translate(-26.62 -21.2)"
                            />
                          </svg>
                        </button>
                        <button
                          type="button"
                          className="carrousel-btn-next"
                          onClick={() => {
                            this.slideCarousel(1);
                          }}
                          data-slide="next"
                        >
                          <svg
                            className="icon-arrow-compliance-ga"
                            data-name="fas fa-angle-right"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 26.12 40.11"
                          >
                            <path
                              d="M27.22,53.48a2.08,2.08,0,0,0,0,3l4,4s1,1,3,0l18-18s1-1,0-3l-18-18a2.08,2.08,0,0,0-3,0l-4,4a2.08,2.08,0,0,0,0,3l12,12Z"
                              transform="translate(-26.54 -20.81)"
                            />
                          </svg>
                        </button>
                      </div>
                      <div
                        id="compliance-table"
                        className="row compliance-table"
                      >
                        <table className="table table-hover table-borderless">
                          <tbody>
                          <tr>
                              <th className="th-table">
                                {t("ga.comparator.ad")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  return (
                                    <td
                                      key={ad.id + " adIDKey"}
                                      id={ad.id + " adID"}
                                      className={
                                        ad.error === 1
                                           ? "error-ad " + ad.id
                                           : 
                                        ad.discarded
                                          ? "discard-ad " + ad.id 
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.id === undefined ? "-" : ad.id}
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            <tr>
                              <th className="th-table">
                                {t("compliance.status")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  //document.getElementById("adID"+ad.id).childNodes[0].classList.contains("discard-card-ad")) ? "discard-ad "+ad.id

                                  return (
                                    <td
                                      key={ad.id + " licenseKey"}
                                      id={ad.id + " license"}
                                      className={
                                        ad.error === 1
                                         ? "error-ad " + ad.id
                                         :
                                        ad.discarded
                                          ? "discard-ad " + ad.id
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      <div className="compliance-array">

                                      {ad.license
                                        ? ad.license.compliance_status === "exc" || ad.license.compliance_status === "Correcto" ? <div><svg><circle r="30%" cx="50%" cy="43%" fill="#00FF00" stroke="#0d6d0d" strokeWidth="1.5"/></svg> {t("compliance.exc") }</div>:
                                        ad.license.compliance_status === "hig" || ad.license.compliance_status === "Leve" ? <div><svg><circle r="30%" cx="50%" cy="43%" fill="#FFFF00" stroke="#6d6d0d" strokeWidth="1.5" /></svg> {t("compliance.hig")} </div>: 
                                        ad.license.compliance_status === "mid" ? <div><svg><circle r="30%" cx="50%" cy="43%" fill="#FFA500" stroke="#916619" strokeWidth="1.5"/></svg> {t("compliance.mid")}</div>: 
                                        ad.license.compliance_status === "low" || ad.license.compliance_status === "Posible Fraude" ? <div><svg><circle r="30%" cx="50%" cy="43%" fill="#FF0000" stroke="#6d0d0d" strokeWidth="1.5" /></svg> {t("compliance.low")}</div> : 
                                        ad.license.compliance_status === "def" || ad.license.compliance_status === "Fraude" ? <div><svg><circle r="30%" cx="50%" cy="43%" fill="#900C3F" stroke="#3d0c0c" strokeWidth="1.5"/></svg> {t("compliance.def")} </div> : "-"
                                        : "-"}
                                        </div>
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            <tr>
                              <th className="th-table">
                                {t("compliance.show_text")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                ){
                                  //document.getElementById("adID"+ad.id).childNodes[0].classList.contains("discard-card-ad")) ? "discard-ad "+ad.id
                                  return (
                                    <td
                                      key={ad.id + " licenseKey"}
                                      id={ad.id + " license"}
                                      className={
                                        ad.error === 1
                                        ? "error-ad " +ad.id
                                        :
                                        ad.discarded
                                          ? "discard-ad " + ad.id
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.compliant_rules
                                        ? ad.compliant_rules.show_text
                                          ? "True"
                                          : "False"
                                        : "-"}
                                    </td>
                                  );
                                }else return null;
                              })}
                            </tr>
                            <tr>
                              <th className="th-table">
                                {t("compliance.registry_match")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  return (
                                    <td
                                      key={ad.id + " titleKey"}
                                      id={ad.id + " title"}
                                      className={
                                        ad.error === 1
                                        ? "error-ad " +ad.id 
                                        :
                                        ad.discarded
                                          ? "discard-ad " + ad.id
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.compliant_rules
                                        ? ad.compliant_rules.registry_match
                                          ? "True"
                                          : "False"
                                        : "-"}
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            <tr>
                              <th className="th-table">
                                {t("compliance.radi_municipality")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  return (
                                    <td
                                      key={ad.id + " ownerIDKey"}
                                      id={ad.id + " ownerID"}
                                      className={
                                        ad.error === 1 
                                        ? "error-ad " + ad.id
                                        :
                                        ad.discarded
                                          ? "discard-ad " + ad.id
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.compliant_rules
                                        ? ad.compliant_rules.radi_municipality
                                          ? "True"
                                          : "False"
                                        : "-"}
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            <tr>
                              <th className="th-table">
                                {t("compliance.pattern_match")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  return (
                                    <td
                                      key={ad.id + " city"}
                                      id={ad.id + " city"}
                                      className={
                                        ad.error === 1
                                        ? "error-ad " + ad.id
                                        :
                                        ad.discarded
                                          ? "discard-ad " + ad.id
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.compliant_rules
                                        ? ad.compliant_rules.pattern_match
                                          ? "True"
                                          : "False"
                                        : "-"}
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            <tr>
                              <th className="th-table">
                                {t("compliance.duplicated")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  return (
                                    <td
                                      key={ad.id + " roomTypeKey"}
                                      id={ad.id + " roomType"}
                                      className={
                                        ad.error === 1
                                        ? "error-ad " + ad.id
                                        :
                                        ad.discarded
                                          ? "discard-ad " + ad.id
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.compliant_rules
                                        ? ad.compliant_rules.duplicated
                                          ? "True"
                                          : "False"
                                        : "-"}
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            <tr>
                              <th className="th-table">
                                {t("compliance.commercial_name")}
                              </th>
                              {this.props.groupAdsStore.ads.map((ad, i) => {
                                if (
                                  i >= this.props.groupAdsStore.index &&
                                  i <
                                    this.props.groupAdsStore.index +
                                      Math.floor(this.maxCards)
                                )
                                  return (
                                    <td
                                      key={ad.id + " priceKey"}
                                      id={ad.id + " price"}
                                      className={
                                        ad.error === 1
                                        ? "error-ad " + ad.id
                                        :
                                        ad.discarded
                                          ? "discard-ad " + ad.id
                                          : !ad.adIsSeen
                                          ? "new-ad " + ad.id
                                          : ad.id
                                      }
                                    >
                                      {ad.compliant_rules
                                        ? ad.compliant_rules.commercial_name
                                          ? "True"
                                          : "False"
                                        : "-"}
                                    </td>
                                  );
                                else return null;
                              })}
                            </tr>
                            
                          </tbody>
                        </table>
                      </div>
                      <hr />
                    </div>
                  </div>
                  {/*<div
                    className="tab-pane fade"
                    id="tasks"
                    style={{ width: "100%" }}
                    role="tabpanel"
                    aria-labelledby="tasks-tab"
                  >
                    <div className="row">
                      <div id="task-content" className="col-md-12">
                        <div id="tasks-table" className="row tasks-table">
                          {this.props.groupAdsStore.processes.map((process) => {
                            return (
                              <table
                                key={process._id}
                                className="table table-hover table-borderless"
                              >
                                <thead key={process._id}>
                                  <tr>
                                    <th>{process.title}</th>
                                  </tr>
                                  <tr>
                                    <th> </th>
                                    <th style={{ textAlign: "center" }}>
                                      {t("ga.tasks.taskName")}
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      {t("ga.tasks.lastMod")}
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      {t("ga.tasks.summary")}
                                    </th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {process.task.map((task) => {
                                    return (
                                      <tr key={task.id_task}>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            width: "10%",
                                          }}
                                        >
                                          {" "}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            width: "15%",
                                          }}
                                        >
                                          {task.name}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            width: "25%",
                                          }}
                                        >
                                          {this.props.processStore.convertToFormatTable(
                                            task.lastModified
                                          )}
                                        </td>
                                        {task.report.length >= 30 ? (
                                          <td
                                            style={{
                                              textAlign: "center",
                                              width: "40%",
                                            }}
                                          >
                                            {task.report.substring(0, 30)}...
                                          </td>
                                        ) : (
                                          <td
                                            style={{
                                              textAlign: "center",
                                              width: "40%",
                                            }}
                                          >
                                            {task.report.substring(0, 30)}
                                          </td>
                                        )}
                                        <td
                                          style={{
                                            textAlign: "center",
                                            width: "10%",
                                          }}
                                        >
                                          <MyButton
                                            data-toggle="modal"
                                            name={t("ga.tasks.edit")}
                                            data-target="#editModal"
                                            className="btn btn-outline-info edit-process"
                                            style={
                                              this.props.groupAdsStore
                                                .userIsTheWorkingUser
                                                ? null
                                                : { opacity: "0.5" }
                                            }
                                            disabled={
                                              this.props.groupAdsStore
                                                .userIsTheWorkingUser
                                                ? "false"
                                                : "true"
                                            }
                                            type="button"
                                            text={
                                              <>
                                                <svg
                                                  className="svg-icon-pencil-tasks-ga"
                                                  data-name="fas fa-pencil-alt"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 63.72 63.68"
                                                >
                                                  <path
                                                    className="cls-1"
                                                    d="M12.1,72.21a5.05,5.05,0,0,1-1.69,0,3,3,0,0,1-2.16-1.81c0-.09,0-.21-.15-.27V70a.2.2,0,0,0,0-.07,7.59,7.59,0,0,1,.05-1.26c.25-1.42.49-2.84.73-4.26.05-.25.1-.49.14-.74.19-1.09.37-2.18.55-3.27.27-1.3.49-2.61.69-3.92.14-.75.28-1.49.4-2.24a1.31,1.31,0,0,1,.42-.81c.33-.29.62-.63.93-1a.06.06,0,0,0,0,0l4-4,0,0c1.34-1.3,2.66-2.61,3.95-4s2.65-2.6,3.94-3.94l.05-.05q2-1.94,4-3.95l0-.05,4-3.95.06-.05,3.88-3.88,0,0c1.34-1.3,2.65-2.61,4-4l0-.06,3.22-3.19a3.92,3.92,0,0,1,.78-.63l.15-.06h.8a3,3,0,0,1,1,.67c.66.67,1.34,1.32,2,2l1.29,1.29,2.71,2.71,1.32,1.36c.88.92,1.77,1.82,2.7,2.69l1.17,1.16,2.06,2.08a2.78,2.78,0,0,1,.71,1v.72a.29.29,0,0,0-.07.22,3.71,3.71,0,0,1-.59.72c-1.09,1.1-2.18,2.19-3.26,3.29q-2,1.92-3.93,3.93l-.08.08c-1.34,1.28-2.64,2.59-3.93,3.92l-.08.08q-2,1.92-3.93,3.93t-4,4l-.94.91-3,3-.09.09C34.59,61.72,33.28,63,32,64.36h0q-2,2-4,4l-.08.07-1,1a.76.76,0,0,1-.44.23L24,70.11l-.86.14L20,70.83,16,71.5c-.41.07-.81.13-1.21.21C13.92,71.88,13,72,12.1,72.21ZM16,55.41h-.58c-1,0-1,0-1.13,1-.05.24-.1.48-.14.71-.19,1.08-.37,2.15-.55,3.22a13.45,13.45,0,0,1-.38,1.91A1.65,1.65,0,0,0,14,64.34l0,0c.65.68,1.31,1.34,2,2l.78.8a.31.31,0,0,0,.32.11L20,66.74l.89-.14L24,66l.79-.11c.16,0,.22-.09.22-.26,0-.44,0-.88,0-1.31,0-.67,0-1.35,0-2,0-1,0-1-1-.95H20c-.23,0-.46,0-.69,0s-.29,0-.28-.26a6.45,6.45,0,0,0,0-.76q0-2,0-3.93c0-.27,0-.53,0-.79s-.06-.23-.23-.23ZM27.62,44.35l-1.19,1.16c-1,1-2.06,2-3,3.07a1.74,1.74,0,0,0,2.71,2.18L45.05,31.84a2.05,2.05,0,0,0,.39-.49,1.66,1.66,0,0,0,0-1.73,1.62,1.62,0,0,0-1.47-.84,1.76,1.76,0,0,0-1.37.61L29.88,42.06l-2,2A.83.83,0,0,0,27.62,44.35Z"
                                                    transform="translate(-8.08 -8.62)"
                                                  />
                                                  <path
                                                    className="cls-1"
                                                    d="M55.88,9.13l1.48-.45a7,7,0,0,1,2.49.13,6.48,6.48,0,0,1,2.85,1.66l1.11,1.09,1.07,1.07c1,1,1.94,2,3,2.94l1,1c.18.19.36.39.55.57a7.28,7.28,0,0,1,2.28,3.43l.14.58a12.29,12.29,0,0,1,0,1.82,12.65,12.65,0,0,1-.48,1.59A7.07,7.07,0,0,1,70,26.36l-2.1,2.11-.07.07c-1.12,1.11-2.25,2.21-3.36,3.33a1.65,1.65,0,0,1-1.74.53,4.19,4.19,0,0,1-.84-.67L60,29.85l-1.31-1.31-2.79-2.78-1.21-1.22q-1.36-1.44-2.79-2.79l-1.22-1.21-.45-.48A20.09,20.09,0,0,1,48,17.63a2.06,2.06,0,0,1,.82-1.95c1-1,2-2,3-3.06l.06-.06C52.42,12,53,11.5,53.51,11A8.28,8.28,0,0,1,55.88,9.13Z"
                                                    transform="translate(-8.08 -8.62)"
                                                  />
                                                </svg>
                                              </>
                                            }
                                            onClick={(e) => {
                                              this.props.processStore.openTaskReport(
                                                e,
                                                process.title,
                                                process._id,
                                                process.task,
                                                task,
                                                process.generalReport,
                                                process.finished,
                                                this.props.groupAdsStore
                                                  .adGroupForm.id
                                              );
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    );
                                  })}
                                  <tr>
                                    <td className="report-btns" colSpan="5">
                                      <div id="report-btns">
                                        <MyButton
                                          className="btn-outline-info "
                                          data-toggle="modal"
                                          data-target="#editModal"
                                          style={
                                            this.props.groupAdsStore
                                              .userIsTheWorkingUser
                                              ? null
                                              : { opacity: "0.5" }
                                          }
                                          disabled={
                                            this.props.groupAdsStore
                                              .userIsTheWorkingUser
                                              ? "false"
                                              : "true"
                                          }
                                          type="button"
                                          text={t("ga.tasks.report")}
                                          //className="btn btn-outline-info mx-auto"
                                          onClick={(e) => {
                                            this.props.processStore.openGenReport(
                                              e,
                                              process.title,
                                              process._id,
                                              process.task,
                                              process.generalReport,
                                              process.finished,
                                              this.props.groupAdsStore
                                                .adGroupForm.id
                                            );
                                          }}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  {<MyButton
                                      //className="btn btn-outline-info mx-auto active"
                                      className="btn-outline-info  active"
                                      data-toggle="modal"
                                      data-target="#finishReportModal"
                                      type="button"
                                      style = {this.props.groupAdsStore.userIsTheWorkingUser ? null : {opacity: "0.5"}}
                                      disabled = {this.props.groupAdsStore.userIsTheWorkingUser ? "false": "true"}
                                      text="Finish Process"
                                      onClick={e => {
                                        this.props.processStore.endProcess(e, process,this.props.groupAdsStore.adGroupForm.id);
                                      }}
                                    />}
                                </tbody>
                              </table>
                            );
                          })}
                          <MyModal />
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>*/}

                  <div
                    className="tab-pane fade"
                    id="status"
                    style={{ width: "100%" }}
                    role="tabpanel"
                    aria-labelledby="status-tab"
                  >
                    <div className="row">
                      <div id="status-content" className="col-md-12">
                        <div id="status-table" className="row status-table">
                          {/* <div style = {{position: "absolute", right:"0"}}>
                          <MyButton style = {{right:"60%"}} className="btn btn-info add-process-btn" type="button" text={"Añadir Estado"} onClick={e => {this.props.groupAdsStore.addStatus(e)}}/>
                          </div> */}
                          <table
                            key="Status_Table"
                            className="table table-hover table-borderless"
                          >
                            <thead key="Status_Head">
                              {/*<tr>
                                      <th style={{ textAlign: "right", right:"60%"}}>                                        
                                        <MyButton style = {{right:"60%"}} className="btn btn-info add-process-btn" type="button" text={"Añadir Estado"} onClick={e => {console.log("HELLO")}} />
                                      </th>
                                  </tr>*/}
                              <tr>
                                <th style={{ textAlign: "center" }}>
                                  {t("ga.status.status")}
                                </th>
                                <th style={{ textAlign: "center" }}>
                                  {t("ga.status.situation")}
                                </th>
                                {/*<th style={{ textAlign: "center" }}>
                                      {t("ga.tasks.lastMod")}
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      Usuario encargado
                                    </th>*/}
                                <th style={{ textAlign: "center" }}>
                                  {t("ga.status.remark")}
                                  <br></br>
                                  <p style={{ fontSize: "0.6rem" }}>
                                    {t("ga.status.lastEdition") + ": "}{" "}
                                    {this.props.groupAdsStore.statesGroup
                                      .message &&
                                    this.props.groupAdsStore.statesGroup.message
                                      .date
                                      ? this.props.groupAdsStore.convertToFormatTable(
                                          this.props.groupAdsStore.statesGroup
                                            .message.date
                                        )
                                      : "-"}
                                    {", " + t("ga.status.user") + ": "}{" "}
                                    {this.props.groupAdsStore.statesGroup
                                      .message &&
                                    this.props.groupAdsStore.statesGroup.message
                                      .username
                                      ? this.props.groupAdsStore.statesGroup
                                          .message.username
                                      : "-"}
                                  </p>
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr key="State_Key">
                                <td
                                  style={{
                                    textAlign: "left",
                                    width: "20%",
                                  }}
                                >
                                  {this.props.groupAdsStore.states.map(
                                    (state, i) => {
                                      return (
                                        <div key={"key_" + state.name}>
                                          <MyRadioButton
                                            id={"id_" + state.name}
                                            name={"name_" + state.name}
                                            value={"value_" + state.name}
                                            //labelValue={state.name}
                                            labelValue={
                                              this.props.userStore.getUser() &&
                                              this.props.userStore.getUser()
                                                .group
                                                ? t(
                                                    "ga.status.statusOptions." +
                                                      this.props.userStore.getUser()
                                                        .group +
                                                      "." +
                                                      state.name +
                                                      "." +
                                                      state.name +
                                                      "Write"
                                                  )
                                                : ""
                                            }
                                            style={
                                              this.props.groupAdsStore
                                                .userIsTheWorkingUser
                                                ? null
                                                : { opacity: "0.9" }
                                            }
                                            disabled={
                                              this.props.groupAdsStore
                                                .userIsTheWorkingUser
                                                ? false
                                                : true
                                            }
                                            onChange={(e) => {
                                              this.props.groupAdsStore.radioChanged(
                                                i
                                              );
                                            }}
                                          ></MyRadioButton>
                                          <br />
                                        </div>
                                      );
                                    }
                                  )}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    width: "20%",
                                  }}
                                >
                                  <select
                                    defaultValue="choose"
                                    className="custom-select"
                                    id="inputGroupSelect01"
                                    onChange={(e) => {
                                      this.props.groupAdsStore.selectChanged(
                                        e.target.value
                                      );
                                    }}
                                  >
                                    <option value="choose" disabled={true}>
                                      {t("profile.choose")}
                                    </option>

                                    {this.props.groupAdsStore.indexSituation >=
                                    0
                                      ? this.props.groupAdsStore.states[
                                          this.props.groupAdsStore
                                            .indexSituation
                                        ].values.map((value, i) => {
                                          return (
                                            <option
                                              key={"key_" + value}
                                              value={value}
                                              disabled={
                                                this.props.groupAdsStore
                                                  .userIsTheWorkingUser
                                                  ? false
                                                  : true
                                              }
                                            >
                                              {/* {value} */}
                                              {this.props.userStore.getUser() &&
                                              this.props.userStore.getUser()
                                                .group
                                                ? t(
                                                    "ga.status.statusOptions." +
                                                      this.props.userStore.getUser()
                                                        .group +
                                                      "." +
                                                      this.props.groupAdsStore
                                                        .states[
                                                        this.props.groupAdsStore
                                                          .indexSituation
                                                      ].name +
                                                      "." +
                                                      value
                                                  )
                                                : ""}
                                            </option>
                                          );
                                        })
                                      : null}
                                  </select>
                                </td>
                                {/*<td
                                          style={{
                                            textAlign: "center",
                                            width: "25%",
                                          }}
                                        >
                                          {this.props.groupAdsStore.convertToFormatTable(
                                            this.props.groupAdsStore.lastModification.date
                                          )}
                                        </td>
                                        
                                        <td
                                            style={{
                                              textAlign: "center",
                                              width: "10%",
                                            }}
                                          >
                                            {this.props.groupAdsStore.lastModification.username}

                                          </td>*/}

                                <td
                                  style={{
                                    textAlign: "left",
                                    width: "50%",
                                  }}
                                >
                                  {this.props.groupAdsStore.statesGroup.message
                                    ? this.props.groupAdsStore.statesGroup
                                        .message.notes
                                    : ""}
                                </td>

                                <td
                                  style={{
                                    textAlign: "center",
                                    width: "10%",
                                  }}
                                >
                                  <MyButton
                                    data-toggle="modal"
                                    name={t("ga.status.editRemark")}
                                    data-target="#editModal"
                                    className="btn btn-outline-info edit-process"
                                    style={
                                      this.props.groupAdsStore
                                        .userIsTheWorkingUser
                                        ? null
                                        : { opacity: "0.5" }
                                    }
                                    disabled={
                                      this.props.groupAdsStore
                                        .userIsTheWorkingUser
                                        ? "false"
                                        : "true"
                                    }
                                    type="button"
                                    text={
                                      <>
                                        <svg
                                          className="svg-icon-pencil-tasks-ga"
                                          data-name="fas fa-pencil-alt"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 63.72 63.68"
                                        >
                                          <path
                                            className="cls-1"
                                            d="M12.1,72.21a5.05,5.05,0,0,1-1.69,0,3,3,0,0,1-2.16-1.81c0-.09,0-.21-.15-.27V70a.2.2,0,0,0,0-.07,7.59,7.59,0,0,1,.05-1.26c.25-1.42.49-2.84.73-4.26.05-.25.1-.49.14-.74.19-1.09.37-2.18.55-3.27.27-1.3.49-2.61.69-3.92.14-.75.28-1.49.4-2.24a1.31,1.31,0,0,1,.42-.81c.33-.29.62-.63.93-1a.06.06,0,0,0,0,0l4-4,0,0c1.34-1.3,2.66-2.61,3.95-4s2.65-2.6,3.94-3.94l.05-.05q2-1.94,4-3.95l0-.05,4-3.95.06-.05,3.88-3.88,0,0c1.34-1.3,2.65-2.61,4-4l0-.06,3.22-3.19a3.92,3.92,0,0,1,.78-.63l.15-.06h.8a3,3,0,0,1,1,.67c.66.67,1.34,1.32,2,2l1.29,1.29,2.71,2.71,1.32,1.36c.88.92,1.77,1.82,2.7,2.69l1.17,1.16,2.06,2.08a2.78,2.78,0,0,1,.71,1v.72a.29.29,0,0,0-.07.22,3.71,3.71,0,0,1-.59.72c-1.09,1.1-2.18,2.19-3.26,3.29q-2,1.92-3.93,3.93l-.08.08c-1.34,1.28-2.64,2.59-3.93,3.92l-.08.08q-2,1.92-3.93,3.93t-4,4l-.94.91-3,3-.09.09C34.59,61.72,33.28,63,32,64.36h0q-2,2-4,4l-.08.07-1,1a.76.76,0,0,1-.44.23L24,70.11l-.86.14L20,70.83,16,71.5c-.41.07-.81.13-1.21.21C13.92,71.88,13,72,12.1,72.21ZM16,55.41h-.58c-1,0-1,0-1.13,1-.05.24-.1.48-.14.71-.19,1.08-.37,2.15-.55,3.22a13.45,13.45,0,0,1-.38,1.91A1.65,1.65,0,0,0,14,64.34l0,0c.65.68,1.31,1.34,2,2l.78.8a.31.31,0,0,0,.32.11L20,66.74l.89-.14L24,66l.79-.11c.16,0,.22-.09.22-.26,0-.44,0-.88,0-1.31,0-.67,0-1.35,0-2,0-1,0-1-1-.95H20c-.23,0-.46,0-.69,0s-.29,0-.28-.26a6.45,6.45,0,0,0,0-.76q0-2,0-3.93c0-.27,0-.53,0-.79s-.06-.23-.23-.23ZM27.62,44.35l-1.19,1.16c-1,1-2.06,2-3,3.07a1.74,1.74,0,0,0,2.71,2.18L45.05,31.84a2.05,2.05,0,0,0,.39-.49,1.66,1.66,0,0,0,0-1.73,1.62,1.62,0,0,0-1.47-.84,1.76,1.76,0,0,0-1.37.61L29.88,42.06l-2,2A.83.83,0,0,0,27.62,44.35Z"
                                            transform="translate(-8.08 -8.62)"
                                          />
                                          <path
                                            className="cls-1"
                                            d="M55.88,9.13l1.48-.45a7,7,0,0,1,2.49.13,6.48,6.48,0,0,1,2.85,1.66l1.11,1.09,1.07,1.07c1,1,1.94,2,3,2.94l1,1c.18.19.36.39.55.57a7.28,7.28,0,0,1,2.28,3.43l.14.58a12.29,12.29,0,0,1,0,1.82,12.65,12.65,0,0,1-.48,1.59A7.07,7.07,0,0,1,70,26.36l-2.1,2.11-.07.07c-1.12,1.11-2.25,2.21-3.36,3.33a1.65,1.65,0,0,1-1.74.53,4.19,4.19,0,0,1-.84-.67L60,29.85l-1.31-1.31-2.79-2.78-1.21-1.22q-1.36-1.44-2.79-2.79l-1.22-1.21-.45-.48A20.09,20.09,0,0,1,48,17.63a2.06,2.06,0,0,1,.82-1.95c1-1,2-2,3-3.06l.06-.06C52.42,12,53,11.5,53.51,11A8.28,8.28,0,0,1,55.88,9.13Z"
                                            transform="translate(-8.08 -8.62)"
                                          />
                                        </svg>
                                      </>
                                    }
                                    onClick={(e) => {
                                      this.props.groupAdsStore.openGenReport(e);
                                    }}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <MyModal />
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="history"
                    role="tabpanel"
                    style={{ width: "100%" }}
                    aria-labelledby="history-tab"
                  >
                    <div className="row">
                      <div id="history-content" className="col-md-12">
                        <div id="history-table" className="row history-table">
                          <table className="table table-hover table-borderless">
                            <thead>
                              <tr>
                                <th> </th>
                                <th className="last-date">
                                  {t("ga.history.last")}
                                  <br />
                                 {this.error === 1
                                        ? "error-ad " + this.id
                                        :
                                  this.props.groupAdsStore.ads[
                                    this.props.groupAdsStore.selectedAdIndex
                                  ]
                                    ? this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore.selectedAdIndex
                                      ]["full"]
                                    : null}{" "}
                                </th>
                                <th>
                                  {t("ga.history.previous")}
                                  <br />
                                  {this.props.groupAdsStore.ads[
                                    this.props.groupAdsStore.selectedAdIndex
                                  ]
                                    ? this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore.selectedAdIndex
                                      ].history.length >= 1
                                      ? this.props.groupAdsStore.ads[
                                          this.props.groupAdsStore
                                            .selectedAdIndex
                                        ].history[0]["full"]
                                      : null
                                    : null}
                                </th>
                                <th>
                                  {t("ga.history.previous")}
                                  <br />
                                  {this.props.groupAdsStore.ads[
                                    this.props.groupAdsStore.selectedAdIndex
                                  ]
                                    ? this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore.selectedAdIndex
                                      ].history.length >= 2
                                      ? this.props.groupAdsStore.ads[
                                          this.props.groupAdsStore
                                            .selectedAdIndex
                                        ].history[1]["full"]
                                      : null
                                    : null}{" "}
                                </th>
                                <th>
                                  {t("ga.history.previous")}
                                  <br />
                                  {this.props.groupAdsStore.ads[
                                    this.props.groupAdsStore.selectedAdIndex
                                  ]
                                    ? this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore.selectedAdIndex
                                      ].history.length >= 3
                                      ? this.props.groupAdsStore.ads[
                                          this.props.groupAdsStore
                                            .selectedAdIndex
                                        ].history[2]["full"]
                                      : null
                                    : null}{" "}
                                </th>
                                <th>
                                  {t("ga.history.previous")}
                                  <br />
                                  {this.props.groupAdsStore.ads[
                                    this.props.groupAdsStore.selectedAdIndex
                                  ]
                                    ? this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore.selectedAdIndex
                                      ].history.length >= 4
                                      ? this.props.groupAdsStore.ads[
                                          this.props.groupAdsStore
                                            .selectedAdIndex
                                        ].history[3]["full"]
                                      : null
                                    : null}{" "}
                                </th>
                                <th>
                                  {t("ga.history.previous")}
                                  <br />
                                  {this.props.groupAdsStore.ads[
                                    this.props.groupAdsStore.selectedAdIndex
                                  ]
                                    ? this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore.selectedAdIndex
                                      ].history.length >= 5
                                      ? this.props.groupAdsStore.ads[
                                          this.props.groupAdsStore
                                            .selectedAdIndex
                                        ].history[4]["full"]
                                      : null
                                    : null}{" "}
                                </th>
                                <th>
                                  {t("ga.history.previous")}
                                  <br />
                                  {this.props.groupAdsStore.ads[
                                    this.props.groupAdsStore.selectedAdIndex
                                  ]
                                    ? this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore.selectedAdIndex
                                      ].history.length >= 6
                                      ? this.props.groupAdsStore.ads[
                                          this.props.groupAdsStore
                                            .selectedAdIndex
                                        ].history[5]["full"]
                                      : null
                                    : null}{" "}
                                </th>
                                <th>
                                  {t("ga.history.previous")}
                                  <br />
                                  {this.props.groupAdsStore.ads[
                                    this.props.groupAdsStore.selectedAdIndex
                                  ]
                                    ? this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore.selectedAdIndex
                                      ].history.length >= 7
                                      ? this.props.groupAdsStore.ads[
                                          this.props.groupAdsStore
                                            .selectedAdIndex
                                        ].history[6]["full"]
                                      : null
                                    : null}{" "}
                                </th>
                                <th>
                                  {t("ga.history.previous")}
                                  <br />
                                  {this.props.groupAdsStore.ads[
                                    this.props.groupAdsStore.selectedAdIndex
                                  ]
                                    ? this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore.selectedAdIndex
                                      ].history.length >= 8
                                      ? this.props.groupAdsStore.ads[
                                          this.props.groupAdsStore
                                            .selectedAdIndex
                                        ].history[7]["full"]
                                      : null
                                    : null}{" "}
                                </th>
                                <th>
                                  {t("ga.history.previous")}
                                  <br />
                                  {this.props.groupAdsStore.ads[
                                    this.props.groupAdsStore.selectedAdIndex
                                  ]
                                    ? this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore.selectedAdIndex
                                      ].history.length >= 9
                                      ? this.props.groupAdsStore.ads[
                                          this.props.groupAdsStore
                                            .selectedAdIndex
                                        ].history[8]["full"]
                                      : null
                                    : null}{" "}
                                </th>
                                <th>
                                  {t("ga.history.previous")}
                                  <br />
                                  {this.props.groupAdsStore.ads[
                                    this.props.groupAdsStore.selectedAdIndex
                                  ]
                                    ? this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore.selectedAdIndex
                                      ].history.length >= 10
                                      ? this.props.groupAdsStore.ads[
                                          this.props.groupAdsStore
                                            .selectedAdIndex
                                        ].history[9]["full"]
                                      : null
                                    : null}{" "}
                                </th>
                                <th>
                                  {t("ga.history.previous")}
                                  <br />
                                  {this.props.groupAdsStore.ads[
                                    this.props.groupAdsStore.selectedAdIndex
                                  ]
                                    ? this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore.selectedAdIndex
                                      ].history.length >= 11
                                      ? this.props.groupAdsStore.ads[
                                          this.props.groupAdsStore
                                            .selectedAdIndex
                                        ].history[10]["full"]
                                      : null
                                    : null}{" "}
                                </th>
                                <th>
                                  {t("ga.history.previous")}
                                  <br />
                                  {this.props.groupAdsStore.ads[
                                    this.props.groupAdsStore.selectedAdIndex
                                  ]
                                    ? this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore.selectedAdIndex
                                      ].history.length >= 12
                                      ? this.props.groupAdsStore.ads[
                                          this.props.groupAdsStore
                                            .selectedAdIndex
                                        ].history[11]["full"]
                                      : null
                                    : null}{" "}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                                {this.props.userStore.user && this.props.userStore.user.productsExtra && this.props.userStore.user.productsExtra.compliance ?
                              <tr>
                                
                              <td className="th-table">
                                {t("compliance.status")}
                              </td>
                              <td
                                className="last-date"
                                id="last-date-complianceStatus"
                              >
                                {this.props.groupAdsStore.hasCorrectIndexVal
                                  ? this.props.groupAdsStore.hasAdAndProperty(
                                      "license"
                                    )
                                    ? this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore
                                          .selectedAdIndex
                                      ].license.compliance_status === "exc" || this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore
                                          .selectedAdIndex
                                      ].license.compliance_status === "Correcto" ? <div className="compliance-array"><svg><circle r="30%" cx="50%" cy="43%" fill="#00FF00" stroke="#0d6d0d" strokeWidth="1.5"/></svg> {t("compliance.exc") }</div>:
                                    this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore
                                          .selectedAdIndex
                                      ].license.compliance_status === "hig" || this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore
                                          .selectedAdIndex
                                      ].license.compliance_status === "Leve" ? <div className="compliance-array"><svg><circle r="30%" cx="50%" cy="43%" fill="#FFFF00" stroke="#6d6d0d" strokeWidth="1.5" /></svg> {t("compliance.hig")} </div>: 
                                    this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore
                                          .selectedAdIndex
                                      ].license.compliance_status === "mid" ? <div className="compliance-array"><svg><circle r="30%" cx="50%" cy="43%" fill="#FFA500" stroke="#916619" strokeWidth="1.5"/></svg> {t("compliance.mid")}</div>: 
                                    this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore
                                          .selectedAdIndex
                                      ].license.compliance_status === "low" || this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore
                                          .selectedAdIndex
                                      ].license.compliance_status === "Posible Fraude" ? <div className="compliance-array"><svg><circle r="30%" cx="50%" cy="43%" fill="#FF0000" stroke="#6d0d0d" strokeWidth="1.5" /></svg> {t("compliance.low")}</div> : 
                                    this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore
                                          .selectedAdIndex
                                      ].license.compliance_status === "def" || this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore
                                          .selectedAdIndex
                                      ].license.compliance_status === "Fraude" ? <div className="compliance-array"><svg><circle r="30%" cx="50%" cy="43%" fill="#900C3F" stroke="#3d0c0c" strokeWidth="1.5"/></svg> {t("compliance.def")} </div> : "-"
                                    
                                    
                                    : "-"
                                  : null}

                                  
                              </td>
                              {this.props.groupAdsStore.hasCorrectIndexVal
                                ? this.props.groupAdsStore.hasAdAndHistory
                                  ? this.props.groupAdsStore.ads[
                                      this.props.groupAdsStore.selectedAdIndex
                                    ].history.map((oldAd, i) => {
                                      return (
                                        <td key={oldAd.id + " license" + i}>
                                          {oldAd.license
                                            ? oldAd.license.compliance_status === "exc" || oldAd.license.compliance_status === "Correcto" ? <div className="compliance-array"><svg><circle r="30%" cx="50%" cy="43%" fill="#00FF00" stroke="#0d6d0d" strokeWidth="1.5"/></svg> {t("compliance.exc") }</div>:
                                            oldAd.license.compliance_status === "hig" || oldAd.license.compliance_status === "Leve" ? <div className="compliance-array"><svg><circle r="30%" cx="50%" cy="43%" fill="#FFFF00" stroke="#6d6d0d" strokeWidth="1.5" /></svg> {t("compliance.hig")} </div>: 
                                            oldAd.license.compliance_status === "mid" ? <div className="compliance-array"><svg><circle r="30%" cx="50%" cy="43%" fill="#FFA500" stroke="#916619" strokeWidth="1.5"/></svg> {t("compliance.mid")}</div>: 
                                            oldAd.license.compliance_status === "low" || oldAd.license.compliance_status === "Posible Fraude" ? <div className="compliance-array"><svg><circle r="30%" cx="50%" cy="43%" fill="#FF0000" stroke="#6d0d0d" strokeWidth="1.5" /></svg> {t("compliance.low")}</div> : 
                                            oldAd.license.compliance_status === "def" || oldAd.license.compliance_status === "Fraude" ? <div className="compliance-array"><svg><circle r="30%" cx="50%" cy="43%" fill="#900C3F" stroke="#3d0c0c" strokeWidth="1.5"/></svg> {t("compliance.def")} </div> : "-"
                                            
                                            : "-"}
                                        </td>
                                      );
                                    })
                                  : null
                                : null}
                            </tr>
                              :<tr>
                                
                                <td className="th-table">
                                  {t("ga.history.licenseStatus")}
                                </td>
                                <td
                                  className="last-date"
                                  id="last-date-licenseStatus"
                                >
                                  {this.props.groupAdsStore.hasCorrectIndexVal
                                    ? this.props.groupAdsStore.hasAdAndProperty(
                                        "license"
                                      )
                                      ? this.props.groupAdsStore.ads[
                                          this.props.groupAdsStore
                                            .selectedAdIndex
                                        ].license.status === "noff"
                                        ? t("ga.noOfficial")
                                        : this.props.groupAdsStore.ads[
                                            this.props.groupAdsStore
                                              .selectedAdIndex
                                          ].license.status === "offi"
                                        ? t("ga.official")
                                        : this.props.groupAdsStore.ads[
                                            this.props.groupAdsStore
                                              .selectedAdIndex
                                          ].license.status === "bfor"
                                        ? t("ga.badFormat")
                                        : this.props.groupAdsStore.ads[
                                            this.props.groupAdsStore
                                              .selectedAdIndex
                                          ].license.status === "noli"
                                        ? t("ga.noLicense")
                                        : "-"
                                      : "-"
                                    : null}
                                </td>
                                {this.props.groupAdsStore.hasCorrectIndexVal
                                  ? this.props.groupAdsStore.hasAdAndHistory
                                    ? this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore.selectedAdIndex
                                      ].history.map((oldAd, i) => {
                                        return (
                                          <td key={oldAd.id + " license" + i}>
                                            {oldAd.license
                                              ? oldAd.license.status === "noff"
                                                ? t("ga.noOfficial")
                                                : oldAd.license.status ===
                                                  "offi"
                                                ? t("ga.official")
                                                : oldAd.license.status ===
                                                  "bfor"
                                                ? t("ga.badFormat")
                                                : oldAd.license.status ===
                                                  "noli"
                                                ? t("ga.noLicense")
                                                : "-"
                                              : "-"}
                                          </td>
                                        );
                                      })
                                    : null
                                  : null}
                              </tr>}
                              <tr>
                                <td className="th-table">
                                  {t("ga.history.licenseCode")}
                                </td>
                                <td
                                  className="last-date"
                                  id="last-date-licenseCode"
                                >
                                  {this.props.groupAdsStore.hasCorrectIndexVal
                                    ? this.props.groupAdsStore.hasAdAndProperty(
                                        "license"
                                      )
                                      ? this.props.groupAdsStore.hasAdAndNestedProperty(
                                          "license",
                                          "webCode"
                                        )
                                        ? this.props.groupAdsStore.ads[
                                            this.props.groupAdsStore
                                              .selectedAdIndex
                                          ].license.webCode
                                        : "-"
                                      : "-"
                                    : null}
                                </td>
                                {this.props.groupAdsStore.hasCorrectIndexVal &&
                                this.props.groupAdsStore.hasAdAndHistory
                                  ? this.props.groupAdsStore.ads[
                                      this.props.groupAdsStore.selectedAdIndex
                                    ].history.map((oldAd, i) => {
                                      return (
                                        <td key={oldAd.id + " license" + i}>
                                          {oldAd.license
                                            ? oldAd.license.webCode
                                              ? oldAd.license.webCode
                                              : "-"
                                            : "-"}
                                        </td>
                                      );
                                    })
                                  : null}
                              </tr>
                              <tr>
                                <td className="th-table">
                                  {t("ga.history.ad")}
                                </td>
                                <td className="last-date" id="last-date-adId">
                                  {this.props.groupAdsStore.hasCorrectIndexVal
                                    ? this.props.groupAdsStore.hasAdAndProperty(
                                        "id"
                                      )
                                      ? this.props.groupAdsStore.ads[
                                          this.props.groupAdsStore
                                            .selectedAdIndex
                                        ].id === undefined
                                        ? "-"
                                        : this.props.groupAdsStore.ads[
                                            this.props.groupAdsStore
                                              .selectedAdIndex
                                          ].id
                                      : null
                                    : null}
                                </td>
                                {this.props.groupAdsStore.hasCorrectIndexVal &&
                                this.props.groupAdsStore.hasAdAndHistory
                                  ? this.props.groupAdsStore.ads[
                                      this.props.groupAdsStore.selectedAdIndex
                                    ].history.map((oldAd, i) => {
                                      return (
                                        <td
                                          key={
                                            this.props.groupAdsStore.ads[
                                              this.props.groupAdsStore
                                                .selectedAdIndex
                                            ].id +
                                            " AdID" +
                                            i
                                          }
                                        >
                                          {this.props.groupAdsStore.ads[
                                            this.props.groupAdsStore
                                              .selectedAdIndex
                                          ].id === undefined
                                            ? "-"
                                            : this.props.groupAdsStore.ads[
                                                this.props.groupAdsStore
                                                  .selectedAdIndex
                                              ].id}
                                        </td>
                                      );
                                    })
                                  : null}
                              </tr>
                              <tr>
                                <td className="th-table">
                                  {t("ga.history.title")}
                                </td>
                                <td className="last-date" id="last-date-title">
                                  {this.props.groupAdsStore.hasCorrectIndexVal
                                    ? this.props.groupAdsStore.hasAdAndProperty(
                                        "name"
                                      )
                                      ? this.props.groupAdsStore.ads[
                                          this.props.groupAdsStore
                                            .selectedAdIndex
                                        ].name
                                      : "-"
                                    : null}
                                </td>
                                {this.props.groupAdsStore.hasCorrectIndexVal &&
                                this.props.groupAdsStore.hasAdAndHistory
                                  ? this.props.groupAdsStore.ads[
                                      this.props.groupAdsStore.selectedAdIndex
                                    ].history.map((oldAd, i) => {
                                      return (
                                        <td key={oldAd.id + " title" + i}>
                                          {oldAd.name === undefined
                                            ? "-"
                                            : oldAd.name}
                                        </td>
                                      );
                                    })
                                  : null}
                              </tr>
                              <tr>
                                <td className="th-table">
                                  {t("ga.history.owner")}
                                </td>
                                <td className="last-date" id="last-date-hostId">
                                  {this.props.groupAdsStore.hasCorrectIndexVal
                                    ? this.props.groupAdsStore.hasAdAndProperty(
                                        "host"
                                      ) &&
                                      this.props.groupAdsStore.hasAdAndNestedProperty(
                                        "host",
                                        "id"
                                      )
                                      ? this.props.groupAdsStore.ads[
                                          this.props.groupAdsStore
                                            .selectedAdIndex
                                        ].host.id
                                      : "-"
                                    : null}
                                </td>
                                {this.props.groupAdsStore.hasCorrectIndexVal &&
                                this.props.groupAdsStore.hasAdAndHistory
                                  ? this.props.groupAdsStore.ads[
                                      this.props.groupAdsStore.selectedAdIndex
                                    ].history.map((oldAd, i) => {
                                      return (
                                        <td key={oldAd.id + " ownerID" + i}>
                                          {oldAd.host && oldAd.host.id
                                            ? oldAd.host.id
                                            : "-"}
                                        </td>
                                      );
                                    })
                                  : null}
                              </tr>
                              <tr>
                                <td className="th-table">
                                  {t("ga.history.type")}
                                </td>
                                <td
                                  className="last-date"
                                  id="last-date-roomType"
                                >
                                  {this.props.groupAdsStore.hasCorrectIndexVal
                                    ? this.props.groupAdsStore.hasAdAndProperty(
                                        "roomType"
                                      )
                                      ? this.props.groupAdsStore.ads[
                                          this.props.groupAdsStore
                                            .selectedAdIndex
                                        ].roomType
                                      : "-"
                                    : null}
                                </td>
                                {this.props.groupAdsStore.hasCorrectIndexVal &&
                                this.props.groupAdsStore.hasAdAndHistory
                                  ? this.props.groupAdsStore.ads[
                                      this.props.groupAdsStore.selectedAdIndex
                                    ].history.map((oldAd, i) => {
                                      return (
                                        <td key={oldAd.id + " roomType" + i}>
                                          {oldAd.roomType
                                            ? oldAd.roomType
                                            : "-"}
                                        </td>
                                      );
                                    })
                                  : null}
                              </tr>
                              <tr>
                                <td className="th-table">
                                  {t("ga.history.capacity")}
                                </td>
                                <td
                                  className="last-date"
                                  id="last-date-capacity"
                                >
                                  {this.props.groupAdsStore.hasCorrectIndexVal
                                    ? this.props.groupAdsStore.hasAdAndProperty(
                                        "capacity"
                                      )
                                      ? this.props.groupAdsStore.ads[
                                          this.props.groupAdsStore
                                            .selectedAdIndex
                                        ].capacity
                                      : "-"
                                    : null}
                                </td>
                                {this.props.groupAdsStore.hasCorrectIndexVal &&
                                this.props.groupAdsStore.hasAdAndHistory
                                  ? this.props.groupAdsStore.ads[
                                      this.props.groupAdsStore.selectedAdIndex
                                    ].history.map((oldAd, i) => {
                                      return (
                                        <td key={oldAd.id + " capacity" + i}>
                                          {oldAd.capacity !== undefined
                                            ? oldAd.capacity
                                            : "-"}
                                        </td>
                                      );
                                    })
                                  : null}
                              </tr>
                              <tr>
                                <td className="th-table">
                                  {t("ga.history.price")}
                                </td>
                                <td className="last-date" id="last-date-price">
                                  {this.props.groupAdsStore.hasCorrectIndexVal
                                    ? this.props.groupAdsStore.hasAdAndProperty(
                                        "price"
                                      )
                                      ? this.props.groupAdsStore.ads[
                                          this.props.groupAdsStore
                                            .selectedAdIndex
                                        ].price
                                      : 0
                                    : null}
                                </td>
                                {this.props.groupAdsStore.hasCorrectIndexVal &&
                                this.props.groupAdsStore.hasAdAndHistory
                                  ? this.props.groupAdsStore.ads[
                                      this.props.groupAdsStore.selectedAdIndex
                                    ].history.map((oldAd, i) => {
                                      return (
                                        <td key={oldAd.id + " price" + i}>
                                          {oldAd.price ? oldAd.price : 0}
                                        </td>
                                      );
                                    })
                                  : null}
                              </tr>
                              <tr>
                                <td className="th-table">
                                  {t("ga.history.takenDays")}
                                </td>
                                <td
                                  className="last-date"
                                  id="last-date-takenDays"
                                >
                                  {this.props.groupAdsStore.hasCorrectIndexVal
                                    ? this.props.groupAdsStore.hasAdAndProperty(
                                        "occupancy"
                                      ) &&
                                      this.props.groupAdsStore.hasAdAndNestedProperty(
                                        "occupancy",
                                        "numCurrentYearReservations"
                                      )
                                      ? this.props.groupAdsStore.ads[
                                          this.props.groupAdsStore
                                            .selectedAdIndex
                                        ]["monthOcc"] +
                                        " / " +
                                        this.props.groupAdsStore.ads[
                                          this.props.groupAdsStore
                                            .selectedAdIndex
                                        ].occupancy.numCurrentYearReservations
                                      : "-"
                                    : null}
                                </td>
                                {this.props.groupAdsStore.hasCorrectIndexVal &&
                                this.props.groupAdsStore.hasAdAndHistory
                                  ? this.props.groupAdsStore.ads[
                                      this.props.groupAdsStore.selectedAdIndex
                                    ].history.map((oldAd, i) => {
                                      return (
                                        <td key={oldAd.id + " occupancy" + i}>
                                          {this.props.groupAdsStore.ads[
                                            this.props.groupAdsStore
                                              .selectedAdIndex
                                          ].occupancy !== undefined &&
                                          this.props.groupAdsStore.ads[
                                            this.props.groupAdsStore
                                              .selectedAdIndex
                                          ].occupancy
                                            .numCurrentYearReservations !==
                                            undefined
                                            ? oldAd["monthOcc"] +
                                              " / " +
                                              this.props.groupAdsStore.ads[
                                                this.props.groupAdsStore
                                                  .selectedAdIndex
                                              ].occupancy
                                                .numCurrentYearReservations
                                            : "-"}
                                        </td>
                                      );
                                    })
                                  : null}
                              </tr>
                              <tr>
                                <td className="th-table">
                                  {t("ga.history.minNights")}
                                </td>
                                <td
                                  className="last-date"
                                  id="last-date-minNights"
                                >
                                  {this.props.groupAdsStore
                                    .hasCorrectIndexVal &&
                                  this.props.groupAdsStore.hasAdAndProperty(
                                    "minNights"
                                  )
                                    ? this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore.selectedAdIndex
                                      ].minNights
                                    : "-"}
                                </td>
                                {this.props.groupAdsStore.hasCorrectIndexVal &&
                                this.props.groupAdsStore.hasAdAndHistory
                                  ? this.props.groupAdsStore.ads[
                                      this.props.groupAdsStore.selectedAdIndex
                                    ].history.map((oldAd, i) => {
                                      return (
                                        <td key={oldAd.id + " minNights" + i}>
                                          {oldAd.minNights !== undefined
                                            ? oldAd.minNights
                                            : "-"}
                                        </td>
                                      );
                                    })
                                  : null}
                              </tr>
                              <tr>
                                <td className="th-table">
                                  {t("ga.history.long")}
                                </td>
                                <td className="last-date" id="last-date-long">
                                  {this.props.groupAdsStore
                                    .hasCorrectIndexVal &&
                                  this.props.groupAdsStore.hasAdAndProperty(
                                    "geometry"
                                  ) &&
                                  this.props.groupAdsStore.ads[
                                    this.props.groupAdsStore.selectedAdIndex
                                  ].geometry.coordinates[0]
                                    ? this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore.selectedAdIndex
                                      ].geometry.coordinates[0]
                                    : "-"}
                                </td>
                                {this.props.groupAdsStore.hasCorrectIndexVal &&
                                this.props.groupAdsStore.hasAdAndHistory
                                  ? this.props.groupAdsStore.ads[
                                      this.props.groupAdsStore.selectedAdIndex
                                    ].history.map((oldAd, i) => {
                                      return (
                                        <td key={oldAd.id + " longitude" + i}>
                                          {oldAd.geometry &&
                                          oldAd.geometry.coordinates[0]
                                            ? oldAd.geometry.coordinates[0]
                                            : "-"}
                                        </td>
                                      );
                                    })
                                  : null}
                              </tr>
                              <tr>
                                <td className="th-table">
                                  {t("ga.history.lat")}
                                </td>
                                <td className="last-date" id="last-date-lat">
                                  {this.props.groupAdsStore
                                    .hasCorrectIndexVal &&
                                  this.props.groupAdsStore.hasAdAndProperty(
                                    "geometry"
                                  ) &&
                                  this.props.groupAdsStore.ads[
                                    this.props.groupAdsStore.selectedAdIndex
                                  ].geometry.coordinates[1]
                                    ? this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore.selectedAdIndex
                                      ].geometry.coordinates[1]
                                    : "-"}
                                </td>
                                {this.props.groupAdsStore.hasCorrectIndexVal &&
                                this.props.groupAdsStore.hasAdAndHistory
                                  ? this.props.groupAdsStore.ads[
                                      this.props.groupAdsStore.selectedAdIndex
                                    ].history.map((oldAd, i) => {
                                      return (
                                        <td key={oldAd.id + " latitude" + i}>
                                          {oldAd.geometry &&
                                          oldAd.geometry.coordinates[1]
                                            ? oldAd.geometry.coordinates[1]
                                            : "-"}
                                        </td>
                                      );
                                    })
                                  : null}
                              </tr>
                              <tr>
                                <td className="th-table">
                                  {t("ga.history.ownerName")}
                                </td>
                                <td
                                  className="last-date"
                                  id="last-date-hostName"
                                >
                                  {this.props.groupAdsStore
                                    .hasCorrectIndexVal &&
                                  this.props.groupAdsStore.hasAdAndProperty(
                                    "host"
                                  ) &&
                                  this.props.groupAdsStore.hasAdAndNestedProperty(
                                    "host",
                                    "nickname"
                                  )
                                    ? this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore.selectedAdIndex
                                      ].host.nickname
                                    : "-"}
                                </td>
                                {this.props.groupAdsStore.hasCorrectIndexVal &&
                                this.props.groupAdsStore.hasAdAndHistory
                                  ? this.props.groupAdsStore.ads[
                                      this.props.groupAdsStore.selectedAdIndex
                                    ].history.map((oldAd, i) => {
                                      return (
                                        <td key={oldAd.id + " ownerName" + i}>
                                          {oldAd.host && oldAd.host.nickname
                                            ? oldAd.host.nickname
                                            : "-"}
                                        </td>
                                      );
                                    })
                                  : null}
                              </tr>

                              <tr>
                                <td className="th-table">
                                  {t("ga.comparator.numReview")}
                                </td>
                                <td
                                  className="last-date"
                                  id="last-date-numReviews"
                                >
                                  {this.props.groupAdsStore
                                    .hasCorrectIndexVal &&
                                  this.props.groupAdsStore.hasAdAndProperty(
                                    "numReviews"
                                  )
                                    ? this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore.selectedAdIndex
                                      ].numReviews
                                    : 0}
                                </td>
                                {this.props.groupAdsStore.hasCorrectIndexVal &&
                                this.props.groupAdsStore.hasAdAndHistory
                                  ? this.props.groupAdsStore.ads[
                                      this.props.groupAdsStore.selectedAdIndex
                                    ].history.map((oldAd, i) => {
                                      return (
                                        <td key={oldAd.id + " numReviews" + i}>
                                          {oldAd.numReviews
                                            ? oldAd.numReviews
                                            : 0}
                                        </td>
                                      );
                                    })
                                  : null}
                              </tr>

                              <tr>
                                <td className="th-table">
                                  {t("ga.comparator.rate")}
                                </td>
                                <td className="last-date" id="last-date-rate">
                                  {this.props.groupAdsStore
                                    .hasCorrectIndexVal &&
                                  this.props.groupAdsStore.hasAdAndProperty(
                                    "rate"
                                  )
                                    ? this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore.selectedAdIndex
                                      ].rate
                                    : 0}
                                </td>
                                {this.props.groupAdsStore.hasCorrectIndexVal &&
                                this.props.groupAdsStore.hasAdAndHistory
                                  ? this.props.groupAdsStore.ads[
                                      this.props.groupAdsStore.selectedAdIndex
                                    ].history.map((oldAd, i) => {
                                      return (
                                        <td key={oldAd.id + " rate" + i}>
                                          {oldAd.rate ? oldAd.rate : 0}
                                        </td>
                                      );
                                    })
                                  : null}
                              </tr>

                              <tr>
                                <td className="th-table">
                                  {t("ga.history.address")}
                                </td>
                                <td
                                  className="last-date"
                                  id="last-date-address"
                                >
                                  {this.props.groupAdsStore
                                    .hasCorrectIndexVal &&
                                  this.props.groupAdsStore.hasAdAndProperty(
                                    "address"
                                  )
                                    ? this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore.selectedAdIndex
                                      ].address
                                    : "-"}
                                </td>
                                {this.props.groupAdsStore.hasCorrectIndexVal &&
                                this.props.groupAdsStore.hasAdAndHistory
                                  ? this.props.groupAdsStore.ads[
                                      this.props.groupAdsStore.selectedAdIndex
                                    ].history.map((oldAd, i) => {
                                      return (
                                        <td key={oldAd.id + " address" + i}>
                                          {oldAd.address ? oldAd.address : "-"}
                                        </td>
                                      );
                                    })
                                  : null}
                              </tr>
                              <tr>
                                <td className="th-table">
                                  {t("ga.history.associate")}
                                </td>
                                <td
                                  className="last-date"
                                  id="last-date-associate"
                                >
                                  {this.props.groupAdsStore
                                    .hasCorrectIndexVal &&
                                  this.props.groupAdsStore.hasAdAndProperty(
                                    "adGroupCode"
                                  )
                                    ? this.props.groupAdsStore.ads[
                                        this.props.groupAdsStore.selectedAdIndex
                                      ].adGroupCode
                                    : "-"}
                                </td>
                                {this.props.groupAdsStore.hasCorrectIndexVal &&
                                this.props.groupAdsStore.hasAdAndHistory
                                  ? this.props.groupAdsStore.ads[
                                      this.props.groupAdsStore.selectedAdIndex
                                    ].history.map((oldAd, i) => {
                                      return (
                                        <td key={oldAd.id + " associate" + i}>
                                          {oldAd.adGroupCode
                                            ? oldAd.adGroupCode
                                            : "-"}
                                        </td>
                                      );
                                    })
                                  : null}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <hr />
                      </div>
                    </div>
                    <hr />
                  </div>

                  {/*HAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA*/}

                  {/*<div
                    className="row tab-pane fade"
                    id="remark"
                    role="tabpanel"
                    style={{ width: "100%" }}
                    aria-labelledby="remark-tab"
                  >
                    <div id="remark-content" className="col-md-12">
                      <div id="remark-table" className="row tasks-table">
                        {this.props.groupAdsStore.ads.map((ad, i) => {
                          if (
                            i >= this.props.groupAdsStore.index &&
                            i <
                              this.props.groupAdsStore.index +
                                Math.floor(this.maxCards)
                          ) {
                            return (
                              <table
                                key={ad.id}
                                className="table table-hover table-borderless"
                              >
                                <thead key={ad.id}>
                                  <tr>
                                    <th>{t("ga.remark.ad") + " " + ad.id}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr key={ad.id + " remark"}>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        width: "10%",
                                      }}
                                    >
                                      {" "}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        width: "90%",
                                      }}
                                    >
                                      {ad.remark ? ad.remark : "-"}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            );
                          } else return null;
                        })}
                      </div>
                      <hr />
                    </div>
                  </div>*/}
                  {/*HAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA2*/}

                  <div
                    className="row tab-pane fade"
                    id="oldProcedure"
                    role="tabpanel"
                    style={{ width: "100%" }}
                    aria-labelledby="oldProcedure-tab"
                  >
                    <div id="oldProcedure-content" className="col-md-12">
                      <div
                        id="oldProcedure-table"
                        className="row oldProcedure-table"
                      >
                        {this.props.groupAdsStore.ads.map((ad, i) => {
                          if (
                            i >= this.props.groupAdsStore.index &&
                            i <
                              this.props.groupAdsStore.index +
                                Math.floor(this.maxCards)
                          ) {
                            return (
                              <table
                                key={ad.id}
                                className="table table-hover table-borderless"
                              >
                                <thead key={ad.id}>
                                  <tr>
                                    <th>
                                      {t("ga.oldProcedure.ad") + " " + ad.id}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr key={ad.id + " oldProcedure"}>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        width: "10%",
                                      }}
                                    >
                                      {" "}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        width: "90%",
                                      }}
                                    >
                                      {ad.oldProcedure ? ad.oldProcedure : "-"}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            );
                          } else return null;
                        })}
                      </div>
                      <hr />
                    </div>
                  </div>
                  {/*HAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA2*/}
                  <div
                    className="tab-pane fade show active"
                    id="map"
                    role="tabpanel"
                    style={{ width: "100%" }}
                    aria-labelledby="map-tab"
                  >
                    <div id="map-content">
                      {/* <Map match={this.props.match} /> */}
                      {this.props.groupAdsStore.found !== null ? (
                        <Map
                          match={this.props.match}
                          found={this.props.groupAdsStore.found}
                          userWho={this.props.groupAdsStore.userWhoHasIt}
                          latLong={[
                            this.props.groupAdsStore.adGroup.longitude,
                            this.props.groupAdsStore.adGroup.latitude,
                          ]}
                        />
                      ) : null}
                    </div>
                  </div>

                  {/* <div
                    className="tab-pane fade show"
                    id="contact"
                    role="tabpanel"
                    style={{ width: "100%" }}
                    aria-labelledby="contact-tab"
                  >
                    <div className="row message-user">
                      <div className="col-md-6 messages">
                        {this.props.groupAdsStore.ads[
                          this.props.groupAdsStore.selectedAdIndex
                        ]
                          ? this.props.groupAdsStore.ads[
                              this.props.groupAdsStore.selectedAdIndex
                            ].contactMessages.map((value, index) => {
                              return (
                                <p
                                  key={"cm_" + index}
                                  className="mess mess-prop"
                                  style={{ width: "100%" }}
                                >
                                  {value}
                                </p>
                              );
                            })
                          : null}
                      </div>
                      <div className="col-md-6">
                        <p>Missatge</p> 
                        <textarea className="message-area" />
                        <MyTextArea
                          id="message-area"
                          label="Message"
                          name="Message"
                          className="message-area"
                          disabled={
                            this.props.groupAdsStore
                              .userIsTheWorkingUser
                              ? false
                              : true
                          }
                          placeholder={t("ga.contact.placeholder")}
                          value={
                            this.props.groupAdsStore.contactMessages.length > 0 &&
                            this.props.groupAdsStore.contactMessages[
                              this.props.groupAdsStore.selectedAdIndex
                            ] ? this.props.groupAdsStore.contactMessages[
                              this.props.groupAdsStore.selectedAdIndex] : ""
                          }
                          onChange={
                            this.props.groupAdsStore.updateContactMessages
                          }
                        />
                        <MyButton
                          id="button-send-message"
                          type="button"
                          className="btn btn-info"
                          text={t("ga.contact.send")}
                          disabled={this.props.groupAdsStore.ads.length > 0 &&
                            this.props.groupAdsStore
                              .userIsTheWorkingUser
                              ? "false"
                              : "true"
                          }
                          onClick={() => this.props.groupAdsStore.sendMessage()}
                        />
                      </div>
                    </div>
                  </div> 
                 */}
                </div>
              </div>
            </div>
            <div className="col-md-1">
                <div className="map-legend-dropdown type_room">
                  <button
                    id="basemaps"
                    className="btn btn-outline-info btn-bck-color  map-legend-dropbtn span"
                  
                  >
                    <svg
                      className="fa fa-circle assigned"
                      style={{
                        height: "0.5rem",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                    <span>{t("ga.assigned")}</span> <br />


                    <svg
                      className="fa fa-circle noAssigned"
                      style={{
                        height: "0.5rem",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                    <span>{t("ga.noAssigned")}</span> <br />


                    <svg
                      className="fa fa-circle inactive"
                      style={{
                        height: "0.5rem",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                    <span>{t("ga.inactive")}</span> <br />
               </button>
               </div>     
            </div>
          </div>
        </main>
      </>
    );
  }
}
export default withTranslation()(GroupAds);
