import { action, runInAction, extendObservable } from "mobx";

class UserStore {
  constructor(rootStore) {
    this.rootStore = rootStore;

    window.addEventListener("storage", (event) => {
      const credentials = JSON.parse(
        window.sessionStorage.getItem("CREDENTIALS_TOKEN")
      );

      if (event.key === "REQUESTING_SHARED_CREDENTIALS" && credentials) {
        window.localStorage.setItem(
          "CREDENTIALS_SHARING",
          JSON.stringify({ token: "hJkls_23&72=07ut" })
        );
        window.localStorage.removeItem("CREDENTIALS_SHARING");
      }
      if (event.key === "CREDENTIALS_SHARING" && !credentials) {
        window.sessionStorage.setItem("CREDENTIALS_TOKEN", event.newValue);
      }
      if (event.key === "CREDENTIALS_FLUSH" && credentials) {
        window.sessionStorage.removeItem("CREDENTIALS_TOKEN");
        this.rootStore.userStore.logout();
      }
    });

    extendObservable(this, {
      user: null,
      isSupervisor: false,
      isGestor: false,
      loginError: false,
      logoutError: false,
      showMessageAlert: false,
      confirmMessageAlert: false,
      messageInProcessAlert: false,
      alertMessage: "",
      get loggedIn() {
        return this.user !== null && this.rootStore.sessionStore.hasSession;
      },
    });

    runInAction("Load user", async () => {
      this.user = await this.rootStore.sessionStore.getSession();
    });
  }

  @action("update property not in form") updateProperty(key, value) {
    this[key] = value;
  }

  getUser = () => {
    return this.user;
  };

  saveUser = async (session) => {
    await this.rootStore.sessionStore.saveSession(session);
    runInAction("Save user", () => {
      this.user = session;
    });
  };

  removeUser = async () => {
    await this.rootStore.sessionStore.deleteSession();
    runInAction("Logout user", () => {
      this.user = null;
    });
  };

  updateUser = async (id, body) => {
    try {
      let extra = this.user.productsExtra
      this.user = (
        await this.rootStore.requestStore.sendUpdateUser(id, body,"User")
      ).data;
      this.user.productsExtra = extra
      await this.saveUser(JSON.parse(JSON.stringify(this.user)));
      return this.user;
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  login = async (user) => {
    try {
      console.log("ENTRANDO A LOGIN")
      //console.log("login")
      runInAction("Init Login", () => {
        this.loginError = false;
      });
      const res = await this.rootStore.requestStore.loginUser(user);
      let region = await this.rootStore.requestStore.getExtraProducts();
      res.data.user.productsExtra = region.data
      if (res !== "err") {
        if (res.data.user) {
          await this.saveUser(res.data.user);
        }

        window.sessionStorage.setItem(
          "CREDENTIALS_TOKEN",
          JSON.stringify({ token: "hJkls_23&72=07ut" })
        );
        let newLog = {
          user: res.data.user.username,
          message: "L'usuari " + res.data.user.username + " ha fet login",
          date: Date.now(),
          type: "user",
          page: "login"
        };
        this.rootStore.requestStore.postLog(newLog);
      } else {
        return "err";
      }
      
      console.log("SALIENDO DE LOGIN")
    } catch (error) {
      console.log("dins error userStore", error);
      runInAction("Error Login", () => {
        this.loginError = error.errors;
        console.log("loginerror", this.loginError);
      });
      return "err"
    }
  };

  onLogout = () => {
    window.localStorage.setItem("CREDENTIALS_FLUSH", Date.now().toString());
    window.localStorage.removeItem("CREDENTIALS_FLUSH");
  };

  logout = async () => {
    try {
      let pastUser = this.rootStore.userStore.getUser().username;

      let newLog = {
        user: pastUser,
        message: "L'usuari " + pastUser + " ha fet logout",
        date: Date.now(),
        type: "user",
        page: "logout"
      };
      this.rootStore.requestStore.postLog(newLog);
      runInAction("Init Logout", () => {
        this.logoutError = false;
      });
      //await this.rootStore.requestStore.logoutUser();
      await this.removeUser();
      this.onLogout();
      this.rootStore.navigationStore.push("/login");
    } catch (error) {
      runInAction("Error Logout", () => {
        this.logoutError = error.errors;
      });
    }
  };
}

export default UserStore;
