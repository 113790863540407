import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import MySelect from "../basicUI/MySelect";
// import MyParentTable from "../basicUI/MyParentTable";
import MyTable from "../basicUI/MyTable";
import MyCheckboxList from "../basicUI/MyCheckboxList";
import MyInput from "../basicUI/MyInput";
import MyButton from "../basicUI/MyButton";
import MyRadioButton from "../basicUI/MyRadioButton";
import Spinner from "react-bootstrap/Spinner";
import MapTableList from "../Map/MapTableList";
import { withTranslation } from "react-i18next";
const channelPin = new BroadcastChannel("pin");
const channelFav = new BroadcastChannel("fav");

@inject(
  "genericListStore",
  "pageStore",
  "sessionStore",
  "navigationStore",
  "requestStore",
  "mapListStore"
)
@observer
class GenericList extends Component {
  async componentDidMount() {
    try {
      let { t } = this.props;
      document.title = t("lists.title");
      this.props.genericListStore.getPlatformFilterOptions();
      this.props.genericListStore.createOptions();
      this.props.genericListStore.initView();

      channelPin.addEventListener("message", async (event) => {
        this.props.mapListStore.userWhoHasIt = event.data.data.user;
        let res = await this.props.requestStore.getAdsByGroupCode(
          event.data.data.id
        );
        res.data.forEach((id) => {
          this.props.mapListStore.changeStyle(id.id, "pin");
        });
      });
      channelFav.addEventListener("message", async (event) => {
        this.props.mapListStore.user.favAds = event.data.data.favAds;
        let res = await this.props.requestStore.getAdsByGroupCode(
          event.data.data.code
        );
        res.data.forEach((child) => {
          this.props.mapListStore.changeStyle(
            child.id,
            "fav",
            event.data.data.code
          );
        });
        await this.props.mapListStore.updateMarkerAndCard(event.data.data.code);
      });
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  async componentDidUpdate() {
    try {
      if (this.props.navigationStore.history.location.pathname === "/lists" && this.props.navigationStore.history.location.search === "") {
        if (this.props.genericListStore.urlFilters !== undefined) {        
          this.props.navigationStore.reload()
        }
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div role="main" className="container-fluid main lists-main-page">
        <div className="row ">
          <div className="col-md-8" id="area-list">
            <div className="row filter-content">
              <div className="col-md-10 filter-container" name="filter-btn">
                <div className="div-content">
                  <MyButton
                    id="flt-occupancy"
                    type="button"
                    className={
                      "btn btn-outline-info flt-occupancy" +
                      (this.props.genericListStore.occupancyFilter.url !== ""
                        ? " fltActive"
                        : "")
                    }
                    text={t("lists.filter.occupation")}
                    onClick={() =>
                      this.props.genericListStore.updateVisibleFilter(
                        "occupancy"
                      )
                    }
                  />
                  <div
                    id="occupancy-form"
                    className={
                      "card occupancy-form" +
                      (this.props.genericListStore.visibleFilter === "occupancy"
                        ? " display-block"
                        : " display-none")
                    }
                  >
                    <form>
                      <div className="row select-months">
                        <div className="col-md-6">
                          <MySelect
                            selectName="occupancyFilter.fromOption"
                            selectClass="select sel"
                            selectedOption={
                              this.props.genericListStore.occupancyFilter
                                .fromOption
                            }
                            onChange={
                              this.props.genericListStore.updateNestedProperty
                            }
                            selectOptions={
                              this.props.genericListStore.occupancyFilter
                                .selectOptions
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <MySelect
                            selectName="occupancyFilter.toOption"
                            selectClass="select sel"
                            selectedOption={
                              this.props.genericListStore.occupancyFilter
                                .toOption
                            }
                            onChange={
                              this.props.genericListStore.updateNestedProperty
                            }
                            selectOptions={
                              this.props.genericListStore.occupancyFilter
                                .selectOptions
                            }
                          />
                        </div>
                      </div>

                      <MyInput
                        id="occupancyFilter.value"
                        className="btn btn-outline-info inputSelect"
                        type="number"
                        label={t("lists.filter.minOccupation")}
                        name="occupancyFilter.value"
                        placeholder={t("lists.filter.ndays")}
                        value={
                          this.props.genericListStore.occupancyFilter.value
                        }
                        onChange={
                          this.props.genericListStore.updateNestedProperty
                        }
                      />
                    </form>
                    <hr />

                    <MyButton
                      id="button-filter-delete-occupancy"
                      type="button"
                      className="btn btn-outline-danger"
                      text={t("lists.filter.delete")}
                      onClick={() =>
                        this.props.genericListStore.deleteFilter("occupancy")
                      }
                    />
                    <MyButton
                      id="button-filter-save-occupancy"
                      type="button"
                      className="save btn btn-info"
                      text={t("lists.filter.save")}
                      onClick={() =>
                        this.props.genericListStore.saveFilter("occupancy")
                      }
                      disabled={
                        this.props.genericListStore.occupancyFilter
                          .fromOption === "-1" ||
                        this.props.genericListStore.occupancyFilter.fromOption >
                          this.props.genericListStore.occupancyFilter.toOption
                      }
                    />
                  </div>
                </div>

                <div className="div-content">
                  <MyButton
                    id="flt-platform"
                    type="button"
                    className={
                      "btn btn-outline-info flt-platform" +
                      (this.props.genericListStore.platformFilter.url !== ""
                        ? " fltActive"
                        : "")
                    }
                    text={t("lists.filter.platform")}
                    onClick={() =>
                      this.props.genericListStore.updateVisibleFilter(
                        "platform"
                      )
                    }
                  />
                  <div
                    id="platform-form"
                    className={
                      "card platform-form" +
                      (this.props.genericListStore.visibleFilter === "platform"
                        ? " display-block"
                        : " display-none")
                    }
                  >
                    <form>
                      <MyCheckboxList
                        name="platformFilter.options"
                        selectedOptions={
                          this.props.genericListStore.platformFilter.options
                        }
                        onChange={
                          this.props.genericListStore.updateCheckboxListFilter
                        }
                        selectOptions={
                          this.props.genericListStore.platformFilter
                            .selectOptions
                        }
                      />
                    </form>
                    <hr />
                    <MyButton
                      id="button-filter-delete-platform"
                      type="button"
                      className="btn btn-outline-danger"
                      text={t("lists.filter.delete")}
                      onClick={() =>
                        this.props.genericListStore.deleteFilter("platform")
                      }
                    />
                    <MyButton
                      id="button-filter-save-platform"
                      type="button"
                      className="save btn btn-info"
                      text={t("lists.filter.save")}
                      onClick={() =>
                        this.props.genericListStore.saveFilter("platform")
                      }
                      disabled={
                        this.props.genericListStore.platformFilter.option ===
                        "-1"
                      }
                    />
                  </div>
                </div>

                <div className="div-content">
                  <MyButton
                    id="flt-license"
                    type="button"
                    className={
                      "btn btn-outline-info flt-license" +
                      (this.props.genericListStore.licenseFilter.url !== ""
                        ? " fltActive"
                        : "")
                    }
                    text={t("lists.filter.license")}
                    onClick={() =>
                      this.props.genericListStore.updateVisibleFilter("license")
                    }
                  />
                  <div
                    id="license-form"
                    className={
                      "card license-form" +
                      (this.props.genericListStore.visibleFilter === "license"
                        ? " display-block"
                        : " display-none")
                    }
                  >
                    <form>
                      <MyCheckboxList
                        name="licenseFilter.options"
                        selectedOptions={
                          this.props.genericListStore.licenseFilter.options
                        }
                        onChange={
                          this.props.genericListStore.updateCheckboxListFilter
                        }
                        selectOptions={
                          this.props.genericListStore.licenseFilter
                            .selectOptions
                        }
                      />
                    </form>
                    <hr />
                    <MyButton
                      id="button-filter-delete-license"
                      type="button"
                      className="btn btn-outline-danger"
                      text={t("lists.filter.delete")}
                      onClick={() =>
                        this.props.genericListStore.deleteFilter("license")
                      }
                    />
                    <MyButton
                      id="button-filter-save-license"
                      type="button"
                      className="save btn btn-info"
                      text={t("lists.filter.save")}
                      onClick={() =>
                        this.props.genericListStore.saveFilter("license")
                      }
                      disabled={
                        this.props.genericListStore.licenseFilter.options === []
                      }
                    />
                  </div>
                </div>

                <div className="div-content">
                  <MyButton
                    id="flt-price"
                    type="button"
                    className={
                      "btn btn-outline-info flt-price" +
                      (this.props.genericListStore.priceFilter.url !== ""
                        ? " fltActive"
                        : "")
                    }
                    text={t("lists.filter.price")}
                    onClick={() =>
                      this.props.genericListStore.updateVisibleFilter("price")
                    }
                  />
                  <div
                    id="price-form"
                    className={
                      "card price-form" +
                      (this.props.genericListStore.visibleFilter === "price"
                        ? " display-block"
                        : " display-none")
                    }
                  >
                    <form>
                      <MyInput
                        id="priceFilter.fromValue"
                        className="btn btn-outline-info inputSelect"
                        type="number"
                        label={t("lists.filter.minPrice")}
                        name="priceFilter.fromValue"
                        placeholder="Min"
                        onChange={
                          this.props.genericListStore.updateNestedProperty
                        }
                        value={
                          this.props.genericListStore.priceFilter.fromValue
                        }
                      />
                      <br />
                      <br />
                      <MyInput
                        id="priceFilter.toValue"
                        className="btn btn-outline-info inputSelect"
                        type="number"
                        label={t("lists.filter.maxPrice")}
                        name="priceFilter.toValue"
                        placeholder="Max"
                        onChange={
                          this.props.genericListStore.updateNestedProperty
                        }
                        value={this.props.genericListStore.priceFilter.toValue}
                      />
                    </form>
                    <hr />
                    <MyButton
                      id="button-filter-delete-price"
                      type="button"
                      className="btn btn-outline-danger"
                      text={t("lists.filter.delete")}
                      onClick={() =>
                        this.props.genericListStore.deleteFilter("price")
                      }
                    />
                    <MyButton
                      id="button-filter-save-price"
                      type="button"
                      className="save btn btn-info"
                      text={t("lists.filter.save")}
                      onClick={() =>
                        this.props.genericListStore.saveFilter("price")
                      }
                      disabled={
                        (this.props.genericListStore.priceFilter.fromValue ===
                          "" &&
                          this.props.genericListStore.priceFilter.toValue ===
                            "") ||
                        (this.props.genericListStore.priceFilter.fromValue !==
                          "" &&
                          this.props.genericListStore.priceFilter.toValue !==
                            "" &&
                          this.props.genericListStore.priceFilter.fromValue >
                            this.props.genericListStore.priceFilter.toValue)
                      }
                    />
                  </div>
                </div>

                {this.props.genericListStore.selectedList === "Licenses" && (
                  <>
                    <div className="div-content">
                      <MyButton
                        id="flt-webads"
                        type="button"
                        className={
                          "btn btn-outline-info flt-webads" +
                          (this.props.genericListStore.webAdsFilter.url !== ""
                            ? " fltActive"
                            : "")
                        }
                        text={t("lists.filter.adVSLicense")}
                        onClick={() =>
                          this.props.genericListStore.updateVisibleFilter(
                            "webAds"
                          )
                        }
                      />
                      <div
                        id="webads-form"
                        className={
                          "card webads-form" +
                          (this.props.genericListStore.visibleFilter ===
                          "webAds"
                            ? " display-block"
                            : " display-none")
                        }
                      >
                        <form>
                          <MyRadioButton
                            id="webAdsFilterToggle"
                            name="webAdsFilter.value"
                            onChange={
                              this.props.genericListStore.toggleNestedProperty
                            }
                            value="webAdsFilter.value"
                            labelValue={t("lists.filter.ad>license")}
                            checked={
                              this.props.genericListStore.webAdsFilter.value
                            }
                          />
                        </form>
                        <hr />
                        <MyButton
                          id="button-filter-delete-webads"
                          type="button"
                          className="btn btn-outline-danger"
                          text={t("lists.filter.delete")}
                          onClick={() =>
                            this.props.genericListStore.deleteFilter("webads")
                          }
                        />
                        <MyButton
                          id="button-filter-save-webads"
                          type="button"
                          className="save btn btn-info"
                          text={t("lists.filter.save")}
                          onClick={() =>
                            this.props.genericListStore.saveFilter("webads")
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
                {this.props.genericListStore.selectedList === "Hosts" && (
                  <>
                    <div className="div-content">
                      <MyButton
                        id="flt-licads"
                        type="button"
                        className={
                          "btn btn-outline-info flt-licads" +
                          (this.props.genericListStore.licAdsFilter.url !== ""
                            ? " fltActive"
                            : "")
                        }
                        text={t("lists.filter.adVSPlatform")}
                        onClick={() =>
                          this.props.genericListStore.updateVisibleFilter(
                            "licAds"
                          )
                        }
                      />
                      <div
                        id="licads-form"
                        className={
                          "card licads-form" +
                          (this.props.genericListStore.visibleFilter ===
                          "licAds"
                            ? " display-block"
                            : " display-none")
                        }
                      >
                        <form>
                          <MyRadioButton
                            id="licAdsFilterToggle"
                            name="licAdsFilter.value"
                            onChange={
                              this.props.genericListStore.toggleNestedProperty
                            }
                            value="licAdsFilter.value"
                            labelValue={t("lists.filter.ad>plat")}
                            checked={
                              this.props.genericListStore.licAdsFilter.value
                            }
                          />
                        </form>
                        <hr />
                        <MyButton
                          id="button-filter-delete-licads"
                          type="button"
                          className="btn btn-outline-danger"
                          text={t("lists.filter.delete")}
                          onClick={() =>
                            this.props.genericListStore.deleteFilter("licads")
                          }
                        />
                        <MyButton
                          id="button-filter-save-licads"
                          type="button"
                          className="save btn btn-info"
                          text={t("lists.filter.save")}
                          onClick={() =>
                            this.props.genericListStore.saveFilter("licads")
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="row" id="lists">
              <div className="col-md-2">
                <div className="tab">
                  <button
                    className={
                      "tablinks" +
                      (this.props.genericListStore.selectedList === "Favourites"
                        ? " active"
                        : "")
                    }
                    onClick={() =>
                      this.props.genericListStore.openListTabs("Favourites")
                    }
                    id="favList"
                  >
                    {t("lists.tabs.fav")}
                  </button>
                  <button
                    className={
                      "tablinks" +
                      (this.props.genericListStore.selectedList === "Ads"
                        ? " active"
                        : "")
                    }
                    onClick={() =>
                      this.props.genericListStore.openListTabs("Ads")
                    }
                    id="adList"
                  >
                    {t("lists.tabs.ad")}
                  </button>
                  <button
                    className={
                      "tablinks" +
                      (this.props.genericListStore.selectedList === "Hosts"
                        ? " active"
                        : "")
                    }
                    onClick={() =>
                      this.props.genericListStore.openListTabs("Hosts")
                    }
                    id="hostList"
                  >
                    {t("lists.tabs.host")}
                  </button>
                  <button
                    className={
                      "tablinks" +
                      (this.props.genericListStore.selectedList === "Licenses"
                        ? " active"
                        : "")
                    }
                    onClick={() =>
                      this.props.genericListStore.openListTabs("Licenses")
                    }
                    id="licenseList"
                  >
                    {t("lists.tabs.license")}
                  </button>
                </div>
              </div>
              <div className="col-md-10 lists" name="scrollTable">
                <div className="tabcontent tab-list">
                  {this.props.genericListStore.loading ? (
                    //"Loading..."
                    <div className="SpinnerDiv">
                      <Spinner
                        animation="border"
                        variant="info"
                        role="status"
                        className="loadingSpinner"
                      >
                        <span className="sr-only">{t("loading")}</span>
                      </Spinner>
                      <p>{t("loading")}</p>
                    </div>
                  ) : (
                    <MyTable
                      selectedList={this.props.genericListStore.selectedList}
                      fields={this.props.genericListStore.tableFields}
                      elems={this.props.genericListStore.tableData}
                      onClickTh={this.props.genericListStore.changeSortCriteria}
                      onClickTd={
                        this.props.genericListStore.updateSelectedElemToDropdown
                      }
                      onMouseEnterTr={this.props.genericListStore.printCircles}
                      onMouseLeaveTr={
                        this.props.genericListStore.removePrintCircles
                      }
                      sortCriteria={this.props.genericListStore.sortCriteria}
                      sortDirection={this.props.pageStore.sortDirection}
                      dropdownFields={
                        this.props.genericListStore.tableDropdownFields
                      }
                      selectedElemToDropdown={
                        this.props.genericListStore.selectedElemToDropdown
                      }
                      elemsInsideDropdown={
                        this.props.genericListStore.elemsInsideDropdown
                      }
                      navigateTo={this.props.genericListStore.goToGroupAdsPage}
                      goToNewPage={this.props.navigationStore.openInNewTab}
                      onClickFocusAd={this.props.genericListStore.focusAd}
                      updateProperty={
                        this.props.genericListStore.updateProperty
                      }
                      //showWebsite={this.props.genericListStore.showWebsite}
                    />
                  )}
                </div>
              </div>
            </div>
            {this.props.genericListStore.loading ? null : (
              <div id="list-pag-Barcelona" className="page">
                <div
                  id="pag-container"
                  className="row pag-content justify-content-center"
                >
                  <ul className="pagination">
                    <li
                      className={
                        this.props.pageStore.page === 0 ? "disabled" : ""
                      }
                    >
                      <button
                        onClick={() => {
                          this.props.pageStore.updateProperty("page", 0);
                          this.props.genericListStore.updateProperty("urlFilters", this.props.genericListStore.getNewUrl());
                          this.props.navigationStore.push("/lists" + this.props.genericListStore.urlFilters);
                          document.getElementsByName("scrollTable")[0].scrollTo(0,0)
                        }}
                        className="btn"
                      >
                        <svg
                          className="svg-icon-pagination"
                          data-name="fas fa-angle-double-left"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 49.54 39.7"
                        >
                          <path
                            d="M58.11,21.11a3.19,3.19,0,0,1,3,1.09c.8.84,1.64,1.63,2.47,2.44l.36.41a3,3,0,0,1-.36,4h0C62.3,30.31,61,31.62,59.71,33s-2.68,2.64-4,4L51.84,40.8a.29.29,0,0,0-.05.13c1.26,1.35,2.59,2.64,3.92,3.93s2.64,2.68,4,4,2.59,2.64,3.92,3.92l.06.06A3,3,0,0,1,64,56.74l-.37.42c-.8.8-1.61,1.59-2.4,2.4a4.18,4.18,0,0,1-1.66,1.12l-.12,0a5.13,5.13,0,0,1-1.41,0,3.39,3.39,0,0,1-1.58-1c-.22-.25-.48-.46-.73-.7l-2.15-2.15c-.59-.64-1.2-1.25-1.84-1.84l-2.16-2.15c-.59-.64-1.2-1.25-1.84-1.84l-2.16-2.15c-.56-.62-1.15-1.21-1.76-1.77l-2.23-2.22c-.56-.62-1.15-1.21-1.76-1.77a3.21,3.21,0,0,1-.94-2.14,3.38,3.38,0,0,1,.93-2.21L41.59,37c.77-.72,1.51-1.46,2.23-2.22L45.59,33c.47-.46.95-.92,1.41-1.39a3.9,3.9,0,0,0,.73-.79A20.88,20.88,0,0,0,49.51,29c.76-.72,1.51-1.45,2.22-2.22l1.77-1.77c.77-.71,1.51-1.45,2.23-2.22.11-.11.24-.2.34-.32A4.32,4.32,0,0,1,58.11,21.11Z"
                            transform="translate(-15.04 -21.06)"
                          />
                          <path
                            d="M34.36,21.1a3.13,3.13,0,0,1,2.87,1c.84.87,1.72,1.71,2.58,2.56l.31.37a3,3,0,0,1-.32,4l-3.92,3.92-.07.07q-2,1.92-3.92,3.92l-1.45,1.43L28,40.85c-.11.18.08.23.15.3l3.74,3.75c.25.26.51.51.77.76l3.19,3.17.07.08c1.29,1.33,2.59,2.64,3.92,3.92A3.47,3.47,0,0,1,40.54,54a3,3,0,0,1-.38,2.75l-.36.42c-.87.85-1.75,1.69-2.6,2.57a3,3,0,0,1-3,1h-.15a4,4,0,0,1-1.55-1.08c-.19-.22-.41-.4-.62-.6-.7-.75-1.43-1.47-2.18-2.17-.58-.63-1.19-1.24-1.82-1.82-.7-.75-1.42-1.47-2.17-2.17-.59-.63-1.19-1.24-1.82-1.82L21.82,48.9s-.1,0-.15-.07c-.57-.6-1.15-1.18-1.75-1.75,0,0,0-.1-.05-.13l-2.13-2.1c-.56-.61-1.14-1.19-1.75-1.75a3.23,3.23,0,0,1-1-2.17,3.4,3.4,0,0,1,1-2.23c.61-.56,1.2-1.15,1.76-1.75.74-.7,1.47-1.42,2.17-2.17.63-.58,1.24-1.19,1.83-1.82s1.46-1.42,2.16-2.16c.61-.57,1.2-1.15,1.77-1.76l2.23-2.23,1.77-1.76,2.22-2.22c.19-.17.38-.34.55-.52A3.6,3.6,0,0,1,34.36,21.1Z"
                            transform="translate(-15.04 -21.06)"
                          />
                        </svg>
                      </button>
                    </li>
                    <li
                      className={
                        this.props.pageStore.page === 0 ? "disabled" : ""
                      }
                    >
                      <button
                        className="btn"
                        onClick={
                          this.props.pageStore.page !== 0
                            ? () => {
                                this.props.pageStore.updateProperty(
                                  "page",
                                  this.props.pageStore.page - 1
                                );
                                this.props.genericListStore.updateProperty("urlFilters", this.props.genericListStore.getNewUrl());
                                this.props.navigationStore.push("/lists" + this.props.genericListStore.urlFilters);
                                document.getElementsByName("scrollTable")[0].scrollTo(0,0)
                              }
                            : null
                        }
                      >
                        <svg
                          className="svg-icon-pagination"
                          data-name="fas fa-angle-left"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 26.2 40.11"
                        >
                          <path
                            d="M52.15,28.68a2.08,2.08,0,0,0,0-3l-4-4s-1-1-3,0L27.08,39.51s-1,1,0,3L44.94,60.63a2.08,2.08,0,0,0,3,0l4-4a2.08,2.08,0,0,0,0-3L40.06,41.6Z"
                            transform="translate(-26.62 -21.2)"
                          />
                        </svg>
                      </button>
                    </li>
                    {this.props.pageStore.page > 1 && (
                      <li className="">
                        <button
                          className="btn"
                          onClick={
                            this.props.pageStore.page > 1
                              ? () => {
                                  this.props.pageStore.updateProperty(
                                    "page",
                                    this.props.pageStore.page - 2
                                  );
                                  this.props.genericListStore.updateProperty("urlFilters", this.props.genericListStore.getNewUrl());
                                  this.props.navigationStore.push("/lists" + this.props.genericListStore.urlFilters);
                                  document.getElementsByName("scrollTable")[0].scrollTo(0,0)
                                }
                              : null
                          }
                        >
                          {this.props.pageStore.page - 1}
                        </button>
                      </li>
                    )}
                    {this.props.pageStore.page > 0 && (
                      <li className="">
                        <button
                          className="btn"
                          onClick={() => {
                            this.props.pageStore.updateProperty(
                              "page",
                              this.props.pageStore.page - 1
                            );
                            this.props.genericListStore.updateProperty("urlFilters", this.props.genericListStore.getNewUrl());
                            this.props.navigationStore.push("/lists" + this.props.genericListStore.urlFilters);
                            document.getElementsByName("scrollTable")[0].scrollTo(0,0)
                          }}
                        >
                          {this.props.pageStore.page}
                        </button>
                      </li>
                    )}
                    <li className="active">
                      <button className="btn">
                        {this.props.pageStore.page + 1}
                      </button>
                    </li>
                    {this.props.pageStore.page <
                      this.props.pageStore.totalPages - 1 && (
                      <li className="">
                        <button
                          className="btn"
                          onClick={() => {
                            this.props.pageStore.updateProperty(
                              "page",
                              this.props.pageStore.page + 1
                            );
                            this.props.genericListStore.updateProperty("urlFilters", this.props.genericListStore.getNewUrl());
                            this.props.navigationStore.push("/lists" + this.props.genericListStore.urlFilters);
                            document.getElementsByName("scrollTable")[0].scrollTo(0,0)
                          }}
                        >
                          {this.props.pageStore.page + 2}
                        </button>
                      </li>
                    )}
                    {this.props.pageStore.page <
                      this.props.pageStore.totalPages - 2 && (
                      <li className="">
                        <button
                          className="btn"
                          onClick={() => {
                            this.props.pageStore.updateProperty(
                              "page",
                              this.props.pageStore.page + 2
                            );
                            this.props.genericListStore.updateProperty("urlFilters", this.props.genericListStore.getNewUrl());
                            this.props.navigationStore.push("/lists" + this.props.genericListStore.urlFilters);
                            document.getElementsByName("scrollTable")[0].scrollTo(0,0)
                          }}
                        >
                          {this.props.pageStore.page + 3}
                        </button>
                      </li>
                    )}
                    <li
                      className={
                        this.props.pageStore.page >=
                        this.props.pageStore.totalPages
                          ? "disabled"
                          : ""
                      }
                    >
                      <button
                        className="btn"
                        onClick={
                          this.props.pageStore.page <
                          this.props.pageStore.totalPages - 1
                            ? () => {
                                this.props.pageStore.updateProperty(
                                  "page",
                                  this.props.pageStore.page + 1
                                );
                                this.props.genericListStore.updateProperty("urlFilters", this.props.genericListStore.getNewUrl());
                                this.props.navigationStore.push("/lists" + this.props.genericListStore.urlFilters);
                                document.getElementsByName("scrollTable")[0].scrollTo(0,0)
                              }
                            : null
                        }
                      >
                        <svg
                          className="svg-icon-pagination"
                          data-name="fas fa-angle-right"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 26.12 40.11"
                        >
                          <path
                            d="M27.22,53.48a2.08,2.08,0,0,0,0,3l4,4s1,1,3,0l18-18s1-1,0-3l-18-18a2.08,2.08,0,0,0-3,0l-4,4a2.08,2.08,0,0,0,0,3l12,12Z"
                            transform="translate(-26.54 -20.81)"
                          />
                        </svg>
                      </button>
                    </li>
                    <li
                      className={
                        this.props.pageStore.page >=
                        this.props.pageStore.totalPages - 1
                          ? "disabled"
                          : ""
                      }
                    >
                      <button
                        className="btn"
                        onClick={
                          this.props.pageStore.page <
                          this.props.pageStore.totalPages - 1
                            ? () => {
                                this.props.pageStore.updateProperty(
                                  "page",
                                  this.props.pageStore.totalPages - 1
                                );
                                this.props.genericListStore.updateProperty("urlFilters", this.props.genericListStore.getNewUrl());
                                this.props.navigationStore.push("/lists" + this.props.genericListStore.urlFilters);
                                document.getElementsByName("scrollTable")[0].scrollTo(0,0)
                              }
                            : null
                        }
                      >
                        <svg
                          className="svg-icon-pagination"
                          data-name="fas fa-double-angle-right"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 49.59 39.74"
                        >
                          <path
                            d="M15.78,25.12l.44-.5c.89-.8,1.67-1.71,2.52-2.54a3.17,3.17,0,0,1,2.51-1,4.29,4.29,0,0,1,.5.07,3.71,3.71,0,0,1,1.27.71l1.06,1c.7.75,1.43,1.48,2.18,2.18.58.63,1.18,1.23,1.82,1.81.7.75,1.43,1.48,2.18,2.18.58.63,1.18,1.24,1.81,1.82s1.39,1.43,2.12,2.1,1.18,1.24,1.81,1.82c.7.75,1.43,1.48,2.18,2.18.58.63,1.19,1.23,1.82,1.81A3.2,3.2,0,0,1,40.92,41c0,.2-.05.4-.09.6A3.62,3.62,0,0,1,40,43.13c-.61.56-1.2,1.15-1.76,1.75-.77.73-1.52,1.47-2.24,2.24-.61.56-1.2,1.15-1.76,1.76-.74.7-1.47,1.42-2.17,2.17-.63.58-1.24,1.19-1.83,1.82S28.77,54.29,28.07,55c-.63.58-1.24,1.19-1.83,1.82S24.78,58.29,24.07,59c-.15.14-.3.27-.44.42a4,4,0,0,1-1.88,1.28,3.08,3.08,0,0,1-2.51-.5,5.92,5.92,0,0,1-.45-.41l-2.64-2.6a1.33,1.33,0,0,0-.35-.36,3.35,3.35,0,0,1-.53-2.33,3.59,3.59,0,0,1,.83-1.65l.06-.06a1.24,1.24,0,0,0,.23-.17l3.54-3.53a.44.44,0,0,0,.16-.23h0c.1,0,.16-.09.23-.16l3.62-3.62c.06-.06.1-.14.15-.2a1.59,1.59,0,0,0,.21-.15l3.57-3.58A1.21,1.21,0,0,0,28,41h0c.06,0,0-.06,0-.08q-1.89-2-3.86-3.85L24,36.89l-3.74-3.75a.69.69,0,0,0-.19-.1c0-.06,0-.11-.07-.14Q18.15,31,16.22,29.13a.1.1,0,0,0-.11-.08.54.54,0,0,0-.07-.13A3,3,0,0,1,15.78,25.12Z"
                            transform="translate(-15.22 -21.06)"
                          />
                          <path
                            d="M39.69,25.06,40,24.7,42.7,22a3,3,0,0,1,2.29-1,3.4,3.4,0,0,1,.46.06h.08l.13,0c.22.13.47.18.69.3a10.78,10.78,0,0,1,1.57,1.37Q49,24,50.17,25.07l1.75,1.75c.73.76,1.47,1.51,2.25,2.24.58.58,1.16,1.17,1.75,1.75L58.09,33l1.83,1.83L62.09,37l1.75,1.75a3.35,3.35,0,0,1,1,2.23,3.41,3.41,0,0,1-1,2.17c-.61.56-1.2,1.15-1.76,1.75-.74.7-1.47,1.43-2.17,2.17s-1.24,1.2-1.83,1.83c-.74.7-1.47,1.42-2.17,2.16s-1.24,1.2-1.83,1.83c-.74.7-1.47,1.42-2.17,2.17-.63.58-1.24,1.19-1.83,1.82-.74.7-1.46,1.43-2.17,2.17-.13.13-.27.24-.39.37a4,4,0,0,1-2,1.33,3.2,3.2,0,0,1-3-1.08C41.7,58.8,40.84,58,40,57.15l-.28-.29a2.39,2.39,0,0,0-.12-.22,3,3,0,0,1-.36-2.55,3.93,3.93,0,0,1,.71-1.2l.06-.07a8.78,8.78,0,0,0,1.13-1.07c1-1,1.9-1.92,2.85-2.88,1.34-1.27,2.63-2.58,3.92-3.9,0,0,0,0,0-.06a1.59,1.59,0,0,0,.21-.15l3.58-3.58a1,1,0,0,0,.14-.21c.1-.17-.08-.22-.16-.3L48.3,37.25a1.44,1.44,0,0,0-.37-.28c0-.18-.17-.26-.28-.37L44,33,40.06,29a3,3,0,0,1-.63-.83A2.93,2.93,0,0,1,39.69,25.06Z"
                            transform="translate(-15.22 -21.06)"
                          />
                        </svg>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
          <MapTableList />
        </div>
      </div>
    );
  }
}

export default withTranslation()(GenericList);
