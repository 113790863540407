import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import Search from "./Search/Search";
import "./App.css";
import { observer, inject } from "mobx-react";

import logo from "../assets/icons/logo_AV.svg";

@inject("userStore", "rootStore")
@observer
class Navbar extends Component {
  // PER CONTROLAR EL LOGIN I LOGOUT DES D'UN ALTRE TAB DEL NAVEGADOR
  componentDidMount() {
    window.localStorage.setItem(
      "REQUESTING_SHARED_CREDENTIALS",
      Date.now().toString()
    );
    window.localStorage.removeItem("REQUESTING_SHARED_CREDENTIALS");
  }

  render() {
    const { t } = this.props;
    /**  TODO RECUPERAR L'ID DE LA SESSIONSTORE**/
    let user = this.props.userStore.getUser();
    let id, search;
    if (user === null) return null;
    else {
      id = user.id;
      search = this.props.rootStore.navigationStore.history.location.search;
      return (
        <div className="navBar">
          <div
            className={
              "alert alert-info" +
              (!!this.props.userStore.messageInProcessAlert
                ? " alert-contact-message show"
                : " alert-contact-message hide")
            }
          >
            <p id="danger-alert-contact-groupAds">
              {this.props.userStore.alertMessage}
            </p>
            {/*<p id="danger-alert-groupAds">
              {this.props.userStore.alertMessage}
            </p>*/}
            {/* <button
              type="button"
              className="close"
              onClick={() => {
                this.props.userStore.updateProperty("messageInProcessAlert", false);
              }}
            >
              x
            </button> */}
          </div>
          <div
            className={
              "alert alert-danger" +
              (!!this.props.userStore.showMessageAlert
                ? " alert-custom show"
                : " alert-custom hide")
            }
          >
            <p id="danger-alert-custom-contact-groupAds">
              {this.props.userStore.alertMessage}
            </p>
            {/*<p id="danger-alert-groupAds">
              {this.props.userStore.alertMessage}
          </p>*/}
            <button
              type="button"
              className="close"
              onClick={() => {
                this.props.userStore.updateProperty("showMessageAlert", false);
              }}
            >
              x
            </button>
          </div>

          <div
            className={
              "alert alert-success" +
              (!!this.props.userStore.confirmMessageAlert
                ? " alert-custom show"
                : " alert-custom hide")
            }
          >
            <p id="succes-alert-contact-groupAds">
              {this.props.userStore.alertMessage}
            </p>
            {/* <p id="succes-alert-groupAds">
              {this.props.userStore.alertMessage}
            </p> */}
            <button
              type="button"
              className="close"
              onClick={() => {
                this.props.userStore.updateProperty(
                  "confirmMessageAlert",
                  false
                );
              }}
            >
              x
            </button>
          </div>
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <NavLink
              className="navbar-brand"
              to="/area"
              onClick={() => {
                if (
                  this.props.rootStore.navigationStore.history.location
                    .pathname === "/area"
                )
                  this.props.rootStore.navigationStore.reload();
                else this.props.rootStore.navigationStore.push("/area");
              }}
            >
              <img src={logo} width="50" alt="" />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarToggler"
              aria-controls="navbarToggler"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarToggler">
              <div className="col-md-3">
                <ul className="navbar-nav nav-fill nav-left" id="nav-primary">
                  {this.props.userStore.user && this.props.userStore.user.productsExtra && this.props.userStore.user.productsExtra.dashboard ? (
                    <li className="nav-item dropdown-nav">
                      {this.props.rootStore.navigationStore.history.location
                        .pathname === "/command" &&
                      this.props.rootStore.navigationStore.history.location
                        .search !== "" ? (
                        <NavLink
                          className="nav-link"
                          activeClassName="active"
                          to={`/command${search}`}
                          /* onClick={() => {
                            console.log(
                              "JA HI SOC",
                              this.props.rootStore.navigationStore.history
                                .location
                            );
                            //this.props.rootStore.navigationStore.reload();
                          }} */
                        >
                          {t("navBar.command")}
                        </NavLink>
                      ) : (
                        <NavLink
                          className="nav-link"
                          activeClassName="active"
                          to="/command"
                          onClick={() => {
                            this.props.rootStore.navigationStore.push(
                              "/command"
                            );
                          }}
                        >
                          {t("navBar.command")}
                        </NavLink>
                      )}
                    </li>
                  ) : null}
                  {this.props.userStore.user && this.props.userStore.user.productsExtra && this.props.userStore.user.productsExtra.compliance? (
                    <li className="nav-item dropdown-nav">
                      {this.props.rootStore.navigationStore.history.location
                        .pathname === "/cockpit" &&
                      this.props.rootStore.navigationStore.history.location
                        .search !== "" ? (
                        <NavLink
                          className="nav-link"
                          activeClassName="active"
                          to={`/cockpit${search}`}
                          /* onClick={() => {
                            console.log(
                              "JA HI SOC",
                              this.props.rootStore.navigationStore.history
                                .location
                            );
                            //this.props.rootStore.navigationStore.reload();
                          }} */
                        >
                          {t("navBar.command")}
                        </NavLink>
                      ) : (
                        <NavLink
                          className="nav-link"
                          activeClassName="active"
                          to="/cockpit"
                          onClick={() => {
                            this.props.rootStore.navigationStore.push(
                              "/cockpit"
                            );
                          }}
                        >
                          {t("navBar.command")}
                        </NavLink>
                      )}
                    </li>
                  ) : null}
                  <li className="nav-item dropdown-nav">
                    {this.props.rootStore.navigationStore.history.location.pathname === "/area" &&
                    this.props.rootStore.navigationStore.history.location.search !== "" ? (
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to={`/area${search}`}
                        onClick={()=>{console.log("YES",search,this.props.rootStore.navigationStore.history.location)}}
                        >
                        {t("navBar.workingArea")}
                      </NavLink>
                    ) : (
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to="/area"
                        onClick={() => {
                          console.log(search,this.props.rootStore.navigationStore.history)
                          this.props.rootStore.navigationStore.push("/area");
                        }}
                      >
                        {t("navBar.workingArea")}
                      </NavLink>
                    )}
                  </li>
                  <li className="nav-item">
                    {this.props.rootStore.navigationStore.history.location.pathname === "/lists" &&
                    this.props.rootStore.navigationStore.history.location.search !== "" ? (
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to={`/lists${search}`}
                       
                      >
                        {t("navBar.lists")}
                      </NavLink>
                    ) : (
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to="/lists"
                      >
                        {t("navBar.lists")}
                      </NavLink>
                    )}
                  </li>
                </ul>
              </div>

              <div className="huha col-md-4">
                <Search />
              </div>

              <div className="col-md-5">
                <ul
                  className="navbar-nav nav-fill nav-right"
                  id="nav-secondary"
                >
                  {this.props.userStore.user.group === "generalitat" ? (
                    /*this.props.userStore.user.laboratori*/ this.props
                      .rootStore.navigationStore.history.location.pathname ===
                      "/cityhall" &&
                    this.props.rootStore.navigationStore.history.location.search !== "" ? (
                      <li className="nav-item dropdown-nav ">
                        {
                          <NavLink
                            to={`/statistics/cityhall${search}`}
                            //to={`/cityhall${search}`}
                            activeClassName="active"
                            className="nav-link"
                            style={{ width: "7rem" }}
                          >
                            {t("navBar.stats")}
                          </NavLink>
                        }
                      </li>
                    ) : (
                      <li className="nav-item dropdown-nav ">
                        {
                          <NavLink
                            to={"/statistics/cityhall"}
                            activeClassName="active"
                            className="nav-link"
                            style={{ width: "7rem" }}
                          >
                            {t("navBar.stats")}
                          </NavLink>
                        }
                      </li>
                    )
                  ) : null}
                  {/* Desplegar les estadistiqus amb STL - Municipi (/statistics i /cityhall), municipi només per a generalitat i frança */}
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to={`/user/${id}`}
                    >
                      {t("navBar.profile")}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to={"/team/list"}
                    >
                      {t("navBar.team")}
                    </NavLink>
                  </li>
                  {this.props.userStore.user !== null &&
                  (this.props.userStore.user.isSupervisor === true ||
                    this.props.userStore.user.isSuperuser) ? (
                    <li className="nav-item dropdown-nav ">
                      <p className="nav-link ">{t("navBar.admin")}</p>
                      <div className="card dropdown-content">
                        {/* {(this.props.userStore.user.isSupervisor === true ||
                            this.props.userStore.user.isSuperuser === true) && (
                            <p className="nav-item">
                              {
                                <NavLink
                                  to={"/team/processes"}
                                  activeClassName="active"
                                  className="nav-link"
                                >
                                  Processes */}
                        {/* {t("navBar.processes")} */}
                        {/* </NavLink>
                              }
                            </p>
                          )} */}
                        {this.props.userStore.user.isSuperuser === true && (
                          <p className="nav-item">
                            {
                              <NavLink
                                to={"/activity"}
                                activeClassName="active"
                                className="nav-link"
                              >
                                {t("navBar.log")}
                              </NavLink>
                            }
                          </p>
                        )}
                        {this.props.userStore.user.isSuperuser === true && (
                          <p className="nav-item">
                            {
                              <NavLink
                                to={"/newUser"}
                                activeClassName="active"
                                className="nav-link"
                              >
                                {t("navBar.newUser")}
                              </NavLink>
                            }
                          </p>
                        )}
                        {this.props.userStore.user.isSupervisor === true && (
                          <p className="nav-item">
                            {
                              <NavLink
                                to={"/workingRegionEdit"}
                                activeClassName="active"
                                className="nav-link"
                              >
                                {t("navBar.modReg")}
                              </NavLink>
                            }
                          </p>
                        )}
                        {this.props.userStore.user.isSuperuser === true && (
                          <p className="nav-item">
                            <span className="nav-link">
                              {this.props.rootStore.getVersion()}
                            </span>
                          </p>
                        )}
                      </div>
                    </li>
                  ) : (
                    <li className="nav-item dropdown-nav ">
                      <NavLink
                        className="nav-link "
                        activeClassName="active"
                        to="/activity"
                      >
                        {t("navBar.log")}
                      </NavLink>
                    </li>
                  )}
                  <li className="nav-item">
                    <NavLink
                      to="/tickets"
                      activeClassName="active"
                      className="nav-link"
                    >
                      {t("navBar.contact")}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/"
                      className="nav-link"
                      onClick={this.props.userStore.logout}
                    >
                      {t("navBar.logout")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      );
    }
  }
}

export default withTranslation()(Navbar);
