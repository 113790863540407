import React, { Component } from "react";
import { observable, action } from "mobx";
import { observer, inject } from "mobx-react";
import { Redirect} from "react-router-dom";
import SubmitButton from "./basicUI/SubmitButton"
import InputField from './basicUI/InputField'
import Link from './basicUI/LinkComponent'
import logo from '../assets/icons/logo_stl_pro.svg'
import background from '../assets/img/screen-login.jpg'
import { withTranslation } from "react-i18next";

@inject("userStore", "rootStore")
@observer
class Login extends Component {
  constructor(props) {
    super(props);
    this.updateFormProperty = this.updateFormProperty.bind(this);
    // const sectionStyle = {
    //   width: "100%",
    //   height: "400px",
    //   backgroundImage: "url(" + { background } + ")"
    // };
  }

  @observable authError = false;
  @observable loginForm = {
    username: "",
    password: "",
    groupCode: "",
  };

  @action updateFormProperty(key, value) {
    this.loginForm[key] = value;
  }
  onCaptcha() {
    console.log('Button clicked');
    
  }

  @action onSubmit = async (e) => {
    e.preventDefault();
    if (
      this.loginForm.username &&
      this.loginForm.password &&
      this.loginForm.groupCode
    ) {
      let res = await this.props.userStore.login({
        username: this.loginForm.username,
        password: this.loginForm.password,
        group: this.loginForm.groupCode,
      });
      console.log(res)
      if (res === "err") this.authError = true;
    }
  };

  componentDidMount() {
    document.title = "STLPRO - Login";
    document.getElementById("boop").style[
      "backgroundImage"
    ] = `url(${background})`;
    document.getElementById("boop").style["backgroundRepeat"] = "no-repeat";
    document.getElementById("boop").style["backgroundPosition"] = "center top";
    document.getElementById("boop").style["backgroundSize"] = "cover";
  }

  componentWillUnmount() {
    document.getElementById("boop").style["backgroundImage"] = "";
    document.getElementById("boop").style["backgroundAttachment"] = "";
    document.getElementById("boop").style["backgroundPosition"] = "";
  }

  render() {
    const {t} = this.props
    if (!this.props.rootStore.sessionStore.hasSession || !this.props.rootStore.userStore.getUser()) {
    return (
      <main  className="container-fluid">
        <div className="row">
          <div className="col-md-3 login">
            <div className="card card-login">
              <div className="card-body">
                <form onSubmit={this.onSubmit}>
                  <img className="icon-login" src={logo} width="60%" alt="logo"/>
                    <div className="form-row">
                      <div className="col-md-12 mb-3">
                      {this.authError ? <p style={{color: 'red'}}>{t("login.error")}</p> : null}
                        <div className="login-fields">
                          <InputField className="input-login" name="username" placeholder={t("login.user")} value={this.loginForm.username} onChange={this.updateFormProperty}/>

                        <InputField className="input-login" type="password" placeholder={t("login.password")} name="password" value={this.loginForm.password} onChange={this.updateFormProperty}/>

                        <InputField className="input-login" name="groupCode" placeholder={t("login.code")} value={this.loginForm.groupCode} onChange={this.updateFormProperty}/>
                      </div>
                    </div>
                  </div>
                  <SubmitButton className="btn form-button-able" text="Login"/>
                  <div className="forgotten-pass">
                    <Link to="/forgotPassword" text={t("login.forgot")} className="nav-link" href="#"/>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
    }
    else {
      return this.props.rootStore.userStore.user.credentials.censusStart ? 
          <Redirect to={{ pathname: "/censusMap", state: { from: this.props.location } }}/>
       : <Redirect to={{ pathname: "/area", state: { from: this.props.location } }}/>
    }
  }
}

export default  withTranslation()(Login);
