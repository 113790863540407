import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";

@inject("mapListStore", "sessionStore")
@observer
class Chart extends Component {
  render() {
    const {t}= this.props;
    let totalAds = 0;
    let pastAngle = 0;
    //Airbnb Homeaway Niumba Rentalia Housetrip Booking Tripadvisor
    let colors = {
      Airbnb: "#FF5A5F",
      Homeaway: "#2A6EBB",
      Niumba: "#E79C27",
      Rentalia: "#78E2E2",
      Leboncoin: "#FC6E29",
      Housetrip: "#303266",
      Booking: "#F5E546",
      Tripadvisor: "#009688"
    };
    if (this.props.mapListStore.focusedRegion && this.props.mapListStore.focusedRegion.stats && this.props.mapListStore.focusedRegion.stats.numAdsByWebsite)
      for (let adtype in this.props.mapListStore.focusedRegion.stats
        .numAdsByWebsite) {
        totalAds += this.props.mapListStore.focusedRegion.stats.numAdsByWebsite[
          adtype
        ]["numAds"];
      }
    if (this.props.sessionStore.initialized) {
      return (
        <>
        
          {/* {this.props.mapListStore.focusedRegion.stats.numAdsByWebsite && "HELLO 2" } */}
          {this.props.mapListStore.focusedRegion && this.props.mapListStore.focusedRegion.stats && this.props.mapListStore.focusedRegion.stats.numAdsByWebsite ?
          <div className="chart">
            <svg width="200" height="200" className="donut">
              <circle r="60" cx="90" cy="90" fill="white" fillOpacity="75%" />
              {/*<circle className="pie" r="75" cx="90" cy="90" fill="#FFFFFF" stroke="transparent" stroke-width="12"></circle>
                <g className="pie"  fill="#transparent" />*/}
              {Object.keys(
                    this.props.mapListStore.focusedRegion.stats.numAdsByWebsite
                  ).map((value, index) => {
                    //console.log('value '+ index + ' =', value);
                    
                    let percentageAds =
                      (this.props.mapListStore.focusedRegion.stats.numAdsByWebsite[
                        value]['numAds']
                       /
                        totalAds) *
                      100;
                    //console.log("percentageAds =", percentageAds);
                    
                    let angle = (percentageAds * (2 * Math.PI * 60)) / 100;
                    let transformString =
                      "translate(90,90) rotate(" +
                      (-90 + pastAngle).toString() +
                      ") translate(-90,-90)";
                    pastAngle += (angle / (2 * Math.PI * 60)) * 360;
                    return (
                      <g key={index}>
                        <circle
                          key={value + index}
                          r="60"
                          cx="90"
                          cy="90"
                          className="pie"
                          fill="none"
                          stroke={colors[value]}
                          strokeDasharray={
                            angle.toString() +
                            " " +
                            (2 * Math.PI * 60).toString()
                          }
                          strokeDashoffset="0"
                          strokeWidth="12"
                          transform={transformString}
                        />
                        <text
                          key={"website" + index}
                          x="90"
                          y="70"
                          textAnchor="middle"
                          fontSize="15px"
                          fontWeight="bold"
                        >
                          {value}
                        </text>
                        <text
                          key={"numero" + index}
                          x="90"
                          y="90"
                          textAnchor="middle"
                          fontSize="14px"
                        >
                          {this.props.mapListStore.focusedRegion.stats
                            .numAdsByWebsite[value]["numAds"] + t("wa.chart.ads")}
                        </text>
                        <text
                          key={"percentatge" + index}
                          x="90"
                          y="110"
                          textAnchor="middle"
                          fontSize="13px"
                        >
                          {percentageAds.toFixed(2) + t("wa.chart.total")}
                        </text>
                      </g>
                    );
                  })}
              <text x="26%" y="40%" className="provider">
                {" "}
              </text>
              <text x="26%" y="50%" className="ads">
                {" "}
              </text>
              <text x="26%" y="60%" className="per">
                {" "}
              </text>
            </svg>
          </div>
          :null}
        </>
      );
    }
  }
}

export default withTranslation()(Chart);
