import { observable } from 'mobx'
import autobind from 'autobind-decorator'
import { createBrowserHistory } from "history";

class NavigationStore {

  constructor(rootStore) {
    this.rootStore = rootStore
  }

  @observable location = null;
  history = createBrowserHistory();

  @autobind push(location) {
    //console.log("Push",location)
    if(this.history.location.pathname+this.history.location.search+this.history.location.hash !== location)    
    this.history.push(location);
  }
  @autobind replace(location) {
    //console.log("Replace",location)
    this.history.replace(location);
  }
  @autobind go(n) {
    this.history.go(n);
  }
  @autobind goBack() {
    this.history.goBack();
  }
  @autobind goForward() {
    this.history.goForward();
  }
  @autobind openInNewTab(url) {
    let win = window.open(url, '_blank');
    win.focus();
  }
  @autobind openInNewTabNoFocus(url) {
    //let win = window.open(url, '_blank');
    window.open(url, '_blank');
  }

  @autobind reload() {
    window.location.reload(true);
  }

}


export default NavigationStore;
