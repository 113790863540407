import React, { Component } from "react";
import { observer } from "mobx-react";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

@observer
class MyCheckbox extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.props.onChange(
      event.target.name,
      event.target.id,
      event.target.checked
    );
  }
  
  render() {
    const tooltip = <Tooltip id="tooltip" >{this.props.tooltipName}</Tooltip>;
    return (<>
      {this.props.tooltipA === "true" && this.props.tooltipName !== "null" ?
        <OverlayTrigger delay={{ show: 500, hide: 100 }} placement= {this.props.toolPlace === "right" ? "right" : this.props.toolPlace === "left" ? "left" : "bottom"} overlay={tooltip} >

        <span key={"div_" + this.props.id}>

          <input
            type="checkbox"
            // key={this.props.id}
            id={this.props.id}
            name={this.props.name}
            onChange={this.onChange}
            value={this.props.value}
            checked={this.props.defaultChecked}
            // className={this.props.className}
          />
                    
            <label
              key={"label_" + this.props.id}
              htmlFor={this.props.id}
              // className={this.props.labelClass}
            >
              {this.props.labelValue}
            </label>
        </span>
        </OverlayTrigger>
      :
      <span key={"div_" + this.props.id}>
        <input
          type="checkbox"
          // key={this.props.id}
          id={this.props.id}
          name={this.props.name}
          onChange={this.onChange}
          value={this.props.value}
          checked={this.props.defaultChecked}
          // className={this.props.className}
        />
        <label
          key={"label_" + this.props.id}
          htmlFor={this.props.id}
          // className={this.props.labelClass}
        >
          {this.props.labelValue}
        </label>
      </span>
      }
    </>);
  }
}

export default MyCheckbox;
