import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";

import MySelect from "../basicUI/MySelect";
import MyCheckbox from "../basicUI/MyCheckbox";
import MyCheckboxList from "../basicUI/MyCheckboxList";
import MyButton from "../basicUI/MyButton";

import mapLight from "../../assets/img/maps/Gris.png";
import mapStreets from "../../assets/img/maps/Relleu.png";
import mapSatellite from "../../assets/img/maps/Fosc.png";
import mapDark from "../../assets/img/maps/Dark.png";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import NewStats from "../NewRegionSelector/NewRegionSelector";

@inject(
  "mapLicenseStore",
)
@observer
class MapLicense extends Component {
  async componentDidMount() {
    try {
		await this.props.mapLicenseStore.initializeStore();
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  /*componentDidUpdate() {
    
  }*/

  componentWillUnmount() {
    this.props.mapLicenseStore.uninitializeStore();
  }

  render() {
	const { t } = this.props;
	return (
		
		<main>
      <div className="row">
      <div className="col-md-half-1"/>
      <div className="col-md-3">
        <div className="row">
        <div className="col-md-9">
          {this.props.mapLicenseStore.focusedRegion ? 
          <h4 className="stats-title">
            {this.props.mapLicenseStore.focusedRegion.name}
          </h4>:null}
        </div>
        <div className="col-md-1">
          
        </div>
        </div>
        <div className="row" style={{marginLeft:"1rem"}}>
				<div style={{marginRight:"2rem"}}>
          <MyButton
              id="flt-platform"
              type="button"
              className={
                "btn btn-outline-info flt-platform" +
                (this.props.mapLicenseStore.roomTypeSelector.url !== ""
                  ? " fltActive"
                  : "")
              }
              text={t("census.roomTypeSelector")}
              onClick={() => {
                this.props.mapLicenseStore.updateVisibleFilter("roomType");}}
            />
            <div
              id="platform-form"
              className={
                "card platform-form" +
                (this.props.mapLicenseStore.visibleFilter === "roomType"
                  ? " display-block"
                  : " display-none")
              }
            >
              <form>
              <MyCheckboxList
                name="roomTypeSelector.options"
                selectedOptions={
                  this.props.mapLicenseStore.roomTypeSelector.options
                }
                onChange={
                  this.props.mapLicenseStore.updateCheckboxListFilter
                }
                selectOptions={
                  this.props.mapLicenseStore.roomTypeSelector.selectOptions
                }
              />
              </form>
              <hr />
              <MyButton
                id="button-filter-delete-roomType"
                type="button"
                className="btn btn-outline-danger"
                text={t("wa.delete")}
                onClick={() =>
                  this.props.mapLicenseStore.deleteFilter("roomType")
                }
              />
              <MyButton
                id="button-filter-save-roomType"
                type="button"
                className="save btn btn-info"
                text={t("wa.save")}
                onClick={() =>
                  this.props.mapLicenseStore.saveFilter("roomType")
                }
                disabled={
                  this.props.mapLicenseStore.roomTypeSelector.option === "-1"
                }
              />
            </div>
          </div>
        <div>
          <MyButton
              id="flt-platform"
              type="button"
              className={
                "btn btn-outline-info flt-platform" +
                (this.props.mapLicenseStore.licensesSelector.url !== ""
                  ? " fltActive"
                  : "")
              }
              text={t("census.licensesSelector")}
              onClick={() => {
                this.props.mapLicenseStore.updateVisibleFilter("licenseAds");}}
            />
            <div
              id="platform-form"
              className={
                "card platform-form" +
                (this.props.mapLicenseStore.visibleFilter === "licenseAds"
                  ? " display-block"
                  : " display-none")
              }
            >
              <form>
              <MyCheckboxList
                name="licensesSelector.options"
                selectedOptions={
                  this.props.mapLicenseStore.licensesSelector.options
                }
                onChange={
                  this.props.mapLicenseStore.updateCheckboxListFilter
                }
                selectOptions={
                  this.props.mapLicenseStore.licensesSelector.selectOptions
                }
              />
              </form>
              <hr />
              <MyButton
                id="button-filter-delete-licenseAds"
                type="button"
                className="btn btn-outline-danger"
                text={t("wa.delete")}
                onClick={() =>
                  this.props.mapLicenseStore.deleteFilter("licenseAds")
                }
              />
              <MyButton
                id="button-filter-save-licenseAds"
                type="button"
                className="save btn btn-info"
                text={t("wa.save")}
                onClick={() =>
                  this.props.mapLicenseStore.saveFilter("licenseAds")
                }
                disabled={
                  this.props.mapLicenseStore.licensesSelector.option === "-1"
                }
              />
            </div>
          {/*<MyCheckbox
            key={"roomType-checkbox"}
            id={"roomType-checkbox"}
            name={"roomType-checkbox"}
            onChange={() => {this.props.mapLicenseStore.checkboxChange("roomType")}}
            defaultChecked={this.props.mapLicenseStore.checkboxRoomType}
          />
					<MySelect
						selectName="roomTypeSelector.fromOption"
						selectClass="select"
						selectedOption={
						this.props.mapLicenseStore.roomTypeSelector
							.fromOption
						}
						onChange={
						this.props.mapLicenseStore.updateNestedPropertyWithPaintingMap
						}
						selectOptions={
						this.props.mapLicenseStore.roomTypeSelector
						.selectOptions
					}
					/>
          <MyCheckbox
            key={"licenseAds-checkbox"}
            id={"licenseAds-checkbox"}
            name={"licenseAds-checkbox"}
            onChange={() => {this.props.mapLicenseStore.checkboxChange("licenseAds")}}
            defaultChecked={this.props.mapLicenseStore.checkboxLicense}
          />
					<MySelect
						selectName="licensesSelector.fromOption"
						selectClass="select"
						selectedOption={
						this.props.mapLicenseStore.licensesSelector
							.fromOption
						}
						onChange={
						this.props.mapLicenseStore.updateNestedPropertyWithPaintingMap
						}
						selectOptions={
						this.props.mapLicenseStore.licensesSelector
						.selectOptions
					}
					/>*/}
				</div>
        <NewStats page="mapLicense" regBool={true}/>
        </div>
        <div id="census-table">
          <div className="row stats-table">
            <table className="table table-borderless">
              {/*<thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>*/}
              <tbody>
                <tr>
                  <td>{t("census.adCount")}</td>
                  <td>{this.props.mapLicenseStore.adCount}</td>
                </tr>
                <tr>
                  <td>{t("census.fraudCount")}</td>
                  <td>{this.props.mapLicenseStore.fraudCount}</td>
                </tr>
                <tr>
                  <td>{t("census.placesCount")}</td>
                  <td>{this.props.mapLicenseStore.placesCount}</td>
                </tr>
                <tr>
                  <td>{t("census.officialCount")}</td>
                  <td>{this.props.mapLicenseStore.officialCount}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
			</div>
			<div className="col-md-8 map-container" id="map">
              <div className="btn-map">
                <div className="btn-group-vertical" id="custom-map-controls">
                  <button
                    onClick={() => {
                      this.props.mapLicenseStore.zoomIn();
                    }}
                    id="zoomIn"
                    type="button"
                    className="btn btn-outline-secondary btn-bck-color"
                  >
                    <svg
                      className="svg-icon-size-map"
                      data-name="fas fa-plus"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 55.93 55.87"
                    >
                      <path
                        className="cls-1"
                        d="M42.7,67.94c-.9.11-1.8,0-2.7.07-.66,0-1.31,0-2,0a4,4,0,0,1-3.2-1.59A4.15,4.15,0,0,1,34,64V48c0-.58,0-1.17,0-1.75,0-.17-.05-.24-.23-.23H16a4,4,0,0,1-3.9-4.15c0-.6,0-1.21,0-1.82.05-.92,0-1.85.06-2.77a8,8,0,0,1,.46-1.24,4.29,4.29,0,0,1,2.15-1.76,8.93,8.93,0,0,1,1.35-.26c.79.07,1.59,0,2.39,0A9,9,0,0,0,20,34h4a6.75,6.75,0,0,0,1.27,0c.92,0,1.83,0,2.75,0s1.57,0,2.35,0A13.12,13.12,0,0,0,32,34a17.83,17.83,0,0,0,1.79,0c.17,0,.19-.1.19-.23,0-.6,0-1.19,0-1.79v-16c0-.17,0-.34,0-.51A4.11,4.11,0,0,1,36,12.66l1-.41A28.36,28.36,0,0,1,40,12.13l2.25,0a3.17,3.17,0,0,1,1.1.22A4,4,0,0,1,46,16.1v16c0,.58,0,1.16,0,1.74,0,.21.07.27.27.26H48c.82.07,1.64,0,2.47,0A9,9,0,0,0,52,34h4a9,9,0,0,0,1.55,0c.83,0,1.65,0,2.47,0h3.93l.36.05a4,4,0,0,1,3.61,3.37c.12.87,0,1.75.06,2.61s.05,1.9-.08,2.85L67.45,44a4.15,4.15,0,0,1-3.51,2H48l-1.72,0c-.21,0-.26.07-.26.27,0,.57,0,1.14,0,1.71V64a4.11,4.11,0,0,1-2,3.46A6.53,6.53,0,0,1,42.7,67.94Z"
                        transform="translate(-12.04 -12.13)"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => {
                      this.props.mapLicenseStore.zoomOut();
                    }}
                    id="zoomOut"
                    type="button"
                    className="btn btn-outline-secondary btn-bck-color"
                  >
                    <svg
                      className="svg-icon-size-map"
                      data-name="fas fa-minus"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 56 12"
                    >
                      <path
                        className="cls-1"
                        d="M15,34a2.94,2.94,0,0,0-3,3v6a2.94,2.94,0,0,0,3,3H65a2.94,2.94,0,0,0,3-3V37a2.94,2.94,0,0,0-3-3Z"
                        transform="translate(-12 -34)"
                      />
                    </svg>
                  </button>
                </div>
                <br />
                <br />
                <OverlayTrigger
                  delay={{ show: 500, hide: 100 }}
                  placement="left"
                  overlay={
                    <Tooltip id="tooltip">{t("wa.map.uncheck")}</Tooltip>
                  }
                >
                  <button
                    onClick={() => {
                      this.props.mapLicenseStore.deselectMarkers();
                    }}
                    id="flt-unpined"
                    type="button"
                    className="btn btn-outline-secondary btn-bck-color"
                  >
                    <svg
                      className="svg-icon-pin-map"
                      data-name="fas fa-map-pin"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 36 64"
                    >
                      <circle className="cls-1" cx="18" cy="18" r="18" />
                      <path
                        className="cls-2"
                        d="M32,26a2,2,0,0,1-4,0s0-11,12-12a2,2,0,0,1,0,4s-7,0-8,8"
                        transform="translate(-22 -8)"
                      />
                      <path
                        className="cls-1"
                        d="M36,48a16.94,16.94,0,0,0,8,0V67l-1.64,3.27a3.17,3.17,0,0,1-1.49,1.51,1.83,1.83,0,0,1-1.74,0,3.17,3.17,0,0,1-1.49-1.51L36,67Z"
                        transform="translate(-22 -8)"
                      />
                    </svg>
                  </button>
                </OverlayTrigger>
                <br />
                <br />
                <OverlayTrigger
                  delay={{ show: 500, hide: 100 }}
                  placement="left"
                  overlay={<Tooltip id="tooltip">{t("wa.map.gmaps")}</Tooltip>}
                >
                  <button
                    className="btn btn-outline-info button-google"
                    onClick={() => {
                      let center = this.props.mapLicenseStore.map.getCenter();
                      this.props.navigationStore.openInNewTab(
                        "https://www.google.es/maps/@" +
                          center.lat +
                          "," +
                          center.lng +
                          "," +
                          this.props.mapLicenseStore.map.getZoom() +
                          "z"
                      );
                    }}
                  >
                    <img
                      className="google-maps-img"
                      src="/logos/google-maps.png"
                      alt="mapgoogle"
                    ></img>
                  </button>
                </OverlayTrigger>
                <br />
                <br />
              </div>

              <div className="map-mode-dropdown">
                <OverlayTrigger
                  delay={{ show: 500, hide: 100 }}
                  placement="right"
                  overlay={<Tooltip id="tooltip">{t("wa.map.style")}</Tooltip>}
                >
                  <button
                    id="basemaps"
                    className="btn btn-outline-info btn-bck-color map-mode-dropbtn"
                  >
                    {" "}
                    <svg
                      className="svg-icon-map-mode"
                      data-name="fas fa-map"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 72 56.32"
                    >
                      <path
                        className="cls-1"
                        d="M24,12V60L7,68s-3,1-3-2V23a4.37,4.37,0,0,1,3-4C11.24,17.59,24,12,24,12Z"
                        transform="translate(-4 -11.84)"
                      />
                      <polygon
                        className="cls-1"
                        points="24 0.16 24 48.16 48 56.16 48 8.16 24 0.16"
                      />
                      <path
                        className="cls-1"
                        d="M56,20l17-8s3-1,3,2V57s0,3-2,4-18,7-18,7Z"
                        transform="translate(-4 -11.84)"
                      />
                    </svg>
                  </button>
                </OverlayTrigger>
                <div className="map-mode-dropdown-content">
                  <button
                    onClick={() => {
                      this.props.mapLicenseStore.setBasemap("mapbox/light-v10");
                    }}
                  >
                    <img id="map-mode-light" src={mapLight} alt="map-light" />
                  </button>
                  <button
                    onClick={(e) => {
                      this.props.mapLicenseStore.setBasemap("mapbox/streets-v11");
                    }}
                  >
                    <img
                      id="map-mode-relief"
                      src={mapStreets}
                      alt="map-streets"
                    />
                  </button>
                  <button
                    onClick={(e) => {
                      this.props.mapLicenseStore.setBasemap(
                        "mapbox/satellite-streets-v11"
                      );
                    }}
                  >
                    <img
                      id="map-mode-relief-dark"
                      src={mapSatellite}
                      alt="map-satellite"
                    />
                  </button>
                  <button
                    onClick={(e) => {
                      this.props.mapLicenseStore.setBasemap("mapbox/dark-v10");
                    }}
                  >
                    <img id="map-mode-dark" src={mapDark} alt="map-dark" />
                  </button>
                </div>
              </div>
			</div>
      </div>
		</main>
		
	)
  }
}
export default withTranslation()(MapLicense);