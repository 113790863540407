import React, {Component} from 'react'
import {observer} from 'mobx-react'
import PropTypes from 'prop-types';

@observer
class SubmitButton extends Component {

  render () {
    return (
      <button
        type={this.props.type}
        disabled={this.props.disabled}
        className={this.props.className}>
        {this.props.text}

    	</button>
    )
  }
}

SubmitButton.propTypes = {
  text: PropTypes.string.isRequired,
};

SubmitButton.defaultProps = {
  type: 'submit'
}


export default SubmitButton;
