import { observable, action } from "mobx";

class StatsNircStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.updateProperty = this.updateProperty.bind(this);
  }

  @observable cityName = "CITY NAME";
  @observable cityId = "";
  @observable cityAdm = "";
  @observable statsLess = [];
  @observable statsMore = [];
  @observable data = [];
  @observable dataGraph = [];
  @observable totalAds = 0;
  @observable totalGroups = 0;
  @observable dateInfo = "03-2024"

  

  @action("update form property") updateFormProperty(key, value) {
    this.contactForm[key] = value;
  }

  @action("update property not in form") updateProperty(key, value) {
    this[key] = value;
  }

  @action("Initialize Store") initializeStore = async () => {
    let search = this.rootStore.navigationStore.history.location.search;
    // console.log(search);
    if (!!search || search === "") {
      if (search[0] === "?") {
        search = search.substr(1);
        let elemParams = search.split("=");
        // console.log(elemParams)
        if (elemParams[0] === "region") {
          // console.log("BBB")
          let city = await this.rootStore.requestStore.getCityHallData(
            elemParams[1]
          );
          let cityname = await this.rootStore.requestStore.getCityHallName(
            elemParams[1]
          );
          //let counts = await this.rootStore.requestStore.getCountStats(elemParams[1])
          //console.log(counts)
          this.totalAds = city.totalAds
          this.totalGroups = city.totalGroupAds
          this.cityId = city._id;
          this.cityName = cityname.name;
          this.cityAdm = city.adm;
          if (city.dateInfo != undefined) this.dateInfo = city.dateInfo;
          //let month = (new Date().getMonth()) % 12;
          //let month = (new Date().getMonth() + 1) % 12;
          // console.log(month, city);
          //this.statsLess = city.city_hall.dataHistory[month];
          this.hutLlcStats = city.hutLlcStats;
          //console.log(month, this.statsLess, this.statsMore)
          console.log("PRE PREPARE DATA")
          this.prepareData();
          console.log("POST PREPARE DATA")
        }
      } else {
        // console.log("AAA");
        let userData = (
          await this.rootStore.requestStore.getUserAssignedRegions(
            this.rootStore.userStore.getUser().id
          )
        ).data;
        this.rootStore.navigationStore.replace(
          "/statistics/statistics?region=" + userData.assignedRegions[0]
        );
        /* 
        let res = await this.rootStore.requestStore.searchBiggestRegion("1");
        this.rootStore.navigationStore.replace(
          "/statistics?region=" + res.data[0]._id
          ); */
        //this.rootStore.navigationStore.reload();
        let city = await this.rootStore.requestStore.getCityHallData(
          userData.assignedRegions[0]
        );
        let cityname = await this.rootStore.requestStore.getCityHallName(
          userData.assignedRegions[0]
        );
        //let counts = await this.rootStore.requestStore.getCountStats(userData.assignedRegions[0])
        this.totalAds = city.totalAds
        this.totalGroups = city.totalGroupAds
        this.cityId = city._id;
        this.cityName = cityname.name;
        this.cityAdm = city.adm;
        if (city.dateInfo != undefined) this.dateInfo = city.dateInfo;
        //let month = (new Date().getMonth()) % 12;
        let month = (new Date().getMonth() + 1) % 12;
        console.log(month, city);
        //this.statsLess = city.city_hall.dataHistory[month];
        this.hutLlcStats = city.hutLlcStats;
        //console.log(month, this.statsLess, this.statsMore)
        console.log("PRE PREPARE DATA")
        this.prepareData();
        console.log("POST PREPARE DATA")
      }
    }
  };

  @action("Create Data for Chart") prepareData() {
    console.log("IN PREPARE DATA")
    this.data = [];
    this.dataGraph = [];
    let totalData = {
      website: "Total",
      firstTable: [
        0,
        0,
        0,
        0,
        0,
      ],
      secondTable: [
        0,
        0,
        0,
        0,
      ],
      thirdTable: [
        0,
        0,
        0,
      ],
    }
    for (let stats in this.hutLlcStats) {
      console.log(stats, this.hutLlcStats[stats]);
      let roomPercentage = Number(Math.round(((this.hutLlcStats[stats]["room"]["correct"]/this.hutLlcStats[stats]["room"]["total"])*100)+'e'+2)+'e-'+2)
      if (this.hutLlcStats[stats]["room"]["total"] === 0)
        roomPercentage = 0
      let housePercentage = Number(Math.round(((this.hutLlcStats[stats]["house"]["correct"]/this.hutLlcStats[stats]["house"]["total"])*100)+'e'+2)+'e-'+2)
      if (this.hutLlcStats[stats]["house"]["total"] === 0)
        housePercentage = 0
      let websiteToPrint = stats
      if (stats === "Homeaway")
        websiteToPrint="Vrbo/Expedia"
      let dataBase = {
        website: websiteToPrint,
        firstTable: [
          this.hutLlcStats[stats]["room"]["total"],
          this.hutLlcStats[stats]["room"]["correct"],
          this.hutLlcStats[stats]["room"]["nonCorrect"],
          this.hutLlcStats[stats]["room"]["errorType"],
          roomPercentage,
        ],
        secondTable: [
          this.hutLlcStats[stats]["house"]["total"],
          this.hutLlcStats[stats]["house"]["correct"],
          this.hutLlcStats[stats]["house"]["nonCorrect"],
          housePercentage,
        ],
        thirdTable: [
          this.hutLlcStats[stats]["total"]["lessNights"],
          this.hutLlcStats[stats]["total"]["noHotels"],
          this.hutLlcStats[stats]["total"]["total"],
        ],
      };
      //console.log(dataBase)
      totalData["firstTable"][0] += this.hutLlcStats[stats]["room"]["total"]
      totalData["firstTable"][1] += this.hutLlcStats[stats]["room"]["correct"]
      totalData["firstTable"][2] += this.hutLlcStats[stats]["room"]["nonCorrect"]
      totalData["firstTable"][3] += this.hutLlcStats[stats]["room"]["errorType"]
      totalData["secondTable"][0] += this.hutLlcStats[stats]["house"]["total"]
      totalData["secondTable"][1] += this.hutLlcStats[stats]["house"]["correct"]
      totalData["secondTable"][2] += this.hutLlcStats[stats]["house"]["nonCorrect"]
      totalData["thirdTable"][0] += this.hutLlcStats[stats]["total"]["lessNights"]
      totalData["thirdTable"][1] += this.hutLlcStats[stats]["total"]["noHotels"]
      totalData["thirdTable"][2] += this.hutLlcStats[stats]["total"]["total"]
      console.log(dataBase)
      this.data.push(dataBase)
      console.log("DATA",this.data)
      let dataChart = {
        name: websiteToPrint,
        total:this.hutLlcStats[stats]["house"]["total"],
        "nirtc Correcte": this.hutLlcStats[stats]["house"]["correct"],
        "no nirtc": this.hutLlcStats[stats]["house"]["nonCorrect"],
      }
      this.dataGraph.push(dataChart)
      
      console.log("CHART",this.dataGraph)
    }
    totalData["firstTable"][4] = Number(Math.round(((totalData["firstTable"][1]/totalData["firstTable"][0])*100)+'e'+2)+'e-'+2)
    totalData["secondTable"][3] = Number(Math.round(((totalData["secondTable"][1]/totalData["secondTable"][0])*100)+'e'+2)+'e-'+2)
    //console.log(totalData)
    //this.data.push(totalData)
    //console.log(this.data)

    
  }
}

export default StatsNircStore;
