import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import MyButton from "../basicUI/MyButton";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Ad from "./AdForMap";
import ProcessTracker from "./ProcessTrackerForWA";
import { withTranslation } from "react-i18next";
const { BroadcastChannel } = require("broadcast-channel");
const channelPin = new BroadcastChannel("pin");
const channelFav = new BroadcastChannel("fav");

@inject(
  "mapListStore",
  "navigationStore",
  "sessionStore",
  "userStore",
  "requestStore",
  "pageStore",
  "groupAdsStore"
)
@observer
class GroupCard extends Component {
  // constructor (props) {
  //       super(props)
  //       this.onClick = this.onClick.bind(this)
  //   }

  async componentDidMount() {
    channelPin.addEventListener("message", async (event) => {
      if (event.id === this.props.groupAd._id) {
        this.props.mapListStore.userWhoHasIt = event.user;
        this.props.mapListStore.changeStyle(this.props.groupAd._id, "pin");
        this.props.groupAd.childrenId.forEach((child) => {
          this.props.mapListStore.changeStyle(child, "pin");
        });
        await this.props.mapListStore.updateMarkerAndCard(
          this.props.groupAd._id
        );
        let mod1 = document.getElementsByClassName("modalw display-block");
        let mod2 = document.getElementsByClassName(
          "modalw modalTasks display-block"
        );
        if (mod1.length > 0 || mod2.length > 0)
          this.props.groupAdsStore.close(false);
      }
    });
    channelFav.addEventListener("message", async (event) => {
      if (event.code === this.props.groupAd._id) {
        this.props.mapListStore.user.favAds = event.favAds;
        this.props.mapListStore.changeStyle(
          this.props.groupAd._id,
          "fav",
          this.props.groupAd._id
        );
        this.props.groupAd.childrenId.forEach((child) => {
          this.props.mapListStore.changeStyle(
            child,
            "fav",
            this.props.groupAd._id
          );
        });
        await this.props.mapListStore.updateMarkerAndCard(
          this.props.groupAd._id
        );
      }
    });
    await this.props.mapListStore.searchCompliance(this.props.groupAd._id, this.props.index)
  }

  componentWillUnmount() {}

  updateUser = async (id) => {
    this.props.mapListStore.user = await this.props.mapListStore.toggleFav(id);
  };

  processFinished = (processList) => {
    let finished = true;
    processList.forEach((value) => {
      finished = finished && value.finished;
    });
    return finished;
  };

  processInProcess = (workingUser) => {
    let inP = false;
    if (
      workingUser !== null &&
      workingUser !== undefined &&
      workingUser !== "-"
    )
      inP = true;
    return inP;
  };

  clickedInProcess = async (idGA) => {
    let res = await this.props.groupAdsStore.getProcesses(idGA);
    this.props.processStore.openGAProcesses(res.process, idGA);
    //this.props.mapListStore.processes = res.process
  };

  overInProcess = async (idGA) => {
    let res = await this.props.groupAdsStore.getProcesses(idGA);
    if (!!res) {
      await this.props.processStore.openGATasks(res.process, idGA);
    }
  };

  onEnters = async (idGA) => {
    let res = await this.props.groupAdsStore.getProcesses(idGA);
    if (!!res) {
      await this.props.processStore.openGATasks(res.process, idGA);
    }
  };

  onLeaves = async (idGA) => {
    this.props.processStore.close(false);
  };

  render() {
    // this.updateUser();
    const { t } = this.props;

    if (this.props.sessionStore.initialized) {
      return (
        <div key={this.props.groupAd._id + this.props.index}>
          <div
            className="card card-ads"
            id={this.props.groupAd._id}
            onMouseEnter={(e) => {
              this.props.mapListStore.changeCirclesColor(this.props.groupAd);
            }}
            onMouseLeave={(e) => {
              this.props.mapListStore.switchCircleColor("repaint");
            }}
          >
            <div
              className={
                this.props.mapListStore.listMode
                  ? "row card-grid"
                  : "row card-list"
              }
              id={this.props.groupAd._id + "-type"}
            >
              {this.props.mapListStore.listMode ? (
                <div
                  className="col-md-3 card-img"
                  id={this.props.groupAd._id + "-card-img"}
                  img={this.props.groupAd.thumbnail}
                >
                  <img
                    className="card-img-top"
                    src={this.props.groupAd.thumbnail}
                    alt={this.props.groupAd._id}
                  />
                </div>
              ) : null}
              <div
                className={
                  this.props.mapListStore.listMode
                    ? "col-md-8 card-info"
                    : "col-md-10 card-info"
                }
                id={this.props.groupAd._id + "-card-info"}
              >
                <div
                  className={
                    this.props.mapListStore.listMode
                      ? "card-info-top-grid"
                      : "card-info-top-list"
                  }
                >
                  <p
                    style={{
                      display: "inline-block",
                      padding: "2px 4px 0px 0px",
                    }}
                  >
                    {t("wa.card.group")}:{" "}
                  </p>
                  <button
                    className="btn btn-default group-ads-btn"
                    onClick={() => {
                      this.props.navigationStore.openInNewTab(
                        "/groupAds/" + this.props.groupAd._id
                      );
                    }}
                  >
                    {this.props.groupAd._id}
                  </button>

                  <div className="group-num-ads">
                    <p>
                      {this.props.groupAd.childrenId.length === 1
                        ? this.props.groupAd.childrenId.length +
                          " " +
                          t("wa.card.ad")
                        : this.props.groupAd.childrenId.length +
                          " " +
                          t("wa.card.ads")}
                    </p>
                  </div>
                  <ProcessTracker
                    groupId={this.props.groupAd._id}
                    fiProc={this.props.groupAd.finishedProcessing}
                    workingUser={this.props.groupAd.workingUser}
                    adState={this.props.groupAd.state}
                    notes={this.props.groupAd.notes}
                    lastModification={this.props.groupAd.lastModification}
                  ></ProcessTracker>
                  {
                    //Aqui va el simbol del motiu
                    this.props.groupAd.reason ? (
                      <svg
                        className="svg-icon-reason"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 63.71 47.79"
                        onMouseEnter={(e) => {
                          document.getElementById(
                            "modalReason" + this.props.groupAd._id
                          ).className = document
                            .getElementById(
                              "modalReason" + this.props.groupAd._id
                            )
                            .className.replace("display-none", "display-block");
                          //Hover in
                        }}
                        onMouseLeave={(e) => {
                          document.getElementById(
                            "modalReason" + this.props.groupAd._id
                          ).className = document
                            .getElementById(
                              "modalReason" + this.props.groupAd._id
                            )
                            .className.replace("display-block", "display-none");
                        }}
                      >
                        <path
                          className="st0"
                          d="M74.9,76.1c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0-0.5,0c-5.3,0-10.6,0-15.8,0c-0.3,0-0.6,0-0.8-0.1	c-0.9-0.4-1.3-1-1.3-2.1c0-5.2,0-10.4,0-15.6c0-1.4,0.8-2.2,2.2-2.2c1.4,0,2.8,0,4.3,0c3.8,0,7.6,0,11.4,0c0.7,0,1.3,0.2,1.7,0.8	c0.4,0.5,0.4,1,0.4,1.5c0,5.1,0,10.2,0,15.4c0,0.6-0.1,1.2-0.5,1.8l0,0c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.2,0.2l0,0c0,0,0,0,0,0	C75.4,75.8,75.1,75.9,74.9,76.1z M60.3,61.4c0,0.3,0,0.5,0,0.8c0,0.8,0,1.5,0,2.3c0,0.3,0,0.6,0,0.9c0,0.7,0,1.5,0,2.2 c0,0.3,0,0.6,0,0.9c0,0.7,0,1.5,0,2.2 c0,0.3,0,0.6,0,0.9 c0,0.1,0,0.2,0,0.2c0,0.3,0.2,0.2,0.3,0.2c3.7,0,7.5,0,11.2,0	c0.4,0,0.5-0.1,0.5-0.5c0-2.9,0-5.8,0-8.7 c0-0.8,0-1.7,0-2.5c0-0.1,0.1-0.3-0.2-0.3c-0.1,0-0.2,0-0.2,0c-0.3,0-0.6,0-0.9,0c-0.7,0-1.5,0-2.2,0c-0.3,0-0.6,0-0.9,0c-0.7,0-1.5,0-2.2,0c-0.3,0-0.6,0-0.9,0c-0.8,0-1.5,0-2.3,0c-0.3,0-0.5,0-0.8,0 C60.2,60,60.1,60.1,60.3,61.4z"
                        />
                        <path
                          className="st0"
                          d="M54.7,56c-0.5,0-1,0-1.5-0.3c-0.7-0.5-1-1.2-1-2c0-0.1,0-0.1,0-0.2c0.3-0.7,0.8-1.2,1.5-1.5c0.1,0,0.1,0,0.2,0	c0.7,0,1.3,0.1,1.8,0.7c0.6,0.7,0.7,1.7,0.2,2.4c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0,0,0	c-0.2,0.1-0.5,0.3-0.7,0.4C54.8,56,54.7,56,54.7,56z"
                        />
                        <path
                          className="st0"
                          d="M46.1,60c0.8,0.1,1.5,0.4,1.9,1.2c0.6,1.4-0.3,2.8-1.9,2.8c-2.3,0-4.6,0-6.9,0c-11,0-21.9,0-32.9,0 c-0.5,0-0.9,0-1.3-0.3c-0.6-0.3-0.9-0.8-1-1.4c0-0.2,0-0.4,0-0.6c0-18.5,0-37,0-55.5C4,5.9,4,5.4,4.2,5c0.3-0.7,0.9-1.1,1.6-1.1 c2.1,0,4.1,0,6.2,0c15.5,0,31,0,46.5,0c1.2,0,2.3,0,3.5,0c1.4,0,2.2,0.9,2.2,2.2c0,11.5,0,23.1,0,34.6c0,1.7,0,3.5,0,5.2 c0,1-0.5,1.6-1.3,1.9c-1.3,0.5-2.7-0.3-2.8-1.7c0-0.1,0-0.1,0-0.2c0-0.7,0-1.5,0-2.2c0-0.3,0-0.6,0-0.9c0-0.7,0-1.5,0-2.2 c0-0.3,0-0.6,0-0.9c0-0.7,0-1.5,0-2.2c0-0.3,0-0.6,0-0.9c0-0.7,0-1.5,0-2.2c0-0.3,0-0.6,0-0.9c0-0.7,0-1.5,0-2.2c0-0.3,0-0.6,0-0.9 c0-0.7,0-1.5,0-2.2c0-0.3,0-0.6,0-0.9c0-0.7,0-1.5,0-2.2c0-0.3,0-0.6,0-0.9c0-0.7,0-1.5,0-2.2c0-0.3,0-0.6,0-0.9c0-0.7,0-1.5,0-2.2 c0-0.3,0-0.6,0-0.9c0-0.7,0-1.5,0-2.2c0-0.3,0-0.6,0-0.9c0-0.7,0-1.5,0-2.2c0-0.3,0-0.6,0-0.9c0-0.7,0-1.5,0-2.2c0-0.3,0-0.6,0-0.9 c0-0.1,0-0.2,0-0.2c0-0.4-0.3-0.2-0.4-0.2c-17.1,0-34.1,0-51.2,0C8,7.9,8,7.9,8,8.4c0,17,0,34.1,0,51.1c0,0.1,0,0.2,0,0.4 C8,60,8.1,60.1,8.2,60c0.1,0,0.2,0,0.2,0c0.3,0,0.6,0,0.9,0c0.7,0,1.5,0,2.2,0c0.3,0,0.6,0,0.9,0c0.7,0,1.5,0,2.2,0 c0.3,0,0.6,0,0.9,0c0.7,0,1.5,0,2.2,0c0.3,0,0.6,0,0.9,0c0.7,0,1.5,0,2.2,0c0.3,0,0.6,0,0.9,0c0.7,0,1.5,0,2.2,0c0.3,0,0.6,0,0.9,0 c0.7,0,1.5,0,2.2,0c0.3,0,0.6,0,0.9,0c0.7,0,1.5,0,2.2,0c0.3,0,0.6,0,0.9,0c0.8,0,1.5,0,2.3,0c0.3,0,0.5,0,0.8,0c0.8,0,1.5,0,2.3,0 c0.3,0,0.6,0,0.9,0c0.7,0,1.5,0,2.2,0c0.3,0,0.6,0,0.9,0c0.7,0,1.5,0,2.2,0c0.3,0,0.6,0,0.9,0C44.7,60.1,45.4,60.1,46.1,60z"
                        />
                        <path
                          className="st0"
                          d="M12.1,30.3c0-2.5,0-4.9,0-7.4c0-3.1,0-6.1,0-9.2c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0.2-0.8,0.8-1.4,1.6-1.6 c0,0,0,0,0,0l0,0c2.8,0,5.6,0,8.3,0c2.7,0,5.5,0,8.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.5,0.2,1,0.5,1.3,1.1 c0.1,0.3,0.2,0.6,0.3,0.9c0,0.1,0,0.2,0,0.2c0,0.3-0.1,0.6,0,0.9c-0.1,0.8,0,1.5,0,2.3c-0.1,0.3-0.1,0.6,0,0.9c-0.1,0.7,0,1.5,0,2.2 c-0.1,0.3-0.1,0.6,0,1c-0.1,0.7,0,1.5,0,2.2c-0.1,0.3-0.1,0.6,0,1c-0.1,0.7,0,1.5,0,2.2c-0.1,0.3-0.1,0.6,0,1c-0.1,0.7,0,1.5,0,2.2 c-0.1,0.4-0.1,0.7-0.3,1.1c-0.4,0.8-1.1,1.1-2,1.1c-0.7,0-1.4,0-2.2,0c-0.3-0.1-0.7-0.1-1,0c-0.7,0-1.5-0.1-2.2,0 c-0.3-0.1-0.6-0.1-1,0c-0.7,0-1.5-0.1-2.2,0c-0.3-0.1-0.6-0.1-1,0c-0.7,0-1.5-0.1-2.2,0c-0.3-0.1-0.6-0.1-0.9,0 c-0.8,0-1.5-0.1-2.3,0c-0.3-0.1-0.6-0.1-0.9,0c-0.1,0-0.2,0-0.2,0c-1-0.1-1.6-0.6-2-1.6C12.1,30.4,12.1,30.3,12.1,30.3z M28.1,25.7 c0-0.3,0-0.6,0-0.9c0.1-0.7,0-1.5,0-2.2c0-0.3,0-0.6,0-0.9c0.1-0.7,0-1.5,0-2.2c0-0.3,0-0.6,0-0.9c0.1-0.7,0-1.5,0-2.2 c0.1-0.3,0-0.4-0.3-0.4c-3.8,0-7.5,0-11.3,0c-0.3,0-0.4,0.1-0.4,0.4c0,3.8,0,7.5,0,11.3c0,0.3,0.1,0.4,0.4,0.3c0.7,0,1.5,0.1,2.2,0 c0.3,0.1,0.6,0.1,0.9,0c0.7,0,1.5,0.1,2.2,0c0.3,0.1,0.6,0.1,0.9,0c0.7,0,1.5,0.1,2.2,0c0.3,0.1,0.6,0.1,0.9,0c0.7,0,1.3,0,2,0 c0.2,0,0.3-0.1,0.3-0.3C28.1,27,28.1,26.4,28.1,25.7z"
                        />
                        <path
                          className="st0"
                          d="M54.7,11.9c0.6,0.3,1.1,0.7,1.4,1.3c0,0.1,0,0.1,0,0.2c0,5.4,0,10.8,0,16.2c0,0.3,0,0.7-0.1,1 c-0.3,0.8-1,1.3-1.9,1.3c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0C53.3,32,53.2,32,53,32c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.6-0.1-1,0 c-0.5,0-1-0.1-1.5,0c-0.2-0.1-0.4-0.1-0.5,0c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.6-0.1-0.9,0c-0.5,0-1-0.1-1.5,0c-0.2-0.1-0.4-0.1-0.5,0 c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.6-0.1-1,0c-0.5,0-1-0.1-1.5,0C44,32,43.8,32,43.6,32c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.6-0.1-1,0 c-0.5,0-1-0.1-1.5,0c-0.2-0.1-0.4-0.1-0.5,0c-0.1,0-0.1,0-0.2,0C40,32,39.7,32,39.4,32c-0.2,0-0.4,0-0.6,0c-0.2-0.1-0.4,0-0.6,0 c-0.9-0.1-1.5-0.4-1.8-1.2c-0.2-0.4-0.2-0.8-0.2-1.3c0-5,0-10.1,0-15.1c0-0.8,0.1-1.4,0.7-2l0,0l0,0c0.3-0.1,0.6-0.3,0.8-0.4 c0.1,0,0.1,0,0.2,0c3.1,0,6.3,0,9.4,0c2.5,0,4.9,0,7.4,0C54.6,11.9,54.7,11.9,54.7,11.9z M41.6,27.9c0.8,0,1.5,0.1,2.3,0 c0.3,0.1,0.6,0.1,0.9,0c0.8,0,1.5,0.1,2.3,0c0.3,0.1,0.6,0.1,0.9,0c0.8,0,1.5,0.1,2.3,0c0.5,0.1,1.1,0,1.6,0c0.4,0,0.5-0.1,0.5-0.5 c0-3.7,0-7.4,0-11.1c0-0.4-0.1-0.5-0.5-0.5c-3.7,0-7.4,0-11.1,0c-0.5,0-0.5,0-0.4,0.5c0,0.8,0,1.5,0,2.3c0,0.3,0,0.6,0,0.9 c0,0.7,0,1.5,0,2.2c0,0.3,0,0.6,0,0.9c0,0.7,0,1.5,0,2.2c0,0.3,0,0.6,0,0.9c0,0.5,0,1,0,1.6c0,0.5,0,0.5,0.5,0.5 C41,28,41.3,28,41.6,27.9z"
                        />
                        <path
                          className="st0"
                          d="M13.4,56c-0.6-0.3-1.1-0.8-1.3-1.4c0-0.1,0-0.1,0-0.2c0-3.2,0-6.4,0-9.7c0-2.4,0-4.7,0-7.1c0-0.1,0-0.1,0-0.2 c0.1-0.2,0.1-0.3,0.2-0.5c0.4-0.7,1.1-1,1.9-1c3.2,0,6.4,0,9.6,0c2.1,0,4.1,0,6.2,0c1.2,0,1.9,0.6,2.1,1.7c0.1,0.3,0.1,0.6,0.1,0.9 c-0.1,0.2,0,0.4,0,0.6c-0.1,0.3-0.1,0.6,0,0.9c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.1,0.4,0,0.5c-0.1,0.5,0,1,0,1.5c-0.1,0.3-0.1,0.6,0,1 c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.1,0.3,0,0.5c-0.1,0.5,0,1,0,1.6c-0.1,0.3-0.1,0.6,0,1c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.1,0.3,0,0.5 c-0.1,0.5,0,1.1,0,1.6c-0.1,0.3-0.1,0.6,0,0.9c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.1,0.3,0,0.5c-0.1,0.5,0,1,0,1.6c-0.1,0.3-0.1,0.6,0,1 c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.1,0.3,0,0.5c0,0.1,0,0.2,0,0.3c-0.1,0.3,0,0.7-0.1,1c-0.2,0.7-0.9,1.3-1.6,1.4c-0.7,0-1.3,0-2,0 c-4.6,0-9.3,0-13.9,0c-0.3,0-0.6,0-0.9,0C13.5,56,13.5,56,13.4,56z M19.8,40c-0.3,0-0.6,0-0.9,0c-0.8,0-1.5,0-2.3,0 c-0.5-0.1-0.5-0.1-0.5,0.4c0,3.7,0,7.4,0,11.1c0,0.4,0.1,0.5,0.5,0.5c3.1,0,6.1,0,9.2,0c0.7,0,1.4,0,2,0c0.2,0,0.3,0,0.3-0.3 c0-0.6,0-1.2,0-1.8c0.1-0.8,0-1.5,0-2.3c0-0.3,0-0.6,0-0.9c0.1-0.8,0-1.5,0-2.3c0-0.3,0-0.6,0-0.9c0.1-0.8,0-1.5,0-2.3 c0-0.3,0-0.6,0-0.9c0-0.2,0.2-0.5-0.2-0.5c-0.6,0-1.2,0-1.8,0c-0.3,0-0.6,0-0.9,0c-0.7,0-1.5,0-2.2,0c-0.3,0-0.6,0-0.9,0 C21.2,40,20.5,40,19.8,40z"
                        />
                        <path
                          className="st0"
                          d="M48.2,37.5c0,0.1,0,0.2,0,0.2c0,0.2,0,0.4,0,0.6c0,0.1-0.1,0.2,0,0.2c-0.2,0.2-0.3,0.5-0.4,0.8 c-0.3,0.4-0.8,0.6-1.2,0.7c-0.2,0-0.4,0-0.6,0c-0.1-0.1-0.3-0.1-0.4-0.2c-1-0.4-1.6-1.6-1.2-2.6c0.5-1.1,1.7-1.6,2.7-1.1 C47.6,36.4,47.9,36.9,48.2,37.5z"
                        />
                        <path
                          className="st0"
                          d="M37.8,48c-0.1,0-0.1,0-0.2,0c-0.1-0.1-0.2-0.1-0.3-0.2c-1-0.4-1.5-1.6-1.1-2.6c0.4-1,1.6-1.5,2.7-1.1 c0.7,0.3,1,0.9,1.2,1.5c0,0.2,0,0.4,0,0.6c-0.2,0.8-0.7,1.3-1.5,1.7c-0.1,0-0.2,0-0.2,0C38.2,48,38,48,37.8,48z"
                        />
                        <path
                          className="st0"
                          d="M40.2,54.4C40,55,39.7,55.6,39,55.9c-0.8,0.4-1.8,0.2-2.4-0.5C36,54.7,36,53.7,36.5,53c0.1-0.1,0.1-0.2,0.2-0.3 c0,0,0,0,0,0c0.3-0.3,0.6-0.4,1-0.6c0.1,0,0.1,0,0.2,0c0.6,0,1.2,0.1,1.7,0.5l0,0c0.4,0.5,0.6,1,0.7,1.6 C40.1,54.2,40.1,54.3,40.2,54.4z"
                        />
                        <path
                          className="st0"
                          d="M54.3,40c-0.9-0.1-1.6-0.4-1.9-1.3c-0.4-1,0-2.1,1.1-2.6c0.9-0.4,2.1,0,2.6,0.9c0.6,1.2,0,2.5-1.4,2.9 c-0.1,0-0.1,0-0.2,0.1C54.4,40,54.3,40,54.3,40z"
                        />
                        <path
                          className="st0"
                          d="M48.2,54.4c0,0.1-0.1,0.2,0,0.2c-0.2,0.1-0.2,0.3-0.2,0.5c-0.4,0.7-1,1-1.8,1c-0.8,0-1.4-0.4-1.7-1.1 c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.1,0-0.2c-0.1-0.7,0-1.3,0.5-1.8c0.3-0.3,0.6-0.4,0.9-0.6c0.1,0,0.1,0,0.2,0c0.2,0,0.5,0,0.7,0 c0.1,0,0.2,0.1,0.2,0c0.2,0.1,0.4,0.3,0.7,0.4c0.4,0.2,0.5,0.7,0.7,1c0,0.1,0,0.2,0,0.3C48.2,54,48.2,54.2,48.2,54.4z"
                        />
                        <path
                          className="st0"
                          d="M53.9,48c-0.1,0-0.2-0.1-0.2,0c-0.1-0.2-0.3-0.2-0.4-0.2c-0.5-0.3-0.7-0.7-1-1.2c0-0.1,0-0.2,0-0.2 c0-0.2,0-0.5,0-0.7c0-0.1,0-0.1,0-0.2c0.3-0.6,0.6-1.1,1.2-1.3c0.4-0.2,0.8-0.1,1.2-0.1c0.1,0,0.1,0,0.2,0c0.5,0.3,1,0.6,1.2,1.1 c0.5,1.1,0,2.2-1,2.7c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2,0-0.2,0C54.3,48,54.1,48,53.9,48z"
                        />
                        <path
                          className="st0"
                          d="M40.2,38c0,1.1-0.9,2-2,2c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2C39.2,36,40.2,36.9,40.2,38z"
                        />
                      </svg>
                    ) : null
                  }
                  <div
                    id={"modalReason" + this.props.groupAd._id}
                    className="modalReasonWA display-none"
                  >
                    <div>
                      <section className="modal-main">
                        <div className="card container">
                          <p style={{ fontSize: "18px" }}>{t("reason")}</p>
                          <p style={{ fontSize: "15px" }}>
                            {this.props.groupAd.reason}
                          </p>
                        </div>
                      </section>
                    </div>
                  </div>
                  <div className="group-info">
                    <div className="group-info-child">
                      <p>
                        <OverlayTrigger
                          delay={{ show: 500, hide: 100 }}
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip">
                              {t("wa.card.license")}
                            </Tooltip>
                          }
                        >
                          <svg
                            className="svg-icon-card-wa"
                            data-name="fas fa-file-signature"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 71.73 63.69"
                          >
                            <path
                              className="cls-1"
                              d="M4.06,59.89q0-2,0-3.93V52c0-1.34,0-2.67,0-4V44q0-2,0-3.93V36c0-1.33,0-2.67,0-4V28.09c0-1.33,0-2.67,0-4v-4c0-1.34,0-2.67,0-4V12.16a11.6,11.6,0,0,1,.12-2A3.14,3.14,0,0,1,5.93,8.36a3.49,3.49,0,0,1,1.36-.21H31.92c0,1.11,0,2.22,0,3.33a1.74,1.74,0,0,0,0,.68v3.92a.65.65,0,0,0-.05.39c0,1,0,2,0,3a1.58,1.58,0,0,0,0,.65v4c-.36,2.67,1.36,4.29,3.94,3.94h4a.75.75,0,0,0,.39,0h2.91a2,2,0,0,0,.72,0h3.92a.78.78,0,0,0,.4,0h3.63c0,1.32,0,2.64,0,4l0,1.87L49.74,36c-.66.58-1.27,1.2-1.87,1.84-.76.7-1.48,1.43-2.18,2.17-.61.56-1.2,1.14-1.76,1.74-.76.71-1.48,1.44-2.2,2.18l-1.81,1.81A31.12,31.12,0,0,0,37.68,48L36,49.55a.41.41,0,0,0-.11.3c0,.7,0,1.4,0,2.1,0,1.34,0,2.67,0,4,0,1.17,0,2.34,0,3.51,0,.31-.09.41-.38.35a2.26,2.26,0,0,1-1-1.08,2.73,2.73,0,0,0-.4-.56A4.67,4.67,0,0,0,32,56.68a5.28,5.28,0,0,0-4-.11,3.27,3.27,0,0,0-1.16.59c-.19.14-.28.13-.35-.11A6.17,6.17,0,0,0,26,55.87a6.69,6.69,0,0,0-.38-1.25c-.31-.89-.55-1.81-.93-2.67a2,2,0,0,0-.74-1,2.92,2.92,0,0,0-4,0,1.8,1.8,0,0,0-.67.93c-.56,1.33-.95,2.72-1.39,4.09l-1,2.92a1.37,1.37,0,0,1-1.43,1c-.48,0-1,0-1.45,0a2,2,0,0,0-2,1.76,2,2,0,0,0,1.61,2.18,9.55,9.55,0,0,0,2.39,0A5.21,5.21,0,0,0,20,61.57a4.76,4.76,0,0,0,.79-1.69L22,56.29l1.23,3.61A15.77,15.77,0,0,0,24,62a2,2,0,0,0,2.17,1.86A2.12,2.12,0,0,0,28,62.25c.3-.43.47-.93.73-1.37a1.37,1.37,0,0,1,2.46,0A5.38,5.38,0,0,0,32,62a5.21,5.21,0,0,0,3.94,1.85c1.34,0,2.67,0,4,0h7.94l4,0c0,.14,0,.29,0,.43,0,1.19,0,2.38,0,3.57a13.51,13.51,0,0,1-.09,1.68,3,3,0,0,1-2.32,2.21,3.12,3.12,0,0,1-.68,0H7.25a4.1,4.1,0,0,1-1-.08c-.21-.1-.42-.18-.62-.29a3.25,3.25,0,0,1-1.42-1.68,14.86,14.86,0,0,1-.1-1.89c0-1.33,0-2.67,0-4Z"
                              transform="translate(-4.06 -8.14)"
                            />
                            <path
                              className="cls-1"
                              d="M35.94,8.16a3.46,3.46,0,0,1,2.64.65c.32.28.6.59.91.89l.37.34c.68.73,1.38,1.44,2.11,2.11s1.28,1.34,2,2,1.28,1.34,2,2l2,2,2,2c.33.34.65.69,1,1a3.46,3.46,0,0,1,.91,1.54,5.86,5.86,0,0,1,.06,1.45H35.93c0-1.33,0-2.66,0-4V12.15Z"
                              transform="translate(-4.06 -8.14)"
                            />
                            <path
                              className="cls-1"
                              d="M74,28.09c.36.37.74.74,1.07,1.13a3.63,3.63,0,0,1,.66,1.28,3.59,3.59,0,0,1-1.23,3.33c-.76.69-1.46,1.46-2.18,2.19a2.68,2.68,0,0,0-.51.5c-.25.34-.48.27-.71,0a5.55,5.55,0,0,0-.49-.48l-2.79-2.78c-.41-.41-.82-.81-1.22-1.22-.91-.95-1.84-1.87-2.79-2.78a5.55,5.55,0,0,0-.48-.49c-.27-.21-.32-.41-.05-.65a2.56,2.56,0,0,0,.52-.52c.78-.77,1.59-1.52,2.34-2.32a3.36,3.36,0,0,1,3.27-1.1,3.84,3.84,0,0,1,1.8,1.18c.19.2.4.38.61.57C72.54,26.66,73.27,27.38,74,28.09Z"
                              transform="translate(-4.06 -8.14)"
                            />
                            <path
                              className="cls-1"
                              d="M68.33,40a2.68,2.68,0,0,0-.5.51Q66.09,42.21,64.42,44a3,3,0,0,0-.59.6c-1.17,1.09-2.26,2.25-3.38,3.39l-.57.57c-1.16,1.13-2.3,2.27-3.42,3.44a3.24,3.24,0,0,0-.58.59c-1.16,1.12-2.3,2.25-3.41,3.42a3.25,3.25,0,0,0-.61.62c-1.12,1.07-2.2,2.19-3.28,3.3l-.71,0c-1.31,0-2.63,0-3.94,0h-4V51.3L43.29,48a5,5,0,0,0,.65-.65c1.15-1.09,2.26-2.21,3.37-3.35l.57-.56c1.17-1.09,2.29-2.23,3.4-3.38a3.3,3.3,0,0,0,.59-.59C53,38.32,54.18,37.18,55.31,36a3.47,3.47,0,0,0,.58-.57c1.17-1.12,2.31-2.26,3.44-3.42l.57-.57c.2-.25.37-.24.58,0s.43.42.64.63c.85.89,1.73,1.76,2.62,2.61a18.23,18.23,0,0,0,1.39,1.39c.85.89,1.72,1.77,2.62,2.62l.54.56C68.65,39.64,68.65,39.64,68.33,40Z"
                              transform="translate(-4.06 -8.14)"
                            />
                          </svg>
                        </OverlayTrigger>
                        {this.props.groupAd.licenseCode ? (
                          <>{this.props.groupAd.licenseCode}</>
                        ) : (
                          <>{"-"}</>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="websites">
                    <div className="group-info-child">
                      <p>
                        <OverlayTrigger
                          delay={{ show: 500, hide: 100 }}
                          placement="bottom"
                          overlay={<Tooltip id="tooltip">Websites</Tooltip>}
                        >
                          <svg
                            className="svg-icon-card-wa"
                            data-name="fas fa-globe"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 61.91 61.88"
                          >
                            <path
                              className="cls-1"
                              d="M44,10.93l1.18,1.23c.1.14.18.28.28.42a20.51,20.51,0,0,1,2.29,4c.53,1.16,1,2.37,1.4,3.58l1.13,4c.16.82.33,1.64.47,2.46A7.26,7.26,0,0,1,51,28.11H48a6.69,6.69,0,0,0-1.34,0c-.87,0-1.74-.05-2.61,0H40a7.39,7.39,0,0,0-1.41,0c-.87,0-1.74-.05-2.61,0H32.05a.9.9,0,0,0-.43,0l-2.65,0c.06-.37.12-.74.19-1.11.18-.94.37-1.89.55-2.83l1.13-4c.41-1.08.81-2.17,1.22-3.25a3,3,0,0,0,.36-.77,24.33,24.33,0,0,1,2.39-3.94l1.26-1.3A6.38,6.38,0,0,1,40,9.12a5.86,5.86,0,0,1,2.48.71A9.9,9.9,0,0,1,44,10.93Z"
                              transform="translate(-9.04 -9.12)"
                            />
                            <path
                              className="cls-1"
                              d="M52,11.55l1.55.7L56,13.61a30.55,30.55,0,0,1,6.35,5.08,33,33,0,0,1,4.2,5.44l1.41,2.58L68.5,28c-.15.19-.38,0-.55.13h-4a19.64,19.64,0,0,0-2.17,0,11.76,11.76,0,0,0-1.78,0H56l-1-.07c0-.2,0-.39-.08-.58-.22-1.12-.37-2.25-.66-3.35a36.26,36.26,0,0,0-1-4A27.33,27.33,0,0,0,52,16.54l-.18-.45a23.91,23.91,0,0,0-1.94-3.93c-.28-.57-.69-1.08-1-1.68A31.54,31.54,0,0,1,52,11.55Z"
                              transform="translate(-9.04 -9.12)"
                            />
                            <path
                              className="cls-1"
                              d="M28,11.55a27.16,27.16,0,0,1,3.08-1.07,16.45,16.45,0,0,0-1,1.59,22.62,22.62,0,0,0-2,4,.62.62,0,0,0-.11.32,25.15,25.15,0,0,0-1.28,3.69,2.43,2.43,0,0,0-.13.38,30.07,30.07,0,0,0-.9,3.63c-.2.67-.3,1.36-.44,2.05a16,16,0,0,1-.39,1.95H24a6.66,6.66,0,0,0-1.34,0c-.89,0-1.79-.05-2.68,0H16.07a21.11,21.11,0,0,0-2.25,0,11.78,11.78,0,0,0-1.78,0h-.48c-.1-.14,0-.26.06-.37a9.53,9.53,0,0,0,.44-1l1.41-2.58a3.28,3.28,0,0,0,.2-.31,31,31,0,0,1,5.85-6.92A33.29,33.29,0,0,1,24,13.59l2.58-1.42A15.05,15.05,0,0,0,28,11.55Z"
                              transform="translate(-9.04 -9.12)"
                            />
                            <path
                              className="cls-1"
                              d="M44,69.25A6.35,6.35,0,0,1,40,71a6.43,6.43,0,0,1-4-1.81c-.38-.39-.76-.79-1.13-1.19a23.48,23.48,0,0,1-2.49-4.1,1.52,1.52,0,0,0-.23-.5c-.47-1.12-.89-2.27-1.31-3.41l-1.09-3.93c0-.12,0-.24-.06-.36-.27-1.21-.5-2.43-.7-3.65L32,52c1.32,0,2.63,0,4,0h4c1.34,0,2.68,0,4,0H48L51,52c0,.22-.07.45-.12.67-.21,1.11-.42,2.22-.64,3.33L49.17,60a26.9,26.9,0,0,1-4,8Z"
                              transform="translate(-9.04 -9.12)"
                            />
                            <path
                              className="cls-1"
                              d="M28,68.58a12.63,12.63,0,0,0-1.45-.66L24,66.54l-.71-.45a31.91,31.91,0,0,1-9.4-9.4,2.29,2.29,0,0,0-.45-.65c-.27-.49-.51-1-.81-1.47a6,6,0,0,0-.6-1.08A7.53,7.53,0,0,0,11.48,52l.57,0h4c1.32,0,2.63,0,4,0h4L25,52c.22,1.34.44,2.68.76,4a35.55,35.55,0,0,0,1,3.94c.08.25.14.51.22.76a21.14,21.14,0,0,0,1.09,3,.45.45,0,0,0,.1.28,25.13,25.13,0,0,0,2,4l1,1.59a.69.69,0,0,1-.42-.08Z"
                              transform="translate(-9.04 -9.12)"
                            />
                            <path
                              className="cls-1"
                              d="M52,68.59l-2.69.94c-.13,0-.25.13-.39.07h0c.31-.51.69-1,1-1.52a23.56,23.56,0,0,0,2-4.09,1.14,1.14,0,0,0,.17-.43A26.44,26.44,0,0,0,53.29,60a31.62,31.62,0,0,0,1-3.94c.34-1.32.53-2.67.75-4L56,52q2,0,4,0h3.94c1.33,0,2.67,0,4,0l.59,0L68,53.41,66.5,56.05a1.34,1.34,0,0,0-.11.14,30.51,30.51,0,0,1-7.49,8.32c-1,.75-2,1.44-3,2.09L53.26,68Z"
                              transform="translate(-9.04 -9.12)"
                            />
                            <path
                              className="cls-1"
                              d="M36,48H32l-3.61,0c-.07-.77-.12-1.54-.2-2.31a13,13,0,0,0-.12-1.4v-.22l-.1-4c0-.29,0-.58,0-.87,0-2.39.16-4.76.4-7.14H51.52a30.74,30.74,0,0,1,.3,3.33c.07,1.56.11,3.12.16,4.68,0,1.13,0,2.26-.09,3.38-.07,1.53-.16,3-.36,4.57H36Z"
                              transform="translate(-9.04 -9.12)"
                            />
                            <path
                              className="cls-1"
                              d="M56,40.08c0-1.34,0-2.67-.12-4s-.17-2.67-.29-4H68l1.92,0a31.42,31.42,0,0,1,1.08,8A34.62,34.62,0,0,1,70.67,44a36.54,36.54,0,0,1-.79,4L68,48H56l-.42,0c.12-1.33.27-2.66.29-4S56,41.4,56,40.08Z"
                              transform="translate(-9.04 -9.12)"
                            />
                            <path
                              className="cls-1"
                              d="M24.15,36.07c-.1.8-.06,1.61-.11,2.42,0,.53,0,1.06,0,1.59s0,1.07,0,1.6c.05.78,0,1.56.11,2.34a2.8,2.8,0,0,0,0,.29L24.39,48H12.05l-1.93,0c-.37-1.31-.57-2.66-.83-4-.1-1.31-.24-2.61-.25-3.93s.15-2.67.26-4,.46-2.68.82-4l1.93,0H24.44C24.32,33.4,24.17,34.73,24.15,36.07Z"
                              transform="translate(-9.04 -9.12)"
                            />
                          </svg>
                        </OverlayTrigger>
                      </p>
                    </div>
                    <div className="card-websites">
                      {this.props.groupAd.childrenWebsite &&
                        this.props.groupAd.childrenWebsite.map((value) => {
                          return (
                            <div key={this.props.groupAd.id + value}>
                              <OverlayTrigger
                                delay={{ show: 500, hide: 100 }}
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip">
                                    {value.toLowerCase()}
                                  </Tooltip>
                                }
                                key={this.props.groupAd.id + value}
                              >
                                <img
                                  key={this.props.groupAd.id + value}
                                  className="ad-platform-logo"
                                  src={"/logos/" + value.toLowerCase() + ".png"}
                                  alt={value}
                                />
                              </OverlayTrigger>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  {this.props.mapListStore.complianceListGroup[this.props.index] ? 
                  <div className="compliance-semaphor">
                    {this.props.mapListStore.complianceListGroup[this.props.index]["exc"] > 0 ? 
                    <div>
                      <svg>
                        <OverlayTrigger
                          delay={{ show: 500, hide: 100 }}
                          placement="bottom"
                          overlay={<Tooltip id="tooltip">{t("compliance.exc")}</Tooltip>}
                        >
                          <circle r="30%" cx="40%" cy="43%" fill="#00FF00" stroke="#0d6d0d" strokeWidth="1.5" />
                        </OverlayTrigger>
                      </svg> 
                      {this.props.mapListStore.complianceListGroup[this.props.index]["exc"]}
                    </div>:null}
                    {this.props.mapListStore.complianceListGroup[this.props.index]["hig"] > 0 ? 
                    <div>
                      <svg>
                        <OverlayTrigger
                          delay={{ show: 500, hide: 100 }}
                          placement="bottom"
                          overlay={<Tooltip id="tooltip">{t("compliance.hig")}</Tooltip>}
                        >
                        <circle r="30%" cx="40%" cy="43%" fill="#FFFF00" stroke="#6d6d0d" strokeWidth="1.5" />
                        </OverlayTrigger>
                        </svg>
                       {this.props.mapListStore.complianceListGroup[this.props.index]["hig"]}
                       </div>:null}
                    {this.props.mapListStore.complianceListGroup[this.props.index]["mid"] > 0 ? 
                    <div>
                      <svg>
                      <OverlayTrigger
                          delay={{ show: 500, hide: 100 }}
                          placement="bottom"
                          overlay={<Tooltip id="tooltip">{t("compliance.mid")}</Tooltip>}
                        >
                        <circle r="30%" cx="40%" cy="43%" fill="#FFA500" stroke="#916619" strokeWidth="1.5" />
                        </OverlayTrigger>
                        </svg>
                       {this.props.mapListStore.complianceListGroup[this.props.index]["mid"]}
                       </div>:null}
                    {this.props.mapListStore.complianceListGroup[this.props.index]["low"] > 0 ? 
                    <div>
                      <svg>
                      <OverlayTrigger
                          delay={{ show: 500, hide: 100 }}
                          placement="bottom"
                          overlay={<Tooltip id="tooltip">{t("compliance.low")}</Tooltip>}
                        >
                      <circle r="30%" cx="40%" cy="43%" fill="#FF0000" stroke="#6d0d0d" strokeWidth="1.5" />
                      </OverlayTrigger>
                    </svg> 
                    {this.props.mapListStore.complianceListGroup[this.props.index]["low"]}
                    </div>:null}
                    {this.props.mapListStore.complianceListGroup[this.props.index]["def"] > 0 ? 
                    <div>
                      <svg>
                      <OverlayTrigger
                          delay={{ show: 500, hide: 100 }}
                          placement="bottom"
                          overlay={<Tooltip id="tooltip">{t("compliance.def")}</Tooltip>}
                        >
                      <circle r="30%" cx="40%" cy="43%" fill="#900C3F" stroke="#3d0c0c" strokeWidth="1.5" />
                      </OverlayTrigger>
                    </svg> 
                    {this.props.mapListStore.complianceListGroup[this.props.index]["def"]}
                    </div>:null}
                  </div>
                  :null}
                </div>
              </div>
              <div
                className={
                  this.props.mapListStore.listMode
                    ? "col-md-1 card-buttons-grid"
                    : "col-md-2 card-buttons-list"
                }
                id={this.props.groupAd._id + "-card-btns"}
              >
                <div className="row">
                  <MyButton
                    id={this.props.groupAd._id + "-btn-fav"}
                    type="button"
                    name={t("wa.tooltips.addFav")}
                    onClick={async () => {
                      await this.updateUser(this.props.groupAd._id);

                      // this.props.mapListStore.changeStyle(
                      //   this.props.groupAd._id,
                      //   "fav"
                      // );
                      this.props.groupAd.childrenId.forEach((child) => {
                        this.props.mapListStore.changeStyle(
                          child,
                          "fav",
                          this.props.groupAd._id
                        );
                      });
                      await this.props.mapListStore.updateMarkerAndCard(
                        this.props.groupAd._id
                      );
                      if (this.props.mapListStore.favFilter) {
                        if (
                          this.props.mapListStore.focusedRegion.hasSubregions
                        ) {
                          await this.props.mapListStore.getChildRegionsData();
                        } else {
                          await this.props.mapListStore.insideMyAssignedRegions();
                          await this.props.mapListStore.getAllApartmentsAndCardsOfApartmentsInBoundaries();
                        }

                        await this.props.mapListStore.printAds();
                        let page = this.props.pageStore.page;
                        this.props.mapListStore.restartDropdown();
                        this.props.pageStore.updateProperty("page", page);
                      }
                      let info = {
                        code: this.props.groupAd._id,
                        favAds: JSON.parse(
                          JSON.stringify(this.props.mapListStore.user.favAds)
                        ),
                      };
                      await channelFav.postMessage(info);
                    }}
                    className="btn btn-default"
                    text={
                      this.props.mapListStore.user &&
                      this.props.mapListStore.user.favAds &&
                      this.props.mapListStore.user.favAds.includes(
                        this.props.groupAd._id
                      ) ? (
                        <svg
                          className="favActive"
                          data-name="fas fa-star"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 66.62 63.69"
                        >
                          <path
                            d="M51.87,69.13c-.78-.45-1.57-.86-2.38-1.25A16.65,16.65,0,0,0,47.87,67c-1.3-.71-2.61-1.41-3.93-2.06-.72-.41-1.45-.79-2.19-1.15s-1.19-.67-1.82-.94a14.72,14.72,0,0,0-1.76.92c-.77.36-1.52.75-2.25,1.18-1.33.65-2.63,1.35-3.93,2a15.78,15.78,0,0,0-1.47.77c-.89.44-1.77.89-2.63,1.39-1.32.65-2.63,1.33-3.92,2a11.05,11.05,0,0,1-1.23.52,4,4,0,0,1-4.54-2.17,3.68,3.68,0,0,1-.42-1.69c0-.28.07-.55.12-.82.17-1.06.35-2.12.53-3.18l.12-.61c.18-1.13.37-2.26.55-3.39.28-1.3.48-2.62.7-3.94a10,10,0,0,0,.25-1.74s.06-.07.07-.11c.12-.72.23-1.43.35-2.15.17-.9.33-1.81.5-2.72a.26.26,0,0,0-.1-.25L20,48.14l-.2-.2c-1.23-1.23-2.47-2.45-3.74-3.64a2.24,2.24,0,0,0-.44-.43c-1.16-1.19-2.37-2.33-3.57-3.48a1.44,1.44,0,0,0-.38-.36C10.46,38.84,9.27,37.65,8,36.51L7.56,36a4.09,4.09,0,0,1-.71-4,4.53,4.53,0,0,1,1.26-1.73,4.46,4.46,0,0,1,2.06-.82L12,29.18c1.34-.16,2.67-.36,4-.59,1.11-.12,2.2-.28,3.3-.48A1.78,1.78,0,0,0,20,28l1.18-.14L24,27.43c1.34-.16,2.68-.35,4-.58a.28.28,0,0,0,.32-.17c.4-.84.81-1.68,1.21-2.51.71-1.35,1.37-2.72,2-4.09A8.26,8.26,0,0,0,32.06,19c.53-.95,1-1.93,1.45-2.92l1.94-3.92L36,11a6.53,6.53,0,0,1,1.12-1.72A3.92,3.92,0,0,1,40.55,8.2a1.06,1.06,0,0,0,.25,0,4.12,4.12,0,0,1,2.34,1.55c.29.4.46.88.73,1.29a9.47,9.47,0,0,0,.54,1.11c.63,1.35,1.3,2.68,2,4,.45,1,1,2,1.46,3l.51,1,1.92,3.92c.42.85.84,1.7,1.25,2.56.07.14.16.23.33.2,1.34.23,2.67.42,4,.58.37.06.74.13,1.11.18l2.9.4a4.33,4.33,0,0,0,1,.15c1,.19,1.93.33,2.91.43l.64.12,3.37.47.39.07a7,7,0,0,1,3.61,1.1A4.77,4.77,0,0,1,73,32a4,4,0,0,1-.72,4l-.45.49c-1.23,1.13-2.42,2.31-3.6,3.5a1.48,1.48,0,0,0-.4.39c-1.24,1.16-2.45,2.35-3.64,3.55a1.27,1.27,0,0,0-.37.36c-1.27,1.19-2.51,2.4-3.74,3.63l-.2.2L59,49c-.06.06-.12.12-.1.22.17.92.34,1.83.5,2.75s.26,1.65.42,2.48L60.06,56c.22,1.31.41,2.62.69,3.92s.43,2.67.68,4l.64,4c0,.24,0,.48-.1.72a4,4,0,0,1-5.09,3,7.35,7.35,0,0,1-1-.43C54.56,70.5,53.23,69.8,51.87,69.13Z"
                            transform="translate(-6.62 -8.15)"
                          />
                        </svg>
                      ) : (
                        <svg
                          className="favDisable"
                          data-name="fas fa-star"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 66.62 63.69"
                        >
                          <path
                            d="M51.87,69.13c-.78-.45-1.57-.86-2.38-1.25A16.65,16.65,0,0,0,47.87,67c-1.3-.71-2.61-1.41-3.93-2.06-.72-.41-1.45-.79-2.19-1.15s-1.19-.67-1.82-.94a14.72,14.72,0,0,0-1.76.92c-.77.36-1.52.75-2.25,1.18-1.33.65-2.63,1.35-3.93,2a15.78,15.78,0,0,0-1.47.77c-.89.44-1.77.89-2.63,1.39-1.32.65-2.63,1.33-3.92,2a11.05,11.05,0,0,1-1.23.52,4,4,0,0,1-4.54-2.17,3.68,3.68,0,0,1-.42-1.69c0-.28.07-.55.12-.82.17-1.06.35-2.12.53-3.18l.12-.61c.18-1.13.37-2.26.55-3.39.28-1.3.48-2.62.7-3.94a10,10,0,0,0,.25-1.74s.06-.07.07-.11c.12-.72.23-1.43.35-2.15.17-.9.33-1.81.5-2.72a.26.26,0,0,0-.1-.25L20,48.14l-.2-.2c-1.23-1.23-2.47-2.45-3.74-3.64a2.24,2.24,0,0,0-.44-.43c-1.16-1.19-2.37-2.33-3.57-3.48a1.44,1.44,0,0,0-.38-.36C10.46,38.84,9.27,37.65,8,36.51L7.56,36a4.09,4.09,0,0,1-.71-4,4.53,4.53,0,0,1,1.26-1.73,4.46,4.46,0,0,1,2.06-.82L12,29.18c1.34-.16,2.67-.36,4-.59,1.11-.12,2.2-.28,3.3-.48A1.78,1.78,0,0,0,20,28l1.18-.14L24,27.43c1.34-.16,2.68-.35,4-.58a.28.28,0,0,0,.32-.17c.4-.84.81-1.68,1.21-2.51.71-1.35,1.37-2.72,2-4.09A8.26,8.26,0,0,0,32.06,19c.53-.95,1-1.93,1.45-2.92l1.94-3.92L36,11a6.53,6.53,0,0,1,1.12-1.72A3.92,3.92,0,0,1,40.55,8.2a1.06,1.06,0,0,0,.25,0,4.12,4.12,0,0,1,2.34,1.55c.29.4.46.88.73,1.29a9.47,9.47,0,0,0,.54,1.11c.63,1.35,1.3,2.68,2,4,.45,1,1,2,1.46,3l.51,1,1.92,3.92c.42.85.84,1.7,1.25,2.56.07.14.16.23.33.2,1.34.23,2.67.42,4,.58.37.06.74.13,1.11.18l2.9.4a4.33,4.33,0,0,0,1,.15c1,.19,1.93.33,2.91.43l.64.12,3.37.47.39.07a7,7,0,0,1,3.61,1.1A4.77,4.77,0,0,1,73,32a4,4,0,0,1-.72,4l-.45.49c-1.23,1.13-2.42,2.31-3.6,3.5a1.48,1.48,0,0,0-.4.39c-1.24,1.16-2.45,2.35-3.64,3.55a1.27,1.27,0,0,0-.37.36c-1.27,1.19-2.51,2.4-3.74,3.63l-.2.2L59,49c-.06.06-.12.12-.1.22.17.92.34,1.83.5,2.75s.26,1.65.42,2.48L60.06,56c.22,1.31.41,2.62.69,3.92s.43,2.67.68,4l.64,4c0,.24,0,.48-.1.72a4,4,0,0,1-5.09,3,7.35,7.35,0,0,1-1-.43C54.56,70.5,53.23,69.8,51.87,69.13Z"
                            transform="translate(-6.62 -8.15)"
                          />
                        </svg>
                      )
                    }
                  />
                </div>
                <div className="row">
                  <MyButton
                    id={this.props.groupAd._id + "-btn-pin"}
                    type="button"
                    name={t("wa.tooltips.addAssigned")}
                    disabled={
                      this.props.mapListStore.found === true
                        ? this.props.userStore.getUser() &&
                          (this.props.groupAd.workingUser ===
                            this.props.userStore.getUser().username ||
                            this.props.groupAd.workingUser === null ||
                            this.props.groupAd.workingUser === undefined ||
                            this.props.groupAd.workingUser === "-")
                          ? "false"
                          : "true"
                        : "true"
                    }
                    style={
                      this.props.mapListStore.found === true
                        ? this.props.userStore.getUser() &&
                          (this.props.groupAd.workingUser ===
                            this.props.userStore.getUser().username ||
                            this.props.groupAd.workingUser === null ||
                            this.props.groupAd.workingUser === undefined ||
                            this.props.groupAd.workingUser === "-")
                          ? null
                          : { opacity: "0.5" }
                        : { opacity: "0.5" }
                    }
                    onClick={async () => {
                      await this.props.mapListStore.toggleWorking(
                        this.props.groupAd
                      );
                      if (
                        this.props.mapListStore.user.username ===
                          this.props.mapListStore.userWhoHasIt ||
                        this.props.mapListStore.userWhoHasIt === null
                      ) {
                        this.props.mapListStore.changeStyle(
                          this.props.groupAd._id,
                          "pin"
                        );
                      }
                      this.props.groupAd.childrenId.forEach((child) => {
                        this.props.mapListStore.changeStyle(child, "pin");
                      });
                      await this.props.mapListStore.updateMarkerAndCard(
                        this.props.groupAd._id
                      );
                      if (this.props.mapListStore.workingFilter) {
                        if (
                          this.props.mapListStore.focusedRegion.hasSubregions
                        ) {
                          await this.props.mapListStore.getChildRegionsData();
                        } else {
                          await this.props.mapListStore.insideMyAssignedRegions();
                          await this.props.mapListStore.getAllApartmentsAndCardsOfApartmentsInBoundaries();
                        }

                        await this.props.mapListStore.printAds();
                        let page = this.props.pageStore.page;
                        this.props.mapListStore.restartDropdown();
                        this.props.pageStore.updateProperty("page", page);
                      }
                      let info = {
                        id: this.props.groupAd._id,
                        user: this.props.mapListStore.userWhoHasIt,
                      };
                      await channelPin.postMessage(info);
                    }}
                    className="btn btn-default"
                    text={
                      this.props.mapListStore.found === true ? (
                        this.props.userStore.getUser() &&
                        this.props.groupAd.workingUser ===
                          this.props.userStore.getUser().username ? (
                          <svg
                            className="pinActive"
                            data-name="fas fa-thumbtack"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 47.88 63.79"
                          >
                            <path
                              d="M57.65,7.9a3.15,3.15,0,0,1,2.27,2.35c.08.53,0,1.07.06,1.6a11.63,11.63,0,0,0,0,1.77,19.6,19.6,0,0,0,0,2.17c0,.54,0,1.07-.06,1.61a5.63,5.63,0,0,1-.37.93,3.38,3.38,0,0,1-1.82,1.4,12.82,12.82,0,0,1-1.75.08c-1.3,0-2.61,0-3.91,0-.3,0-.37.08-.33.37.13,1.21.26,2.43.38,3.65.05.46.09.92.14,1.37.09.88.19,1.76.29,2.64.09,1.31.25,2.63.4,3.94.08.83.17,1.66.24,2.49a.44.44,0,0,0,.3.43A22.4,22.4,0,0,1,55.94,36a22.66,22.66,0,0,1,4,3.25,3,3,0,0,0,.44.5,16.28,16.28,0,0,1,2.5,3.93c.1.26.2.51.29.77a17.68,17.68,0,0,1,.64,2.58l.08.66a11.38,11.38,0,0,1-.06,1.75,3.26,3.26,0,0,1-1,1.56,4.13,4.13,0,0,1-1.45.69H48l-3.71,0c-.19,0-.25.06-.25.25,0,1.26,0,2.52,0,3.78v7.94A9.73,9.73,0,0,1,43.94,65l-1.32,2.69-1.54,3c-.42.84-.57.93-1.5.88a2,2,0,0,1-.64-.7c-.57-1.06-1-2.2-1.57-3.23L36,64.88V55.74c0-1.26,0-2.52,0-3.78,0-.19-.06-.25-.25-.25l-3.7,0H20a15.73,15.73,0,0,1-1.68-.07c-.28-.15-.58-.22-.85-.39a3.34,3.34,0,0,1-1.34-1.73,10,10,0,0,1-.07-1.82,1.12,1.12,0,0,0,.06-.44,15.16,15.16,0,0,1,.94-3.57l.35-.72a17.35,17.35,0,0,1,2.16-3.21l.44-.49c.61-.58,1.19-1.18,1.84-1.7a24.82,24.82,0,0,1,4.61-2.9c.17-.08.32-.13.34-.37.06-.86.16-1.71.24-2.57,0-.19.05-.38.07-.57l.33-3.36.42-4c.13-1.24.25-2.48.39-3.72,0-.22,0-.3-.27-.29H24a13.49,13.49,0,0,1-1.68-.07,3.37,3.37,0,0,1-1.63-1,9,9,0,0,1-.62-1.09L20,15.79V11.86L20.08,10a4.93,4.93,0,0,1,.85-1.33,3,3,0,0,1,2-.85c.44,0,.88,0,1.31,0H56.67C57,7.85,57.33,7.88,57.65,7.9Z"
                              transform="translate(-16.05 -7.84)"
                            />
                          </svg>
                        ) : this.props.groupAd.workingUser !== undefined &&
                          this.props.groupAd.workingUser !== null &&
                          this.props.groupAd.workingUser !== "-" ? (
                            <svg
                                      className="svg-icon-pin pinActiveFromAnother"
                                      data-name="fas fa-thumbtack"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 47.88 63.79"
                                    >
                                      <path
                                        className="cls-1"
                                        d="M57.65,7.9a3.15,3.15,0,0,1,2.27,2.35c.08.53,0,1.07.06,1.6a11.63,11.63,0,0,0,0,1.77,19.6,19.6,0,0,0,0,2.17c0,.54,0,1.07-.06,1.61a5.63,5.63,0,0,1-.37.93,3.38,3.38,0,0,1-1.82,1.4,12.82,12.82,0,0,1-1.75.08c-1.3,0-2.61,0-3.91,0-.3,0-.37.08-.33.37.13,1.21.26,2.43.38,3.65.05.46.09.92.14,1.37.09.88.19,1.76.29,2.64.09,1.31.25,2.63.4,3.94.08.83.17,1.66.24,2.49a.44.44,0,0,0,.3.43A22.4,22.4,0,0,1,55.94,36a22.66,22.66,0,0,1,4,3.25,3,3,0,0,0,.44.5,16.28,16.28,0,0,1,2.5,3.93c.1.26.2.51.29.77a17.68,17.68,0,0,1,.64,2.58l.08.66a11.38,11.38,0,0,1-.06,1.75,3.26,3.26,0,0,1-1,1.56,4.13,4.13,0,0,1-1.45.69H48l-3.71,0c-.19,0-.25.06-.25.25,0,1.26,0,2.52,0,3.78v7.94A9.73,9.73,0,0,1,43.94,65l-1.32,2.69-1.54,3c-.42.84-.57.93-1.5.88a2,2,0,0,1-.64-.7c-.57-1.06-1-2.2-1.57-3.23L36,64.88V55.74c0-1.26,0-2.52,0-3.78,0-.19-.06-.25-.25-.25l-3.7,0H20a15.73,15.73,0,0,1-1.68-.07c-.28-.15-.58-.22-.85-.39a3.34,3.34,0,0,1-1.34-1.73,10,10,0,0,1-.07-1.82,1.12,1.12,0,0,0,.06-.44,15.16,15.16,0,0,1,.94-3.57l.35-.72a17.35,17.35,0,0,1,2.16-3.21l.44-.49c.61-.58,1.19-1.18,1.84-1.7a24.82,24.82,0,0,1,4.61-2.9c.17-.08.32-.13.34-.37.06-.86.16-1.71.24-2.57,0-.19.05-.38.07-.57l.33-3.36.42-4c.13-1.24.25-2.48.39-3.72,0-.22,0-.3-.27-.29H24a13.49,13.49,0,0,1-1.68-.07,3.37,3.37,0,0,1-1.63-1,9,9,0,0,1-.62-1.09L20,15.79V11.86L20.08,10a4.93,4.93,0,0,1,.85-1.33,3,3,0,0,1,2-.85c.44,0,.88,0,1.31,0H56.67C57,7.85,57.33,7.88,57.65,7.9Z"
                                        transform="translate(-16.05 -7.84)"
                                      />
                                    </svg>
                          // <svg
                          //   className="pinActiveFromAnother"
                          //   data-name="fas fa-thumbtack"
                          //   xmlns="http://www.w3.org/2000/svg"
                          //   viewBox="0 0 47.88 63.79"
                          // >
                          //   <path
                          //     d="M57.65,7.9a3.15,3.15,0,0,1,2.27,2.35c.08.53,0,1.07.06,1.6a11.63,11.63,0,0,0,0,1.77,19.6,19.6,0,0,0,0,2.17c0,.54,0,1.07-.06,1.61a5.63,5.63,0,0,1-.37.93,3.38,3.38,0,0,1-1.82,1.4,12.82,12.82,0,0,1-1.75.08c-1.3,0-2.61,0-3.91,0-.3,0-.37.08-.33.37.13,1.21.26,2.43.38,3.65.05.46.09.92.14,1.37.09.88.19,1.76.29,2.64.09,1.31.25,2.63.4,3.94.08.83.17,1.66.24,2.49a.44.44,0,0,0,.3.43A22.4,22.4,0,0,1,55.94,36a22.66,22.66,0,0,1,4,3.25,3,3,0,0,0,.44.5,16.28,16.28,0,0,1,2.5,3.93c.1.26.2.51.29.77a17.68,17.68,0,0,1,.64,2.58l.08.66a11.38,11.38,0,0,1-.06,1.75,3.26,3.26,0,0,1-1,1.56,4.13,4.13,0,0,1-1.45.69H48l-3.71,0c-.19,0-.25.06-.25.25,0,1.26,0,2.52,0,3.78v7.94A9.73,9.73,0,0,1,43.94,65l-1.32,2.69-1.54,3c-.42.84-.57.93-1.5.88a2,2,0,0,1-.64-.7c-.57-1.06-1-2.2-1.57-3.23L36,64.88V55.74c0-1.26,0-2.52,0-3.78,0-.19-.06-.25-.25-.25l-3.7,0H20a15.73,15.73,0,0,1-1.68-.07c-.28-.15-.58-.22-.85-.39a3.34,3.34,0,0,1-1.34-1.73,10,10,0,0,1-.07-1.82,1.12,1.12,0,0,0,.06-.44,15.16,15.16,0,0,1,.94-3.57l.35-.72a17.35,17.35,0,0,1,2.16-3.21l.44-.49c.61-.58,1.19-1.18,1.84-1.7a24.82,24.82,0,0,1,4.61-2.9c.17-.08.32-.13.34-.37.06-.86.16-1.71.24-2.57,0-.19.05-.38.07-.57l.33-3.36.42-4c.13-1.24.25-2.48.39-3.72,0-.22,0-.3-.27-.29H24a13.49,13.49,0,0,1-1.68-.07,3.37,3.37,0,0,1-1.63-1,9,9,0,0,1-.62-1.09L20,15.79V11.86L20.08,10a4.93,4.93,0,0,1,.85-1.33,3,3,0,0,1,2-.85c.44,0,.88,0,1.31,0H56.67C57,7.85,57.33,7.88,57.65,7.9Z"
                          //     transform="translate(-16.05 -7.84)"
                          //   />
                          // </svg>
                        ) : (
                          <svg
                            className="pinDisable"
                            data-name="fas fa-thumbtack"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 47.88 63.79"
                          >
                            <path
                              d="M57.65,7.9a3.15,3.15,0,0,1,2.27,2.35c.08.53,0,1.07.06,1.6a11.63,11.63,0,0,0,0,1.77,19.6,19.6,0,0,0,0,2.17c0,.54,0,1.07-.06,1.61a5.63,5.63,0,0,1-.37.93,3.38,3.38,0,0,1-1.82,1.4,12.82,12.82,0,0,1-1.75.08c-1.3,0-2.61,0-3.91,0-.3,0-.37.08-.33.37.13,1.21.26,2.43.38,3.65.05.46.09.92.14,1.37.09.88.19,1.76.29,2.64.09,1.31.25,2.63.4,3.94.08.83.17,1.66.24,2.49a.44.44,0,0,0,.3.43A22.4,22.4,0,0,1,55.94,36a22.66,22.66,0,0,1,4,3.25,3,3,0,0,0,.44.5,16.28,16.28,0,0,1,2.5,3.93c.1.26.2.51.29.77a17.68,17.68,0,0,1,.64,2.58l.08.66a11.38,11.38,0,0,1-.06,1.75,3.26,3.26,0,0,1-1,1.56,4.13,4.13,0,0,1-1.45.69H48l-3.71,0c-.19,0-.25.06-.25.25,0,1.26,0,2.52,0,3.78v7.94A9.73,9.73,0,0,1,43.94,65l-1.32,2.69-1.54,3c-.42.84-.57.93-1.5.88a2,2,0,0,1-.64-.7c-.57-1.06-1-2.2-1.57-3.23L36,64.88V55.74c0-1.26,0-2.52,0-3.78,0-.19-.06-.25-.25-.25l-3.7,0H20a15.73,15.73,0,0,1-1.68-.07c-.28-.15-.58-.22-.85-.39a3.34,3.34,0,0,1-1.34-1.73,10,10,0,0,1-.07-1.82,1.12,1.12,0,0,0,.06-.44,15.16,15.16,0,0,1,.94-3.57l.35-.72a17.35,17.35,0,0,1,2.16-3.21l.44-.49c.61-.58,1.19-1.18,1.84-1.7a24.82,24.82,0,0,1,4.61-2.9c.17-.08.32-.13.34-.37.06-.86.16-1.71.24-2.57,0-.19.05-.38.07-.57l.33-3.36.42-4c.13-1.24.25-2.48.39-3.72,0-.22,0-.3-.27-.29H24a13.49,13.49,0,0,1-1.68-.07,3.37,3.37,0,0,1-1.63-1,9,9,0,0,1-.62-1.09L20,15.79V11.86L20.08,10a4.93,4.93,0,0,1,.85-1.33,3,3,0,0,1,2-.85c.44,0,.88,0,1.31,0H56.67C57,7.85,57.33,7.88,57.65,7.9Z"
                              transform="translate(-16.05 -7.84)"
                            />
                          </svg>
                        )
                      ) : (
                        <svg
                            className="pinDisable"
                            data-name="fas fa-thumbtack"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 47.88 63.79"
                          >
                            <path
                              d="M57.65,7.9a3.15,3.15,0,0,1,2.27,2.35c.08.53,0,1.07.06,1.6a11.63,11.63,0,0,0,0,1.77,19.6,19.6,0,0,0,0,2.17c0,.54,0,1.07-.06,1.61a5.63,5.63,0,0,1-.37.93,3.38,3.38,0,0,1-1.82,1.4,12.82,12.82,0,0,1-1.75.08c-1.3,0-2.61,0-3.91,0-.3,0-.37.08-.33.37.13,1.21.26,2.43.38,3.65.05.46.09.92.14,1.37.09.88.19,1.76.29,2.64.09,1.31.25,2.63.4,3.94.08.83.17,1.66.24,2.49a.44.44,0,0,0,.3.43A22.4,22.4,0,0,1,55.94,36a22.66,22.66,0,0,1,4,3.25,3,3,0,0,0,.44.5,16.28,16.28,0,0,1,2.5,3.93c.1.26.2.51.29.77a17.68,17.68,0,0,1,.64,2.58l.08.66a11.38,11.38,0,0,1-.06,1.75,3.26,3.26,0,0,1-1,1.56,4.13,4.13,0,0,1-1.45.69H48l-3.71,0c-.19,0-.25.06-.25.25,0,1.26,0,2.52,0,3.78v7.94A9.73,9.73,0,0,1,43.94,65l-1.32,2.69-1.54,3c-.42.84-.57.93-1.5.88a2,2,0,0,1-.64-.7c-.57-1.06-1-2.2-1.57-3.23L36,64.88V55.74c0-1.26,0-2.52,0-3.78,0-.19-.06-.25-.25-.25l-3.7,0H20a15.73,15.73,0,0,1-1.68-.07c-.28-.15-.58-.22-.85-.39a3.34,3.34,0,0,1-1.34-1.73,10,10,0,0,1-.07-1.82,1.12,1.12,0,0,0,.06-.44,15.16,15.16,0,0,1,.94-3.57l.35-.72a17.35,17.35,0,0,1,2.16-3.21l.44-.49c.61-.58,1.19-1.18,1.84-1.7a24.82,24.82,0,0,1,4.61-2.9c.17-.08.32-.13.34-.37.06-.86.16-1.71.24-2.57,0-.19.05-.38.07-.57l.33-3.36.42-4c.13-1.24.25-2.48.39-3.72,0-.22,0-.3-.27-.29H24a13.49,13.49,0,0,1-1.68-.07,3.37,3.37,0,0,1-1.63-1,9,9,0,0,1-.62-1.09L20,15.79V11.86L20.08,10a4.93,4.93,0,0,1,.85-1.33,3,3,0,0,1,2-.85c.44,0,.88,0,1.31,0H56.67C57,7.85,57.33,7.88,57.65,7.9Z"
                              transform="translate(-16.05 -7.84)"
                            />
                          </svg>
                      )
                    }
                  />
                </div>
                <div className="row">
                  <MyButton
                    id={this.props.groupAd._id + "-btn-fav"}
                    type="button"
                    name="Host contacted"
                    onClick={() => {
                      console.log("To do message");
                    }}
                    className="btn btn-default"
                    text={
                      <svg
                        className={
                          "svg-icon-message" +
                          (!!this.props.groupAd.ownerContacted
                            ? " messActive"
                            : " messDisable")
                        }
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 63.71 47.79"
                      >
                        <path
                          d="M8,43.71V35.78c0-1.12,0-2.25,0-3.37,0-.57.31-.92.77-.91a1.53,1.53,0,0,1,.65.29c.83.68,1.68,1.34,2.56,2s1.8,1.39,2.74,2a10.5,10.5,0,0,0,1.26.93q1.91,1.46,3.87,2.83a.75.75,0,0,0,.32.23c1.22.94,2.48,1.84,3.74,2.73l1.74,1.27,2.26,1.64c1.41,1.07,2.8,2.19,4.26,3.2a20.25,20.25,0,0,0,3.67,2.12,8.77,8.77,0,0,0,4,.94,9.45,9.45,0,0,0,4.11-1,21.06,21.06,0,0,0,3.83-2.26,7.59,7.59,0,0,0,1-.73l3-2.29c.8-.55,1.59-1.11,2.35-1.71.57-.37,1.11-.77,1.65-1.19,1.23-.87,2.45-1.74,3.64-2.66l.36-.25c1.32-.95,2.64-1.9,3.92-2.9A10.5,10.5,0,0,0,65,35.79c1-.67,1.91-1.38,2.83-2.11.82-.57,1.61-1.18,2.39-1.81l.1-.05a.82.82,0,0,1,1-.23.94.94,0,0,1,.4.94c0,1.09,0,2.17,0,3.25V55.71c-.06.83,0,1.66-.06,2.49a6,6,0,0,1-5.16,5.37c-.93.11-1.86,0-2.79.07H14.05a6.15,6.15,0,0,1-2.53-.54,6,6,0,0,1-3.17-3.46l-.26-1C8,57.65,8,56.67,8,55.71v-12Z"
                          transform="translate(-8 -15.85)"
                        />
                        <path
                          d="M59.78,15.85h3.93c.85.05,1.71,0,2.57.05a6,6,0,0,1,5.37,5.37c.07.86,0,1.72.06,2.58l-.07.95a3.51,3.51,0,0,1-1.5,2.08c-.42.29-.81.64-1.21,1l-1.31,1-3.77,2.86-.15.11q-2,1.41-3.93,2.9a16.67,16.67,0,0,0-1.44,1.06c-.87.59-1.72,1.21-2.56,1.86-1,.68-2,1.39-2.92,2.12a9.34,9.34,0,0,0-1.08.79l-.27.17c-2.59,1.8-5,3.86-7.65,5.55-.54.3-1.08.61-1.65.87s-1.06.35-1.59.55l-.76,0-1-.09a12.61,12.61,0,0,1-3-1.36l-1-.65c-1.72-1.14-3.28-2.48-5-3.67a20.11,20.11,0,0,0-1.95-1.37,7.47,7.47,0,0,0-1.16-.87c-.93-.72-1.87-1.4-2.83-2.06s-1.69-1.28-2.57-1.86a14.91,14.91,0,0,0-1.44-1.07L16,31.81a.05.05,0,0,0-.05,0l-.74-.57L12,28.8l-1.22-.95c-.41-.32-.8-.67-1.22-1A3.76,3.76,0,0,1,8.08,25L8,23.86c.06-.85,0-1.69.06-2.54a6,6,0,0,1,5.37-5.4c.86-.07,1.72,0,2.58-.06H59.78Z"
                          transform="translate(-8 -15.85)"
                        />
                      </svg>
                    }
                  />
                </div>
                <div className="row">
                  <MyButton
                    id={this.props.groupAd._id + "-btn-arrow"}
                    type="button"
                    onClick={() => {
                      this.props.mapListStore.toggleDropdown(
                        this.props.groupAd._id,
                        "arrow"
                      );
                      this.props.mapListStore.getAdsFromGroupAds(
                        this.props.groupAd._id,
                        this.props.index
                      );
                    }}
                    className="btn btn-default dropdown-toggle arrow"
                    data-toggle="child-div-content"
                    text={""}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row card-childs">
            <div
              id={this.props.groupAd._id + "-dropdown"}
              className="col-md-12 ad-dropdown-container div-content"
              style={{ display: "none" }}
            >
              <div className="child-div-content">
                {/*ZONA DEL BUCLE PER ALS ADS*/}
                {this.props.mapListStore.adList &&
                this.props.mapListStore.adList[this.props.index]
                  ? this.props.mapListStore.adList[this.props.index].map(
                      (ad, j) => {
                        return (
                          <Ad
                            ga={this.props.groupAd._id}
                            key={this.props.groupAd._id + this.props.index + j}
                            ad={ad}
                            index={j}
                          ></Ad>
                        );
                      }
                    )
                  : null}
              </div>
            </div>
          </div>
          <hr />
        </div>
      );
    }
  }
}

export default withTranslation()(GroupCard);
