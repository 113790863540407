import React, {Component} from 'react'
import {observer} from 'mobx-react'
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";


@observer
class LinkComponent extends Component {

  render () {
    return (
			<Link to={this.props.to} className="nav-link" href="#">
				{this.props.text}
			</Link>
    )
  }
}

LinkComponent.propTypes = {
  to: PropTypes.string.isRequired
}

LinkComponent.propTypes = {
  text: PropTypes.string.isRequired
}

export default LinkComponent;