import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { withTranslation } from "react-i18next";
// import PropTypes from "prop-types";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

@inject("genericListStore")
@observer
class MyTable extends Component {
  constructor(props) {
    super(props);
    this.onClickTh = this.onClickTh.bind(this);
    this.onClickTd = this.onClickTd.bind(this);
    this.navigateTo = this.navigateTo.bind(this);
    this.onMouseEnterTr = this.onMouseEnterTr.bind(this);
    this.onMouseLeaveTr = this.onMouseLeaveTr.bind(this);
    this.goToNewPage = this.goToNewPage.bind(this);
    this.onClickFocusAd = this.onClickFocusAd.bind(this);
  }

  findAncestor(el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
  }

  onClickTh(event, key) {
    if (event.target.id) this.props.onClickTh(event.target.id);
    else this.props.onClickTh(key);
  }

  onClickTd(event) {
    let parentTr = this.findAncestor(event.target, "reference-parent");
    this.props.onClickTd(parseInt(parentTr.id));
  }

  navigateTo(event) {
    let parentElem = this.findAncestor(event.target, "navigate-to-reference");
    this.props.navigateTo(parentElem.id);
  }

  onMouseEnterTr(ad) {
    this.props.genericListStore.printCircles(ad);
  }

  onMouseLeaveTr(ad) {
    this.props.genericListStore.removePrintCircles(ad);
  }

  onClickFocusAd(ad) {
    this.props.genericListStore.focusAd(ad);
  }

  goToNewPage(ad) {
    this.props.goToNewPage(ad.url);
  }

  parseLicenseStatus(licStat) {
    let state;
    switch (licStat) {
      case "noff":
        state = "Not official";
        break;
      case "offi":
        state = "Official";
        break;
      case "bfor":
        state = "Bad format";
        break;
      default:
        state = "No license";
    }
    return state;
  }

  calculateTam = () => {
    this.maxChars = 16;
    let tam = window.innerWidth;
    tam < 1750
      ? this.props.updateProperty("showWebsite", false)
      : this.props.updateProperty("showWebsite", true);
    tam < 1600
      ? this.props.updateProperty("showPrice", false)
      : this.props.updateProperty("showPrice", true);
    tam < 1750
      ? (this.maxChars = 16)
      : tam < 1820
      ? (this.maxChars = 16)
      : (this.maxChars = 20);
  };

  componentDidMount() {
    this.maxChars = 16;
    let tam = window.innerWidth;
    tam < 1750
      ? this.props.updateProperty("showWebsite", false)
      : this.props.updateProperty("showWebsite", true);
    tam < 1600
      ? this.props.updateProperty("showPrice", false)
      : this.props.updateProperty("showPrice", true);
    tam < 1750
      ? (this.maxChars = 16)
      : tam < 1820
      ? (this.maxChars = 16)
      : (this.maxChars = 20);

    window.addEventListener("resize", this.calculateTam);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.calculateTam);
  }

  render() {
    let {t} = this.props
    console.log(this.props)
    return (
      <table className="tableFixHead">
        <thead>
          <tr>
            <th style={{ width: "10%" }}>
              {/* <button className="ddw-arrow" disabled>
								<i className="fas fa-angle-down"></i>
							</button> */}
            </th>
            {this.props.fields.map((field) => (
              <th
                key={field.key}
                id={field.key}
                onClick={(e) => {
                  this.onClickTh(e, field.key);
                }}
                style={field.width}
              >
                {this.props.sortCriteria === field.key &&
                this.props.sortDirection === 1 ? (
                  <svg
                    className="fas fa-angle-up"
                    style={{width:"1rem", "marginRight":"1rem"}}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="currentColor"
                      d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"
                    ></path>
                  </svg>
                ) : this.props.sortCriteria === field.key &&
                  this.props.sortDirection === -1 ? (
                  <svg
                    className="fas fa-angle-down"
                    style={{width:"1rem", "marginRight":"1rem"}}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 40.11 26.16"
                  >
                    <path
                      className="cls-1"
                      fill="currentColor"
                      d="M27.34,28.77a2.08,2.08,0,0,0-3,0l-4,4s-1,1,0,3L38.42,53.74s1,1,3,0L59.36,35.67a2.07,2.07,0,0,0,0-3l-4-4a2.08,2.08,0,0,0-3,0l-12,12Z"
                      transform="translate(-19.91 -28.02)"
                    />
                  </svg>
                ) : null}
                {field.value}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="lists-div">
          {this.props.elems.map((elem, i) => (
            <React.Fragment key={"fragment_" + i}>
              <tr
                key={
                  elem.groupKey
                    ? elem.groupKey + "_1_" + i
                    : elem.id + "_1_" + i
                }
                id={i}
                onClick={
                  this.props.selectedList === "Ads"
                    ? () => this.onClickFocusAd(elem._id)
                    : null
                }
                className={
                  "reference-parent" +
                  (this.props.selectedElemToDropdown === i
                    ? " reference-parent-clicked"
                    : "")
                }
              >
                <td
                  key={"td" + i}
                  id={elem.groupCode ? elem.groupCode : elem.groupKey}
                  className="navigate-to-reference"
                  style={{ width: "10%" }}
                >
                  {this.props.selectedList === "Favourites" ? (
                    <OverlayTrigger
                      delay={{ show: 500, hide: 100 }}
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip">Vés al grup d'anuncis</Tooltip>
                      }
                    >
                      <button
                        onClick={(e) => {
                          this.navigateTo(e);
                        }}
                      >
                        <svg
                          className="svg-icon-lists"
                          data-name="fas fa-external-link-alt"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 64 64"
                        >
                          <path
                            className="cls-1"
                            d="M36,18a2,2,0,0,0-2-2H14s-6,0-6,6V66s0,6,6,6H58s6,0,6-6V50a2,2,0,0,0-2-2H58a2,2,0,0,0-2,2V64H16V24H34a2,2,0,0,0,2-2Z"
                            transform="translate(-8 -8)"
                          />
                          <path
                            className="cls-1"
                            d="M55,18,25,48a2.77,2.77,0,0,0,0,4l3,3a2.77,2.77,0,0,0,4,0L62,25l5,5a2.77,2.77,0,0,0,4,0,4.38,4.38,0,0,0,1-3V11a2.94,2.94,0,0,0-3-3H53a3.55,3.55,0,0,0-3,2c-1,2,0,3,0,3Z"
                            transform="translate(-8 -8)"
                          />
                        </svg>
                      </button>
                    </OverlayTrigger>
                  ) : null}

                  {this.props.selectedList === "Ads" ? (
                    <OverlayTrigger
                      delay={{ show: 500, hide: 100 }}
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip">Vés al grup d'anuncis</Tooltip>
                      }
                    >
                      <button
                        onClick={(e) => {
                          this.navigateTo(e);
                        }}
                      >
                        <svg
                          className="svg-icon-lists"
                          data-name="fas fa-external-link-alt"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 64 64"
                        >
                          <path
                            className="cls-1"
                            d="M36,18a2,2,0,0,0-2-2H14s-6,0-6,6V66s0,6,6,6H58s6,0,6-6V50a2,2,0,0,0-2-2H58a2,2,0,0,0-2,2V64H16V24H34a2,2,0,0,0,2-2Z"
                            transform="translate(-8 -8)"
                          />
                          <path
                            className="cls-1"
                            d="M55,18,25,48a2.77,2.77,0,0,0,0,4l3,3a2.77,2.77,0,0,0,4,0L62,25l5,5a2.77,2.77,0,0,0,4,0,4.38,4.38,0,0,0,1-3V11a2.94,2.94,0,0,0-3-3H53a3.55,3.55,0,0,0-3,2c-1,2,0,3,0,3Z"
                            transform="translate(-8 -8)"
                          />
                        </svg>
                      </button>
                    </OverlayTrigger>
                  ) : (
                    <button
                      onClick={(e) => {
                        this.onClickTd(e);
                      }}
                    >
                      <svg
                        className="svg-icon-lists-arrow"
                        data-name="fas fa-angle-down"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 40.11 26.16"
                      >
                        <path
                          className="cls-1"
                          d="M27.34,28.77a2.08,2.08,0,0,0-3,0l-4,4s-1,1,0,3L38.42,53.74s1,1,3,0L59.36,35.67a2.07,2.07,0,0,0,0-3l-4-4a2.08,2.08,0,0,0-3,0l-12,12Z"
                          transform="translate(-19.91 -28.02)"
                        />
                      </svg>
                    </button>
                  )}

                  {this.props.selectedList === "Ads" ? (
                    <button
                      className="goAd"
                      onClick={() => {
                        this.goToNewPage(elem);
                      }}
                    >
                      <OverlayTrigger
                        delay={{ show: 500, hide: 100 }}
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip">
                            {elem.website.toLowerCase()}
                          </Tooltip>
                        }
                      >
                        <img
                          className="ad-platform-logo"
                          src={"/logos/" + elem.website.toLowerCase() + ".png"}
                          alt={elem.website}
                        />
                      </OverlayTrigger>
                    </button>
                  ) : null}
                </td>
                {this.props.fields.map((field) => {
                  return (
                  <td
                    key={field.key + "_2_" + i}
                    onClick={
                      this.props.selectedList !== "Ads"
                        ? (e) => {
                            this.onClickTd(e);
                          }
                        : null
                    }
                  >
                    <table>
                      <tbody>
                        <tr>
                          {elem[field.key] ? (
                            field.isArray ? (
                              elem[field.key].length > 0 ? (
                                <td>
                                  {elem[field.key][0].length > 0
                                    ? elem[field.key][0].map((arrayElem, j) => {
                                        return (
                                          <span key={arrayElem + j}>
                                            {j !== 0 ? ", " : null}
                                            {field.key === "licensesStatus"
                                              ? this.parseLicenseStatus(
                                                  arrayElem
                                                )
                                              : arrayElem}
                                          </span>
                                        );
                                      })
                                    : "-"}
                                </td>
                              ) : (
                                <td>-</td>
                              )
                            ) : field.key === "licStatus" ? (
                              <td>
                                {this.parseLicenseStatus(elem[field.key])}
                              </td>
                            ) : field.key === "licCompliance" ? (
                              <td>
                                {
                                elem[field.key] === "exc" || elem[field.key] === "Correcto" ? <div className="compliance-array"><svg><circle r="30%" cx="50%" cy="43%" fill="#00FF00" stroke="#0d6d0d" strokeWidth="1.5"/></svg> {t("compliance.exc") }</div>:
                                elem[field.key] === "hig" || elem[field.key] === "Leve" ? <div className="compliance-array"><svg><circle r="30%" cx="50%" cy="43%" fill="#FFFF00" stroke="#6d6d0d" strokeWidth="1.5" /></svg> {t("compliance.hig")} </div>: 
                                elem[field.key] === "mid" ? <div className="compliance-array"><svg><circle r="30%" cx="50%" cy="43%" fill="#FFA500" stroke="#916619" strokeWidth="1.5"/></svg> {t("compliance.mid")}</div>: 
                                elem[field.key] === "low" || elem[field.key] === "Posible Fraude" ? <div className="compliance-array"><svg><circle r="30%" cx="50%" cy="43%" fill="#FF0000" stroke="#6d0d0d" strokeWidth="1.5" /></svg> {t("compliance.low")}</div> : 
                                elem[field.key] === "def" || elem[field.key] === "Fraude" ? <div className="compliance-array"><svg><circle r="30%" cx="50%" cy="43%" fill="#900C3F" stroke="#3d0c0c" strokeWidth="1.5"/></svg> {t("compliance.def")} </div> : "-"
                                }
                              </td>
                            ) : elem[field.key].length > this.maxChars ? (
                              <td key={field.key + "_" + i}>
                                {elem[field.key].substring(0, this.maxChars) +
                                  "..."}
                              </td>
                            ) : (
                              <td key={field.key + "_" + i}>
                                {elem[field.key]}
                              </td>
                            )
                          ) : (
                            <td>-</td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </td>
                )})}
              </tr>
              {this.props.selectedElemToDropdown === i &&
                this.props.elemsInsideDropdown != [] &&
                this.props.selectedList !== "Ads" && (
                  <>
                    <tr className="dropdown-thead thead-ads-child">
                      <td style={{ width: "10%" }} className="td-blanck"></td>
                      {this.props.dropdownFields.map((ddField) => {
                        return (
                          <th
                            key={ddField.key}
                            id={ddField.key}
                            style={ddField.width}
                          >
                            {ddField.value.length > this.maxChars ? (
                              <span>
                                {ddField.value.substring(0, this.maxChars)}...
                              </span>
                            ) : (
                              <span>{ddField.value}</span>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                    {this.props.elemsInsideDropdown.map((elemInDrop) => {
                      return (
                        <tr
                          key={elemInDrop["id"]}
                          onMouseEnter={() => {
                            this.onMouseEnterTr(elemInDrop);
                          }}
                          onMouseLeave={() => {
                            this.onMouseLeaveTr(elemInDrop);
                          }}
                          className="ads-child"
                        >
                          <td
                            id={elemInDrop.adGroupCode}
                            className="navigate-to-reference td-blanck"
                            style={{ width: "10%" }}
                          >
                            {this.props.selectedList !== "Favourites" ? (
                              <OverlayTrigger
                                delay={{ show: 500, hide: 100 }}
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip">
                                    Vés al grup d'anuncis
                                  </Tooltip>
                                }
                              >
                                <button
                                  className="ddw-arrow"
                                  onClick={(e) => {
                                    this.navigateTo(e);
                                  }}
                                >
                                  <svg
                                    className="svg-icon-lists"
                                    data-name="fas fa-external-link-alt"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 64 64"
                                  >
                                    <path
                                      className="cls-1"
                                      d="M36,18a2,2,0,0,0-2-2H14s-6,0-6,6V66s0,6,6,6H58s6,0,6-6V50a2,2,0,0,0-2-2H58a2,2,0,0,0-2,2V64H16V24H34a2,2,0,0,0,2-2Z"
                                      transform="translate(-8 -8)"
                                    />
                                    <path
                                      className="cls-1"
                                      d="M55,18,25,48a2.77,2.77,0,0,0,0,4l3,3a2.77,2.77,0,0,0,4,0L62,25l5,5a2.77,2.77,0,0,0,4,0,4.38,4.38,0,0,0,1-3V11a2.94,2.94,0,0,0-3-3H53a3.55,3.55,0,0,0-3,2c-1,2,0,3,0,3Z"
                                      transform="translate(-8 -8)"
                                    />
                                  </svg>
                                </button>
                              </OverlayTrigger>
                            ) : null}
                            <button
                              className="goAd"
                              onClick={() => {
                                this.goToNewPage(elemInDrop);
                              }}
                            >
                              <OverlayTrigger
                                delay={{ show: 500, hide: 100 }}
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip">
                                    {elemInDrop.website.toLowerCase()}
                                  </Tooltip>
                                }
                              >
                                <img
                                  className="ad-platform-logo"
                                  src={
                                    "/logos/" +
                                    elemInDrop.website.toLowerCase() +
                                    ".png"
                                  }
                                  alt={elemInDrop.website}
                                />
                              </OverlayTrigger>
                            </button>
                          </td>
                          {this.props.dropdownFields.map((ddField) => {
                            console.log(ddField)
                            return (
                            <td key={ddField.key + "_2_" + i}>
                              {elemInDrop[ddField.key] ? (
                                ddField.key === "license.status" ? (
                                  <span>
                                    {this.parseLicenseStatus(
                                      elemInDrop[ddField.key]
                                    )}
                                  </span>
                                ) : ddField.key === "license.compliance_status" ? (
                                  <span>
                                    {
                                    elemInDrop[ddField.key] === "exc" || elemInDrop[ddField.key] === "Correcto" ? <div className="compliance-array"><svg><circle r="30%" cx="50%" cy="43%" fill="#00FF00" stroke="#0d6d0d" strokeWidth="1.5"/></svg> {t("compliance.exc") }</div>:
                                    elemInDrop[ddField.key] === "hig" || elemInDrop[ddField.key] === "Leve" ? <div className="compliance-array"><svg><circle r="30%" cx="50%" cy="43%" fill="#FFFF00" stroke="#6d6d0d" strokeWidth="1.5" /></svg> {t("compliance.hig")} </div>: 
                                    elemInDrop[ddField.key] === "mid" ? <div className="compliance-array"><svg><circle r="30%" cx="50%" cy="43%" fill="#FFA500" stroke="#916619" strokeWidth="1.5"/></svg> {t("compliance.mid")}</div>: 
                                    elemInDrop[ddField.key] === "low" || elemInDrop[ddField.key] === "Posible Fraude" ? <div className="compliance-array"><svg><circle r="30%" cx="50%" cy="43%" fill="#FF0000" stroke="#6d0d0d" strokeWidth="1.5" /></svg> {t("compliance.low")}</div> : 
                                    elemInDrop[ddField.key] === "def" || elemInDrop[ddField.key] === "Fraude" ? <div className="compliance-array"><svg><circle r="30%" cx="50%" cy="43%" fill="#900C3F" stroke="#3d0c0c" strokeWidth="1.5"/></svg> {t("compliance.def")} </div> : "-"
                                    }
                                  </span>
                                ) : elemInDrop[ddField.key].length >
                                  this.maxChars ? (
                                  <span>
                                    {elemInDrop[ddField.key].substring(
                                      0,
                                      this.maxChars
                                    )}
                                    ...
                                  </span>
                                ) : (
                                  <span>{elemInDrop[ddField.key]}</span>
                                )
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                          )})}
                        </tr>
                      );
                    })}
                  </>
                )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    );
  }
}

MyTable.propTypes = {
  // text: PropTypes.any.isRequired,
};

MyTable.defaultProps = {
  // type: 'submit'
};

export default withTranslation()(MyTable);
