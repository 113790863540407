import { observable, action } from 'mobx'

class StatisticStore {

  constructor(rootStore) {
    this.rootStore = rootStore
    this.updateProperty = this.updateProperty.bind(this);
  }
  
  @observable pageSelected = "None";
  
  @action("update form property") updateFormProperty(key, value) {
		this.contactForm[key] = value;		
	}

	@action("update property not in form") updateProperty(key, value) {
		this[key] = value
	}

  @action("Initialize Store") initialiceStore() {
    this.pageSelected = "None";
  }

}
export default StatisticStore
