import { observable, action, computed } from "mobx";
import { asyncComputed } from "computed-async-mobx";
import i18n from "../../i18n";

class ActivityStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }
  @observable url = "";
  @observable visibleFilter = "";
  @observable logs = [];
  @observable startDayFilter = {
    fromOption: "-1",
    toOption: "-1",
    value: "0",
    url: "",
    selectOptions: [
      { key: "01", value: "1" },
      { key: "02", value: "2" },
      { key: "03", value: "3" },
      { key: "04", value: "4" },
      { key: "05", value: "5" },
      { key: "06", value: "6" },
      { key: "07", value: "7" },
      { key: "08", value: "8" },
      { key: "09", value: "9" },
      { key: "10", value: "10" },
      { key: "11", value: "11" },
      { key: "12", value: "12" },
      { key: "13", value: "13" },
      { key: "14", value: "14" },
      { key: "15", value: "15" },
      { key: "16", value: "16" },
      { key: "17", value: "17" },
      { key: "18", value: "18" },
      { key: "19", value: "19" },
      { key: "20", value: "20" },
      { key: "21", value: "21" },
      { key: "22", value: "22" },
      { key: "23", value: "23" },
      { key: "24", value: "24" },
      { key: "25", value: "25" },
      { key: "26", value: "26" },
      { key: "27", value: "27" },
      { key: "28", value: "28" },
      { key: "29", value: "29" },
      { key: "30", value: "30" },
      { key: "31", value: "31" },
    ],
  };
  @observable startMonthFilter = {
    fromOption: "-1",
    toOption: "-1",
    value: "0",
    url: "",
    selectOptions: [],
  };
  @observable startYearFilter = {
    fromOption: "-1",
    toOption: "-1",
    value: "0",
    url: "",
    selectOptions: [
      { key: "2018", value: "2018" },
      { key: "2019", value: "2019" },
      { key: "2020", value: "2020" },
    ],
  };
  @observable endDayFilter = {
    fromOption: "-1",
    toOption: "-1",
    value: "0",
    url: "",
    selectOptions: [
      { key: "01", value: "1" },
      { key: "02", value: "2" },
      { key: "03", value: "3" },
      { key: "04", value: "4" },
      { key: "05", value: "5" },
      { key: "06", value: "6" },
      { key: "07", value: "7" },
      { key: "08", value: "8" },
      { key: "09", value: "9" },
      { key: "10", value: "10" },
      { key: "11", value: "11" },
      { key: "12", value: "12" },
      { key: "13", value: "13" },
      { key: "14", value: "14" },
      { key: "15", value: "15" },
      { key: "16", value: "16" },
      { key: "17", value: "17" },
      { key: "18", value: "18" },
      { key: "19", value: "19" },
      { key: "20", value: "20" },
      { key: "21", value: "21" },
      { key: "22", value: "22" },
      { key: "23", value: "23" },
      { key: "24", value: "24" },
      { key: "25", value: "25" },
      { key: "26", value: "26" },
      { key: "27", value: "27" },
      { key: "28", value: "28" },
      { key: "29", value: "29" },
      { key: "30", value: "30" },
      { key: "31", value: "31" },
    ],
  };
  @observable endMonthFilter = {
    fromOption: "-1",
    toOption: "-1",
    value: "0",
    url: "",
    selectOptions: [],
  };
  @observable endYearFilter = {
    fromOption: "-1",
    toOption: "-1",
    value: "0",
    url: "",
    selectOptions: [
      { key: "2018", value: "2018" },
      { key: "2019", value: "2019" },
      { key: "2020", value: "2020" },
    ],
  };

  @observable usersFilters = {
    fromOption: "-1",
    toOption: "-1",
    value: "0",
    url: "",
    selectOptions: [],
  };

  @observable adFilter = "";
  @observable selectedSuggestion = false;

  @observable messageFilter = {
    fromOption: "-1",
    toOption: "-1",
    value: "0",
    url: "",
    selectOptions: [],
  };

  @observable trueFilter = {
    start: "",
    end: "",
    user: "",
    ad: "",
    message: "",
    page: "",
  };

  @observable pageFilter = {
    fromOption: "-1",
    toOption: "-1",
    value: "0",
    url: "",
    selectOptions: [
      { key: "", value: "Seleccioni una opció"},
      { key: "WA", value: "Working Area" },
      { key: "GA", value: "Group Ads" },
      { key: "Profile", value: "Profile" },
      { key: "Proces", value: "Process" },
      { key: "Desagrupar anuncis", value: "Desagrupar anuncis" },
      { key: "Transfer Ads", value: "Transferir anuncis" },
      { value: "Merge Ads", key: "Merge" },
      { value: "Contacte", key: "Contact" },
    ],
  } 

  @observable superuser = false;

  asyncSuggestions = asyncComputed([], 100, async () => {
    try {
      const inputValue = this.adFilter.trim().toLowerCase();
      const inputLength = inputValue.length;

      let res = [];
      if (inputLength > 0) {
        res = await this.rootStore.requestStore.searchAdsById(this.adFilter);
      }
      if (res.length > 1) this.updateProperty("selectedSuggestion", false);
      return res;
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  });

  @computed get suggestions() {
    return this.asyncSuggestions.get();
  }

  @action("Create options for filters") createOptions = () => {
    this.startMonthFilter.selectOptions = [
      { key: "01", value: i18n.t("months.january") },
      { key: "02", value: i18n.t("months.february") },
      { key: "03", value: i18n.t("months.march") },
      { key: "04", value: i18n.t("months.april") },
      { key: "05", value: i18n.t("months.may") },
      { key: "06", value: i18n.t("months.june") },
      { key: "07", value: i18n.t("months.july") },
      { key: "08", value: i18n.t("months.august") },
      { key: "09", value: i18n.t("months.september") },
      { key: "10", value: i18n.t("months.october") },
      { key: "11", value: i18n.t("months.november") },
      { key: "12", value: i18n.t("months.december") },
    ];

    this.endMonthFilter.selectOptions = [
      { key: "01", value: i18n.t("months.january") },
      { key: "02", value: i18n.t("months.february") },
      { key: "03", value: i18n.t("months.march") },
      { key: "04", value: i18n.t("months.april") },
      { key: "05", value: i18n.t("months.may") },
      { key: "06", value: i18n.t("months.june") },
      { key: "07", value: i18n.t("months.july") },
      { key: "08", value: i18n.t("months.august") },
      { key: "09", value: i18n.t("months.september") },
      { key: "10", value: i18n.t("months.october") },
      { key: "11", value: i18n.t("months.november") },
      { key: "12", value: i18n.t("months.december") },
    ];

    this.messageFilter.selectOptions = [
      { key: "", value: "Seleccioni una opció"},
      { key: "user", value: i18n.t("activity.update.user") },
      { key: "ad", value: i18n.t("activity.update.ad") },
      { key: "group", value: i18n.t("activity.update.ga") },
      { key: "process", value: i18n.t("activity.update.process") },
      { key: "visited", value: i18n.t("activity.update.new") },
    ];
    if(this.superuser){
      this.pageFilter.selectOptions = [
        { key: "", value: "Seleccioni una opció"},
        { key: "login", value: "Login" },
        { key: "logout", value: "Logout" },
        { key: "WA", value: "Working Area" },
        { key: "GA", value: "Group Ads" },
        { key: "Profile", value: "Profile" },
        { key: "Proces", value: "Process" },
        { key: "Desagrupar anuncis", value: "Desagrupar anuncis" },
        { key: "Transfer Ads", value: "Transferir anuncis" },
        { value: "Merge Ads", key: "Merge" },
        { value: "Contacte", key: "Contact" },
        { value: "Working Region", key: "Working Region" },
        { value: "Nou usuari", key: "New User" }
      ]
    }
  };

  @action("Click on a suggestion") clickSuggestion = (id, name, adm, elem) => {
    this.updateProperty("adFilter", elem);
    this.updateProperty("selectedSuggestion", true);
  };

  @action("Delete filter") deleteFilter = (param) => {
    switch (param) {
      case "start":
        this.updateNestedProperty("startDayFilter.toOption", "-1");
        this.updateNestedProperty("startMonthFilter.toOption", "-1");
        this.updateNestedProperty("startYearFilter.toOption", "-1");
        break;
      case "end":
        this.updateNestedProperty("endDayFilter.toOption", "-1");
        this.updateNestedProperty("endMonthFilter.toOption", "-1");
        this.updateNestedProperty("endYearFilter.toOption", "-1");
        break;
      case "user":
        this.updateNestedProperty("usersFilters.toOption", "-1");
        break;
      case "ad":
        this.updateProperty("adFilter", "");
        break;
      case "message":
        this.updateNestedProperty("messageFilter.toOption", "-1");
        break;
      case "page":
        this.updateNestedProperty("pageFilter.toOption", "-1");
        break;
      default:
    }
  };

  @action("update attribute") updateVisibleFilter = (value) => {
    if (this.visibleFilter === value) this.visibleFilter = "";
    else {
      this.visibleFilter = value;
    }
  };

  @action("update attribute") updateProperty = (attrName, value) => {
    this[attrName] = value;
  };

  @action("update nested attribute") updateNestedProperty = (
    attrPath,
    value
  ) => {
    let path = attrPath.split(".");
    this[path[0]][path[1]] = value;
  };

  getNewUrl = () => {
    try {
      let url = "?page=" + (this.rootStore.pageStore.page + 1);
      if (!!this.trueFilter.start) url += "&start=" + this.trueFilter.start;
      if (!!this.trueFilter.end) url += "&end=" + this.trueFilter.end;
      if (!!this.trueFilter.user) url += "&user=" + this.trueFilter.user;
      if (!!this.trueFilter.ad) url += "&ad=" + this.trueFilter.ad;
      if (!!this.trueFilter.message)
        url += "&message=" + this.trueFilter.message;
      if(!!this.trueFilter.page) url += "&p="+this.trueFilter.page
      return url;
    } catch (e) {
      if (e.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  // getNewUrl = () => {
  //   try {
  //     /*
  //     let res = "?focusedRegion=" + this.focusedRegion._id;
  //     res += "&adFilters=" + this.favFilter + "$" + this.workingFilter;

  //     if (!!this.occupancyFilter.url) res += "&" + this.occupancyFilter.url;
  //     if (!!this.platformFilter.url) {
  //       res += "&" + this.platformFilter.url;
  //     }
  //     if (!!this.licenseFilter.url) {
  //       res += "&" + this.licenseFilter.url;
  //     }
  //     if (!!this.priceFilter.url) {
  //       res += "&" + this.priceFilter.url;
  //     }
  //     if (!!this.minAdsFilter.url) {
  //       res += "&" + this.minAdsFilter.url;
  //     }
  //     if (!!this.webAdsFilter.url) {
  //       res += "&" + this.webAdsFilter.url;
  //     }
  //     if (!!this.licAdsFilter.url) {
  //       res += "&" + this.licAdsFilter.url;
  //     }
  //     res += "&" + this.additionalFilters;
  //     if (!!this.sortCriteria) {
  //       res += "&sort=" + this.sortCriteria;
  //       res += "&dir=" + this.rootStore.pageStore.sortDirection;
  //     }
  //     res += "&page=" + this.rootStore.pageStore.page;

  //     // Limit ha d'anar l'últim perqué a la funció this.getTotalData() es trunca per limit
  //     // i s'agafa el seu número per decidir el nombre d'elements totals a agafar
  //     if (this.limitFromURL !== "") res += "&limit=" + this.limitFromURL;
  //     else res += "&limit=" + this.rootStore.pageStore.len;
  //     return res;
  //     */

  //     // return ""
  //   } catch (e) {
  //     if (e.toString() === "Error: AuthError")
  //       console.log("Authentication failed");
  //   }
  // };

  //
  // @action("Save filters") saveFilters = async (firstTime) => {
  //
  // @action("Save filters") saveFilters = async (resetPage) => {
  //
  @action("Save filters") saveFilters = async (firstTime, resetPage) => {
    if (
      this.startDayFilter.toOption === "-1" ||
      this.startMonthFilter.toOption === "-1" ||
      this.startYearFilter.toOption === "-1"
    )
      this.updateNestedProperty("trueFilter.start", "");
    else
      this.updateNestedProperty(
        "trueFilter.start",
        this.startDayFilter.toOption +
          "/" +
          this.startMonthFilter.toOption +
          "/" +
          this.startYearFilter.toOption
      );
    if (
      this.endDayFilter.toOption === "-1" ||
      this.endMonthFilter.toOption === "-1" ||
      this.endYearFilter.toOption === "-1"
    )
      this.updateNestedProperty("trueFilter.end", "");
    else
      this.updateNestedProperty(
        "trueFilter.end",
        this.endDayFilter.toOption +
          "/" +
          this.endMonthFilter.toOption +
          "/" +
          this.endYearFilter.toOption
      );
    if (this.usersFilters.toOption === "-1" && this.usersFilters.value === "0")
      this.updateNestedProperty("trueFilter.user", "");
    else
      this.updateNestedProperty("trueFilter.user", this.usersFilters.toOption);
    this.updateNestedProperty("trueFilter.ad", this.adFilter);
    if (this.messageFilter.toOption === "-1" && this.messageFilter.value === "0")
      this.updateNestedProperty("trueFilter.message", "");
    else
      this.updateNestedProperty(
        "trueFilter.message",
        this.messageFilter.toOption
      );
    if (this.pageFilter.toOption === "-1" && this.pageFilter.value === "0")
      this.updateNestedProperty("trueFilter.page", "");
    else
      this.updateNestedProperty(
        "trueFilter.page",
        this.pageFilter.toOption
      );
    //
    this.updateProperty("url", this.getNewUrl());
    console.log(this.url);
    if (
      this.rootStore.navigationStore.history.location.search !== this.url &&
      firstTime
    )
      this.rootStore.navigationStore.replace("/activity" + this.url);
    else if (
      this.rootStore.navigationStore.history.location.search !== this.url
    )
      this.rootStore.navigationStore.push("/activity" + this.url);
    //
    // this.updateProperty("url", this.getNewUrl());
    // if (this.rootStore.navigationStore.history.location.search !== this.url)
    //   this.rootStore.navigationStore.push("/activity" + this.url);
    //
    //S'ha de ficar la crida a la BD aqui
    let logRet;
    if(this.superuser)  logRet = await this.rootStore.requestStore.getLogsForActivity(this.url);
    else logRet = await this.rootStore.requestStore.getLogsForActivityNoSuperuser(this.url);
    if (logRet) {
      this.logs = logRet.data;
    }

    this.rootStore.pageStore.updateProperty(
      "totalPages",
      Math.ceil(this.logs.length / this.rootStore.pageStore.len)
    );
    if (resetPage) this.rootStore.pageStore.updateProperty("page", 0);
  };

  @action("Initialize Filters via the information gathered via URL")
  initializeFilters = () => {
    let search = this.rootStore.navigationStore.history.location.search;
    if (!!search) {
      if (search[0] === "?") search = search.substr(1);
      let searchParams = search.split("&");
      for (let searchParam of searchParams) {
        let params = searchParam.split("=");
        let key = params[0];
        let value = params[1];
        let day, month, year, included;
        switch (key) {
          case "page":
            this.rootStore.pageStore.updateProperty(key, parseInt(value) - 1);
            break;
          case "start":
            [day, month, year] = value.split("/");
            this.updateNestedProperty("startDayFilter.toOption", day);
            this.updateNestedProperty("startMonthFilter.toOption", month);
            this.updateNestedProperty("startYearFilter.toOption", year);
            break;
          case "end":
            [day, month, year] = value.split("/");
            this.updateNestedProperty("endDayFilter.toOption", day);
            this.updateNestedProperty("endMonthFilter.toOption", month);
            this.updateNestedProperty("endYearFilter.toOption", year);
            break;
          case "user":
            included = false;
            this.usersFilters.selectOptions.forEach((option) => {
              if (option.key === value) included = true;
              return;
            });
            if (included)
              this.updateNestedProperty("usersFilters.toOption", value);
            break;
          case "ad":
            this.updateProperty("adFilter", value);
            break;
          case "message":
            included = false;
            this.messageFilter.selectOptions.forEach((option) => {
              if (option.key === value) included = true;
              return;
            });
            if (included)
              this.updateNestedProperty("messageFilter.toOption", value);
            break;
          case "p":
            included = false;
            break;
          default:
            break;
        }
      }
    }
  };

  @action("Initialize Store") initializeStore = async () => {
    let users = (
      await this.rootStore.requestStore.getAllUsers({
        projection: "e$d$g",
        limit: "9999999999",
      })
    ).data;
    this.usersFilters.selectOptions.push({ key: "", value: "Seleccioni una opció"})
    users.forEach((option, i) => {
      console.log(option.isSuperuser);
      if(!option.isSuperuser)
      this.usersFilters.selectOptions.push({
        key: option.username,
        value: option.username,
      });
      else if (this.superuser){
        this.usersFilters.selectOptions.push({
          key: option.username,
          value: option.username,
        });
      }
    });

    this.initializeFilters();

    this.saveFilters(true, false);
    this.activateTabFromURL();
  };

  @computed get tableData() {
    //Agafa de len * page fins (len * page + 1) - 1, ja que slice(start,end) agafa des de start fins end - 1
    if (this.logs) {
      let data = this.logs.slice(
        this.rootStore.pageStore.len * this.rootStore.pageStore.page,
        this.rootStore.pageStore.len * (this.rootStore.pageStore.page + 1)
      );

      for (let i = 0; i < data.length; i++) {
        for (var key in data[i]) {
          if (data[i].hasOwnProperty(key)) {
            if (!data[i][key]) {
              data[i][key] = "-";
            }
          }
        }
      }
      return data;
    } else return [];
  }

  activateTabFromURL = () => {
    let urlsplitted = this.url.split("&");
    let start = urlsplitted[1];
    let end = urlsplitted[2];
    let date = new Date(Date.now());
    date.setDate(date.getDate() + 1);
    if (start !== undefined) {
      let startDate = start.split("=")[1];
      if (end !== undefined) {
        if (end.split("=")[0] === "end" && start.split("=")[0] === "start") {
          let endDate = end.split("=")[1];
          let endSplit = endDate.split("/");
          let startSplit = startDate.split("/");
          if (
            parseInt(endSplit[2]) === date.getFullYear() &&
            parseInt(endSplit[1]) === date.getMonth() + 1
          ) {
            //It can be: today, yesterday,last week, last month
            if (parseInt(endSplit[0]) === date.getDate()) {
              //It can be: today, last week, last month
              date.setDate(date.getDate() - 1);
              if (parseInt(startSplit[0]) === date.getDate()) {
                //It can be: today, last month
                if (
                  parseInt(startSplit[1]) === date.getMonth() + 1 &&
                  parseInt(startSplit[2]) === date.getFullYear()
                ) {
                  //It is today
                  let tab = document.getElementById("todayActivity");
                  tab.className += " active";
                } else {
                  date.setMonth(date.getMonth() - 1);
                  if (
                    parseInt(startSplit[1]) === date.getMonth() + 1 &&
                    parseInt(startSplit[2]) === date.getFullYear()
                  ) {
                    //It is month
                    let tab = document.getElementById("monthActivity");
                    tab.className += " active";
                  }
                }
              } else {
                date.setDate(date.getDate() - 7);
                if (
                  parseInt(startSplit[0]) === date.getDate() &&
                  parseInt(startSplit[1]) === date.getMonth() + 1 &&
                  parseInt(startSplit[2]) === date.getFullYear()
                ) {
                  //It is last week
                  let tab = document.getElementById("weekActivity");
                  tab.className += " active";
                }
              }
            } else {
              date.setDate(date.getDate() - 1);
              if (parseInt(endSplit[0]) === date.getDate()) {
                //It can be: yesterday
                date.setDate(date.getDate() - 1);
                if (
                  parseInt(startSplit[0]) === date.getDate() &&
                  parseInt(startSplit[2]) === date.getFullYear() &&
                  parseInt(startSplit[1]) === date.getMonth() + 1
                ) {
                  //It is yesterday
                  let tab = document.getElementById("yesterdayActivity");
                  tab.className += " active";
                }
              }
            }
          }
        }
      } else {
        //It can be: old
        date.setMonth(date.getMonth() - 2);
        let splited = startDate.split("/");
        if (
          start.split("=")[0] === "end" &&
          parseInt(splited[1]) === date.getMonth() + 1 &&
          parseInt(splited[0]) === date.getDate() &&
          parseInt(splited[2]) === date.getFullYear()
        ) {
          //It's old
          let tab = document.getElementById("oldActivity");
          tab.className += " active";
        }
      }
    }
  };

  parseArray = (array) => {
    let res = "";
    let firstIteration = true;
    for (let arr of array) {
      if (firstIteration) {
        firstIteration = false;
        res = arr;
      } else res = res + ", " + arr;
    }
    if (res === "") res = "-";
    return res;
  };

  convertToFormatTable = (date) => {
    ///hh:mm DD-MM-YYYY
    //"2013-03-10T02:00:00Z
    if (date !== null && date !== undefined) {
      let t = date.split("T", 2);
      let h = t[0];
      t = t[1].split(".")[0];
      t = t.split(":", 3);
      h = h.split("-", 3);
      t[0] = parseInt(t[0]);
      if (t[0] < 10) t[0] = "0" + t[0];
      if (t[0] === 24) t[0] = "00";
      return (
        h[2] +
        "-" +
        h[1] +
        "-" +
        h[0] +
        "      " +
        t[0] +
        ":" +
        t[1] +
        ":" +
        t[2]
      );
    } else return null;
  };
}
export default ActivityStore;
