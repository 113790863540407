import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
//import MySelect from "../basicUI/MySelect";
//import MyButton from "../basicUI/MyButton";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

@inject("transferAdsStore", "sessionStore", "navigationStore","requestStore")
@observer
class TransferAds extends Component {
  componentDidMount() {
    let { t } = this.props;
    document.title = t("transfer.title");
    let {
      match: {
        params: { code },
      },
    } = this.props;
    this.props.transferAdsStore.updateProperty("receiver", code);
    this.props.transferAdsStore.initStore();
  }

  componentWillUnmount() {
    this.props.transferAdsStore.clearStore();
  }

  selectThisGroup = (element, array) => {
    if (element.selected) {
      element.selected = false;
      element.selectedChildren = [];
      this.props.transferAdsStore.removeSelectedTuple(element.childrenId);
      for (let children of element.childrenId) {
        let el = document.getElementById("checkbox - " + children);
        if (el) el.className = el.className.replace("checked", "nocheck");
      }
    } else {
      element.selected = true;
      element.selectedChildren = [];
      this.props.transferAdsStore.addSelectedTuple(element.childrenId);
      for (let children of element.childrenId) {
        element.selectedChildren.push(children);
        let el = document.getElementById("checkbox - " + children);
        if (el) el.className = el.className.replace("nocheck", "checked");
      }
    }
  };

  openThisGroup = (element, array) => {
    if (element.opened) {
      element.opened = false;
    } else {
      element.opened = true;
      this.props.transferAdsStore.takeChilds(element);
    } /*
    for (let e of array) {
      if (e.selected) {
        e.selectedChildren = [];
      }
      e.selected = false;
    }
    element.selected = true;*/
  };

  selectThisChildren = (element, children) => {
    if (
      element.selectedChildren &&
      element.selectedChildren.includes(children)
    ) {
      for (let childIndex in element.selectedChildren) {
        if (element.selectedChildren[childIndex] === children) {
          element.selectedChildren.splice(childIndex, 1);
        }
      }
      this.props.transferAdsStore.removeSelectedAd(children);
      if (element.selectedChildren.length === 0) {
        element.selected = false;
        let el = document.getElementById("checkbox" + element.id);
        if (el) el.className = el.className.replace("checked", "nocheck");
      }
    } else {
      if (!element.selectedChildren) element.selectedChildren = [];
      element.selectedChildren.push(children);
      this.props.transferAdsStore.addSelectedAd(children);
      if (element.selectedChildren.length === element.childrenId.length) {
        element.selected = true;
        let el = document.getElementById("checkbox" + element.id);
        if (el) el.className = el.className.replace("nocheck", "checked");
      }
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        {this.props.transferAdsStore.initialized ? (
          <main role="main" className="container-fluid wre-page">
            <div className="row merge-groups">
              {/* Operation selector */}
              <div className="col-md-8 merge-top ">
                <button
                  type="button"
                  className="btn btn-outline-info edit-merge"
                  style={{ float: "right" }}
                  data-toggle="modal"
                  data-target=".edit-merge-confirmacio-modal"
                >
                  {t("transfer.confirm")}
                </button>
                <p>
                  {t("transfer.receiver") +
                    this.props.transferAdsStore.receiver}
                  <br />
                  {t("transfer.municipality") +
                    this.props.transferAdsStore.receiverMunicipality}
                </p>
                <p>
                  {t("transfer.description.d1")}
                  <br />
                  {t("transfer.description.d2")}
                  <br />
                  {t("transfer.description.d3")}
                  <br />
                  {t("transfer.description.d4")}
                </p>
                <div
                  className="modal fade edit-merge-confirmacio-modal"
                  role="dialog"
                  aria-labelledby="AreaEditModal"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <br />
                      <p>{t("transfer.modal.trans")}</p>
                      <p>
                        {this.props.transferAdsStore.selectedAds &&
                          this.props.transferAdsStore.selectedAds.map(
                            (ad, i) => {
                              return (
                                <span key={ad + "_" + i}>
                                  {ad}
                                  <br />
                                </span>
                              );
                            }
                          )}
                      </p>
                      <p>
                        {t("transfer.modal.question") +
                          this.props.transferAdsStore.receiver +
                          " ?"}
                      </p>
                      <div className="modal-dialog-buttons">
                        <button
                          type="button"
                          className="btn btn-outline-danger"
                          data-toggle="modal"
                          data-target=".edit-merge-confirmacio-modal"
                        >
                          {t("transfer.cancel")}
                        </button>
                        <button
                          type="button"
                          className="btn btn-info"
                          data-toggle="modal"
                          data-target=".edit-merge-confirmacio-modal"
                          onClick={async (e) => {
                            await this.props.transferAdsStore.confirmButton();
                            this.props.navigationStore.openInNewTab(
                              "/groupAds/" +
                                this.props.transferAdsStore.receiver
                            );
                            this.props.navigationStore.reload();
                          }}
                        >
                          {t("transfer.confirm")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row merge-groups">
              {/* Column operators */}
              <div className="col-md-8 merge-left">
                <h5>{t("transfer.columns.group.left")}</h5>
                <input
                  id="regionSearch"
                  className="region-inputs"
                  value={this.props.transferAdsStore.leftSearch}
                  onChange={(e) => {
                    this.props.transferAdsStore.updateProperty(
                      "search",
                      e.target.value
                    );
                    this.props.transferAdsStore.searchElements();
                  }}
                  placeholder={t("transfer.columns.group.leftPlaceholder")}
                ></input>

                <hr />
              </div>
            </div>
            {/* Column visual data and input buttons for move data */}
            <div className="row transfer-ads">
              <div className="col-md-8 wre-list leftMerge">
                {this.props.transferAdsStore.elements &&
                this.props.transferAdsStore.elements.length > 0
                  ? this.props.transferAdsStore.elements.map(
                      (element, index, array) => {
                        if (
                          element.childrenId.length === 0 ||
                          element.id === this.props.transferAdsStore.receiver
                        ) {
                          console.log(element.id);
                          console.log(element.childrenId.length);
                          return null;
                        }
                        let classname = "transfer-child-text ";
                        if (element.selected) {
                          classname += "selected";
                        } else {
                          classname += "unselected";
                        }
                        let title = "";
                        let municipality =
                          " - " + t("transfer.municipalityName");
                        if (element.title) title = " - " + element.title;
                        if (element.municipality && element.municipality !== "")
                          municipality += element.municipality;
                        else municipality += t("transfer.noMunicipality");
                        return (
                          <div key={element.id} className="regionDiv">
                            <button
                              id={element.id}
                              onClick={(e) => {
                                this.openThisGroup(element, array);
                              }}
                            >
                              <svg
                                className="svg-icon-lists-arrow"
                                data-name="fas fa-angle-down"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 40.11 26.16"
                              >
                                <path
                                  className="cls-1"
                                  d="M27.34,28.77a2.08,2.08,0,0,0-3,0l-4,4s-1,1,0,3L38.42,53.74s1,1,3,0L59.36,35.67a2.07,2.07,0,0,0,0-3l-4-4a2.08,2.08,0,0,0-3,0l-12,12Z"
                                  transform="translate(-19.91 -28.02)"
                                />
                              </svg>
                            </button>
                            <input
                              type="checkbox"
                              id={"checkbox" + element.id}
                              name={"checkbox." + element.id}
                              className={"nocheck"}
                              onChange={(e) => {
                                this.selectThisGroup(element, array);
                                let el = document.getElementById(
                                  "checkbox" + element.id
                                );
                                if (el.classList.contains("nocheck")) {
                                  el.className = el.className.replace(
                                    "nocheck",
                                    "checked"
                                  );
                                } else
                                  el.className = el.className.replace(
                                    "checked",
                                    "nocheck"
                                  );
                              }}
                            ></input>
                            <p id={element.id} className={classname}>
                              {element.id + title + municipality}
                            </p>
                            {element.opened && element.childs
                              ? element.childs.map((children, index) => {
                                  let inputClassName = "transfer-child-button";
                                  let childClassname = "transfer-child-text";

                                  if (
                                    element.selectedChildren &&
                                    element.selectedChildren.includes(
                                      children._id
                                    )
                                  ) {
                                    inputClassName += " checked";
                                    childClassname += " selected";
                                  } else {
                                    childClassname += " unselected";
                                    inputClassName += " nocheck";
                                  }
                                  return (
                                    <div
                                      id={"left" + element.id + children._id}
                                      key={"left" + element.id + children._id}
                                      className="regionDiv leftChild"
                                    >
                                      <img
                                        id={"image" + element.id + children._id}
                                        className="transfer-ads-img"
                                        src={children.images[0]}
                                        alt="No available"
                                        key={
                                          "imageKey" + element.id + children._id
                                        }
                                      />
                                      <input
                                        type="checkbox"
                                        id={"checkbox - " + children._id}
                                        name={"checkbox." + children._id}
                                        key={
                                          "checkbox" + element.id + children._id
                                        }
                                        className={inputClassName}
                                        onChange={(e) => {
                                          let el = document.getElementById(
                                            "checkbox - " + children._id
                                          );
                                          this.selectThisChildren(
                                            element,
                                            children._id
                                          );
                                          if (
                                            el.classList.contains("nocheck")
                                          ) {
                                            el.className = el.className.replace(
                                              "nocheck",
                                              "checked"
                                            );
                                          } else
                                            el.className = el.className.replace(
                                              "checked",
                                              "nocheck"
                                            );
                                        }}
                                      ></input>

                                      <p
                                        id={"p" + element.id + children._id}
                                        key={"p" + element.id + children._id}
                                        className={childClassname}
                                      >
                                        {children._id}
                                      </p>
                                      <p
                                        id={"nirtc" + element.id + children._id}
                                        key={
                                          "nirtc" + element.id + children._id
                                        }
                                        className={childClassname}
                                      >
                                        {children.license.code
                                          ? t("transfer.license") +
                                            ":  " +
                                            children.license.code
                                          : t("transfer.license") +
                                            ":  " +
                                            t("transfer.license")}
                                      </p>
                                      <OverlayTrigger
                                        delay={{ show: 500, hide: 100 }}
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id="tooltip">
                                            {children.name === undefined
                                              ? ""
                                              : children.name}
                                          </Tooltip>
                                        }
                                      >
                                        <p
                                          id={
                                            "name" + element.id + children._id
                                          }
                                          key={
                                            "name" + element.id + children._id
                                          }
                                          className={childClassname}
                                        >
                                          {children.name === undefined
                                            ? ""
                                            : children.name.length >= 50
                                            ? children.name.substring(0, 50) +
                                              "..."
                                            : children.name}
                                        </p>
                                      </OverlayTrigger>
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        );
                      }
                    )
                  : null}
              </div>
            </div>
          </main>
        ) : null}
      </>
    );
  }
}

export default withTranslation()(TransferAds);
