import { observable, action, computed } from "mobx";
class ForgotPasswordStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.updateFormProperty = this.updateFormProperty.bind(this);
  }
  // Form fields
  @observable forgotPasswordForm = {
    username: "",
    group: "",
  };
  // Control variables
  @observable formSent = false;
  @observable formIsValid = true;
  @observable emailConfirmed = false;
  @observable showError = false;
  @observable isLoading = false;
  @observable showMessage = false;

  @action("update form property") updateFormProperty(key, value) {
    this.forgotPasswordForm[key] = value;
  }

  @action("update property not in form") updateProperty(key, value) {
    this[key] = value;
  }

  @computed get hasUsername() {
    return this.forgotPasswordForm.username !== "";
  }

  @computed get hasGroup() {
    return this.forgotPasswordForm.group !== "";
  }

  @computed get checkIfFormIsValid() {
    return this.hasUsername && this.hasGroup;
  }

  @computed get messageError() {
    return (
      this.forgotPasswordForm.hasUsername !== "" ||
      this.forgotPasswordForm.hasGroup !== ""
    );
  }
  @action("send email to reset password") sendEmail = async (e) => {
    try {
      e.preventDefault();
      this.updateProperty("formSent", true);
      this.updateProperty("showError", false);
      this.updateProperty("formIsValid", this.checkIfFormIsValid);
      this.updateProperty("showError", this.messageError);
      if (this.formIsValid) {
        this.updateProperty("isLoading", true);
        await this.rootStore.requestStore.forgotPassword(
          this.forgotPasswordForm.username,
          this.forgotPasswordForm.group
        );
        this.updateFormProperty("username", "");
        this.updateFormProperty("group", "");
        this.updateProperty("emailConfirmed", true);
        this.updateProperty("isLoading", false);
        this.updateProperty("showError", false);
        this.updateProperty("showMessage", true);
        // }
      } else {
        this.updateProperty("formSent", false);
        this.updateProperty("isLoading", false);
        this.updateProperty("showMessage", false);
        this.updateProperty("showError", true);
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
      this.updateProperty("showError", true);
      this.updateProperty("formSent", false);
      this.updateProperty("isLoading", false);
      this.updateProperty("showMessage", false);
    }
  };
}
export default ForgotPasswordStore;
