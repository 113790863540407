import { observable, action } from "mobx";

class LogStore {
  @observable logs = null;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action("Get logs by username") getLogs = async (username, filterValue) => {
    try {
    if (filterValue === this.filter) this.rootStore.pageStore.changeDirection();
    else this.rootStore.pageStore.restartDirection();
    this.rootStore.pageStore.setPage(0);
    this.rootStore.pageStore.setTotalData(0);
    this.filter = filterValue;
    let auxArray = [];
    let auxData = 0;
    let aux = await this.rootStore.requestStore.getInitialLogsByUser(
      username,
      this.filter,
      this.rootStore.pageStore.len,
      this.rootStore.pageStore.searchDirection
    );
    for (
      let i = 1;
      i < this.rootStore.pageStore.maxPages && aux.data.length > 0;
      i++
    ) {
      auxData += aux.data.length;
      auxArray.push(aux.data);
      let lastVar;
      switch (this.filter) {
        case "date":
          lastVar = auxArray[i - 1][auxArray[i - 1].length - 1].date;
          break;
        case "ref":
          lastVar = auxArray[i - 1][auxArray[i - 1].length - 1].referenceId;
          break;
        case "username":
          lastVar = auxArray[i - 1][auxArray[i - 1].length - 1].username;
          break;
        case "message":
          lastVar = auxArray[i - 1][auxArray[i - 1].length - 1].message;
          break;
        default:
          lastVar = auxArray[i - 1][auxArray[i - 1].length - 1]._id;
      }
      let lastUnique = auxArray[i - 1][auxArray[i - 1].length - 1].date;
      aux = await this.rootStore.requestStore.getLogsByUser(
        username,
        this.filter,
        lastVar,
        lastUnique,
        this.rootStore.pageStore.len,
        this.rootStore.pageStore.searchDirection
      );
    }
    if (aux.data.length > 0) {
      auxArray.push(aux.data);
      auxData += aux.data.length;
    }
    this.rootStore.pageStore.setTotalData(auxData);
    this.rootStore.pageStore.setPageArray(auxArray);
    this.logs = this.rootStore.pageStore.pageArray[0];
  } catch(error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  @action("Get Next logs") getNext = async num => {
    this.rootStore.pageStore.nextPage(num);
    this.logs = this.rootStore.pageStore.pageArray[
      this.rootStore.pageStore.page
    ];
  };

  @action("Get Previous logs") getPrevious = async num => {
    this.rootStore.pageStore.previousPage(num);
    this.logs = this.rootStore.pageStore.pageArray[
      this.rootStore.pageStore.page
    ];
  };

  @action("Get Last logs") getLast = async () => {
    this.rootStore.pageStore.setPage(this.rootStore.pageStore.totalPages - 1);
    this.logs = this.rootStore.pageStore.pageArray[
      this.rootStore.pageStore.totalPages - 1
    ];
  };

  @action("Get First logs") getFirst = async () => {
    this.rootStore.pageStore.setPage(0);
    this.logs = this.rootStore.pageStore.pageArray[0];
  };
}
export default LogStore;
