import { observable, action, computed } from "mobx";
import { asyncComputed } from "computed-async-mobx";
//const { BroadcastChannel } = require("broadcast-channel");
//const channelTab = new BroadcastChannel("tab");

class SearchStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.updateProperty = this.updateProperty.bind(this);
    this.updateFormProperty = this.updateFormProperty.bind(this);
  }

  // Form fields
  @observable searchForm = {
    option: "region_name",
    text: ""
  };

  // Control variables
  @observable dataFound = "";
  @observable isLoading = false;
  @observable showError = false;
  @observable formSent = false;
  @observable formIsValid = true;
  @observable showAlert = false;


  //Other variables
  asyncSuggestions = asyncComputed([], 100, async () => {
    try {
      const inputValue = this.searchForm.text.trim().toLowerCase();
      const inputLength = inputValue.length;
      let res = [];
      if (inputLength > 0) {
        if (this.searchForm.option === "ad_id") {
          res = await this.rootStore.requestStore.searchAdsByIdRegex(
            this.searchForm.text
          );
        } else if (this.searchForm.option === "groupad_id") {
          res = await this.rootStore.requestStore.searchAdsByGroupId(
            this.searchForm.text
          );
        } else if (this.searchForm.option === "license_code") {
          res = await this.rootStore.requestStore.searchAdsByLicenseCode(
            this.searchForm.text
          );
          let setAux = new Set()
          for(let r of res){
            setAux.add(r.license.code)
          }
          res = []
          for(let s of setAux)
            res.push({"licenseCode":s})
          // res.map(doc => (doc.license = doc["license"]["code"]));
        } else if (this.searchForm.option === "host_name") {
          res = await this.rootStore.requestStore.searchAdsByHost(
            this.searchForm.text
          );
          let setAux = new Set()
          for(let r of res){
            setAux.add(r.host.nickname)
          }
          res = []
          for(let s of setAux)
            res.push({"hostNickname":s})
          // res.map(doc => (doc.host = doc["host"]["nickname"]));
        } else if (this.searchForm.option === "region_name") {
          res = await this.rootStore.requestStore.searchRegionsByName(
            this.searchForm.text
          );
        }
        else if (this.searchForm.option === "tag") {
          res = await this.rootStore.requestStore.searchAdsByTag(
            this.searchForm.text)
        } else if (this.searchForm.option === "host_id") {
          res = await this.rootStore.requestStore.searchAdsByHostId(
            this.searchForm.text
          );
          let setAux = new Set()
          for(let r of res){
            setAux.add(r.host.id)
          }
          res = []
          for(let s of setAux)
            res.push({"id":s})
          // res.map(doc => (doc.host = doc["host"]["nickname"]));
        }
      }

      return res;
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  });

  @computed get suggestions() {
    return this.asyncSuggestions.get();
  }

  @action("update form property") updateFormProperty(key, value) {
    this.searchForm[key] = value;
  }


  @action("find") async find(option, value) {
    try {
      let result = null;
      //console.log("OPTION ES... ", option)
      switch (option) {
        case "license_code":
          result = await this.rootStore.requestStore.getApartmentById2("i",value);
          break;
        case "host_name":
          result = await this.rootStore.requestStore.getApartmentById2("h",value);
          break;
        case "ad_id":
          result = await this.rootStore.requestStore.getApartmentById2("a",value);
          break;
        case "groupad_id":
          result = await this.rootStore.requestStore.getGroupAds("b",value);
          break;
        case "region_name":
          result = await this.rootStore.requestStore.getRegionInfoForSearch(value)
          break;
        case "tag":
          result = await this.rootStore.requestStore.getGroupAds("s",value);
          break;
        case "host_id":
          result = await this.rootStore.requestStore.getApartmentById2("o",value);
          break;
        default:
          break;
      }
      return result.data
    } catch (error) {
      console.log(error);
    }
  }

  @computed get fieldsToShow() {
    let res = [""];
    
    if (this.searchForm.option === "region_name") res = ["name", "adm", "parentName"];
    else if (this.searchForm.option === "host_name") res = ["hostNickname"];
    else if (this.searchForm.option === "license_code") res = ["licenseCode"];
    else if (this.searchForm.option === "tag") res = ["id", "tags"]
    else if (this.searchForm.option === "groupad_id") res = ["_id"]
    else res = ["id"];
    return res;
  }

  @action("update property not in form") updateProperty(key, value) {
    this[key] = value;
  }

  @computed get checkIfFormIsValid() {
    return this.text !== "";
  }

  goToSuggestion = async (id, name = "", adm) => {
    try {
      switch (this.searchForm.option) {
        case "license_code":
          if (this.rootStore.navigationStore.history.location.pathname === "/lists") {
          this.rootStore.navigationStore.push("/lists?list=Licenses&group_by=licCode&sort=groupKey&dir=1&page=1&limit=20&i=" + id)
          this.rootStore.navigationStore.reload()
          }
          else this.rootStore.navigationStore.openInNewTab("/lists?list=Licenses&group_by=licCode&sort=groupKey&dir=1&page=1&limit=20&i=" + id);
          break;
        case "host_name":
          if (this.rootStore.navigationStore.history.location.pathname === "/lists") {
            this.rootStore.navigationStore.push("/lists?list=Hosts&group_by=hostId&&sort=hostNickname&dir=1&page=1&limit=20&h=" + id)
            this.rootStore.navigationStore.reload()
          }
          else this.rootStore.navigationStore.openInNewTab("/lists?list=Hosts&group_by=hostId&&sort=hostNickname&dir=1&page=1&limit=20&h=" + id);
          break;
        case "ad_id":
          if (this.rootStore.navigationStore.history.location.pathname.includes("/groupAds")) {
            this.rootStore.navigationStore.push("/groupAds/" + id)
            this.rootStore.navigationStore.reload()
          }
          else {
            this.rootStore.navigationStore.openInNewTab("/groupAds/" + id);
          }
          break;
        case "groupad_id":
          if (this.rootStore.navigationStore.history.location.pathname.includes("/groupAds")) {
            this.rootStore.navigationStore.push("/groupAds/" + id)
            this.rootStore.navigationStore.reload()
          }
          else this.rootStore.navigationStore.openInNewTab("/groupAds/" + id);
          break;
        case "region_name":
          if (
            this.rootStore.navigationStore.history.location.pathname === "/area"
          ) {
            this.rootStore.mapListStore.newTab(id, name, adm);
          } else {
            //this.rootStore.navigationStore.push("/area");
            this.rootStore.navigationStore.openInNewTab("/area?focusedRegion="+ id + "&adFilters=false$false$false&=undefined&page=1&limit=20")
            //await this.rootStore.navigationStore.openInNewTab("/area");
            // let a = {
            //   id: id,
            //   name: name,
            //   adm: adm
            // }
            // await channelTab.postMessage(a);
            //await this.rootStore.mapListStore.newTab(id, name, adm, true);        
          }
          break;
        case "tag":
          if (this.rootStore.navigationStore.history.location.pathname.includes("/groupAds")) {
            this.rootStore.navigationStore.push("/groupAds/" + id)
            this.rootStore.navigationStore.reload()
          }
          else this.rootStore.navigationStore.openInNewTab("/groupAds/" + id);
          break;
        case "host_id":
          if (this.rootStore.navigationStore.history.location.pathname === "/lists") {
            this.rootStore.navigationStore.push("/lists?list=Hosts&group_by=hostId&&sort=groupKey&dir=1&page=1&limit=20&o=" + id)
            this.rootStore.navigationStore.reload()
          }
          else this.rootStore.navigationStore.openInNewTab("/lists?list=Hosts&group_by=hostId&&sort=groupKey&dir=1&page=1&limit=20&o=" + id);
          break;
        default:
          break;
      }

      this.updateFormProperty("text", "");

      //demanar regió en cas de name_region i id_region
      //canvi de pantalla en cas de id_ad i id_group a pantalla de adGroup
      //canvi de pantalla a llistats en cas de id_license i name_host
    } catch (error) {
      console.log(error);
    }
  };

  search = async e => {
    try {
      // e.preventDefault();
      // this.updateProperty('formSent', true)
      // this.updateProperty('formIsValid', this.checkIfFormIsValid)
      // this.updateProperty('showError', false)
      // if (this.formIsValid) {
      //   const response = await this.rootStore.requestStore.search(
      //     this.searchForm.option,
      //     this.searchForm.text,
      //   )
      //   if (response.data.message === 'data found') {
      //     this.updateProperty('dataFound', true)
      //     this.updateProperty('showError', false)
      //     this.updateProperty('formSent', false)
      //     this.updateFormProperty('option', 'id_ad')
      //     this.updateFormProperty('text', '')
      //   } else {
      //     this.updateProperty('dataFound', false)
      //     this.updateProperty('showError', true)
      //     this.updateProperty('formSent', false)
      //   }
      // }
    } catch (error) {
      console.log(error);
      this.updateProperty("showError", true);
      this.updateProperty("formSent", false);
      this.updateProperty("isLoading", false);
    }
  };
}

export default SearchStore;

// import { observable, action, computed } from "mobx";
// import { asyncComputed } from "computed-async-mobx"

// class SearchStore {

//   constructor (rootStore) {
// 		this.rootStore = rootStore;
//     this.updateFormProperty = this.updateFormProperty.bind(this)
//   }

//   // Form fields
//   // @observable searchForm = {
//   //   option: "region_name",
//   //   text: ""
//   // }
//   @observable selectedOption = "region_name"
//   @observable searchText = ""

//   // Control variables
//   @observable dataFound = ""
//   @observable isLoading = false
//   @observable showError = false
//   @observable formSent = false
//   @observable formIsValid = true

//   selectOptions = [
//     {key: 'region_name', value: 'Nom regió'},
//     {key: 'ad_id', value: 'Id anunci'},
//     {key: 'license_code', value: 'Codi llicència'},
//     {key: 'host_name', value: 'Nom propietari'},
//   ]

//   //Other variables
//   suggestions = asyncComputed([],100, async () => {
//     const inputValue = this.searchText.trim().toLowerCase();
//     const inputLength = inputValue.length;

//     let res = []
//     if (inputLength > 0) {
//       if (this.selectedOption === 'ad_id') {
//         res = await this.rootStore.requestStore.searchAdsById(
//           this.searchText
//         )
//       } else if (this.selectedOption === 'license_code') {
//         res = await this.rootStore.requestStore.searchAdsByLicenseCode(
//           this.searchText
//         )
//         res.map( doc =>
//           doc.license = doc['license']['code']
//         )
//       } else if (this.selectedOption === 'host_name') {
//         res = await this.rootStore.requestStore.searchAdsByHost(
//           this.searchText
//         )
//         console.log(`res = ${res}`);

//         res.map( doc =>
//           doc.host = doc['host']['nickname']
//         )
//       } else if (this.selectedOption === 'region_name') {
//         res = await this.rootStore.requestStore.searchRegionsByName(
//           this.searchText
//         )
//       }
//     }

//     return res
//   });

//   @action("update form property") updateFormProperty (key, value) {
//     console.log(key, value);

//     this.searchForm[key] = value
//   }

//   @action("update property") updateProperty(key, value) {
//     // this[key] = value;
//     console.log(key, value);

//     this.selectedOption = 'value';
//   }

//   @computed get suggestionscomp() {
//     return this.suggestions.get()
//   }

//   @computed get fieldsToShow() {
//     let res = ['']
//     if (this.selectedOption === 'region_name')
//       res = ['name', 'adm']
//     else if (this.selectedOption === 'host_name')
//       res = ['host']
//     else if (this.selectedOption === 'license_code')
//       res = ['license']
//     else
//       res = ['_id']
//     return res
//   }

//   // @action("update property not in form") updateProperty (key, value) {
//   //   this[key] = value
// 	// }

//   @computed get checkIfFormIsValid() {
//     return this.text !== ''
//   };

//   goToSuggestion = async (value) => {
//     try {
//       console.log(`value to search: ${value}`);

//       switch(this.selectedOption){
//         // case "":
//         // selected.innerHTML="Select";
//         case 'license_code':
//           console.log('license_code case');

//           //Ad ID
//           // location.href = "lists.html?adID="+filter;
//           this.rootStore.navigationStore.push("/list/license/?i=" + value);
//           break;
//         case 2:
//           //Host ID
//           // location.href = "lists.html?hostID="+filter;

//           break;
//         case 3:
//           //License ID
//           // location.href = "lists.html?licenseID="+filter;

//           break;
//         case 4:
//           // newTab(filter);
//           // localStorage.setItem("tab-"+filter, filter );
//           break;
//         case 5:
//           //Ad Title
//           // location.href = "lists.html?adTitle="+filter;

//           break;
//         case 6:
//           //Host name
//           // location.href = "lists.html?hostName="+filter;

//           break;
//         default:
//           break

//       }

//       this.updateFormProperty('text', '')

//       //demanar regió en cas de name_region i id_region
//       //canvi de pantalla en cas de id_ad i id_group a pantalla de adGroup
//       //canvi de pantalla a llistats en cas de id_license i name_host
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   search = async (e) => {
//     try {
//       // e.preventDefault();

//       // this.updateProperty('formSent', true)
//       // this.updateProperty('formIsValid', this.checkIfFormIsValid)
//       // this.updateProperty('showError', false)

//       // if (this.formIsValid) {
//       //   const response = await this.rootStore.requestStore.search(
//       //     this.selectedOption,
//       //     this.searchText,
//       //   )
//       //   if (response.data.message === 'data found') {
//       //     this.updateProperty('dataFound', true)
//       //     this.updateProperty('showError', false)
//       //     this.updateProperty('formSent', false)
//       //     this.updateFormProperty('option', 'id_ad')
//       //     this.updateFormProperty('text', '')
//       //   } else {
//       //     this.updateProperty('dataFound', false)
//       //     this.updateProperty('showError', true)
//       //     this.updateProperty('formSent', false)
//       //   }
//       // }
//     } catch (error) {
//       console.log(error);
//       this.updateProperty('showError', true)
//       this.updateProperty('formSent', false)
//       this.updateProperty('isLoading', false)
//     }
//   };
// }

// export default SearchStore;
