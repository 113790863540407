import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { /*Link, */NavLink } from "react-router-dom";
import "../App.css";
import { observer, inject } from "mobx-react";
@inject("userStore", "rootStore")
@observer
class Navbar extends Component {
  // PER CONTROLAR EL LOGIN I LOGOUT DES D'UN ALTRE TAB DEL NAVEGADOR
  componentDidMount() {
    /* window.localStorage.setItem(
      "REQUESTING_SHARED_CREDENTIALS",
      Date.now().toString()
    );
    window.localStorage.removeItem("REQUESTING_SHARED_CREDENTIALS"); */
  }

  render() {
    //const { t } = this.props;
    /**  TODO RECUPERAR L'ID DE LA SESSIONSTORE**/
    let user = this.props.userStore.getUser();
    //let id;
    if (user === null) return null;
    else {
      //id = user.id;
      return (
        <div className="verticalNavbar navbar navbar-expand-lg navbar-light bg-light">
          <ul>
          <li>
              <nav>
                <NavLink
                  className="verticalNavbar-brand"
                  to= "/statistics/statistics"
                  onClick={() => {
                    if (
                      this.props.rootStore.navigationStore.history.location
                        .pathname === "/cityhall"
                    )
                      this.props.rootStore.navigationStore.reload();
                    else
                      this.props.rootStore.navigationStore.push("/statistics/statistics");
                  }}
                >
                  {"Stats"}
                </NavLink>
              </nav>
            </li><li>
              <nav>
                <NavLink
                  className="verticalNavbar-brand"
                  to= "/statistics/cityhall"
                  onClick={() => {
                    if (
                      this.props.rootStore.navigationStore.history.location
                        .pathname === "/cityhall"
                    )
                      this.props.rootStore.navigationStore.reload();
                    else
                      this.props.rootStore.navigationStore.push("/statistics/cityhall");
                  }}
                >
                  {"CityHall"}
                </NavLink>
              </nav>
            </li>
          </ul>
        </div>
      );
    }
  }
}

export default withTranslation()(Navbar);
