import { observable, action } from "mobx";


class LicenseListStore {
  @observable list = null;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action("get list data") getList = async () => {
    try {
  		let search = this.rootStore.navigationStore.history.location.search
      let res = await this.rootStore.requestStore.getListByLicense(search);
  		this.setDashboard(res.data);

    } catch(error) {
	      if (error.toString() === "Error: AuthError")
	        console.log("Authentication failed");
			}
		}

  @action("Set list") setDashboard = ({ list }) => {
    this.list = list;
  };
}

export default LicenseListStore;
