import { observable, action } from "mobx";
import i18n from "../../i18n";

class RegionSelectorStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  // Control variables

  @observable listRegions = [];
  @observable Regions = [];
  @observable adms = [[]];
  @observable minAdm = 90;
  @observable childrens = [[]];
  @observable tabRegions = [];
  @observable showAlert = false;
  @observable confirmAlert = false;

  @observable opened = false;
  @observable adm = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
  };

  aadm = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
  };

  @observable listR = {
    adm: {
      0: {
        id: -1,
        adm: [],
      },
      1: {
        id: -1,
        adm: [],
      },
      2: {
        id: -1,
        adm: [],
      },
      3: {
        id: -1,
        adm: [],
      },
      4: {
        id: -1,
        adm: [],
      },
      5: {
        id: -1,
        adm: [],
      },
      6: {
        id: -1,
        adm: [],
      },
    },
  };

  totalR = [];

  @observable indexAdm = -1;

  @observable lastSelectedTab = [];
  @observable lastSelectedIndex = -12;

  @action("update property") updateProperty(key, value) {
    this[key] = value;
  }

  @action("load initial tabs") loadInitialTabs = async () => {
    try {
      let user = await this.rootStore.sessionStore.getSession();
      let res = await this.rootStore.requestStore.getUserById(user.id);
      let tabs = [];
      let res2;
      for (let i in res.data.openedStatsTabs) {
        res2 = await this.rootStore.requestStore.getRegionForStats2(
          res.data.openedStatsTabs[i]
        );
        tabs.push(res2.data[0]);
      }
      this.tabRegions = tabs;
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("delete from Tab") deleteFromTab = async (id) => {
    try {
      let stop = false;
      let name, adm;
      for (let i in this.tabRegions) {
        if (this.tabRegions[i]._id === id) {
          let deleteRegion = this.tabRegions.splice(i, 1);
          name = deleteRegion[0].name;
          adm = deleteRegion[0].adm;
          this.updateProperty("confirmAlert", true);
          document.getElementById("succes-alert-stats").innerHTML =
          i18n.t("wa.notifications.remove") + name + " " + adm + i18n.t("wa.notifications.removed");
          setTimeout(() => {
            this.updateProperty("confirmAlert", false);
          }, 5000);
          stop = true;
        }
        if (stop) break;
      }
      let user = await this.rootStore.sessionStore.getSession();
      await this.rootStore.requestStore.addTabToUser(user.id, this.tabRegions);
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("add To Tab") addToTab = async (id) => {
    try {
      if (this.tabRegions.some((item) => item._id === id)) {
        this.updateProperty("showAlert", true);
        document.getElementById("danger-alert-stats").innerHTML =
          i18n.t("wa.notifications.alreasySelected");
        setTimeout(() => {
          this.updateProperty("showAlert", false);
        }, 5000);
      } else if (this.tabRegions.length >= 10) {
        this.updateProperty("showAlert", true);
        document.getElementById("danger-alert-stats").innerHTML =
        "<strong>"+ i18n.t("wa.notifications.attention") + "</strong> " + i18n.t("wa.notifications.tabs");
        setTimeout(() => {
          this.updateProperty("showAlert", false);
        }, 5000);
      } else {
        let res = await this.rootStore.requestStore.getRegionForStats2(id);
        let name, adm;
        res.data.map((item) => {
          name = item.name;
          adm = item.adm;
          return (name, adm);
        });

        if (res === undefined) {
          this.updateProperty("showAlert", true);
          document.getElementById("danger-alert-stats").innerHTML =
          i18n.t("ga.notifications.notUpdated")
          setTimeout(() => {
            this.updateProperty("showAlert", false);
          }, 5000);
        } else {
          this.updateProperty("confirmAlert", true);
          document.getElementById("succes-alert-stats").innerHTML =
            i18n.t("wa.notifications.add") + name + " " + adm + i18n.t("wa.notifications.added");
          setTimeout(() => {
            this.updateProperty("confirmAlert", false);
          }, 5000);
        }
        this.tabRegions.push(res.data[0]);
        let user = await this.rootStore.sessionStore.getSession();
        await this.rootStore.requestStore.addTabToUser(
          user.id,
          this.tabRegions
        );
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("find subRegions") findSubRegions = async (id, adm) => {
    try {
      let a = {
        _id: id,
        adm: adm,
      };
      let res2 = await this.rootStore.requestStore.getChildRegionsDataForStats(
        a
      );
      return res2.data;
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("Get listRegions ids") getlistRegionsIds = async (allRegions) => {
    try {
      console.log(allRegions)
      if (allRegions) {
        let res = await this.rootStore.requestStore.searchBiggestRegion("1");
        console.log(res.data[0])
        this.listRegions = [res.data[0]._id];
      }
      else {
        let user = await this.rootStore.sessionStore.getSession();
        let res = await this.rootStore.requestStore.getUserById(user.id);
        console.log(res.data.assignedRegions)
        this.listRegions = res.data.assignedRegions;
      }
      for (let i in this.listRegions) {
        let res = await this.rootStore.requestStore.getRegionForStats(
          this.listRegions[i]
        );
        this.totalR.push(res.data[0]);
      }
      this.Regions = JSON.parse(JSON.stringify(this.totalR));
      this.totalR = [];
      console.log(this.Regions)
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };
}
export default RegionSelectorStore;
