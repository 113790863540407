import React, { Component } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

@observer
class MyButton extends Component {

    constructor (props) {
        super(props)
        this.onClick = this.onClick.bind(this)
    }

    onClick (event) {
        this.props.onClick(event.target.name, event.target.value)
    }

//placement bottom Y right

    render () {
        const tooltip = <Tooltip id="tooltip" >{this.props.name}</Tooltip>;

        return (
            <>
            {this.props.name ?

                <OverlayTrigger delay={{ show: 500, hide: 100 }} placement= {this.props.toolPlace === "right" ? "right" : "bottom"} overlay={tooltip} >
                    <button
                    id={this.props.id}
                    type={this.props.type}
                    className={this.props.className}
                    name={this.props.name}
                    disabled={this.props.disabled === "true"? true : false}
                    style= {this.props.style}
                    onClick={this.props.onClick}
                    onMouseLeave={this.props.onMouseLeave}
                    onMouseOut={this.props.onMouseOut}
                    onMouseEnter={this.props.onMouseEnter}
                    onMouseOver={this.props.onMouseOver}>
                    {this.props.text}
                    </button>
                </OverlayTrigger>

                 :

                <button
                    id={this.props.id}
                    type={this.props.type}
                    className={this.props.className}
                    name={this.props.name}
                    disabled={this.props.disabled === "true"? true : false}
                    style= {this.props.style}
                    onClick={this.props.onClick}
                    onMouseLeave={this.props.onMouseLeave}
                    onMouseOut={this.props.onMouseOut}
                    onMouseEnter={this.props.onMouseEnter}
                    onMouseOver={this.props.onMouseOver}>
                    {this.props.text}
                </button>

                }
            </>
        );
    }
}

MyButton.propTypes = {
  text: PropTypes.any.isRequired
};

MyButton.defaultProps = {
  type: "submit"
};

export default MyButton;
