import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import CityHall from "../CityHall/CityHall";
import VerticalNavbar from "../VerticalNavbar";
import StatsNirc from "./StatsNirc/StatsNirc";

@inject("userStore", "statisticStore", "navigationStore")
@observer
class Statistics extends Component {
  async componentDidMount() {
    try {
      //let { t } = this.props;
      this.props.statisticStore.initializeStore();
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  render() {
    //const { t } = this.props;
    this.user = this.props.userStore.getUser();

    if (this.user === null) return null;
    else this.username = this.user.username;
    let page = this.props.navigationStore.history.location.pathname;
    console.log(page);
    return (
      <div style={{ display: "flex" }}>
        <div className="bg-light">
          <VerticalNavbar from="Statistics"></VerticalNavbar>
        </div>
        <div  style={{ width: "90%"}}>
          {page === "/statistics/cityhall" ? (
            <CityHall></CityHall>
          ) : page === "/statistics/statistics" ? (
            <StatsNirc />
          ) : (
            "ERROR 404"
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Statistics);
