// import { observable, action } from "mobx";
import { action } from "mobx";


class HostListStore {
  // @observable favAds = null;
  // @observable favRegions = null;
  // @observable logs = null;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action("get host list data") getHostListStore = async () => {
    // let res = await this.rootStore.requestStore.getDashboardData();
    // this.setDashboard(res.data);
  };

  @action("Set host list") setHostListStore = ({ ads, regions, logs }) => {
    // this.favAds = ads;
    // this.favRegions = regions;
    // this.logs = logs;
  };
}

export default HostListStore;
