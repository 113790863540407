import { observable, action, computed } from "mobx";

class ResetPasswordStore {

  constructor (rootStore) {
	this.rootStore = rootStore;
    this.updateFormProperty = this.updateFormProperty.bind(this)

    this.lowerCaseLetters = /[a-z]/g
    this.upperCaseLetters = /[A-Z]/g
    this.numbers = /[0-9]/g
    this.specialChars = /[^\w\s]/gi
    this.requiredLength = 8
  }

  // Form fields
  @observable resetForm = {
    password: "",
    confirmPassword: ""
  }

  // Control variables
  @observable username = ""
  @observable group = ""
  @observable passwordHasBeenUpdated = ""
  @observable isLoading = true
  @observable showError = false
  @observable formSent = false
  @observable formIsValid = true

  // Other variables
  @observable token = null


  @action("update form property") updateFormProperty (key, value) {
    this.resetForm[key] = value
  }

  @action("update property not in form") updateProperty (key, value) {
    this[key] = value
  }

  @computed get hasLetter() {
    return !!this.resetForm.password.match(this.lowerCaseLetters)
  }
  @computed get hasCapital() {
    return !!this.resetForm.password.match(this.upperCaseLetters)
  }
  @computed get hasNumber() {
    return !!this.resetForm.password.match(this.numbers)
  }
  @computed get hasSpecialChar() {
    return !!this.resetForm.password.match(this.specialChars) || this.resetForm.password.match('_')
  }
  @computed get hasLength() {
    if (this.resetForm.password.length >= this.requiredLength) return true
    else return false
  }
  @computed get passwordsMatch() {
    if (this.resetForm.password.length > 0
      && this.resetForm.password === this.resetForm.confirmPassword) return true
    else return false
  };

  @computed get checkIfFormIsValid() {
    return (this.hasCapital
    && this.hasLength
    && this.hasLetter
    && this.hasNumber
    && this.hasSpecialChar
    && this.passwordsMatch)
  };

  @action("get resetPassword data") getResetPassword = async () => {
    try {
      this.updateProperty('isLoading', true)
      let search = this.rootStore.navigationStore.history.location.search
      if (search[0] === '?') search = search.substr(1)
      let searchSplitted = search.split('=')
      let groupCode = searchSplitted[1]
      this.group = groupCode
      const response = await this.rootStore.requestStore.resetPassword(
        this.token,
        groupCode
      )
      if (response.data.message === 'password reset link a-ok') {
        this.updateProperty('username', response.data.username)
        this.updateProperty('passwordHasBeenUpdated', false)
        this.updateProperty('showError', false)
      }
      this.updateProperty('isLoading', false)

    } catch (error) {
  	  if (error.toString() === "Error: AuthError")
  	   console.log("Authentication failed");

      this.updateProperty('passwordHasBeenUpdated', false)
      this.updateProperty('isLoading', false)
      this.updateProperty('showError', true)
    }
  }

  // @action("get resetPassword data") getResetPassword = async () => {
  //   try {
  //     this.updateProperty('isLoading', true)
  //     const response = await this.rootStore.requestStore.resetPassword(
  //       this.token
  //     )
  //
  //     if (response.data.message === 'password reset link a-ok') {
  //       this.updateProperty('username', response.data.username)
  //       this.updateProperty('passwordHasBeenUpdated', false)
  //       this.updateProperty('showError', false)
  //     }
  //
  //     this.updateProperty('isLoading', false)
  //
  //   } catch (error) {
  //     console.log(error);
  //     this.updateProperty('passwordHasBeenUpdated', false)
  //     this.updateProperty('isLoading', false)
  //     this.updateProperty('showError', true)
  //   }
  // }

  updatePassword = async (e) => {
    try {
      e.preventDefault();
      this.updateProperty('formSent', true)
      this.updateProperty('formIsValid', this.checkIfFormIsValid)
      this.updateProperty('showError', false)
      if (this.formIsValid) {
        const response = await this.rootStore.requestStore.updatePasswordViaEmail(
          this.username,
          this.group,
          this.resetForm.password,
          this.token
        )
        if (response.data.message === 'password updated') {
          this.updateProperty('passwordHasBeenUpdated', true)
          this.updateProperty('showError', false)
          this.updateProperty('formSent', false)
          this.updateFormProperty('password', '')
          this.updateFormProperty('confirmPassword', '')
        } else {
          this.updateProperty('passwordHasBeenUpdated', false)
          this.updateProperty('showError', true)
          this.updateProperty('formSent', false)
        }
      }
    } catch (error) {
  	      if (error.toString() === "Error: AuthError")
  	        console.log("Authentication failed");

      this.updateProperty('showError', true)
      this.updateProperty('formSent', false)
      this.updateProperty('isLoading', false)
    }
  };
}

export default ResetPasswordStore;
