import React, { Component } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import MyRadioButton from "./MyRadioButton";

@observer
class MyRadioButtonList extends Component {
    
  render() {
    console.log(this.props.selectOptions)
    return (
      <div className="form-group">
        <label htmlFor={this.props.id}>{this.props.label}</label>
        {this.props.selectOptions.map(selectopt => (
          <MyRadioButton
            key={selectopt.key}
            id={selectopt.key}
            name={this.props.name}
            onChange={this.props.onChange}
            value={selectopt.key}
            labelValue={selectopt.value}
            checked={this.props.checked === selectopt.key}
            //tooltipA = {this.props.tooltipA ===  "true"? "true" : "false"}
            //tooltipName = {this.props.tooltipA === "true"? selectopt.name : "null"}
            //toolPlace = {this.props.toolPlace}
          />
        ))}
      </div>
    );
  }
}

MyRadioButtonList.propTypes = {
  selectedOptions: PropTypes.array.isRequired
};

export default MyRadioButtonList;
