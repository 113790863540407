//const axios = require("axios");
import axios from "axios";
const XLSX = require("xlsx");
//const FormData = require("form-data");

class RequestStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    logoutIfSessionHasExpired = async (data) => {
        if (data === "auth error") {
            await this.rootStore.userStore.logout();
            throw new Error("AuthError");
        }
    };

    getDashboardData = async () => {
        try {
            let res = await axios.get("/api/utils/dashboard", {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    forgotPassword = async (username, groupCode) => {
        try {
            let res = await axios.post("/api/session/forgotPassword", {
                username,
                groupCode,
            });
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    updatePasswordViaEmail = async (
        username,
        group,
        password,
        resetPasswordToken
    ) => {
        try {
            let res = await axios.put("/api/session/updatePasswordViaEmail", {
                username,
                group,
                password,
                resetPasswordToken,
            });
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    resetPassword = async (resetPasswordToken, groupCode) => {
        try {
            const res = await axios.get("/api/session/resetPassword", {
                params: {
                    resetPasswordToken,
                    groupCode,
                },
            });
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    updatePasswordViaProfile = async (username, password) => {
        try {
            let res = await axios.put("/api/session/updatePasswordViaProfile", {
                username,
                password,
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "S'ha actualitzat la constrasenya de l'usuari" + username,
                date: Date.now(),
                type: "user",
                page: "Profile",
            };
            this.postLog(newLog);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendContactEmail = async (title, description) => {
        try {
            const res = await axios.post("/api/utils/sendContactEmail", {
                title,
                description,
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "L'usuari" +
                    this.rootStore.userStore.getUser().username +
                    "ha enviat un correu de contacte",
                date: Date.now(),
                type: "contact",
                page: "Contact",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getApartmentById = async (id) => {
        try {
            let res = await axios.get("/api/ad/" + id);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getApartmentById2 = async (letter, id) => {
        try {
            let res = await axios.get("/api/ad/?" + letter + "=" + id, {
                params: {
                    projection: "l$h$i&o",
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getRegions = async (params) => {
        try {
            let res = await axios.get("/api/region", { params: params });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getRegion = async (query) => {
        try {
            let res = await axios.get("/api/region/" + query, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getRegionForStats = async (query) => {
        try {
            let res = await axios.get("/api/region/?q=" + query, {
                params: {
                    projection: "q$f$h$i",
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getRegionForStats2 = async (query) => {
        try {
            let res = await axios.get("/api/region/?q=" + query, {
                params: {
                    projection: "q$f$h$i",
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getRegionID = async (name, adm) => {
        try {
            let res = await axios.get(
                "/api/region?f=" + name + "&h=" + adm,
                {}
            );
            if (res.data[0] === undefined) return "error";
            await this.logoutIfSessionHasExpired(res.data);
            return res.data[0]._id;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAdGroupByCode = async (code) => {
        try {
            let res = await axios.get("/api/adGroup/TOT/?b=" + code, {});
            await this.logoutIfSessionHasExpired(res.data);
            if (res === undefined) return "error";
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAdsByGroupCode = async (code) => {
        try {
            let res = await axios.get("/api/ad/?l=" + code, {
                params: {
                    projection: "a",
                },
            });
            if (res.data[0] === undefined) return "error";
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getUserData = async (username) => {
        try {
            let res = await axios.get(
                "/api/user/profile/?username=" + username,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    updateUserData = async (id, body, username) => {
        try {
            let res = await axios.put("/api/user/" + id, body);
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "S'han modificat les regions assignades de l'usuari " +
                    username,
                date: Date.now(),
                type: "user",
                page: "Working Region",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    //-------------------------------------------------------
    sendUpdateAdIsNotNew = async (id, body) => {
        try {
            const res = await axios.put("/api/ad/" + id, {
                adIsSeen: body,
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message: "S'ha visitat l'anunci nou " + id,
                date: Date.now(),
                idAd: id,
                type: "visited",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getMaxCode = async () => {
        try {
            const res = await axios.get("/api/adGroup/idGroup", {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendUpdateAdCode = async (id, body) => {
        try {
            const res = await axios.put("/api/ad/" + id, {
                adGroupCode: body,
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "S'ha modificat l'anunci " +
                    id +
                    "per a que pertanyi al grup d'anunci " +
                    body,
                date: Date.now(),
                idAd: id,
                idGroupAds: body,
                type: "ad",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendUpdateAdIsDiscarded = async (id, discards, noDiscards, idAd, code) => {
        try {
            const res = await axios.put("/api/adGroup/" + id, {
                discardedChildrenId: discards,
                childrenId: noDiscards,
            });
            let ress = [idAd];
            await axios.post("/api/adGroup/", {
                _id: code,
                childrenId: ress,
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "sendUpdateAdIsDiscarded: Actualment no es fa servir aquesta funció, si hi ha algun log, comprobar referencies. S'ha actualizat els grups d'anuncis " +
                    id +
                    " i " +
                    code,
                date: Date.now(),
                idGroupAds: [id, code],
                type: "group",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendUpdateProcesses = async (id, body) => {
        try {
            const res = await axios.put("/api/adGroup/" + id, {
                process: body,
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "S'ha actualitzat els processos del grup d'anuncis " + id,
                date: Date.now(),
                idGroupAds: id,
                type: "group",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendUpdateAdIsKeept = async (id, discards, noDiscards, iid) => {
        try {
            const res = await axios.put("/api/adGroup/" + id, {
                discardedChildrenId: discards,
                childrenId: noDiscards,
            });
            const resss = await axios.get("/api/ad/" + iid, {});
            const ress = await axios.put(
                "/api/adGroup/" + resss.data.adGroupCode,
                {}
            );
            if (ress.data.childrenId.length > 1) {
                for (let i in ress.data.childrenId) {
                    if (ress.data.childrenId[i] === iid) {
                        await axios.put(
                            "/api/adGroup/" + resss.data.adGroupCode,
                            {
                                discardedChildrenId:
                                    ress.data.discardedChildrenId,
                                childrenId: ress.data.childrenId,
                            }
                        );
                        break;
                    }
                }
            } else {
                await axios.delete(
                    "/api/adGroup/" + resss.data.adGroupCode,
                    {}
                );
            }
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "sendUpdateAdIsKeept: Actualment no es fa servir aquesta funció, si hi ha algun log, comprobar referencies. S'ha actualitzat uns grups d'anuncis " +
                    id +
                    " i " +
                    +resss.data.adGroupCode,
                date: Date.now(),
                idGroupAds: [id, resss.data.adGroupCode],
                type: "group",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    //--------------------------------------------------------------------------
    sendUpdateAdGroup = async (id, body, diff) => {
        try {
            let geo;
            let title;
            title = body.title;
            if (body.latitude === "" && body.longitude === "") geo = null;
            else geo = [parseFloat(body.longitude), parseFloat(body.latitude)];
            let res;
            if (geo !== null) {
                res = await axios.put("/api/adGroup/" + id, {
                    address: body.adAdress,
                    license: body.licenseNumber,
                    municipality: body.municipality,
                    roomType: body.typeOfRoom,
                    title: title,
                    tags: body.tags,
                    "host.name": body.ownerName,
                    geometry: {
                        type: "Point",
                        coordinates: geo,
                    },
                });
            } else {
                res = await axios.put("/api/adGroup/" + id, {
                    address: body.adAdress,
                    license: body.licenseNumber,
                    municipality: body.municipality,
                    roomType: body.typeOfRoom,
                    title: title,
                    tags: body.tags,
                    "host.name": body.ownerName,
                    geometry: null,
                });
            }
            let logMessage =
                "S'ha modificat dades del grup d'anunci: " +
                id +
                ". Dades modificades: ";
            if (diff.adAdress !== "noModified")
                logMessage +=
                    "Adreça: de " + diff.adAdress + " a " + body.adAdress + " ";
            if (diff.code !== "noModified")
                logMessage += "Codi: de " + diff.code + " a " + body.code + " ";
            if (diff.latitude !== "noModified")
                logMessage +=
                    "Latitud: de " +
                    diff.latitude +
                    " a " +
                    body.latitude +
                    " ";
            if (diff.longitude !== "noModified")
                logMessage +=
                    "Longitud: de " +
                    diff.longitude +
                    " a " +
                    body.longitude +
                    " ";
            if (diff.licenseNumber !== "noModified")
                logMessage +=
                    "NIRTC: de " +
                    diff.licenseNumber +
                    " a " +
                    body.licenseNumber +
                    " ";
            if (diff.municipality !== "noModified")
                logMessage +=
                    "Municipi: de " +
                    diff.municipality +
                    " a " +
                    body.municipality +
                    " ";
            if (diff.ownerName !== "noModified")
                logMessage +=
                    "Propietari: de " +
                    diff.ownerName +
                    " a " +
                    body.ownerName +
                    " ";
            if (diff.title !== "noModified")
                logMessage +=
                    "Titol: de " + diff.title + " a " + body.title + " ";
            if (diff.typeOfRoom !== "noModified")
                logMessage +=
                    "Tipus d'habitació: de " +
                    diff.typeOfRoom +
                    " a " +
                    body.typeOfRoom +
                    " ";
            let addedTags = "";
            let removedTags = "";
            for (let phrase of diff.tags) {
                let splitted = phrase.split("/");
                if (splitted[0] === "Added")
                    addedTags += " " + splitted[1] + ",";
                else if (splitted[0] === "Removed")
                    removedTags += " " + splitted[1] + ",";
            }
            if (diff.tags !== [])
                logMessage +=
                    "Tags afegits:" + addedTags + " eliminats:" + removedTags;

            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message: logMessage,
                date: Date.now(),
                idGroupAds: id,
                type: "group",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    insertInProcessList = async (process) => {
        try {
            let res = await axios.post("/api/process", {
                _id: process._id,
                title: process.title,
                generalReport: process.generalReport,
                task: process.task,
                finished: process.finished,
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "S'han actualitzat els processos amb id: " + process._id,
                date: Date.now(),
                type: "other",
                page: "Proces",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    updateProcessList2 = async (process2, group) => {
        try {
            for (let i in this.rootStore.groupAdsStore.processes) {
                if (
                    this.rootStore.groupAdsStore.processes[i]._id ===
                    process2._id
                ) {
                    this.rootStore.groupAdsStore.processes[i] = process2;
                }
            }
            let aux = "process." + process2._id;
            let res = await axios.put("/api/adGroup/" + group, {
                [aux]: {
                    _id: process2._id,
                    title: process2.title,
                    generalReport: process2.generalReport,
                    task: process2.task,
                    finished: process2.finished,
                },
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "S'han actualitzat els processos amb id " +
                    process2._id +
                    " del grup d'anuncis " +
                    group,
                date: Date.now(),
                idGroupAds: group,
                type: "group",
                page: "Proces",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    updateProcessList = async (process) => {
        try {
            let intId = parseInt(process._id);
            let res = await axios.put("/api/process/" + intId, {
                _id: process._id,
                title: process.title,
                generalReport: process.generalReport,
                task: process.task,
                finished: process.finished,
            });
            await this.logoutIfSessionHasExpired(res.data);
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "S'han actualitzat els processos amb id: " + process._id,
                date: Date.now(),
                type: "other",
                page: "Proces",
            };
            this.postLog(newLog);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    updateProcessState = async (process, group) => {
        try {
            parseInt(process._id);
            for (let i in this.rootStore.groupAdsStore.processes) {
                if (
                    this.rootStore.groupAdsStore.processes[i]._id ===
                    process._id
                ) {
                    this.rootStore.groupAdsStore.processes[i].finished = true;
                }
            }
            let aux = "process." + process._id;
            let res = await axios.put("/api/adGroup/" + group, {
                [aux]: {
                    _id: process._id,
                    title: process.title,
                    generalReport: process.generalReport,
                    task: process.task,
                    finished: true,
                },
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "S'han actualitzat els processos amb id " +
                    process._id +
                    " del grup d'anuncis: " +
                    group,
                date: Date.now(),
                idGroupAds: group,
                type: "group",
                page: "Proces",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    loginUser = async (user) => {
        try {
            console.log("ENTRANDO A LLAMADA LOGIN_USER");
            let res = await axios.post("/api/session/login", {
                username: user.username,
                password: user.password,
                groupCode: user.group,
            });
            console.log("AFTER LLAMADA LOGIN_USER");
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
            return "err";
        }
    };

    logoutUser = async () => {
        try {
            await axios.get("/api/session/logout");
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    searchAdsById = async (text) => {
        try {
            text = text.replace("(", "");
            text = text.replace(")", "");
            let res = await axios.get("/api/ad/searchById", {
                params: {
                    value: text,
                    limit: 10,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    searchAdsByGroupId = async (text) => {
        try {
            text = text.replace("(", "");
            text = text.replace(")", "");
            let res = await axios.get("/api/adGroup", {
                params: {
                    b: "contains/$/" + text,
                    mySearch: true,
                    projection: "a$b",
                    limit: 10,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    searchAdsByLicenseCode = async (text) => {
        try {
            text = text.replace("(", "");
            text = text.replace(")", "");
            let user = await this.rootStore.userStore.getUser();
            let res = await axios.get("/api/ad", {
                params: {
                    i: "contains/$/" + text,
                    mySearch: true,
                    projection: "i",
                    limit: 30,
                    userID: user.id,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    searchAdsByHost = async (text) => {
        try {
            text = text.replace("(", "");
            text = text.replace(")", "");
            let user = await this.rootStore.userStore.getUser();
            let res = await axios.get("/api/ad", {
                params: {
                    h: "contains/$/" + text,
                    mySearch: true,
                    projection: "h",
                    limit: 30,
                    userID: user.id,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    searchRegionsByName = async (text) => {
        try {
            text = text.replace("(", "");
            text = text.replace(")", "");
            let res = await axios.get("/api/region", {
                params: {
                    f: "contains/$/" + text,
                    mySearch: true,
                    projection: "f$h$j",
                    limit: 10,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getApartmentsData = async (fr) => {
        try {
            let res = await axios.get(
                "/api/ad/?regionName=" + fr._id + "&adm=" + fr.adm,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getApartmentsDataForAGroups = async (ads, userFromGa) => {
        try {
            let user = await this.rootStore.userStore.getUser();
            if (!user && userFromGa) {
                user = userFromGa;
                user["id"] = userFromGa._id;
            }
            let res = await axios.get("/api/ad/adsAreInAssignedR", {
                params: {
                    userID: user.id,
                    adList: ads,
                    projection: "a",
                    limit: 1,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getMultipleApartmentsById = async (ids) => {
        try {
            let res = await axios.get("/api/ad", {
                params: {
                    a: "in/$/" + ids,
                    projection: "a$b$f$g$i$j$l$m$n$geo",
                    // limit: 20
                },
            });
            await this.logoutIfSessionHasExpired(res.data);

            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getChildRegionsData = async (region) => {
        try {
            let newAdm = parseInt(region.adm.substr(region.adm.length - 1)) + 1;

            let queryParams = {
                parent: region._id,
                adm: region.adm,
                newAdm: "adm" + newAdm,
                limit: "99999",
                dir: "1",
                sort: "a",
            };

            let res = await axios.get("/api/region/regionsInMap", {
                params: queryParams,
            });
            await this.logoutIfSessionHasExpired(res.data);

            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getChildRegionsDataForStats = async (fr) => {
        try {
            let newAdm = parseInt(fr.adm.substr(fr.adm.length - 1)) + 1;
            let res = await axios.get(
                "/api/region/?parent=" +
                    fr._id +
                    "&adm=" +
                    fr.adm +
                    "&newAdm=adm" +
                    newAdm +
                    "&limit=99999&dir=1&sort=a",
                {
                    params: {
                        projection: "q$f$h$i",
                    },
                }
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getUserById = async (id, params) => {
        try {
            let res = await axios.get("/api/user/" + id, { params: params });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendUpdateUser = async (id, body, page, modified) => {
        try {
            const res = await axios.put("/api/user/" + id, body);
            await this.logoutIfSessionHasExpired(res.data);
            let logMessage =
                "S'ha modificat diversos atributs de l'usuari " +
                this.rootStore.userStore.getUser().username;
            if (modified.favAds === "modified")
                logMessage =
                    "S'ha modificat els anuncis preferits de l'usuari " +
                    this.rootStore.userStore.getUser().username;
            else if (modified.tabsOpen === "modified")
                logMessage =
                    "S'ha modificat els tabs oberts de l'usuari " +
                    this.rootStore.userStore.getUser().username;
            else {
                if (modified.username !== "noModified")
                    logMessage +=
                        ", Username de " +
                        modified.username +
                        " a " +
                        body.username;
                if (modified.firstName !== "noModified")
                    logMessage +=
                        ", Nom de " +
                        modified.firstName +
                        " a " +
                        body.firstName;
                if (modified.lastName !== "noModified")
                    logMessage +=
                        ", Cognoms de " +
                        modified.lastName +
                        " a " +
                        body.lastName;
                if (modified.group !== "noModified")
                    logMessage +=
                        ", Grup de " + modified.group + " a " + body.group;
                if (modified.email !== "noModified")
                    logMessage +=
                        ", email de " + modified.email + " a " + body.email;
                logMessage += ".";
            }
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message: logMessage,
                date: Date.now(),
                type: "user",
                page: page,
            };
            this.postLog(newLog);

            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    changeUserClicked = async (id, userName, newValue) => {
        try {
            let query = "usersWhoClicked." + userName;
            let res = await axios.put("/api/ad/" + id, {
                [query]: newValue,
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getProcessList = async () => {
        try {
            let res = await axios.get("/api/process", {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getUsersFromGroup = async (group) => {
        try {
            let res = await axios.get("/api/user/team/?user=" + group, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    // getInitialUsersFromGroup = async (filter, len, dir) => {
    //   let res = await axios.get(
    //     "/api/user/team/initial/?filter=" +
    //     filter +
    //     "&len=" +
    //     len +
    //     "&dir=" +
    //     dir,
    //     {}
    //   );
    //   await this.logoutIfSessionHasExpired(res.data);
    //   return res;
    // };

    // getUsersFromGroup = async (filter, last, unique, len, dir) => {
    //   let res = await axios.get(
    //     "/api/user/team/?filter=" +
    //     filter +
    //     "&last=" +
    //     last +
    //     "&unique=" +
    //     unique +
    //     "&len=" +
    //     len +
    //     "&dir=" +
    //     dir,
    //     {}
    //   );
    //   await this.logoutIfSessionHasExpired(res.data);
    //   return res;
    // };

    getInitialLogsFromGroup = async (filter, len, dir) => {
        try {
            let res = await axios.get(
                "/api/log/group/initial/?filter=" +
                    filter +
                    "&len=" +
                    len +
                    "&dir=" +
                    dir,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getLogsFromGroup = async (filter, last, unique, len, dir) => {
        try {
            let res = await axios.get(
                "/api/log/group/?filter=" +
                    filter +
                    "&unique=" +
                    unique +
                    "&last=" +
                    last +
                    "&len=" +
                    len +
                    "&dir=" +
                    dir,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    //Tots els ads de la BD pertanyen al mateix grup, especificat el dia 30 - 11
    getAdsFromGroup = async (filter, last, unique, len, dir) => {
        try {
            let res = await axios.get(
                "/api/ad/group/?filter=" +
                    filter +
                    "&unique=" +
                    unique +
                    "&last=" +
                    last +
                    "&len=" +
                    len +
                    "&dir=" +
                    dir,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);

            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getFavsListPaginated = async (filters) => {
        try {
            let res = await axios.get("/api/ad/getFavsPage" + filters, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getHostsListPaginated = async (filters) => {
        try {
            // console.log("filters =", filters);

            let res = await axios.get("/api/ad/getHostsPage" + filters, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getLicensesListPaginated = async (filters) => {
        try {
            let res = await axios.get("/api/ad/getLicensesPage" + filters, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAdListPaginated = async (filters) => {
        try {
            let res = await axios.get("/api/ad/getAdsPage" + filters, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getPageIndexes = async (filters, maxPages) => {
        try {
            let res = await axios.get("/api/ad/getPageIndexes" + filters, {
                params: {
                    maxPages,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);

            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getInitialAdsFromGroup = async (filter, len, dir) => {
        try {
            let res = await axios.get(
                "/api/ad/group/initial?filter=" +
                    filter +
                    "&len=" +
                    len +
                    "&dir=" +
                    dir,
                {}
            );

            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getLogsByUser = async (username, filter, last, unique, len, dir) => {
        try {
            let res = await axios.get(
                "/api/log/user/?username=" +
                    username +
                    "&filter=" +
                    filter +
                    "&len=" +
                    len +
                    "&lastUnique" +
                    unique +
                    "&lastParam=" +
                    last +
                    "&dir=" +
                    dir,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getInitialLogsByUser = async (username, filter, len, dir) => {
        try {
            let res = await axios.get(
                "/api/log/user/initial/?username=" +
                    username +
                    "&filter=" +
                    filter +
                    "&len=" +
                    len +
                    "&dir=" +
                    dir,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    // PAGINACIÓ LLICÈNCIES
    getLicensesByUser = async (username, filter, last, unique, len, dir) => {
        try {
            let res = await axios.get(
                "/api/ad/license/?username=" +
                    username +
                    "&filter=" +
                    filter +
                    "&last=" +
                    last +
                    "&lastUnique" +
                    unique +
                    "&len=" +
                    len +
                    "&dir=" +
                    dir,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getInitialLicenseByUser = async (username, filter, len, dir) => {
        try {
            let res = await axios.get(
                "/api/ad/license/initial/?username=" +
                    username +
                    "&filter=" +
                    filter +
                    "&len=" +
                    len +
                    "&dir=" +
                    dir,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAdsLicense = async (username, id) => {
        try {
            let res = await axios.get(
                "/api/ad/license/" + id + "/?username=" + username,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getHosts = async (params) => {
        try {
            let res = await axios.get(
                "/api/ad/host/?last=" +
                    params.last +
                    "&filter=" +
                    params.filter +
                    "&len=" +
                    params.len +
                    "&dir=" +
                    params.dir +
                    "&user=" +
                    params.username +
                    "&min=" +
                    params.minPrice +
                    "&max=" +
                    params.maxPrice +
                    "&platform=" +
                    params.platform +
                    "&state=" +
                    params.state +
                    "&license=" +
                    params.license +
                    "&minres=" +
                    params.minRes +
                    "&maxres=" +
                    params.maxRes +
                    "&lm=" +
                    params.firstMonth +
                    "&fm=" +
                    params.lastMonth,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getInitialHosts = async (params) => {
        try {
            let res = await axios.get(
                "/api/ad/host/initial/?filter=" +
                    params.filter +
                    "&len=" +
                    params.len +
                    "&dir=" +
                    params.dir +
                    "&user=" +
                    params.username +
                    "&min=" +
                    params.minPrice +
                    "&max=" +
                    params.maxPrice +
                    "&platform=" +
                    params.platform +
                    "&state=" +
                    params.state +
                    "&license=" +
                    params.license +
                    "&minres=" +
                    params.minRes +
                    "&maxres=" +
                    params.maxRes +
                    "&lm=" +
                    params.firstMonth +
                    "&fm=" +
                    params.lastMonth,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAdsByHost = async (host) => {
        try {
            let res = await axios.get("/api/ad/?h=" + host, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getListByLicense = async (filter) => {
        try {
            let res = await axios.get("/api/ad/" + filter, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAdsTeam = async (username, region) => {
        try {
            let res = await axios.get("/api/ad/team/?username=" + username, {
                params: region,
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getGroupAds = async (letter, code) => {
        try {
            let res = await axios.get("/api/adGroup/?" + letter + "=" + code, {
                params: {
                    projection: "b$s",
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAdsFromGroupAd = async (code) => {
        try {
            let res = await axios.get("/api/adGroup/ad/" + code, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getInfoMap = async (focusedRegion, filter, url, ascDesc, value) => {
        try {
            let queryParams =
                "/api/adGroup/mapAdGroups?regionName=" +
                focusedRegion._id +
                "&adm=" +
                focusedRegion.adm +
                "&len=" +
                filter.len +
                "&maxPages=" +
                filter.maxPages +
                "&workingUser=" +
                filter.workingUser +
                "&favAds=" +
                filter.favAds +
                "&notWorking=" +
                filter.notWorking +
                "&" +
                url;
            let res = await axios.get(queryParams, {
                params: {
                    ascDesc: ascDesc,
                    val: value,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getlistGroupAdsInMap = async (focusedRegion, boundaries, filter, url) => {
        try {
            let queryParams =
                "/api/adGroup/mapAdGroups?regionName=" +
                focusedRegion._id +
                "&adm=" +
                focusedRegion.adm +
                "&len=" +
                filter.len +
                "&maxPages=" +
                filter.maxPages +
                "&workingUser=" +
                filter.workingUser +
                "&favAds=" +
                filter.favAds +
                "&notWorking=" +
                filter.notWorking +
                "&" +
                url;

            if (!!boundaries) {
                queryParams += "&boundaries=" + JSON.stringify(boundaries);
            }
            let res = await axios.get(queryParams, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAllGroupAds = async () => {
        try {
            let res = await axios.get("/api/adGroup", {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    changeWorking = async (code, username, page) => {
        try {
            let res = await axios.put("/api/adGroup/ads/" + code, {
                workingUser: username,
            });
            let message =
                "changeWorking: Actualment no es fa servir aquesta funció, si hi ha algun log, comprobar referencies. S'ha modificat el grup d'anunci " +
                code;
            if (username)
                message += " per a que ara el treballi l'usuari: " + username;
            else
                message +=
                    " per a que ara no el treballi l'usuari: " +
                    this.rootStore.userStore.getUser().username;
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message: message,
                date: Date.now(),
                idGroupAds: code,
                type: "group",
                page: page,
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    searchBiggestRegion = async (admNumber) => {
        try {
            let res = await axios.get("/api/region/IncGeo/?h=adm" + admNumber);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAdStatisticsList = async (regionId = null) => {
        try {
            let res = await axios.get("/api/ad/statistics", {
                params: {
                    regionId: regionId,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    addTabToUser = async (id, body) => {
        try {
            const res = await axios.put("/api/user/" + id, {
                openedStatsTabs: body,
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "S'han modificat les tabs de la WA de l'usuari " +
                    this.rootStore.userStore.getUser().username,
                date: Date.now(),
                type: "user",
                page: "WA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAdGroupById = async (id, params) => {
        try {
            if (!params) params = {};
            let res = await axios.get("/api/adGroup/" + id, params);
            /*let newLog = {
        user: this.rootStore.userStore.getUser().username,
        message: "S'ha modificat l'adGroup " + id,
        date: Date.now(),
        idGroupAds: id,
        type: "group",
      };
      this.postLog(newLog);*/
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getMultipleApartmentsByIdForAdGroups = async (ids) => {
        try {
            let res = await axios.get("/api/ad/ads", {
                params: {
                    a: "in/$/" + ids,
                    limit: 9999999,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);

            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getMultipleAds = async (ids, params) => {
        try {
            if (!params.a) params["a"] = "in/$/" + ids;
            let res = await axios.get("/api/ad/ads", {
                params: params,
            });
            await this.logoutIfSessionHasExpired(res.data);

            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getMultipleAdsForGroupEditor = async (ids, params) => {
        try {
            if (!params.a) params["a"] = "in/$/" + ids;
            let res = await axios.get("/api/ad/adsEditGroup", {
                params: params,
            });
            await this.logoutIfSessionHasExpired(res.data);

            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getLastAdGroup = async () => {
        try {
            let res = await axios.get("/api/adGroup/last", {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    newAdGroup = async (adGroup) => {
        try {
            let res = await axios.post("/api/adGroup/", adGroup);
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message: "S'ha afegit un nou adGroup amb id " + adGroup._id,
                date: Date.now(),
                idGroupAds: adGroup._id,
                type: "group",
                page: "Desagrupar anuncis",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    modifyAdGroup = async (adGroup) => {
        try {
            let res = await axios.put("/api/adGroup/" + adGroup._id, adGroup);
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message: "S'ha modificat l'adGroup " + adGroup._id,
                date: Date.now(),
                idGroupAds: adGroup._id,
                type: "group",
                page: "Desagrupar anuncis",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    modifyAdList = async (ads, gaId) => {
        try {
            let res = await axios.put("/api/ad/list", { ids: ads, ga: gaId });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "S'ha modificat els ads ( " +
                    JSON.stringify(ads) +
                    " ) per a que siguin part del Grup d'anunci " +
                    gaId,
                date: Date.now(),
                idAd: ads,
                idGroupAds: gaId,
                type: "ad",
                page: "Desagrupar anuncis",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    modifyAdListIndividual = async (ads, idArray) => {
        try {
            let res = await axios.put("/api/ad/list", {
                ids: ads,
                ini: idArray[0],
                last: idArray[idArray.length - 1],
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "S'ha modificat els ads ( " +
                    JSON.stringify(ads) +
                    " ) per a que siguin part del seu propi Grup d'anunci, ids dels nous grups d'anuncis: " +
                    JSON.stringify(idArray),
                date: Date.now(),
                idAd: ads,
                idGroupAds: JSON.stringify(idArray),
                type: "ad",
                page: "Desagrupar anuncis",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    insideMyAssignedRegionsForWA = async (id, name, adm) => {
        try {
            let user = this.rootStore.userStore.getUser();
            let res = await axios.get(
                "/api/region/subR/?parentId=" +
                    id +
                    "&adm=" +
                    adm +
                    "&limit=99999&dir=1&sort=a",
                {
                    params: {
                        projection: "q$h$i",
                        userFromWA: user.id,
                    },
                }
            );
            await this.logoutIfSessionHasExpired(res.data);
            if (res.data === true) return true;
            else return false;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAdByIdForUsersWhoClicked = async (id) => {
        try {
            let res = await axios.get("/api/ad/" + id, {
                params: {
                    projection: "a$s$l$m$f$t",
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAllUsers = async (params) => {
        try {
            let res = await axios.get("api/user", { params: params });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    changeWorkingNew = async (id, username, page) => {
        try {
            let res = await axios.put("/api/adGroup/workingUser/" + id, {
                workingUser: username,
            });
            let message = "S'ha modificat el grup d'anunci " + id;
            if (username)
                message += " per a que ara el treballi l'usuari: " + username;
            else
                message +=
                    " per a que ara no el treballi l'usuari: " +
                    this.rootStore.userStore.getUser().username;
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message: message,
                date: Date.now(),
                idGroupAds: id,
                type: "group",
                page: page,
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAllProcesses = async (query) => {
        try {
            let res = await axios.get("/api/adGroup/" + query, {
                params: {
                    projection: "b$o",
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    postLog = async (newLog) => {
        try {
            console.log(newLog.message);
            let res = await axios.post("/api/log/", newLog);
            await this.logoutIfSessionHasExpired(res.data);
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    // getAllLogs = async () => {
    //   try {
    //     let res = await axios.get("/api/log/", {params:{limit:400}});
    //     await this.logoutIfSessionHasExpired(res.data);
    //     return res;
    //   } catch (err) {
    //     console.log("An error ocurred while executing the action requested");
    //   }
    // }

    getAllAds = async (params) => {
        try {
            let res = await axios.get("/api/ad/", { params: params });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getLogsForActivity = async (url) => {
        try {
            let res = await axios.get("/api/log/activity/" + url, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) {}
    };

    getLogsForActivityNoSuperuser = async (url) => {
        try {
            let res = await axios.get("/api/log/activityNoSuper/" + url, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) {}
    };

    registerWebCredentials = async (username, formData) => {
        try {
            console.log("REQUEST STORE formData =", formData);
            //console.log("ENTERING REQUEST REGISTER WEB CREDENTIALS")
            let res = await axios.put(
                "/api/user/registerWebCredentials/" + username,
                formData
            );
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "S'ha modificatles credencials de web de l'usuari " +
                    username,
                date: Date.now(),
                type: "user",
                page: "Profile",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            //console.log("EXIT REQUEST REGISTER WEB CREDENTIALS")
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    register = async (body) => {
        try {
            //console.log("entering Register in Request Store")
            let res = await axios.get("/api/session/registerFromPage", {
                params: body,
            });
            /*{
        email:body.email,
        username: body.username,
        isSuperuser: body.isSuperuser,
        isSupervisor: body.isSupervisor,
        password:body.password,
        firstName: body.firstName,
        lastName: body.lastName,
        group: body.group,
        regions: body.regions
      });
      */

            await this.logoutIfSessionHasExpired(res.data);
            if (!res.data.error) {
                let newLog = {
                    user: this.rootStore.userStore.getUser().username,
                    message:
                        "S'ha creat el nou usuari amb username: " +
                        body.username,
                    date: Date.now(),
                    type: "user",
                    page: "New User",
                };
                this.postLog(newLog);
            }
            console.log("res =", res);
            //console.log("exiting Register in Request Store")
            return res;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendAirbnbMessage = async (formData) => {
        try {
            let res = await axios.post(
                "/api/utils/sendAirbnbMessage/",
                formData
            );
            console.log("in request store already");
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "L'usuari" +
                    this.rootStore.userStore.getUser().username +
                    " ha enviat un missatge per Airbnb",
                date: Date.now(),
                type: "user",
                page: "GA",
            };
            this.postLog(newLog);

            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log("error is:", error);

            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendHomeawayMessage = async (formData) => {
        try {
            let res = await axios.post(
                "/api/utils/sendHomeawayMessage/",
                formData
            );
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "L'usuari" +
                    this.rootStore.userStore.getUser().username +
                    " ha enviat un missatge per Homeaway",
                date: Date.now(),
                type: "user",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendTripadvisorMessage = async (formData) => {
        try {
            let res = await axios.post(
                "/api/utils/sendTripadvisorMessage/",
                formData
            );
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "L'usuari" +
                    this.rootStore.userStore.getUser().username +
                    " ha enviat un missatge per Tripadvisor",
                date: Date.now(),
                type: "user",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendLeboncoinMessage = async (formData) => {
        try {
            console.log("send Leboncoin message");

            let res = await axios.post(
                "/api/utils/sendLeboncoinMessage/",
                formData
            );
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "L'usuari" +
                    this.rootStore.userStore.getUser().username +
                    " ha enviat un missatge per Leboncoin",
                date: Date.now(),
                type: "user",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendHousetripMessage = async (formData) => {
        try {
            let res = await axios.post(
                "/api/utils/sendHousetripMessage/",
                formData
            );
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "L'usuari" +
                    this.rootStore.userStore.getUser().username +
                    " ha enviat un missatge per Housetrip",
                date: Date.now(),
                type: "user",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendNiumbaMessage = async (formData) => {
        try {
            let res = await axios.post(
                "/api/utils/sendNiumbaMessage/",
                formData
            );
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "L'usuari" +
                    this.rootStore.userStore.getUser().username +
                    " ha enviat un missatge per Niumba",
                date: Date.now(),
                type: "user",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendRentaliaMessage = async (formData) => {
        try {
            console.log("send rentalia message");

            let res = await axios.post(
                "/api/utils/sendRentaliaMessage/",
                formData
            );
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "L'usuari" +
                    this.rootStore.userStore.getUser().username +
                    " ha enviat un missatge per Rentalia",
                date: Date.now(),
                type: "user",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getPlatforms = async () => {
        try {
            let res = await axios.get("/api/hired/platforms/", {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getStates = async () => {
        try {
            let res = await axios.get("/api/hired/states/", {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    searchAdsByTag = async (text) => {
        try {
            text = text.replace("(", "");
            text = text.replace(")", "");
            let res = await axios.get("/api/adGroup/searchByTag", {
                params: {
                    value: text,
                    limit: 10,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    updateNotesAndLastModification = async (message, id) => {
        try {
            const res = await axios.put("/api/adGroup/" + id, {
                "state.message": message,
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "S'ha actualitzat el comentari del grup d'anuncis " +
                    id +
                    " amb el comentari: " +
                    message.notes,
                date: Date.now(),
                idGroupAds: id,
                type: "group",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    /*
  let aux = "process." + process2._id;
      let res = await axios.put("/api/adGroup/" + group, {
        [aux]: {
          _id: process2._id,
          title: process2.title,
          generalReport: process2.generalReport,
          task: process2.task,
          finished: process2.finished,
        },
  */

    updateStateGA = async (updatedState, id) => {
        try {
            const res = await axios.put("/api/adGroup/state/" + id, {
                state: updatedState,
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "S'ha actualitzat l'estat/situacio del grup d'anuncis " +
                    id +
                    ", l'estat actual es: " +
                    updatedState.name,
                date: Date.now(),
                idGroupAds: id,
                type: "group",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getStateGA = async (id) => {
        try {
            const res = await axios.get("/api/adGroup/" + id, {
                params: {
                    projection: "p$q$r",
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAllStates = async (group) => {
        try {
            const res = await axios.get("/api/hired/concrete", {
                params: {
                    group: group,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    /*
  let res = await axios.get("/api/ad/?l=" + code, {
        params: {
          projection: "a",
  */

    getAllUsersExceptSuperusers = async (params) => {
        try {
            let res = await axios.get("api/user/noSuperuser", {
                params: params,
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    searchGroupsForMerge = async (text, list) => {
        try {
            let res = await axios.get("/api/adGroup/searchById", {
                params: {
                    value: text,
                    nin: list,
                    limit: 20,
                    //municipality:true,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    mergeAdGroup = async (left, right) => {
        try {
            let res = await axios.put("/api/adGroup/mergeGroups", {
                query: {
                    left: left.id,
                    right: right.id,
                },
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "S'han modificat els adgroups " +
                    left.id +
                    " i " +
                    right.id +
                    ". Tots els anuncis de " +
                    right.id +
                    " s'han assignat a " +
                    left.id,
                date: Date.now(),
                idGroupAds: [left.id, right.id],
                type: "group",
                page: "Merge",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    newGroupFromMergeAds = async (left, lChilds, right, rChilds) => {
        try {
            console.log(left, lChilds, right, rChilds);
            let res = await axios.post("/api/adGroup/newFromMerge", {
                query: {
                    left: left,
                    right: right,
                    lChilds: lChilds,
                    rChilds: rChilds,
                },
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "S'ha creat un nou AdGroup amb id " +
                    res.data._id +
                    " amb anuncis provenents dels grups " +
                    left +
                    " i " +
                    right +
                    ".",
                date: Date.now(),
                idGroupAds: [res.data._id, left.id, right.id],
                idAd: lChilds.concat(rChilds),
                type: "group",
                page: "Merge",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    mergeTransferAds = async (left, right, rChilds) => {
        try {
            console.log(left, right, rChilds);
            let res = await axios.put("/api/adGroup/mergeTransferAds", {
                query: {
                    left: left.id,
                    right: right.id,
                    rChilds: rChilds,
                },
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "S'han transferit els ads amb identificadors " +
                    rChilds +
                    "desde " +
                    right.id +
                    " a " +
                    left.id,
                date: Date.now(),
                idGroupAds: [left.id, right.id],
                idAd: rChilds,
                type: "group",
                page: "Merge",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getRegionInfoForSearch = async (value) => {
        try {
            let res = await axios.get(
                "/api/region/regionSearch/?f=" + value.name,
                {
                    params: {
                        parentName: value.parentName,
                        projection: "q$f$h$j",
                    },
                }
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    searchAdsByHostId = async (text) => {
        try {
            text = text.replace("(", "");
            text = text.replace(")", "");
            let user = await this.rootStore.userStore.getUser();
            let res = await axios.get("/api/ad", {
                params: {
                    o: "contains/$/" + text,
                    mySearch: true,
                    limit: 30,
                    projection: "o",
                    userID: user.id,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    transferAds = async (receiver, ads) => {
        try {
            console.log("HOLA");
            let res = await axios.put("/api/adGroup/transferAds", {
                query: {
                    group: receiver,
                    ads: ads,
                },
            });
            console.log("TEST - CREATE LOG");
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "S'han transferit els anuncis " +
                    JSON.stringify(ads) +
                    " al grup d'anuncis: " +
                    receiver,
                date: Date.now(),
                idGroupAds: receiver,
                idAd: ads,
                type: "group",
                page: "Transfer Ads",
            };
            await this.postLog(newLog);
            console.log("LOG - SENDED");
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getMunicipality = async (groupId) => {
        try {
            let res = await axios.get(
                "/api/adGroup/getMunicipality?b=" + groupId,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    searchDiscardedAds = async (discardedAds) => {
        try {
            let res = await axios.get(
                "/api/adGroup/getGroupDiscardedAds?ads=" +
                    JSON.stringify(discardedAds)
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    searchAdsByIdRegex = async (text) => {
        try {
            text = text.replace("(", "");
            text = text.replace(")", "");
            let user = await this.rootStore.userStore.getUser();
            let res = await axios.get("/api/ad", {
                params: {
                    a: "contains/$/" + text,
                    mySearch: true,
                    projection: "a$f$l",
                    limit: 10,
                    userID: user.id,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getCityHallData = async (city) => {
        try {
            let res = await axios.get("/api/cityHall/getCityHall", {
                params: {
                    name: city,
                    // projection: "l$f",
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            console.log(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getCityHallName = async (city) => {
        try {
            let res = await axios.get("/api/region/getCityHall", {
                params: {
                    name: city,
                    // projection: "l$f",
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getUserAssignedRegions = async (id) => {
        try {
            let res = await axios.get("/api/user/getAssignedRegions", {
                params: { id: id, projection: "f" },
            });

            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getExtraProducts = async () => {
        try {
            let res = await axios.get("/api/hired/extra", {});

            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    changeWorkingNewForGroupEditor = async (id, username, page, actualUser) => {
        try {
            let res = await axios.put("/api/adGroup/workingUser/" + id, {
                workingUser: username,
            });
            let message = "S'ha modificat el grup d'anunci " + id;
            if (username)
                message +=
                    ". L'usuari " +
                    username +
                    " s'ha assignat el grup d'anunci durant la creació del nou grup";
            else
                message +=
                    ". L'usuari " +
                    actualUser +
                    " no s'ha assignat el grup d'anunci durant la creació del nou grup";
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message: message,
                date: Date.now(),
                idGroupAds: id,
                type: "group",
                page: page,
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };
    getMail = async (id) => {
        try {
            let res = await axios.get("/api/mail/" + id, {});
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendTicket = async (ticket) => {
        try {
            let res = await axios.post("/api/mail/sendTicket", {
                ticket: ticket,
            });
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };
    /*
  sendFileForTicket = async (file, ticketID) => {
    try {
      let formData = new FormData();
      console.log(file);
      formData.append("manualName", file, file.name);
      for (let key of formData.entries()) {
        console.log(key[0] + ", " + key[1]);
        console.log(formData["manualName"]);
      }
      console.log(formData);
      console.log(formData.get("manualName"));
      console.log(formData.get("firstname"));
      let res = await axios.put(
        "/api/mail/sendFileForTicket",
        {body:formData},
        {
          headers: formData.getHeaders()
          //  {"Content-Type": `multipart/form-data; boundary=${formData._boundary}`,}
          
        }
      );

      return res;
    } catch (error) {
      console.error(error);
      console.log("An error ocurred while executing the action requested");
    }
  };*/

    sendConfirmationMail = async (subject, text, username, group, html) => {
        try {
            //console.log(text, username)
            let res = await axios.post("/api/mail/sendConfirmationMail", {
                subject: subject,
                text: text,
                username: username,
                group: group,
                html: html,
            });
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getGroupAdsErrorCount = async (id) => {
        try {
            console.log(id);
            let res = await axios.get("/api/adGroup/getErrorCount", {
                params: {
                    id: id,
                },
            });
            console.log("Tornant", res);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    generateExcel = async (userName, data) => {
        try {
            console.log("INSIDE THE GENERATE EXCEL");
            let d = new Date().toLocaleString([], {
                dateStyle: "short",
                timeStyle: "short",
            });
            console.log(d);
            d = d.replaceAll("/", "-");
            console.log(d);

            const workSheet = XLSX.utils.json_to_sheet(data);
            var wscols = [{ wch: 12 }, { wch: 20 }, { wch: 50 }, { wch: 20 }];

            workSheet["!cols"] = wscols;
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, "information");
            let buf = XLSX.write(workBook, {
                bookType: "xlsx",
                type: "buffer",
            });
            const url = window.URL.createObjectURL(new Blob([buf]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", userName + "_" + d + ".xlsx");
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
            //console.log(error)
        }
    };

    getAdsForExcel = async (focusedRegion, url) => {
        try {
            console.log("AAAAAAAAAAAAAAAAAA", url);
            let queryParams =
                "/api/adGroup/getExcelAds?regionName=" +
                focusedRegion._id +
                "&adm=" +
                focusedRegion.adm +
                "&" +
                url;
            let res = await axios.get(queryParams, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getCountStats = async (id) => {
        try {
            let queryParams = "/api/ad/getCountStats";
            let res = await axios.get(queryParams, {
                params: {
                    id: id,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            console.log(res)
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    uploadImage = async(formData) => {
        try{
            let res = await axios.post("/api/mail/uploadImage", formData, {headers:{"Content-Type":"multipart/form-data"}})
            let imageUrl = res.data.imageUrl
            res = await axios.get("/api/mail/getImage", {params : {imageUrl:imageUrl},responseType: "arraybuffer"});
            return {image:res, url:imageUrl};
        } catch(error){
            console.log(
                "An error ocurred while uploading the image:", error
            );
        }

    }

    searchComplianceForGroupAd = async (id) => {
        try{
            let res = await axios.get("/api/ad/getComplianceForGroupAd", {
                params: {
                    id: id,
                },
            })
            return(res)
        } catch(error){
            console.log(
               "An error ocurred while executing the action requested"
            );
        }
    }
}

export default RequestStore;
