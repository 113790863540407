import { observable, action } from "mobx";

class AdStore {

  @observable apartment = null

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action("Get apartment") getApartment = async (id) => {
    try {
    let res = await this.rootStore.requestStore.getApartmentById(id)
    this.apartment = res.data
  } catch(error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }
}
export default AdStore;
