import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import MyInput from "../basicUI/MyInput";
import MySelect from "../basicUI/MySelect";
import MyButton from "../basicUI/MyButton";
import MyTextArea from "../basicUI/MyTextArea";

@inject("ticketsStore", "sessionStore", "navigationStore", "userStore")
@observer
class Tickets extends Component {
  async componentDidMount() {
    try {
      if (this.props.sessionStore.initialized) {
        let { t } = this.props;
        document.title = t("tickets.title");
        this.props.ticketsStore.initializeStore();
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  componentWillUnmount() {
    try {
      this.props.ticketsStore.clearStore();
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  render() {
    const { t, /*i18n*/ } = this.props;
    /*const changeLanguage = (lng) => {
      //console.log(lng)
      i18n.changeLanguage(lng);
      document.title = t("tickets.title");
    };*/
    if (this.props.userStore.user) {
      if (this.props.ticketsStore.isLoading) {
        return <div>{t("processing")}</div>;
      }
      return (
        <>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <div className="row ticket-title">
                <h4>{t("tickets.title")}</h4>
                <hr></hr>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="row ticket-row">
                    <p className="ticket-text">{t("tickets.requester")}</p>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="row ticket-row">
                    <MyInput
                      id="requester"
                      visible="false"
                      label="requester"
                      className={
                        "controlDisable form-control generic-input disabled"
                      }
                      name="requester"
                      value={this.props.ticketsStore.ticket.mail}
                      disabled="disabled"
                      placeholder={t("tickets.placeholders.requester")}
                    ></MyInput>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="row ticket-row">
                    <p className="ticket-text">{t("tickets.subject")}</p>
                    {this.props.ticketsStore.ticket.subject === "" ? (
                      <p style={{ color: "red" }}>*</p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="row ticket-row">
                    <MyInput
                      id="ticket.subject"
                      visible="false"
                      label="Name"
                      className={
                        "controlDisable form-control generic-input  editing ticket-input"
                      }
                      name="ticket.subject"
                      style={{
                        background: "none",
                        border: "1px solid #ced4da",
                      }}
                      value={this.props.ticketsStore.ticket.subject}
                      onChange={this.props.ticketsStore.updateNestedProperty}
                      placeholder={t("tickets.placeholders.subject")}
                    ></MyInput>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="row ticket-row">
                    <p className="ticket-text">{t("tickets.priority.text")}</p>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="row ticket-row">
                    <MySelect
                      selectName="priorityFilter.toOption"
                      selectClass="select"
                      selectedOption={
                        this.props.ticketsStore.priorityFilter.toOption
                      }
                      onChange={(attrName, value) => {
                        this.props.ticketsStore.updateNestedProperty(
                          attrName,
                          value
                        );
                        this.props.ticketsStore.updateNestedProperty(
                          "ticket.priority",
                          value
                        );
                      }}
                      selectOptions={
                        this.props.ticketsStore.priorityFilter.selectOptions
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="row ticket-row">
                    <p className="ticket-text">{t("tickets.description")}</p>
                    {this.props.ticketsStore.ticket.description === "" ? (
                      <p style={{ color: "red" }}>*</p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="row ticket-row">
                    <div id="editor" className="form-control input-contact info-contact">
                    </div>
                    {/*<MyTextArea
                      id="ticket.description"
                      label="Description"
                      name="ticket.description"
                      className="form-control input-contact info-contact"
                      placeholder={t("tickets.placeholders.description")}
                      value={this.props.ticketsStore.ticket.description}
                      rows="5"
                      onChange={this.props.ticketsStore.updateNestedProperty}
                    />*/}
                  </div>
                </div>
              </div>
              <div className="row" style={{ float: "right" }}>
                {/*<form
                    id="uploadForm"
                    action="upload_file"
                    role="form"
                    method="post"
                    enctype="multipart/form-data"
                  >
                    <input type="file" id="file" name="file" onChange={this.props.ticketsStore.onFileChange}/>
                    </form>*/}
                {this.props.ticketsStore.allValuesOnTicket ? (
                  <></>
                ) : (
                  <p style={{ color: "red", "marginRight": "1rem" }}>
                    {t("tickets.errorEmpty")}
                  </p>
                )}
                {this.props.ticketsStore.ticketSended ? (
                  <p style={{ color: "green", "marginRight": "1rem" }}>
                    {t("tickets.sent")}
                  </p>
                ) : (
                  <></>
                )}
                <MyButton
                  type="button"
                  className="btn btn-info"
                  text={t("tickets.send")}
                  onClick={() => {
                    this.props.ticketsStore.sendTicket();
                  }}
                >
                  {t("tickets.send")}
                </MyButton>
              </div>
            
            </div>
            <div className="col-md-3"></div>
          </div>
        </>
      );
    } else return null;
  }
}
export default withTranslation()(Tickets);
