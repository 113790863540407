import React, { Component } from "react";
// import { observable, action, computed, configure } from "mobx";
import { observer, inject } from "mobx-react";
import SubmitButton from "../basicUI/SubmitButton"
import InputField from '../basicUI/InputField'
import logo from '../../assets/icons/logo_stl_pro.svg'
import background from '../../assets/img/screen-login.jpg'

// configure({
//     enforceActions: 'observed'
// });
@inject("resetPasswordStore")
@observer
class ResetPassword extends Component {

  async componentDidMount() {
    try {
      document.title = 'STLPRO - Reset Password'
      this.props.resetPasswordStore.updateProperty('token', this.props.match.params.token)
      await this.props.resetPasswordStore.getResetPassword()
      document.getElementById("boop").style["backgroundImage"] = `url(${background})`
      document.getElementById("boop").style["backgroundAttachment"] = "fixed"
      document.getElementById("boop").style["backgroundPosition"] = "center top"
    } catch (error) {
      if (error.toString() === "Error")
        console.log("Authentication failed");
    }
  }

  componentWillUnmount() {
    document.getElementById("boop").style["backgroundImage"] = ""
    document.getElementById("boop").style["backgroundAttachment"] = ""
    document.getElementById("boop").style["backgroundPosition"] = ""
  }

  render() {
    if (this.props.resetPasswordStore.isLoading) {
      return (
        <div>Processant...</div>
      );
    }
    if (this.props.resetPasswordStore.passwordHasBeenUpdated || this.props.resetPasswordStore.showError) {
      document.getElementById('fields').style.display = 'none'
    }
    return (
      <main className="container-fluid">
        <div className="row">
          <div className="col-md-3 login">
            <div className="card card-login">
              <div className="card-body">
                <form onSubmit={this.props.resetPasswordStore.updatePassword}>
                  <img className="icon-login" src={logo} width="100%" alt="logo" />
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      {this.props.resetPasswordStore.passwordHasBeenUpdated && (
                        <div id="rightmessage">
                          <p>La contrasenya s'ha actualitzat correctament.
                          <br />
                            <br />Si us plau, cliqui <a href="/login">aquí</a> per iniciar sessió.
                            </p>
                        </div>
                      )}
                      {(this.props.resetPasswordStore.showError) && (
                        <div >
                          <p style={{ color: "red" }}>Hi ha hagut un problema amb el canvi de contrasenya.</p>
                          <p >Si us plau, cliqui <a href="/forgotPassword">aquí</a> per tornar a demanar un enllaç i intentar-ho de nou.</p>
                        </div>
                      )}
                      <div className="login-fields" id="fields">
                        <InputField type="password" name="password" label="Nova contrasenya" value={this.props.resetPasswordStore.resetForm.password} onChange={this.props.resetPasswordStore.updateFormProperty} />
                        <InputField type="password" name="confirmPassword" label="Confirmi la nova contrasenya" value={this.props.resetPasswordStore.resetForm.confirmPassword} onChange={this.props.resetPasswordStore.updateFormProperty} />
                        <SubmitButton text="Desar" />
                        <br />
                        <br />
                        <br />
                        {!this.props.resetPasswordStore.formIsValid && (
                          <p id="letter" className="invalid" style={{ color: "red" }}>
                            Si us plau, assegura't que es compleixen totes les condicions llistades abaix.
                          </p>
                        )}
                        <span>La nova contrasenya ha de contenir com a mínim:</span>
                        <br />
                        <br />
                        <p align="left" style={{ fontSize: "0.9rem", marginLeft: "1.9rem" }} id="letter">Una lletra <b style={this.props.resetPasswordStore.hasLetter ? { color: "green" } : { color: "" }} >minúscula</b></p>
                        <p align="left" style={{ fontSize: "0.9rem", marginLeft: "1.9rem" }} id="capital">Una lletra <b style={this.props.resetPasswordStore.hasCapital ? { color: "green" } : { color: "" }}>majúscula</b></p>
                        <p align="left" style={{ fontSize: "0.9rem", marginLeft: "1.9rem" }} id="number">Un <b style={this.props.resetPasswordStore.hasNumber ? { color: "green" } : { color: "" }}>número</b></p>
                        <p align="left" style={{ fontSize: "0.9rem", marginLeft: "1.9rem" }} id="specialChar">Un <b style={this.props.resetPasswordStore.hasSpecialChar ? { color: "green" } : { color: "" }}>caràcter especial</b></p>
                        <p align="left" style={{ fontSize: "0.9rem", marginLeft: "1.9rem" }} id="length">Mínim <b style={this.props.resetPasswordStore.hasLength ? { color: "green" } : { color: "" }}>8 caràcters</b></p>
                        <p align="left" style={{ fontSize: "0.9rem", marginLeft: "1.9rem", color: this.props.resetPasswordStore.passwordsMatch ? "green" : "" }} id="passwordsMatch" className={this.props.resetPasswordStore.passwordsMatch ? "is-valid" : ""}>Les dues contrasenyes han de ser iguals</p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default ResetPassword;
